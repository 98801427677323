export const professionalSituationList = [
	{
		codigo: 'CUENTA_AJENA',
		descripcion: 'Cuenta ajena'
	},
	{
		codigo: 'CUENTA_PROPIA',
		descripcion: 'Cuenta propia-autónomo'
	},
	{
		codigo: 'DESEMPLEADO',
		descripcion: 'Desempleado'
	},
	{
		codigo: 'PENSIONISTA',
		descripcion: 'Pensionista'
	},
	{
		codigo: 'RENTISTA',
		descripcion: 'Rentista'
	},
	{
		codigo: 'ESTUDIANTE',
		descripcion: 'Estudiante'
	},
	{
		codigo: 'OTROS',
		descripcion: 'Otros'
	}
].map((x) => ({ label: x.descripcion, value: x.codigo }));
