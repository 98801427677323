import { useEffect, useState } from 'react';

import { AnalyticsWrapper, BackendWrapper, OperationsWrapper } from '@coinscrap/webapp-core';

import { getCurrency, setCurrency } from 'libs/Currency';

export const useSimulation = (
	craInstitution,
	targetId,
	targetType,
	contributionData,
	user,
	capitalMaximum,
	capitalMinimum,
	onError,
	trigger
) => {
	const { createOperation } = OperationsWrapper.use();
	const { emitEvent } = AnalyticsWrapper.use();
	const { getInstanceApi } = BackendWrapper.use();
	const apiInstance = getInstanceApi();

	// QUOTE ID
	const [quoteSimulationId, setQuoteSimulationId] = useState('');

	// PERIOD
	const [period, setPeriod] = useState(contributionData?.chargePeriod || 'anual');

	// DISABILITY COVERAGE

	//cobertura por incapacidad permanente absoluta
	const [disabilityCoverage, setDisabilityCoverage] = useState(
		contributionData?.additionalCoverages?.find((x) => x === 98) || false
	);

	//cobertura por incapacidad permanente absoluta por accidente
	const [disabilityCoverageAccident, setDisabilityCoverageAccident] = useState(
		contributionData?.additionalCoverages?.find((x) => x === 99) || false
	);

	// CAPITAL
	// const [capital, setCapital] = useState(contributionData?.amountToCover || 80000);
	const [capital, setCapital] = useState(setCurrency(getCurrency(contributionData?.amountToCover, 0, true)) || 80000);

	useEffect(() => {
		if (capital > capitalMaximum) {
			setCapital(capitalMaximum);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [isValidCapital, setIsValidCapital] = useState(true);
	const [invalidCapitalReason, setInvalidCapitalReason] = useState(
		'El capital máximo asegurable es de ' + getCurrency(capitalMaximum, 0)
	);

	// RECIBO
	const [invoice, setInvoice] = useState(
		setCurrency(getCurrency(contributionData?.periodicContribution, 0, true)) || 0
	);

	const [valueChanged, setValueChanged] = useState({ type: undefined });

	const [loading, setLoading] = useState('Calculando importe');
	const [credentialsValid, setCredentialsValid] = useState();
	const [institutionAccountId, setInstitutionAccountId] = useState(undefined);

	// Crear la institution si no existe
	useEffect(() => {
		if (!institutionAccountId) {
			(async () => {
				const contributtionData = await createOperation(
					'CREATE_INSTITUTION_ACCOUNT',
					{
						institutionIdentifier: craInstitution.identifier
					},
					undefined,
					undefined,
					{ userId: user.id }
				);
				setInstitutionAccountId(contributtionData.institutionAccountId);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Validar credenciales
	useEffect(() => {
		if (institutionAccountId) {
			(async () => {
				const contributtionData = await createOperation(
					'VALIDATE_CREDENTIALS',
					{
						institutionAccountId: institutionAccountId,
						payload: {
							value: {
								authenticationMethodIdentifier: 'none',
								authenticationMethodFieldsValue: {}
							}
						}
					},
					undefined,
					undefined,
					{ userId: user.id }
				);
				// eslint-disable-next-line no-console
				console.log('🧿 VALIDATE_CREDENTIALS', contributtionData);
				setCredentialsValid(true);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [institutionAccountId]);

	const doSimulation = async (institutionAccountId) => {
		if (!institutionAccountId) return;
		try {
			setLoading('Calculando importe');

			const periodMap = {
				mensual: 'MONTHLY',
				MONTHLY: 'MONTHLY',
				trimestral: 'QUARTERLY',
				QUARTERLY: 'QUARTERLY',
				semestral: 'SEMIANNUALLY',
				SEMIANNUALLY: 'SEMIANNUALLY',
				anual: 'YEARLY',
				YEARLY: 'YEARLY'
			};
			const policyConfiguration = {
				chargePeriod: periodMap[period],
				additionalCoverages: []
			};

			if (disabilityCoverage) {
				policyConfiguration.additionalCoverages.push(98); // 98 → Código cobertura de Caser para invalidez.
			}

			if (disabilityCoverage && disabilityCoverageAccident) {
				policyConfiguration.additionalCoverages.push(99); // 99 → Código cobertura de Caser para invalidez por accidente.
			}

			if (valueChanged.type === 'invoice') {
				// console.log('💥 invoice', invoice);
				// console.log('💥 setCurrency(invoice)', setCurrency(invoice));
				policyConfiguration.periodicContribution = setCurrency(invoice);
			} else {
				// console.log('🏀 capital', capital);
				// console.log('🏀 setCurrency(capital)', setCurrency(capital));
				policyConfiguration.amountToCover = setCurrency(capital);
			}

			let contributtionData;
			try {
				contributtionData = await apiInstance.saveTargetContributionData(targetId, policyConfiguration);
			} catch (e) {
				setCapital(policyConfiguration?.amountToCover || capitalMaximum);
				setInvoice(policyConfiguration?.periodicContribution || 0);

				onError && onError(e);
				return;
			}

			// eslint-disable-next-line no-console
			console.log('✅ SIMULATE_PRODUCT', contributtionData);

			// TEALIUM EVENT
			emitEvent({
				tealium_event: 'quoter_calculate_success',
				billing_cycle: period,
				company_id: '0001',
				protocol_id: '36000367',
				product_id: '53102',
				product_name: 'CASER VIDA STARBIEN',
				product_price: policyConfiguration?.amountToCover || capitalMaximum,
				insurance_collective_number: '36000367',
				insurance_individual_number: 'xxxxxx',
				quote_simulation_id: contributtionData.id
			});
			setQuoteSimulationId(contributtionData.id);

			// CAPITAL
			// setCapital(contributtionData?.value?.amountToCover);
			setCapital(getCurrency(contributtionData?.value?.amountToCover, 0, true) || getCurrency(capitalMaximum, 0, true));

			// IS VALID CAPITAL
			setIsValidCapital(
				contributtionData?.value?.amountToCover !== '' &&
				contributtionData?.value?.amountToCover <= capitalMaximum &&
				contributtionData?.value?.amountToCover >= capitalMinimum
			);

			// INVALID REASON
			if (contributtionData?.value?.amountToCover > capitalMaximum) {
				setInvalidCapitalReason('El capital máximo asegurable es de ' + getCurrency(capitalMaximum, 0));
			} else if (
				contributtionData?.value?.amountToCover === '' ||
				contributtionData?.value?.amountToCover < capitalMinimum
			) {
				setInvalidCapitalReason('El capital mínimo asegurable es de ' + getCurrency(capitalMinimum, 0));
			}

			// setInvoice(contributtionData?.value?.periodicContribution);
			setInvoice(getCurrency(contributtionData?.value?.periodicContribution, 2, true) || getCurrency(0, 0, true));
		} catch (e) {
			onError && onError(e);
		} finally {
			setLoading(undefined);
		}
	};

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (credentialsValid && institutionAccountId) {
			const timeOutId = setTimeout(() => {
				doSimulation(institutionAccountId);
			}, 2000);
			return () => clearTimeout(timeOutId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueChanged, credentialsValid, institutionAccountId, trigger]);

	return [
		loading,
		(newLoading) => {
			setLoading(newLoading);
		},
		invoice,
		(newInvoice) => {
			setInvoice(+newInvoice);
			setValueChanged({ type: 'invoice' });
		},
		capital,
		(newCapital) => {
			setCapital(+newCapital);
			setValueChanged({ type: 'capital' });
		},
		isValidCapital,
		(newValidCapital) => {
			setIsValidCapital(newValidCapital);
		},
		invalidCapitalReason,
		(newInvalidCapitalReason) => {
			setInvalidCapitalReason(newInvalidCapitalReason);
		},
		period,
		(newPeriod) => {
			setPeriod(newPeriod);
			setValueChanged({ type: 'period' });
		},
		disabilityCoverage,
		(newDisabilityCoverage) => {
			setDisabilityCoverage(newDisabilityCoverage);
			setValueChanged({ type: 'disabilityCoverage' });
		},
		disabilityCoverageAccident,
		(newDisabilityCoverageAccident) => {
			setDisabilityCoverageAccident(newDisabilityCoverageAccident);
			setValueChanged({ type: 'disabilityCoverageAccident' });
		},
		quoteSimulationId
	];
};
