import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
    Button,
    NavigationWrapper,
    PickerHOC,
    SessionWrapper,
    TargetTypesWrapper,
    Text,
    UiWrapper,
    UserWrapper,
    View
} from '@coinscrap/webapp-core';

import Header from 'layout/components/Header/Header';
import { CONFIG } from '../../config/config';
import { useAgentSimulationActions } from '../../libs/hooks/useAgentSimulationActions';
import { useInitialScrollToTop } from '../../libs/hooks/useInitialScrollToTop';
import DashboardFilters from './components/Filters/Filters';
import { GlobalAgent } from './components/GlobalAgent/GlobalAgent';
import { NormalAgent } from './components/NormalAgent/NormalAgent';
import { DashboardContext } from './contexts/Dashboard';
import { DashboardFiltersContext } from './contexts/DashboardFilters';

import { Wrapper } from 'layout/Wrapper';

export const Dashboard = PickerHOC({
	agentUser: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	},
	targetType: {
		arrayItemMatcher: {
			itemPropertyPath: 'identifier',
			type: 'value',
			value: 'CASER_VIDA_RIESGO'
		},
		sourcePath: 'targetTypes',
		sourceWrapper: TargetTypesWrapper
	}
})(() => {
	const { query } = NavigationWrapper.use();
	const { useHeader, useLoading } = UiWrapper.use();
	const { agentUser, targetType } = PickerHOC.use();
	const { clearSession } = SessionWrapper.use();

	const [isLocalDev, setIsLocalDev] = useState(false);
	const [loading, setLoading] = useState();

	const [autoNewSimulation, setAutoNewSimulation] = useState(query?.new === 'true' || false);

	const agentOffice = useMemo(() => agentUser?.identityProviders[0].metadata?.ptv || undefined, [agentUser]);

	const { newSimulationHandler, accessSimulationHandler } = useAgentSimulationActions(
		targetType.id,
		setLoading,
		agentOffice
	);
	const [start, setstart] = useState(false);

	const agentRole = useMemo(() => agentUser?.roles[0]?.identifier || undefined, [agentUser]);
	const { setAgent, setIsGlobal, resetFilterValues } = useContext(DashboardContext);
	const { updateEndDateValue } = useContext(DashboardFiltersContext);

	const refreshSimulations = () => {
		updateEndDateValue();
		resetFilterValues();
	};

	useHeader(<Header isAgentView onClickContactButton={() => clearSession()} textButton={'Cerrar sesión'} />, []);
	useLoading(loading, { loadingMessage: loading });
	useInitialScrollToTop();

	useEffect(() => {
		const startDash = async () => {
			setAgent(agentUser);
			setIsGlobal(agentRole);

			if (window.location.hostname === 'webapp.localhost') {
				setIsLocalDev(true);
			}

			setstart(true);
		};

		startDash();
	}, [agentUser, agentRole, setAgent, setIsGlobal]);

	useEffect(() => {
		if (query?.new) {
			setAutoNewSimulation(query?.new === 'true' || false);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query?.new]);

	useEffect(() => {
		const autoNewSimu = async () => {
			try {
				let receivedData = query?.data || '';
				// console.log('receivedData', receivedData);
				let decoded64 = Buffer.from(receivedData, 'base64');
				// console.log('decoded64', decoded64);
				let receivedDataObj = JSON.parse(decoded64);
				// console.log('receivedDataObj', receivedDataObj);

				newSimulationHandler(CONFIG.project.agent.addReceivedData ? receivedDataObj : undefined);
			} catch (e) {
				newSimulationHandler(undefined);
			}
		};

		if (start && autoNewSimulation) {
			//eslint-disable-next-line
			autoNewSimu().catch(console.error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoNewSimulation, start]);

	return (
		<Wrapper>
			<Text headingPrimarySt>Listado de pólizas</Text>
			{isLocalDev && <Text style={{ alignSelf: 'center' }}>{agentRole}</Text>}

			<View rowSt justifyBetweenSt style={{ maxWidth: 1300, marginTop: 30 }}>
				<Button style={{ marginBottom: 20 }} fixedWidthSt onClick={newSimulationHandler}>
					Nueva simulación
				</Button>

				<Button style={{ marginBottom: 20 }} fixedWidthSt onClick={refreshSimulations}>
					Actualizar simulaciones
				</Button>
			</View>

			<DashboardFilters />

			<View
				dahboardFullCenterSt
				style={
					{
						// backgroundColor: 'green',
					}
				}
			>
				{start && (
					<>
						{agentRole === 'GLOBAL_AGENT' ? (
							<GlobalAgent accessSimulation={accessSimulationHandler} />
						) : (
							<NormalAgent accessSimulation={accessSimulationHandler} />
						)}
					</>
				)}
			</View>
		</Wrapper>
	);
});
