import { isMobile } from 'react-device-detect';

import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from '../config';
import commonStyles from './common';

UiWrapper.defineMedia('isTripleRowMq', '(max-width: 700px)');
UiWrapper.defineMedia('isElementAroundFlexMq', '(max-width: 850px)');

UiWrapper.defineComponent('view', {
	styles: {
		default: {
			width: '100%',
			color: CONFIG.colors.black,
			boxSizing: 'border-box',
			fontFamily: CONFIG.fonts.regular
		},

		// LOADING MODAL
		loadingModalSt: {
			display: 'flex',
			height: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: CONFIG.colors.loadingModalBackground
		},

		totalCenterSt: {
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		//Double grid columns for web. One-and-one grid colum for mobile devices.
		inputRowSt: {
			alignItems: 'flex-start',
			display: 'grid',
			marginBottom: 8,
			gap: isMobile ? 50 : 20,
			gridTemplateColumns: isMobile ? 'repeat(1, minmax(0, 1fr))' : 'repeat(2, minmax(0, 1fr))',
			rowGap: isMobile ? 5 : 25,
			width: isMobile ? '100%' : '80%'
		},

		//Triple grid columns for web. One-and-one grid colum for mobile devices.
		tripleInputRowSt: {
			$mediaQueries: {
				isTripleRowMq: {
					gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
				}
			},
			alignItems: 'flex-start',
			display: 'grid',
			marginBottom: 27,
			gap: isMobile ? 50 : 20,
			gridTemplateColumns: isMobile ? 'repeat(1, minmax(0, 1fr))' : 'repeat(3, minmax(0, 1fr))',
			rowGap: isMobile ? 5 : 25,
			width: isMobile ? '100%' : '80%'
		},

		callToActionSt: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			marginBottom: 15,
			gap: 16,
			width: '100%'
		},

		//Spacing around flex wrapper
		elementsAroundFlexSt: {
			$mediaQueries: {
				isElementAroundFlexMq: {
					flexFlow: 'row wrap',
					gap: 8
				}
			},
			display: 'flex',
			flexDirection: 'row',
			flexWrap: isMobile ? 'wrap' : 'nowrap',
			justifyContent: 'space-around',
			gap: 16,
			width: 'fit-content'
		},
		//View showing that the entered capital is invalid in Simulation.jsx
		invalidCapitalSt: {
			alignSelf: 'center',
			backgroundColor: CONFIG.colors.error,
			color: CONFIG.colors.danger,
			marginBottom: 10,
			maxWidth: 350,
			fontSize: 16,
			textAlign: 'center',
			padding: 10
		},
		//Terms, conditions and checks wrapper
		conditionsWrapperSt: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: isMobile ? 'center' : 'center',
			width: '100%',
			cursor: 'pointer'
		},
		//Add and show beneficiary
		beneficiarySt: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			marginTop: 40,
			width: isMobile ? '100%' : '80%'
		},
		//PCB and Health questionnaries
		questionnariesSt: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginTop: 10,
			width: '80%'
		},
		//PBC and Health wrapper
		wrapperQuestionnariesSt: {
			alignItems: 'center',
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'space-between',
			width: '70%'
		},
		//PBC and Health text wrapper
		wrapperTextQuestionnariesSt: {
			flexDirection: 'row',
			justifyContent: 'flex-start'
		},
		//PBC and Health buttons wrapper
		wrapperButtonsQuestionnariesSt: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			marginLeft: isMobile ? 5 : 30,
			width: 'initial'
		},
		//Bank Account's banner
		bannerBankAccountSt: {
			backgroundColor: CONFIG.colors.primaryLighter,
			fontFamily: CONFIG.fonts.bold,
			fontSize: 20,
			marginBottom: 10,
			marginTop: isMobile ? 5 : 20,
			padding: '20px',
			textAlign: 'center',
			width: '110%'
		},
		wrapperStepSt: {
			alignItems: 'flex-start',
			display: 'flex',
			flexDirection: 'column',
			marginTop: 20,
			width: 'initial'
		},
		itemStepSt: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			marginBottom: 15,
			marginTop: 20,
			width: 'initial'
		},
		//Agent Dashboard
		agentTableRowSt: {
			display: 'grid',
			backgroundColor: CONFIG.colors.primaryLighter,
			gap: 20,
			gridTemplateColumns: 'repeat(2, minmax(0, 1fr)) 175px 175px 150px 100px minmax(0, 1fr)',
			marginBottom: 25,
			padding: '20px 50px',
			width: '100%'
		},
		isGlobalAgentTableRowSt: {
			display: 'grid',
			backgroundColor: CONFIG.colors.primaryLighter,
			gap: 20,
			gridTemplateColumns: 'repeat(2, minmax(0, 1fr)) 175px 175px 150px minmax(0, .5fr) 100px minmax(0, 1fr)',
			marginBottom: 25,
			padding: '20px 50px',
			width: '100%'
		},
		//Wrapper for FollowUp view
		progressWrapperSt: {
			alignItems: 'stretch',
			display: 'flex',
			flexDirection: 'row',
			marginBottom: isMobile ? 10 : 0,
			marginTop: isMobile ? 40 : 40,
			width: 'initial'
		},
		progressTextWrapperSt: {
			alignItems: 'baseline',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-around',
			marginLeft: 30,
			width: 'initial'
		},
		//Progress bar for FollowUp view
		followUpSt: {
			backgroundColor: CONFIG.colors.tertiaryLight,
			borderRadius: 45,
			display: 'flex',
			flexDirection: 'column',
			height: 470,
			position: 'relative',
			width: 32
		},
		//Dashboard follow up non-active dots
		dotsSt: {
			backgroundColor: CONFIG.colors.tertiary,
			borderRadius: '50%',
			height: 16,
			width: 16
		},
		//Dashboard follow up active dots
		activeDotsSt: {
			backgroundColor: CONFIG.colors.secondary,
			borderRadius: '50%',
			height: 16,
			width: 16
		},
		//Buttons wrapper Back and Next
		backNextWrapperSt: {
			display: 'flex',
			justifyContent: 'space-around',
			gap: 16,
			width: 'initial'
		},
		//Main header
		headerSt: {
			alignItems: 'center',
			backgroundColor: 'white',
			borderBottom: '1px solid rgba(241, 212, 190, 0.4)',
			boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-around',
			height: 95,
			position: 'fixed',
			top: 0,
			width: '100%',
			zIndex: 5
		},

		//Buttons wrapper modal agent notification
		notificationWrapperSt: {
			display: 'flex',
			flexDirection: isMobile ? 'column' : 'row',
			justifyContent: 'space-around',
			gap: 16,
			marginTop: 30,
			width: 'initial'
		},

		marginTopSt: {
			marginTop: isMobile ? 10 : 40
		},

		marginTopMobileSt: {
			marginTop: isMobile ? 40 : 10
		},

		marginBottomMobileSt: {
			marginBottom: isMobile ? 15 : 0
		},

		//REVISAR HACIA ABAJO
		alignItemsSt: {
			alignItems: 'end'
		},

		horizontalSt: {
			flexDirection: 'row'
		},

		startSt: {
			justifyContent: 'flex-start'
		},

		endSt: {
			justifyContent: 'flex-end'
		},

		rowSt: {
			flexDirection: 'row'
		},

		spaceBetweenSt: {
			justifyContent: 'space-between'
		},

		bottomSt: {
			alignItems: 'flex-end'
		},

		fullHeightSt: {
			height: '100%'
		},

		fullWidthSt: {
			width: '100%'
		},

		fullWidthMobileSt: {
			width: isMobile && '100%'
		},
		...commonStyles.styles
	}
});
