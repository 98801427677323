import React from 'react';

import {
    ApplicationWrapper,
    BackendWrapper,
    NavigationWrapper,
    SessionWrapper,
    UiWrapper
} from '@coinscrap/webapp-core';

import routes from 'config/routes';

import config from '../config';
import { EntityWrappers } from './WrapEntitiesHandlers';

const WrappersComponent = ({ children }) => {
	return (
		<ApplicationWrapper>
			<BackendWrapper>
				<UiWrapper config={config}>
					<NavigationWrapper>
						<SessionWrapper
							entryPoint={{
								pageIfValid: routes.loginAgent,
								pageIfInvalid: routes.loginAgent
							}}
						>
							<EntityWrappers>{children}</EntityWrappers>
						</SessionWrapper>
					</NavigationWrapper>
				</UiWrapper>
			</BackendWrapper>
		</ApplicationWrapper>
	);
};

export default WrappersComponent;
