import { isMobile } from 'react-device-detect';

import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from '../config';

UiWrapper.defineComponent('input', {
	styles: {
		default: {
			border: 'none',
			color: CONFIG.colors.tertiary,
			height: '100%',
			width: 'auto',

			fontFamily: CONFIG.fonts.bold,
			fontSize: isMobile ? '18px' : '26px',
			textAlign: 'center'
		}
	}
});
