import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';

UiWrapper.defineComponent('inputSlider', {
	styles: {
		default: {
			railComponent: {
				height: 5,
				backgroundColor: '#979797',
				borderRadius: 15
			},
			trackComponent: {
				height: 5,
				backgroundColor: CONFIG.colors.success,
				borderRadius: 15
			},
			thumbComponent: {
				width: 42,
				height: 42,
				marginTop: -21,
				color: '#ffffff',
				backgroundColor: '#ffffff',
				borderWidth: 18,
				borderColor: CONFIG.colors.success,
				borderStyle: 'solid'
			}
		}
	}
});
