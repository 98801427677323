import React from 'react';

import { CONFIG } from 'config/config';

export default function InfoRedImg({ style }) {
	return (
		<svg
			style={{ ...style, flexShrink: 0 }}
			width="16"
			height="16"
			viewBox="0 0 12 12"
			fill={CONFIG.colors.secondary}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Group 23">
				<rect id="Rectangle" width="12" height="12" fill={CONFIG.colors.secondary} fillOpacity="0.01" />
				<path
					id="Combined Shape"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1 6C1 3.24292 3.24292 1 6 1C8.75708 1 11 3.24292 11 6C11 8.75708 8.75708 11 6 11C3.24292 11 1 8.75708 1 6ZM1.5 6C1.5 8.4812 3.5188 10.5 6 10.5C8.4812 10.5 10.5 8.4812 10.5 6C10.5 3.5188 8.4812 1.5 6 1.5C3.5188 1.5 1.5 3.5188 1.5 6ZM6 3C5.86182 3 5.75 3.11182 5.75 3.25V3.75C5.75 3.88818 5.86182 4 6 4C6.13818 4 6.25 3.88818 6.25 3.75V3.25C6.25 3.11182 6.13818 3 6 3ZM5.75 5.25C5.75 5.11182 5.86182 5 6 5C6.13818 5 6.25 5.11182 6.25 5.25V8.75C6.25 8.88818 6.13818 9 6 9C5.86182 9 5.75 8.88818 5.75 8.75V5.25Z"
					fill={CONFIG.colors.secondary}
				/>
			</g>
		</svg>
	);
}
