import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
	AnalyticsWrapper,
	Button,
	EnvelopesWrapper,
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';

import arrowRigth from 'assets/images/svgs/arrowRigth.svg';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { SpanCallUs } from 'config/components/span';
import Header from 'layout/components/Header/Header';

import { Wrapper } from 'layout/Wrapper';

import * as S from './stylesB2C';

const getPeriod = (period) => {
	switch (period) {
		case 'semestral':
		case 'SEMIANNUALLY':
			return 'sem';
		case 'trimestral':
		case 'QUARTERLY':
			return 'tri';
		case 'mensual':
		case 'MONTHLY':
			return 'mes';
		case 'anual':
		case 'YEARLY':
			return 'anual';
		default:
			return 'mes';
	}
};

export const ProductCreationFinishedB2C = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		},
		envelope: {
			sourcePath: 'envelopes',
			sourceWrapper: EnvelopesWrapper,
			arrayItemMatcher: {
				type: 'param',
				paramName: 'envelopeId',
				itemPropertyPath: 'id'
			}
		}
	},
	LoadingScreen
)(() => {
	const { goBack } = NavigationWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { pageView, emitEvent, updateData } = AnalyticsWrapper.use();
	const { getPreSignedCombinedSignedEnvelopeUrl } = EnvelopesWrapper.use();
	const [loading, setLoading] = useState();
	const { envelope, target, user } = PickerHOC.use();

	const creationData = target?.targetProperties?.find((x) => x.name === 'creationData')?.value;
	const contributionData = target?.targetProperties?.find((x) => x.name === 'contributionData')?.value;
	const [urlSigned, setUrlSigned] = useState();
	useEffect(() => {
		async function fetchPreSignedUrl() {
			const { url: preSignedDocumentUrl } = await getPreSignedCombinedSignedEnvelopeUrl(envelope?.id);
			setUrlSigned(preSignedDocumentUrl);
		}
		fetchPreSignedUrl();
	}, [envelope, getPreSignedCombinedSignedEnvelopeUrl]);

	const downloadDoc = (url) => {
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `doc.pdf`);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};

	const next = async () => {
		let msg = 'El proceso ha terminado, puedes cerrar la ventana.';
		setLoading(undefined);
		openModal(
			() => (
				<ErrorModal>
					<Text headingPrimarySt>{msg}</Text>
				</ErrorModal>
			),
			{
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-finish'
			}
		);
	};

	useEffect(() => {
		// TEALIUM PAGE VIEW
		const tealiumData = {
			page_type: 'payment confirmation',
			process_name: 'particulares simulador riesgo vida',
			process_step: 'cierre contratacion',
			process_type: 'contratacion'
		};

		pageView({
			pageType: tealiumData.page_type,
			processName: tealiumData.process_name,
			processStep: tealiumData.process_step,
			processType: tealiumData.process_type
		});

		updateData(tealiumData);

		// TEALIUM EVENT
		emitEvent({
			tealium_event: 'quoter_purchase_success',
			billing_cycle: getPeriod(contributionData?.chargePeriod),
			company_id: '0001',
			protocol_id: '36000367',
			product_id: '53102',
			product_name: 'CASER VIDA STARBIEN',
			product_price: contributionData.periodicContribution,
			insurance_collective_number: '36000367',
			product_certificate_id: 'xxxxxx',
			order_id: creationData.quoteSimulationId,
			transaction_id: 'xxxxxx'
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);
	useLoading(loading, { loadingMessage: loading });

	return (
		<Wrapper width={'80%'}>
			<Text headingPrimarySt>¡Enhorabuena! Contrato creado con éxito</Text>
			<S.StepWrapper>
				<S.StepWrapperItem>
					<S.StepCircle>
						<img src={arrowRigth} alt={'arrow icon'} />
					</S.StepCircle>
					<Text manualContractSt>Recibirás un correo electrónico con toda la información sobre tu póliza.</Text>
				</S.StepWrapperItem>
				<S.StepWrapperItem>
					<S.StepCircle>
						<img src={arrowRigth} alt={'arrow icon'} />
					</S.StepCircle>
					<Text manualContractSt>
						Dispones de un plazo de 30 días naturales, a contar desde la fecha de contratación, para desistir del
						presente seguro de vida, en cuyo caso se te reintegrará el importe de la prima cobrada sin aplicación de
						penalización alguna. Para ello, puedes llamar al 915909738.
					</Text>
				</S.StepWrapperItem>
				<S.StepWrapperItem>
					<S.StepCircle>
						<img src={arrowRigth} alt={'arrow icon'} />
					</S.StepCircle>
					<Text manualContractSt>
						Recuerda que dándote de alta en el{' '}
						<SpanCallUs>
							<a href={'https://www.caser.es/ecliente/acceso'}>Área de Clientes de Caser Seguros</a>
						</SpanCallUs>
						, tendrás a tu disposición toda la documentación en cualquier momento.
					</Text>
				</S.StepWrapperItem>
			</S.StepWrapper>

			<View style={{ marginTop: 30 }}>
				<Button selectedSt onClick={() => downloadDoc(urlSigned)}>
					DESCARGAR CONTRATO
				</Button>
			</View>
			<BackNextButton
				onClickNext={next}
				nextText={'FINALIZAR'}
				nextWidth={300}
				isBackShown={false}
				isNextDisabled={false}
				justifyContent={'center'}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
