import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FilePond, registerPlugin } from 'react-filepond';

import {
    IdentityDocumentsWrapper,
    NavigationWrapper,
    PickerHOC,
    TargetsWrapper,
    Text,
    UiWrapper,
    UserWrapper
} from '@coinscrap/webapp-core';

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import moment from 'moment';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import InputLabel from 'components/InputLabel/InputLabel';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import Header from 'layout/components/Header/Header';

import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';

import * as S from './styles';

//FILEPOND
registerPlugin(
	FilePondPluginFileEncode,
	FilePondPluginImageExifOrientation,
	FilePondPluginImagePreview,
	FilePondPluginFileValidateSize,
	FilePondPluginFileValidateType
);

var signatures = {
	JVBERi0: 'application/pdf',
	R0lGODdh: 'image/gif',
	R0lGODlh: 'image/gif',
	iVBORw0KGgo: 'image/png',
	'/9j/': 'image/jpg'
};

function detectMimeType(b64) {
	for (var s in signatures) {
		if (b64.indexOf(s) === 0) {
			return signatures[s];
		}
	}
}

export const IdentityBiometricExtraDocs = PickerHOC({
	dniDocuments: {
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	dniData: {
		arrayItemMatcher: {
			type: 'value',
			itemPropertyPath: 'main',
			value: true
		},
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	target: {
		sourceWrapper: TargetsWrapper,
		sourcePath: 'targets',
		arrayItemMatcher: {
			type: 'param',
			paramName: 'targetId',
			itemPropertyPath: 'id'
		}
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	}
})(() => {
	const {
		// eslint-disable-next-line no-unused-vars
		addIdentityDocumentData,
		createIdentityDocument,
		changeIdentityDocumentValidationMode,
		uploadIdentityDocumentImages
	} = IdentityDocumentsWrapper.use();

	const { target, user, dniDocuments } = PickerHOC.use();
	const { updateUser } = UserWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const issuingCountry = user?.metadata?.nationalityIDS?.toUpperCase();
	const [loading, setLoading] = useState();

	// CREATION DATA
	const creationData = target?.targetProperties?.find((x) => x.name === 'creationData')?.value;

	useEffect(() => {
		if (issuingCountry === 'ESP') {
			setLoading('El DNI del usuario es Español, no se necesita permiso de residencia...');
			navigateTo(routes.activateProduct);
		} else {
			(async () => {
				const residenceDNI = dniDocuments.find((d) => d.secondSurname === 'RESIDENCE');
				if (residenceDNI) {
					setLoading('Ya se ha añadido un permiso de residencia...');
					navigateTo(routes.activateProduct);
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// FILES
	const [files, setFiles] = useState([]);

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	// NAVIGATION
	const back = async () => {
		goBack();
	};

	const next = async () => {
		try {
			setLoading('Guardando...');
			await updateUser({
				metadata: {
					...user?.metadata,
					hasResidenceDoc: true
				}
			});
			//await new Promise((r) => setTimeout(r, 10000));
			setLoading('Actualizando');
			await saveTargetProperty(target.id, 'creationData', {
				...creationData,
				checks: { ...creationData.checks, hasResidenceDoc: true }
			});

			const newResidenceDNI = await createIdentityDocument('dni');
			await changeIdentityDocumentValidationMode(newResidenceDNI.id, true);

			if (files.length) {
				const filesProcessed = files.map((f) => {
					const bs64 = f.getFileEncodeBase64String();
					return 'data:' + detectMimeType(bs64) + ';base64,' + bs64;
				});
				await uploadIdentityDocumentImages(newResidenceDNI.id, true, filesProcessed[0], filesProcessed[1]);
			}

			await addIdentityDocumentData(
				newResidenceDNI.id,
				{
					number: user.metadata?.documentNumber,
					name: user.name,
					surname: user.surname,
					secondSurname: 'RESIDENCE',
					birthDate: moment(user?.metadata?.birthDate).toISOString(),
					gender: user.metadata?.gender === 'Male' ? 'M' : 'H',
					validDate: undefined
				},
				true
			);

			setLoading('Empezando el proceso...');
			navigateTo(routes.activateProduct);
		} catch (e) {
			let messageError = e.message.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-idsExtraDocs'
			});
		}
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);

	return (
		<Wrapper>
			<HorizontalProgressBar step={4} total={6} styleWrapper={{ maxWidth: 450 }} label={isB2B ? 'Firma' : ''} />
			<Text headingPrimarySt isB2B>
				Permiso de residencia
			</Text>
			<Text headingSecondarySt>
				Como has añadido un documento de identidad que no es Español necesitamos que adjuntes un permiso de residencia
				Español, por favor.
			</Text>
			<S.InnerWrapper>
				<InputLabel label={'Permiso de residencia'} type="custom">
					<FilePond
						server={{
							load: (source, load, error, progress, abort, headers) => {
								var myRequest = new Request(source);
								fetch(myRequest)
									.then(function (response) {
										response
											.blob()
											.then(function (myBlob) {
												//TODO HAY QUE GUARDAR CON MIME CORRECTO EN s3, porque si no lo que no sean imágenes no se verá bien.
												//https://github.com/pqina/filepond/issues/192#issuecomment-874459958
												//https://stackoverflow.com/questions/18998543/set-content-type-on-blob
												const finalblob = myBlob.slice(0, myBlob.size, 'image');
												load(finalblob);
											})
											.catch((e) => {
												//eslint-disable-next-line no-console
												console.log(e.message);
											});
									})
									.catch((e) => {
										//eslint-disable-next-line no-console
										console.log(e.message);
									});
							}
						}}
						//oninit={() => console.log('FILEPOND INIT')}
						instantUpload={false}
						required={true}
						name="files"
						labelIdle={`<span class="filepond--label-action">Añadir</span>`}
						files={files}
						storeAsFile={false}
						onupdatefiles={setFiles}
						allowFileEncode
						allowMultiple={true}
						maxFiles={2}
						allowImageExifOrientation={true}
						//File size validation
						allowFileSizeValidation={true}
						maxFileSize={'5MB'}
						maxTotalFileSize={'10MB'}
						labelMaxFileSizeExceeded={'El fichero es demasiado grande'}
						labelMaxFileSize={'El tamaño máximo es de {filesize}'}
						//File type validation
						allowFileTypeValidation={true}
						acceptedFileTypes={['image/*', 'application/pdf']}
						labelFileTypeNotAllowed={'Tipo de archivo no válido'}
						fileValidateTypeLabelExpectedTypes={'Se aceptan pdf o jpg'}
						credits={{}}
					/>
				</InputLabel>
			</S.InnerWrapper>
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				nextText={isB2B ? 'EMPEZAR' : 'CONTINUAR'}
				isBackShown={!isMobile}
				isNextDisabled={false}
				justifyContent={'evenly'}
			/>
		</Wrapper>
	);
});
