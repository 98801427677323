import React, { useEffect, useRef, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import {
    Button,
    IdentityDocumentsWrapper,
    NavigationWrapper,
    PickerHOC,
    Text,
    UiWrapper,
    UserWrapper,
    View
} from '@coinscrap/webapp-core';

import 'aliceonboarding/dist/aliceonboarding.css';
import Header from 'layout/components/Header/Header';
import { WrapperKYC } from '../../layout/Wrapper';
import VanillaJSONEditor from '../../libs/VanillaJSONEditor';
import { aliceOnError } from '../../libs/aliceOnError';
import { useGetAliceCertificate } from '../../libs/hooks/useGetAliceCertificate';

import { CONFIG } from 'config/config';
import routes from 'config/routes';
import { downloadDoc } from 'libs/downloadDoc';

import * as S from './styles';

//import IframeResizer from 'iframe-resizer-react';
export const KYCIdentityBiometric = PickerHOC({
	dniData: {
		arrayItemMatcher: {
			type: 'value',
			itemPropertyPath: 'main',
			value: true
		},
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	}
})(() => {
	const { dniData, user } = PickerHOC.use();
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const { navigateTo, goBack, query } = NavigationWrapper.use();
	const getAliceCertificate = useGetAliceCertificate();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, _] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	const [successFlow, setsuccessFlow] = useState(undefined);
	const [aliceCertUrl, setaliceCertUrl] = useState(undefined);

	useEffect(() => {
		const fetchCert = async () => {
			const clientAlicePdfCertificate = await getAliceCertificate(dniData?.id);
			setaliceCertUrl(clientAlicePdfCertificate?.url);
		};
		if (successFlow !== undefined) {
			//eslint-disable-next-line
			fetchCert().catch(console.error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [successFlow]);

	// ### DNI PROCESS
	const scanManaggerReference = useRef();
	let currentLang = 'es';

	const [showEditor, setShowEditor] = useState(false);

	useLoading(undefined, { loadingMessage: undefined });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={true} />, []);
	return (
		<WrapperKYC width={'80%'} widthMobile={'100%'}>
			{successFlow ? (
				<View>
					<Text headingPrimarySt errorSt>
						Proceso terminado correctamente
					</Text>
					{successFlow?.value?.data?.report?.user_id && (
						<>
							<Button
								selectedSt
								style={{ width: '100%', marginTop: 30, marginBottom: 0 }}
								onClick={() => {
									setShowEditor(showEditor ? false : true);
								}}
							>
								{showEditor ? 'Ocultar reporte' : 'Ver reporte'}
							</Button>

							{showEditor && (
								<View className="my-editor" style={{ minWidth: '100%', padding: 10 }}>
									<VanillaJSONEditor
										content={{
											json: successFlow?.value?.data?.report,
											text: undefined
										}}
										mode="tree"
										mainMenuBar={false}
										navigationBar={false}
										statusBar={false}
										readOnly={true}
									/>
								</View>
							)}
						</>
					)}
					{successFlow?.value?.data?.report?.user_id && aliceCertUrl && (
						<Button
							selectedSt
							style={{ width: '100%', marginTop: 30, marginBottom: 0 }}
							onClick={() => downloadDoc(aliceCertUrl, 'aliceCert_' + successFlow.value.data.report.user_id + '.pdf')}
						>
							Descargar certificado
						</Button>
					)}
					{successFlow?.value?.data?.report?.user_id && (
						<Button
							selectedSt
							style={{ width: '100%', marginTop: 30, marginBottom: 0 }}
							onClick={() => {
								window.open(
									'https://dashboard.alicebiometrics.com/#/client/coinscrap-caser-riesgo-kyc-dev/users/' +
										successFlow.value.data.report.user_id,
									'_blank'
								);
							}}
						>
							Ver en Alice
						</Button>
					)}
					<Button
						style={{ width: '100%', marginTop: 30, marginBottom: 0 }}
						onClick={() => {
							window.location.replace(window.location.origin + '/caser/seguro-vida?kyc=true');
						}}
					>
						Iniciar nuevo proceso con selfie y documento de identidad español
					</Button>
					<Button
						style={{ width: '100%', marginTop: 30, marginBottom: 0 }}
						onClick={() => {
							window.location.replace(window.location.origin + '/caser/seguro-vida?kyc=true&selfie=false');
						}}
					>
						Iniciar nuevo proceso sin selfie y documento de identidad español
					</Button>
					<Button
						style={{ width: '100%', marginTop: 30, marginBottom: 0 }}
						onClick={() => {
							window.location.replace(window.location.origin + '/caser/seguro-vida?kyc=true&docType=residencePermit');
						}}
					>
						Iniciar nuevo proceso con selfie y permiso de residencia español
					</Button>
					<Button
						style={{ width: '100%', marginTop: 30, marginBottom: 60 }}
						onClick={() => {
							window.location.replace(window.location.origin + '/caser/seguro-vida?kyc=true&selfie=false&country=PRT');
						}}
					>
						Iniciar nuevo proceso sin selfie y documento de identidad portugués
					</Button>
				</View>
			) : (
				<IdentityDocumentsWrapper.ScanManager
					ref={scanManaggerReference}
					style={{
						// marginTop: 30,
						width: '100%',
						height: '100%'
						// minHeight: isMobile ? '500px' : '750px',
						// overflow: 'hidden',
					}}
					identityDocumentId={dniData?.id}
					mockEnabled={false}
					scanConfig={{
						// providerIdentifier: 'mock',
						additionalStages: query?.selfie === 'false' ? [] : ['selfie'],
						completeTimeout: 2000,
						// Custom "Scan" dependant parameters (for this case is Alice)
						metadata: {
							isIOS: isIOS,
							issuingCountry: query?.country ? query?.country : 'ESP',
							// adjustHeigths: true,
							typographyTheme: 'corporate',
							iconsTheme: 'modern',
							iconsVariant: 'shape',
							language: currentLang,
							documentCapturingType: 'all',
							documentAutoCapturing: true,
							customMessages: {
								es: {
									onboarding_stages_title: 'Documento de identidad',
									onboarding_sides_title_id_card: 'Documento de identidad',
									onboarding_stages_id_card: 'Documento de identidad',
									onboarding_stages_description: 'Proceso de identificación por vídeo'
								},
								onboarding_country_selector_start: 'CONTINUAR'
							},
							customTheme: {
								alice_color_primary: CONFIG.colors.primary,
								alice_color_secondary: CONFIG.colors.grey,
								alice_color_accent: CONFIG.colors.tertiary
								/*
							NOT available in BACKEND ALL IN ONE PROCESS
						alice_title_font_family: CONFIG.fonts.regular,
						alice_font_family: CONFIG.fonts.regular,
						*/
							}
						}
					}}
					onSuccess={(data) => {
						// Respuesta OK, continuamos con lo que venga
						const aliceDNI = data?.value?.data?.report?.documents[0]?.summary_fields?.find(
							(sf) => sf?.name === 'id_number'
						)?.value;
						// const sameBenefDNI = target?.creationData?.details?.beneficiaries?.find(
						// 	be => be?.dni.toUpperCase() === aliceDNI.toUpperCase(),
						// );
						const sameBenefDNI = false;
						const diferentToReceived = user?.metadata?.document && aliceDNI !== user?.metadata?.document;

						if (sameBenefDNI) {
							openModal(
								(close) => (
									<S.ModalWrapper>
										<Text headingPrimarySt>Se ha encontrado un error en el listado de beneficiarios</Text>
										<Text headingSecondarySt>No puedes añadirte tú mismo como beneficiario</Text>
										<Button
											style={{ marginTop: 30, marginBottom: 0, display: 'inline-block' }}
											onClick={() => {
												close();
												navigateTo(routes.clientData);
											}}
										>
											Modificar
										</Button>
									</S.ModalWrapper>
								),
								{
									disableClickAway: true,
									closeButton: false,
									executeOnClose: () => {
										navigateTo(routes.clientData);
									},
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-alice-sameBenef'
								}
							);
						} else if (diferentToReceived) {
							openModal(
								(close) => (
									<S.ModalWrapper>
										<Text headingPrimarySt errorSt>
											Se ha encontrado un error en el documento añadido
										</Text>
										<Text headingTertiarySt errorSt>
											El número del documento escaneado no coincide con el que se indicó al inicio del proceso.
										</Text>
										<Button
											style={{ marginTop: 30, marginBottom: 0 }}
											onClick={() => {
												close();
												CONFIG.routing.identityBiometric.modifyDNI(navigateTo);
											}}
										>
											Modificar
										</Button>
									</S.ModalWrapper>
								),
								{
									disableClickAway: true,
									closeButton: false,
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-alice-diferent'
								}
							);
						} else {
							setsuccessFlow(data);
							// navigateTo(routes.activateProduct);
						}
					}}
					onFailure={(data) => {
						aliceOnError(data, openModal, navigateTo, scanManaggerReference);
					}}
					onCancel={(data) => {
						navigateTo(routes.bankAccount);
					}}
				/>
			)}
		</WrapperKYC>
	);
});
