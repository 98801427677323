import { isMobile } from 'react-device-detect';

import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const SmallButton = styled.button`
	font-size: 18px;
	font-weight: 800;
	min-width: ${isMobile ? '100%' : '225px'};
	margin: ${isMobile ? '10px 0px' : '10px'};
	color: white;
	text-align: center;
	padding: ${isMobile ? '7px 10px' : '10px 10px'};
	border-radius: 1px;
	outline: none;
	border: 1px solid ${CONFIG.colors.primary};
	background-color: ${CONFIG.colors.primary};

	:focus {
		outline: none;
	}
`;
