import { isMobile } from 'react-device-detect';

import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/globalStyle';

export const InnerWrapper = styled.div`
	${verticalFlex}
	flex-grow: 1;
	width: 90%;

	.title {
		margin-top: 40px;
	}
`;

export const DocumentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 20px 10px;
	width: 100%;
	border-radius: 10px;
	margin-top: 10px;
`;
export const DocumentWrapperText = styled.p`
	font-size: ${isMobile ? '12px' : '16px'};
	color: ${CONFIG.colors.primary};
	overflow-x: hidden;
	margin-right: 20px;
`;

export const DocumentWrapperButtons = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	justify-items: flex-end;
	align-items: center;
`;
export const DocumentWrapperIconPDF = styled.img`
	width: 16px;
	height: auto;
	cursor: pointer;
	color: ${(props) => {
		if (props.selected) {
			return CONFIG.colors.secondary;
		} else {
			return CONFIG.colors.primary;
		}
	}};
`;
export const DocumentWrapperIconDownload = styled.img`
	width: 14px;
	height: auto;
	cursor: pointer;
`;
export const DocumentButton = styled.button`
	text-align: center;
	cursor: pointer;
	outline: none;

	transition: all 0.3s;

	color: ${(props) => {
		if (props.selected) {
			return '#FDE29C';
		} else {
			return CONFIG.colors.primary;
		}
	}};
	background-color: ${(props) => {
		if (props.selected) {
			return CONFIG.colors.primary;
		} else {
			return 'white';
		}
	}};
	font-size: 16px;
	padding: 10px 15px;
	border-radius: 10px;
	border: 1px solid ${CONFIG.colors.primary};
	margin: 0px 8px;
	margin-top: 15px;
	:focus {
		outline: none;
	}
`;

export const DocumentLabel = styled.div`
	text-align: center;
	user-select: none;
	outline: none;

	transition: all 0.3s;

	color: ${(props) => {
		if (props.read) {
			return '#FDE29C';
		} else {
			return '#FF9929';
		}
	}};
	background-color: ${(props) => {
		if (props.read) {
			return '#FF9929';
		} else {
			return 'white';
		}
	}};
	font-size: 12px;
	padding: 3px;
	border-radius: 3px;
	border: 1px solid #ff9929;
`;
