import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

// progress bar

export const Label = styled.p`
	font-size: 12px;
	color: #7a7a7a;
	margin: 4px 0px;
`;
export const ProgressBar = styled.div`
	width: 100%;
	height: 26px;
	border-radius: 45px;
	background: #e5e4e4;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	position: 'relative';
`;
export const ProgressBarItem = styled.div`
	width: 32px;
	/* border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
  background: ${CONFIG.colors.primary}; */
	/* background: transparent; */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
`;
export const HorizontalProgressBarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-bottom: 45px;
`;
export const ProgressBarItemStep = styled.div`
	background: ${CONFIG.colors.primary};
	border-top-left-radius: ${(props) => {
		if (props.index === 0) {
			return '45px';
		} else {
			return 0;
		}
	}};

	border-top-right-radius: ${({ index, length }) => {
		if (index === 0) {
			return '0px';
		} else if (index + 1 === length) {
			return '45px';
		} else {
			return 0;
		}
	}};
	border-bottom-right-radius: ${({ index, length }) => {
		if (index === 0) {
			return '0px';
		} else if (index + 1 === length) {
			return '45px';
		} else {
			return 0;
		}
	}};
	border-bottom-left-radius: ${({ index, length }) => {
		if (index === 0) {
			return '45px';
		} else if (index + 1 === length) {
			return '0px';
		} else {
			return '0px';
		}
	}};
	width: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	position: 'absolute';
`;
export const ProgressBarItemDot = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 50%;

	background: ${({ step }) => {
		if (step) {
			return 'white';
		} else {
			return CONFIG.colors.tertiary;
		}
	}};
`;
