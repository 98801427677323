import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('selectItem', {
	styles: {
		default: {
			// border: "1px solid #f60",
			textComponent: {
				fontSize: '15px',
				color: 'black'
			}
		}
	}
});
