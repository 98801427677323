import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import {
    Button,
    Input,
    InputCheck,
    InstitutionsWrapper,
    NavigationWrapper,
    PickerHOC,
    TargetTypesWrapper,
    Text,
    Tooltip,
    UiWrapper,
    UserWrapper,
    View,
    VinculationWrapper
} from '@coinscrap/webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import SendLinkModal from 'components/SendLinkModal/SendLinkModal';
import TextSpan from 'components/TextSpan/TextSpan';
import { SpanCurrency } from 'config/components/span';
import TooltipSimple from 'config/components/tooltipSimple';
import Header from 'layout/components/Header/Header';
import { useSendLink } from 'libs/hooks/useSendLink';
import { useSimulation } from 'libs/hooks/useSimulation';
import { ClientSimulationWrapper } from 'wrappers/customWrapper/ClientSimulationWrapper';

import { CONFIG } from 'config/config';
import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';
import { getCurrency } from 'libs/Currency';

import * as S from './styles';

export const SimulationAgent = PickerHOC({
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	},
	vinculation: {
		sourcePath: 'vinculation',
		sourceWrapper: VinculationWrapper
	},
	instance: {
		sourcePath: 'vinculationApiInstance',
		sourceWrapper: VinculationWrapper
	},
	targetTypes: {
		sourcePath: 'targetTypes',
		sourceWrapper: TargetTypesWrapper
	},
	simulation: {
		sourcePath: 'simulation',
		sourceWrapper: ClientSimulationWrapper
	},
	client: {
		sourcePath: 'client',
		sourceWrapper: ClientSimulationWrapper
	},
	caserInstitution: {
		arrayItemMatcher: {
			itemPropertyPath: 'identifier',
			type: 'value',
			value: 'caser'
		},
		sourcePath: 'institutions',
		sourceWrapper: InstitutionsWrapper
	}
})(() => {
	const { updateSimulation, loadSimulationForClient } = ClientSimulationWrapper.use();

	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const pickerValues = PickerHOC.use();
	const { simulation, client, caserInstitution, targetTypes } = pickerValues;

	const targetType = targetTypes?.find((x) => simulation?.targetTypeId === x?.id);

	useEffect(() => {
		(async () => {
			//TODO QUITAR CUANDO EN CHANGE PRODUCT AL ACTUALIZAR EL PRODUCT SE ACTUALICE BIEN EL TARGET SIN TENER QUE HACER ESTO AL VOLVER AL SUMMARY
			await loadSimulationForClient(client.id, simulation.id);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onError = (e) => {
		openModal(
			() => (
				<ErrorModal>
					{e?.payload?.caserError?.error?.message ||
						`La simulación ha fallado, recuerda los límites de capital total (${capitalMinimum}€ - ${capitalMaximum}€) cuando configures la prima mensual/trimestral/semestral/anual`}
				</ErrorModal>
			),
			{
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-simulationAgentError'
			}
		);
	};

	const textPeriodicidad = () => {
		switch (period) {
			case 'mensual':
				return 'mes';
			case 'trimestral':
				return 'trimestre';
			case 'semestral':
				return 'semestre';
			case 'anual':
				return 'año';
			default:
				return '';
		}
	};

	// CREATION DATA
	const creationData = simulation?.targetProperties?.find((x) => x.name === 'creationData')?.value;

	// CAPITAL MÁXIMO
	// eslint-disable-next-line no-unused-vars
	const [capitalMaximum, setCapitalMaximum] = useState(180000);

	// CAPITAL MÍNIMO
	// eslint-disable-next-line no-unused-vars
	const [capitalMinimum, setCapitalMinimum] = useState(20000);

	const [
		simulationLoading,
		// eslint-disable-next-line no-unused-vars
		setSimulationLoading,
		invoice,
		setInvoice,
		capital,
		setCapital,
		isValidCapital,
		setIsValidCapital,
		invalidCapitalReason,
		setInvalidCapitalReason,
		period,
		setPeriod,
		disabilityCoverage,
		setDisabilityCoverage,
		disabilityCoverageAccident,
		setDisabilityCoverageAccident
	] = useSimulation(caserInstitution, targetType, creationData, client, capitalMaximum, capitalMinimum, onError);

	const send = useSendLink(client.id);
	const { navigateTo, goBack } = NavigationWrapper.use();

	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	const [inputWidth, setInputWidth] = useState(2);
	const [inputWidthCapital, setInputWidthCapital] = useState(2);

	const textPeriodicidadLong = () => {
		switch (period) {
			case 'mensual':
				return 'al mes';
			case 'trimestral':
				return 'trimestralmente';
			case 'semestral':
				return 'semestralmente';
			case 'anual':
				return 'al año';
			default:
				return '';
		}
	};

	// NAVIGATION
	const back = async () => {
		navigateTo(routes.clientDataAgent);
	};

	const next = async () => {
		setLoading('Guardando...');

		// HAY QUE AJUSTAR CORE PARA PODER ALMACENAR contributionData COMO EN LA PARTE B2C DE USUARIO
		await updateSimulation({
			price: Number(capital.replace('.', '').replace(',', '.')),
			periodicity: period,
			periodicContribution: Number(invoice.replace('.', '').replace(',', '.')),
			disabilityCoverage,
			details: creationData?.details
		});
		setLoading(false);
		openModal(
			(close) => (
				<SendLinkModal
					close={close}
					user={client}
					send={send}
					next={() => {
						navigateTo(routes.followUp);
					}}
				/>
			),
			{
				disableClickAway: true,
				closeButton: false,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-SimulationAgentError'
			}
		);
	};

	useEffect(() => {
		const findPeriodsAndDecimals = [...invoice.toString()].filter((item) => item === '.' || item === ',');
		setInputWidth(invoice.toString().trim().length + 2 + findPeriodsAndDecimals.length * -0.6);

		const findPeriodsAndDecimalsCapital = [...capital.toString()].filter((item) => item === '.' || item === ',');
		setInputWidthCapital(capital.toString().trim().length + 2 + findPeriodsAndDecimalsCapital.length * -0.6);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoice, capital]);

	useLoading(simulationLoading || loading, {
		loadingMessage: simulationLoading || loading
	});
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} isB2B={true} />, []);

	return (
		<Wrapper width={'80%'}>
			<Text headingPrimarySt isB2B>
				{isB2B ? 'Confirma tu seguro' : '¿Quieres contratar un seguro de vida?'}
			</Text>
			<Text headingSecondarySt>
				{
					{
						true: (
							<>
								Por <TextSpan style={{ fontWeight: 800, color: CONFIG.colors.secondary }}>{invoice} €</TextSpan>{' '}
								{textPeriodicidadLong()} vas a contratar tu seguro de vida con un capital de{' '}
								<TextSpan style={{ fontWeight: 800, color: CONFIG.colors.secondary }}>{capital} €</TextSpan>{' '}
							</>
						),
						false: (
							<>
								Por solo <TextSpan style={{ fontWeight: 800, color: CONFIG.colors.secondary }}>{invoice} €</TextSpan>{' '}
								{textPeriodicidadLong()} puedes tener un seguro de vida que te cubra{' '}
								<TextSpan style={{ fontWeight: 800, color: CONFIG.colors.secondary }}>{capital} €</TextSpan> cuando tú
								faltes{' '}
							</>
						)
					}[isB2B]
				}

				<TooltipSimple>
					<Text tooltipTextSt>
						Pago del capital garantizado, alternativo al seguro principal, si el asegurado/a sufre una incapacidad
						permanente absoluta para toda profesión durante la vigencia del seguro. Incapacidad Permanente Absoluta se
						define como la situación física irreversible provocada por accidente o enfermedad originados
						independientemente de la voluntad del asegurado/a, determinante de su total ineptitud para el mantenimiento
						permanente de cualquier relación laboral o actividad profesional.
					</Text>
				</TooltipSimple>
			</Text>
			<Text featuresPrimarySt>{isMobile ? 'Capital a asegurar' : 'Escoge el capital a asegurar'}</Text>
			<S.InputWrapper>
				<Input
					style={{
						color: CONFIG.colors.secondary,
						fontSize: '36px',
						fontFamily: CONFIG.fonts.bold,
						width: inputWidthCapital + 'ch'
					}}
					value={capital}
					onChange={(e) => {
						if (simulationLoading) return;
						const capital = e.target.value || '';
						const val = capital.replace(/\D/g, '');
						setIsValidCapital(val !== '' && val <= capitalMaximum && val >= capitalMinimum);
						if (val > capitalMaximum) {
							setInvalidCapitalReason('El capital máximo asegurable es de ' + getCurrency(capitalMaximum, 0));
						} else if (val === '' || val < capitalMinimum) {
							setInvalidCapitalReason('El capital mínimo asegurable es de ' + getCurrency(capitalMinimum, 0));
						}
						setCapital(val);
					}}
				/>
				<SpanCurrency>€</SpanCurrency>
			</S.InputWrapper>
			<View
				invalidCapitalSt
				style={{
					display: !isValidCapital && invalidCapitalReason ? 'block' : 'none',
					visibility: !isValidCapital && invalidCapitalReason ? 'visible' : 'hidden'
				}}
			>
				{!isValidCapital && invalidCapitalReason}
			</View>
			<Text featuresPrimarySt featuresMarginBottomSt>
				{isMobile ? 'Periodicidad del pago' : 'Selecciona la periodicidad'}
			</Text>
			<View elementsAroundFlexSt>
				<Button
					simulationPeriodSt
					selectedSt={period === 'mensual'}
					onClick={() => {
						setPeriod('mensual');
					}}
				>
					Mensual
				</Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'trimestral'}
					onClick={() => {
						setPeriod('trimestral');
					}}
				>
					Trimestral
				</Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'semestral'}
					onClick={() => {
						setPeriod('semestral');
					}}
				>
					Semestral
				</Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'anual'}
					onClick={() => {
						setPeriod('anual');
					}}
				>
					Anual
				</Button>
			</View>
			<View
				conditionsWrapperSt
				marginTopMobileSt
				onClick={() => {
					if (disabilityCoverage && disabilityCoverageAccident) {
						setDisabilityCoverageAccident(!disabilityCoverageAccident);
					}
					setDisabilityCoverage(!disabilityCoverage);
				}}
			>
				<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={disabilityCoverage} />
				<Text marginLefConditiontSt>
					Incluir cobertura por incapacidad permanente absoluta{' '}
					<TooltipSimple>
						<Text tooltipTextSt>
							Pago del capital garantizado, adicional a los riesgos de incapacidad antes descritos, si el asegurado/a
							sufre una incapacidad permanente absoluta derivada de un accidente de circulación que sobreviene al
							asegurado/a en su calidad de peatón en vía pública, causado por un vehículo, o bien como conductor no
							profesional o pasajero de un vehículo terrestre, durante la vigencia del seguro.
						</Text>
					</TooltipSimple>
					<Tooltip open={false} />
				</Text>
			</View>

			<Text
				featuresPrimarySt
				featuresMarginTopSt
				isB2B
				style={{ marginTop: isMobile ? '16px' : '25px', marginBottom: 0 }}
			>
				La prima resultante es de :
			</Text>
			<S.InputWrapper>
				<Input
					style={{
						color: CONFIG.colors.secondary,
						fontSize: '36px',
						fontFamily: CONFIG.fonts.bold,
						width: inputWidth + 'ch'
					}}
					value={invoice}
					onChange={(e) => {
						let val = e.target.value;
						if (val.includes(',')) {
							val = val.split(',')[0];
						}
						setInvoice(val);
					}}
				/>
				<SpanCurrency>{`€ / ${textPeriodicidad()}`}</SpanCurrency>
			</S.InputWrapper>

			<BackNextButton
				onClickBack={back}
				onClickNext={next}
				isBackDisabled={false}
				isNextDisabled={
					!Number(invoice.toString().replace('.', '').replace(',', '.')) ||
					!Number(capital.toString().replace('.', '').replace(',', '.'))
				}
			/>
		</Wrapper>
	);
});
