import React from 'react';

import { Button, Text, View } from '@coinscrap/webapp-core';

import TooltipSimple from 'config/components/tooltipSimple';

const QuestionnaireBox = ({ text, tooltipText, onChange, value = null, listWithTooltips = false }) => {
	return (
		<View questionnariesSt>
			<View wrapperQuestionnariesSt>
				<Text boxQuestionnariesSt>
					<View wrapperTextQuestionnariesSt style={{ width: listWithTooltips ? '90%' : '100%' }}>
						{text}
					</View>
					{tooltipText && (
						<View style={{ width: '10%' }}>
							<TooltipSimple>
								<Text tooltipTextSt>{tooltipText}</Text>
							</TooltipSimple>
						</View>
					)}
				</Text>
			</View>
			<View wrapperButtonsQuestionnariesSt>
				<Button
					questionarySt={value}
					noSt={!value}
					onClick={() => {
						//console.log('CLICK YES');
						onChange(true);
					}}
				>
					Sí
				</Button>
				<Button
					questionarySt={!value}
					noSt={value || value == null}
					onClick={() => {
						//console.log('CLICK NO');
						onChange(false);
					}}
				>
					No
				</Button>
			</View>
		</View>
	);
};

export default QuestionnaireBox;
