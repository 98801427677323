import React, { useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Modal from '@material-ui/core/Modal';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Button, Text, UiWrapper, View } from '@coinscrap/webapp-core';

import CloseButton from 'components/CloseButton/CloseButton';

import { CONFIG } from 'config/config';

const CommercialActionsModal = ({ isOpen, onClose, children, onClick }) => {
	const faqRef = useRef();
	const { useBackground } = UiWrapper.use();
	useBackground(CONFIG.colors.white);
	const [isScroll, setIsScroll] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(true);

	const observer = useRef();

	const lastPlanElementRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[entries.length - 1].isIntersecting) {
					setButtonDisabled(false);
					setIsScroll(false);
				} else {
					setIsScroll(true);
				}
			});
			if (node) observer.current.observe(node);
		},
		[observer]
	);

	return (
		<Modal open={isOpen} onClose={onClose} key={'terms_modal'}>
			<View fullHeightSt style={{ backgroundColor: CONFIG.colors.white, overflowY: 'auto' }}>
				<View fullHeightSt style={{ justifyContent: 'flex-start' }}>
					<View startSt fullHeightSt>
						<View wInitialSt style={{ marginTop: 10, marginLeft: 'auto' }}>
							<CloseButton
								color={CONFIG.colors.black}
								action={() => {
									onClose();
								}}
							/>
						</View>

						<View
							startSt
							style={{
								overflowY: 'scroll',
								height: 'calc(100% - 150px)',
								maxWidth: 850,
								padding: 10,
								width: '90%'
							}}
						>
							{children}
							<div>
								<Text tooltipTextSt>
									Al marcar esta casilla, consiente que CAJA DE SEGUROS REUNIDOS, COMPAÑÍA DE SEGUROS Y REASEGUROS,
									S.A., pueda ofrecerle por cualquier medio de comunicación, incluidas llamadas telefónicas, correo
									electrónico, SMS, MMS, o equivalente, sus productos y servicios de seguros, bien directamente o a
									través de sus Agentes. Encontrándose legitimado este tratamiento por el consentimiento libremente
									prestado por usted. Sus datos se conservarán indefinidamente hasta que no manifieste su oposición a la
									recepción de este tipo de comunicaciones.
								</Text>
							</div>
							<View ref={lastPlanElementRef} style={{ height: 10 }}>
								<View ref={faqRef} />
							</View>
						</View>

						<ArrowForwardIosIcon
							onClick={() => {
								faqRef.current.scrollIntoView({ behavior: 'smooth' });
							}}
							style={{
								position: 'fixed',
								bottom: isMobile ? 25 : 30,
								right: 30,
								width: 45,
								height: 'auto',
								transform: 'rotate(90deg)',
								opacity: isScroll ? 1 : 0,
								cursor: 'pointer'
							}}
						/>
						<Button
							termsSt
							disabled={!!buttonDisabled}
							onClick={() => {
								onClick && onClick();
								onClose();
							}}
						>
							Aceptar
						</Button>
					</View>
				</View>
			</View>
		</Modal>
	);
};

export default CommercialActionsModal;
