import { isMobile } from 'react-device-detect';

import styled from 'styled-components/macro';

import { CONFIG } from '../config';

export const SpanCurrency = styled.span`
	color: ${CONFIG.colors.secondary};
	font-family: ${CONFIG.fonts.bold};
	font-size: ${isMobile ? '30px' : '36px'};
`;

export const SpanBold = styled.span`
	font-family: ${CONFIG.fonts.bold};
	font-size: ${CONFIG.fontSizes.medium};
`;

export const SpanUnderline = styled.span`
	text-decoration: underline;
	cursor: pointer;
	font-size: ${CONFIG.fontSizes.medium};
`;

export const SpanCallUs = styled.span`
	color: ${CONFIG.colors.primary};
	font-family: ${CONFIG.fonts.regular};
	font-size: 14;
	a {
		color: ${CONFIG.colors.primary};
	}
`;
