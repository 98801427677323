import { isMobile } from 'react-device-detect';

import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const Button = styled.button`
	cursor: pointer;
	font-size: ${isMobile ? '14px' : '18px'};
	font-family: ${(props) => {
		if (props.selected) {
			return CONFIG.fonts.bold;
		} else {
			return CONFIG.fonts.light;
		}
	}};
	width: 49%;
	transition: 0.6s all;
	color: ${(props) => {
		if (props.selected) {
			return 'white';
		} else {
			return '#333333';
		}
	}};
	font-weight: ${(props) => {
		if (props.selected) {
			return 600;
		} else {
			return 400;
		}
	}};
	text-align: center;
	padding: 14px 20px;
	border-radius: 1px;
	outline: none;
	border: ${(props) => {
		if (!props.selected) {
			return '1px solid #B1B1B1';
		} else {
			return '1px solid #30353F';
		}
	}};

	background-color: ${(props) => {
		if (props.selected) {
			return CONFIG.colors.primary;
		} else {
			return 'white';
		}
	}};

	:focus {
		outline: none;
	}

	:hover {
		opacity: 0.7;
	}

	:disabled {
		opacity: 0.5;
		cursor: default;
	}
`;

export const OptionsSmokeAndAlcohol = styled.div`
	align-self: normal;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	color: rgb(32, 37, 43);
	box-sizing: border-box;
	font-family: Montserrat-Regular;
`;

export const FormWContainer = styled.div`
	align-items: baseline;
	display: grid;
	gap: 24px;
	grid-template-columns: ${(props) => props.columns || '1fr 1fr 1fr'};
	width: 80%;
	@media (max-width: 450px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;

export const MainTitle = styled.h1`
	color: ${CONFIG.colors.primary};
	font-family: ${CONFIG.fonts.bold};
	font-size: ${CONFIG.fontSizes.large};
	margin-bottom: 8px;
	text-align: center;
`;
