import { useCallback } from 'react';

import { BackendWrapper } from '@coinscrap/webapp-core';

export const useGetGlobalAgentVinculations = () => {
	const { getInstanceApi } = BackendWrapper.use();
	const apiInstance = getInstanceApi();
	const getGlobalAgentVinculations = useCallback(async () => {
		if (apiInstance) {
			const vinculations = await apiInstance.microserviceCall('Us')(
				`/vinculations?filter=${encodeURIComponent(
					JSON.stringify({
						fields: ['id', 'mainUserId', 'secondaryUserId'],
						order: 'createDate DESC'
					})
				)}`,
				'GET',
				{}
			);
			//console.log(vinculations);
			return vinculations;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return getGlobalAgentVinculations;
};
