import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
    NavigationWrapper,
    PickerHOC,
    Slider,
    TargetsUtilsWrapper,
    TargetsWrapper,
    Text,
    UiWrapper,
    UserWrapper
} from '@coinscrap/webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { SmallButton } from 'components/SmallButton/SmallButton';
import Header from 'layout/components/Header/Header';
import { caserOnError } from '../../libs/caserOnError';

import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';
import { getCurrency } from 'libs/Currency';

import * as S from './styles';

export const ActivateProduct = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { navigateTo, goBack, useBackRoute } = NavigationWrapper.use();
	const [trigger, setTrigger] = useState(0);
	const [trigger2, setTrigger2] = useState(0);
	const pickerValues = PickerHOC.use();
	const { activate, postTargetCustom } = TargetsUtilsWrapper.use();
	const { target, user } = pickerValues;
	const creationData = target?.targetProperties?.find((x) => x.name === 'creationData')?.value;
	const contributionData = target?.targetProperties?.find((x) => x.name === 'contributionData')?.value;
	const [createdNow, setCreatedNow] = useState();
	const [finishedNow, setFinishedNow] = useState();
	const targetStatus = target.status;
	const envelopeId = target?.signData?.envelopeId;

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	useBackRoute(undefined);

	useEffect(() => {
		if (targetStatus === 'TEMPORARY' || targetStatus === 'PENDING_FAILED') {
			(async () => {
				try {
					setCreatedNow(true);
					await activate(target.id);
				} catch (e) {
					caserOnError(e, 'ACTIVATION', openModal, navigateTo, setTrigger, undefined, undefined);
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetStatus, trigger]);

	useEffect(() => {
		if (
			(!envelopeId || createdNow || (envelopeId && targetStatus === 'ACTIVATION_FAILED')) &&
			(targetStatus === 'PENDING' || targetStatus === 'ACTIVATION_FAILED')
		) {
			(async () => {
				try {
					await postTargetCustom(target.id, 'start-activation', {});
					setFinishedNow(true);
				} catch (e) {
					openModal(
						(close) => (
							<ErrorModal>
								<Text headingPrimarySt>{'Ha ocurrido un error al preparar los documentos de firma'}</Text>
								<SmallButton
									onClick={() => {
										setSlideIndex(1);
										setTrigger2((t) => t + 1);
										close();
									}}
								>
									Reintentar
								</SmallButton>
								<SmallButton
									onClick={() => {
										navigateTo(routes.productCreationFinishedManual);
										close();
									}}
								>
									Firmar mas adelante
								</SmallButton>
							</ErrorModal>
						),
						{
							disableClickAway: true,
							closeButton: false,
							modalClass: isMobile ? 'modal-xl' : 'modal-main',
							key: 'modal-EnvelopesCreationError'
						}
					);
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetStatus, envelopeId, createdNow, trigger2]);

	const [slideIndex, setSlideIndex] = useState(1);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);

	// NAVIGATION
	const next = async () => {
		try {
			navigateTo(routes.signatureSteps, {
				mode: 'replace',
				routeParams: { envelopeId }
			});
		} catch (e) {
			let messageError = e.message.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-activatenext'
			});
		}
	};

	const isDisabled = () => {
		return (
			(targetStatus !== 'ACTIVE' && targetStatus !== 'ACTIVATING') ||
			(createdNow ? !finishedNow || !envelopeId : !envelopeId) ||
			slideIndex < 4
		);
	};

	const textPeriodicidadLong = (period) => {
		switch (period) {
			case 'mensual':
			case 'MONTHLY':
				return 'al mes';
			case 'trimestral':
			case 'QUARTERLY':
				return 'trimestralmente';
			case 'semestral':
			case 'SEMIANNUALLY':
				return 'semestralmente';
			case 'anual':
			case 'YEARLY':
				return 'al año';
			default:
				return '';
		}
	};

	const textPeriodicidad = (period) => {
		switch (period) {
			case 'mensual':
			case 'MONTHLY':
				return 'Mensual';
			case 'trimestral':
			case 'QUARTERLY':
				return 'Trimestral';
			case 'semestral':
			case 'SEMIANNUALLY':
				return 'Semestral';
			case 'anual':
			case 'YEARLY':
				return 'Anual';
			default:
				return '';
		}
	};

	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);

	useEffect(() => {
		if (slideIndex < 4) {
			const timeOutId = setTimeout(() => {
				setSlideIndex(slideIndex + 1);
			}, 3500);
			return () => clearTimeout(timeOutId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slideIndex]);

	useLoading(false);
	return (
		<Wrapper>
			{!isB2B && <HorizontalProgressBar step={5} total={6} styleWrapper={{ maxWidth: 450 }} />}
			{
				{
					1: (
						<Text headingPrimarySt isB2B style={{ marginTop: 30, maxWidth: 550 }}>
							¡Ya casi estamos! Tu póliza se está procesando
						</Text>
					),
					2: (
						<Text headingPrimarySt isB2B style={{ marginTop: 30, maxWidth: 550 }}>
							Tu resumen
						</Text>
					),
					3: (
						<Text headingPrimarySt isB2B style={{ marginTop: 30, maxWidth: 550 }}>
							Garantía de calidad
						</Text>
					),
					4: (
						<Text headingPrimarySt isB2B style={{ marginTop: 30, maxWidth: 550 }}>
							Garantía de tranquilidad
						</Text>
					)
				}[slideIndex]
			}
			<S.InnerWrapper style={{ paddingTop: 15 }}>
				<Slider>
					{
						{
							1: (
								<S.SliderItem>
									<Text headingSecondarySt style={{ marginTop: 10 }}>
										Estamos trabajando en finalizar el contrato de tu seguro. El proceso puede tardar hasta 30 segundos,
										no cierres la ventana.
									</Text>
								</S.SliderItem>
							),
							2: (
								<S.SliderItem>
									<Text headingSecondarySt style={{ marginTop: 10, maxWidth: 900 }}>
										Tu seguro de vida tiene una prima de {getCurrency(contributionData.periodicContribution, 2)}{' '}
										{textPeriodicidadLong(contributionData?.chargePeriod)} y asegura un capital de{' '}
										{getCurrency(contributionData?.amountToCover, 0)}
									</Text>
									<Text headingSecondarySt style={{ marginTop: 10, maxWidth: 900 }}>
										Periodicidad: {textPeriodicidad(contributionData?.chargePeriod)}
									</Text>
									{creationData?.details?.beneficiaryType === 'default' ? (
										<Text headingSecondarySt style={{ marginTop: 10, maxWidth: 900 }}>
											Beneficiario: {user?.name} {user?.surname}
										</Text>
									) : (
										<Text headingSecondarySt style={{ marginTop: 10, maxWidth: 900 }}>
											Beneficiarios:{' '}
											{creationData?.details?.beneficiaries.map((beneficiary, index, array) => {
												if (index === 0) {
													return `${beneficiary?.name} ${beneficiary?.surname}`;
												} else if (index > 0 && index === array.length - 1) {
													return ` y ${beneficiary?.name} ${beneficiary?.surname}`;
												} else {
													return `, ${beneficiary?.name} ${beneficiary?.surname}`;
												}
											})}
										</Text>
									)}
								</S.SliderItem>
							),
							3: (
								<S.SliderItem>
									<Text headingSecondarySt style={{ marginTop: 10 }}>
										Tendrás un contrato firmado digitalmente con Caser que te ofrecerá todas las garantías y seguridad.
										En todo momento puedes acceder a tu contrato en el área de clientes de Caser Seguros.
									</Text>
								</S.SliderItem>
							),
							4: (
								<S.SliderItem>
									<Text headingSecondarySt style={{ marginTop: 10 }}>
										¡Esta ha sido una decisión inteligente! El seguro de vida es una forma de ofrecer protección
										financiera, asegurando que tus seres queridos reciban una compensación económica si falleces o que
										estés cubierto si padeces una invalidez
									</Text>
								</S.SliderItem>
							)
						}[slideIndex]
					}
				</Slider>
				<S.SliderDots>
					<S.SliderDot selected={slideIndex === 1} />
					<S.SliderDot selected={slideIndex === 2} />
					<S.SliderDot selected={slideIndex === 3} />
					<S.SliderDot selected={slideIndex === 4} />
				</S.SliderDots>
			</S.InnerWrapper>
			<BackNextButton
				onClickNext={next}
				isBackShown={false}
				isNextClickable={!isDisabled()}
				isNextLoading={isDisabled()}
				isNextDisabled={isDisabled()}
				marginBetween={0}
				nextText={'FIRMAR'}
				justifyContent={'center'}
			/>
		</Wrapper>
	);
});
