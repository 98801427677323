import React from 'react';
import { isMobile } from 'react-device-detect';

import { Button, View } from '@coinscrap/webapp-core';

import leftArrowBlue from 'assets/images/svgs/leftArrowBlue.svg';
import rigthArrowBlue from 'assets/images/svgs/rigthArrowBlue.svg';

import * as S from './styles';

const BackNextButton = ({
	marginTop = isMobile ? '40px' : '50px',
	marginBottom = isMobile ? '20px' : '50px',
	marginBetween = isMobile ? '10px' : '30px',
	onClickBack,
	onClickNext,
	isBackDisabled = false,
	isNextDisabled = false,
	backText = 'VOLVER',
	nextText = 'CONTINUAR',
	backWidth = isMobile ? '100%' : 180,
	nextWidth = isMobile ? '100%' : 180,
	isBackShown = true,
	isNextShown = true,
	//Los loaders mejor que sean undefined por defecto para que los gestione el boton si el usuario no especifica false o true
	isBackLoading,
	isNextLoading,
	isBackClickable = true,
	isNextClickable = true,
	justifyContent = 'between',
	hasIcon = false
}) => {
	// const backCursor = isBackDisabled ? 'not-allowed' : 'pointer';
	// const nextCursor = isNextDisabled ? 'not-allowed' : 'pointer';

	return (
		<View
			rowSt
			itemsCenterSt
			justifyBetweenSt={justifyContent === 'between' ? true : false}
			justifyEndSt={justifyContent === 'end' ? true : false}
			justifyStartSt={justifyContent === 'start' ? true : false}
			justifyEvenlySt={justifyContent === 'evenly' ? true : false}
			justifyAroundSt={justifyContent === 'around' ? true : false}
			justifyCenterSt={justifyContent === 'center' ? true : false}
			wInitialSt
			style={{
				marginTop: marginTop,
				marginBottom: marginBottom,
				width: '100%'
			}}
		>
			{isBackShown && (
				<View backNextWrapperSt>
					<Button
						backSt
						disabled={isBackDisabled}
						loading={isBackLoading}
						onClick={() => isBackClickable && onClickBack()}
					>
						{hasIcon && <S.ArrowIcon src={leftArrowBlue} alt={'left arrow'} marginRight={'20px'} />}
						{backText}
					</Button>
				</View>
			)}
			{isNextShown && (
				<View backNextWrapperSt>
					<Button nextSt disabled={isNextDisabled} loading={isNextLoading} onClick={isNextClickable && onClickNext}>
						{nextText}
						{hasIcon && <S.ArrowIcon src={rigthArrowBlue} alt={'rigth arrow'} marginLeft={'20px'} />}
					</Button>
				</View>
			)}
		</View>
	);
};

export default BackNextButton;
