/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { DateRange, DefinedRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import { Button, Text, View } from '@coinscrap/webapp-core';

import moment from 'moment';

import { CONFIG } from '../../../../config/config';
import { DashboardContext } from '../../contexts/Dashboard';
import { DashboardFiltersContext } from '../../contexts/DashboardFilters';

import { es } from 'date-fns/locale';
import { statusList, Types as statusTypes } from 'libs/targetStatus';

const DashboardFilters = () => {
	const { resetFilterValues: resetFilterValuesDash } = useContext(DashboardContext);

	const {
		dashboardFilters,
		setFilterShown,
		setFilterValues,
		setCheckFilterValues,
		setCheckFilterActive,
		setFilterDatesActive,
		resetFilterValues
	} = useContext(DashboardFiltersContext);

	const [checkFilters, setCheckFilters] = useState(0);

	useEffect(() => {
		setCheckFilterActive('status');
		setFilterDatesActive();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkFilters]);

	const filter = () => {
		setFilterShown(false);
		resetFilterValuesDash();
		setCheckFilters(checkFilters + 1);
	};

	const resetFilters = () => {
		resetFilterValues();
		resetFilterValuesDash();
		setCheckFilters(checkFilters + 1);
	};

	const StyledFormControl = withStyles((theme) => ({
		root: {
			borderColor: `${CONFIG.colors.primary} !important`,
			padding: 20
		}
	}))((props) => <FormControl component="fieldset" {...props} />);

	// console.log('dashboardFilters', dashboardFilters);
	return (
		<View style={{ maxWidth: 1300 }}>
			<View alignItemsStartSt>
				<View rowSt justifyStartSt>
					<Button
						style={{ marginBottom: 30, marginRight: 20 }}
						fixedWidthSt
						onClick={() => {
							if (dashboardFilters.filters.shown) {
								setFilterShown(false);
							} else {
								setFilterShown(true);
							}
						}}
					>
						{!dashboardFilters.filters.shown ? 'Mostrar ' : 'Ocultar '}
						filtros
					</Button>
					{(dashboardFilters.filters.status.active || dashboardFilters.filters.dates.active) && (
						<Button style={{ marginBottom: 30 }} fixedWidthSt onClick={() => resetFilters()}>
							Borrar filtros
						</Button>
					)}
				</View>

				<View w100St alignItemsStartSt>
					{dashboardFilters.filters.status.active && (
						<View rowSt wInitialSt style={{ marginBottom: 30 }}>
							<Chip label="Estado" style={{ marginRight: 10 }} />
							{dashboardFilters.filters.status.value.map((status, index) => {
								return (
									<View wInitialSt key={status}>
										<Chip label={statusTypes[status]} variant="outlined" style={{ marginRight: 10 }} />
									</View>
								);
							})}
						</View>
					)}
					{dashboardFilters.filters.dates.active && (
						<View rowSt wInitialSt style={{ marginBottom: 30 }}>
							<Chip label="Fechas" style={{ marginRight: 10 }} />
							<Chip
								label={dashboardFilters.filters.dates.value.startDate.toLocaleDateString('es-ES')}
								variant="outlined"
								style={{ marginRight: 10 }}
							/>
							<Chip
								label={dashboardFilters.filters.dates.value.endDate.toLocaleDateString('es-ES')}
								variant="outlined"
								style={{ marginRight: 10 }}
							/>
						</View>
					)}
				</View>
			</View>

			{dashboardFilters.filters.shown && (
				<View alignItemsStartSt style={{ marginBottom: 30 }}>
					<Button fixedWidthSt style={{ marginBottom: 20 }} onClick={() => filter()}>
						Filtrar
					</Button>
					<View rowSt justifyBetweenSt alignItemsStartSt>
						{/* ESTADO */}
						<View alignItemsStartSt justifyStartSt style={{ width: 'initial' }}>
							<Text>Estado</Text>
							<StyledFormControl>
								<FormGroup>
									{statusList.map((opt) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														checked={dashboardFilters.filters.status.value.includes(opt.value)}
														onChange={(e) => {
															setCheckFilterValues('status', e);
														}}
														name={opt.value}
													/>
												}
												label={opt.label}
											/>
										);
									})}
								</FormGroup>
							</StyledFormControl>
						</View>

						{/* FECHAS */}
						<View alignItemsStartSt justifyStartSt style={{ width: 'initial' }}>
							<Text>Fechas</Text>
							<StyledFormControl>
								<DateRange
									onChange={(item) => {
										setFilterValues('dates', item.selection);
									}}
									showSelectionPreview={true}
									moveRangeOnFirstSelection={false}
									retainEndDateOnFirstSelection={true}
									months={2}
									ranges={[dashboardFilters.filters.dates.value]}
									direction="horizontal"
									preventSnapRefocus={true}
									locale={es}
									editableDateInputs={true}
									rangeColors={[CONFIG.colors.primary]}
									minDate={new Date('2018/01/01')}
									maxDate={new Date()}
									dateDisplayFormat="d 'de' MMMM 'de' yyyy"
									fixedHeight={true}
									ariaLabels={{
										dateInput: {
											selection: {
												startDate: 'Fecha de inicio',
												endDate: 'Fecha de fin'
											}
										},
										monthPicker: 'Selector Mensual',
										yearPicker: 'Selector Anual',
										prevButton: 'Previo',
										nextButton: 'Siguiente'
									}}
								/>
								<DefinedRange
									onChange={(item) => {
										setFilterValues('dates', item.selection);
									}}
									ranges={[dashboardFilters.filters.dates.value]}
									inputRanges={[]}
									staticRanges={[
										{
											label: 'Hoy',
											range: () => ({
												startDate: new Date(moment().hours(0).minutes(0).seconds(0).milliseconds(0).format()),
												endDate: new Date(moment().hours(23).minutes(59).seconds(59).format())
											}),
											isSelected() {
												return true;
											}
										},
										{
											label: 'Ayer',
											range: () => ({
												startDate: new Date(
													moment().subtract(1, 'day').hours(0).minutes(0).seconds(0).milliseconds(0).format()
												),
												endDate: new Date(moment().subtract(1, 'day').hours(23).minutes(59).seconds(59).format())
											}),
											isSelected() {
												return true;
											}
										},
										{
											label: 'Esta semana',
											range: () => ({
												startDate: new Date(
													moment().startOf('week').hours(0).minutes(0).seconds(0).milliseconds(0).format()
												),
												endDate: new Date(moment().hours(23).minutes(59).seconds(59).format())
											}),
											isSelected() {
												return true;
											}
										},
										{
											label: 'Este mes',
											range: () => ({
												startDate: new Date(
													moment().startOf('month').hours(0).minutes(0).seconds(0).milliseconds(0).format()
												),
												endDate: new Date(moment().hours(23).minutes(59).seconds(59).format())
											}),
											isSelected() {
												return true;
											}
										}
									]}
									rangeColors={[CONFIG.colors.primary]}
								/>
							</StyledFormControl>
						</View>
					</View>
				</View>
			)}
		</View>
	);
};

export default DashboardFilters;
