import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const InputWrapper = styled.input`
	border: ${(props) => {
		if (!props.value) {
			return `1px solid ${CONFIG.colors.tertiaryLight}`;
		} else {
			return `1px solid ${CONFIG.colors.tertiaryLight}`;
		}
	}};
	border-radius: 3px;
	color: ${CONFIG.colors.tertiary};
	height: 29px;
	font-family: ${CONFIG.fonts.regular};
	font-size: 14px;
	padding: 4px 12px;
	width: 100%;

	::placeholder {
		color: ${CONFIG.colors.tertiary};
		opacity: 0.8;
	}
`;
