import { isMobile } from 'react-device-detect';

import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/globalStyle';

export const Wrapper = styled.div`
	${verticalFlex}
	justify-content: 'center';
	flex-grow: 1;
	margin-top: ${(props) => (props?.isB2B || isMobile ? '70px' : '90px')};
	width: ${(props) => (isMobile ? '335px' : props.wrapperWidth || '100%')};
`;

export const WrapperKYC = styled.div`
	${verticalFlex}
	justify-content: 'center';
	flex-grow: 1;
	margin-top: ${(props) => (props?.isB2B || isMobile ? '70px' : '90px')};
	width: ${(props) =>
		isMobile ? (props.wrapperWidthMobile ? props.wrapperWidthMobile : '335px') : props.wrapperWidth || '100%'};
`;
export const DesktopWrapper = styled.div`
	height: 100vh;
	&::-webkit-scrollbar-track {
		background-color: gray;
		border-radius: 7px;
		&:hover {
			background-color: whitesmoke;
		}
	}

	&::-webkit-scrollbar {
		display: block !important;
		width: 10px;
		background-color: whitesmoke;

		&:hover {
			background-color: whitesmoke;
		}
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(180, 176, 176);
		border-radius: 7px;
		transition: all 0.3s ease-in-out;
		&:hover {
			background-color: rgb(148, 146, 146);
			cursor: pointer !important;
		}
	}
`;

export const MaxWidthDesktop = styled.div`
	max-width: 1300px;
`;
export const MaxWidthMobile = styled.div`
	max-width: 850px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
`;
