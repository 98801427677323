import { isMobile } from 'react-device-detect';

import styled from 'styled-components/macro';

export const ConditionsTextSpan = styled.span`
	font-size: ${isMobile ? '14px' : '18px'};
	font-weight: 800;
`;

export const FormWContainer = styled.div`
	align-items: baseline;
	display: grid;
	gap: 24px;
	grid-template-columns: ${(props) => props.columns || '1fr 1fr'};
	width: 60%;
	@media (max-width: 450px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;
