export const professions = [
	{
		codigo: '200000',
		descripcion: 'ABOGADO'
	},
	{
		codigo: '200001',
		descripcion: 'FISCAL'
	},
	{
		codigo: '200002',
		descripcion: 'JUEZ'
	},
	{
		codigo: '200003',
		descripcion: 'JURISTA'
	},
	{
		codigo: '200004',
		descripcion: 'PROCURADOR'
	},
	{
		codigo: '200005',
		descripcion: 'ACTOR /ACTRIZ'
	},
	{
		codigo: '200006',
		descripcion: 'ACTOR DOBLAJE.'
	},
	{
		codigo: '200007',
		descripcion: 'STUNTMAN'
	},
	{
		codigo: '200008',
		descripcion: 'CASCADOR (CINE -ACTUACIÓN)'
	},
	{
		codigo: '200009',
		descripcion: 'ACTUARIO'
	},
	{
		codigo: '200010',
		descripcion: 'ADIESTRAMIENTO ANIMALES DOMÉSTICOS'
	},
	{
		codigo: '200011',
		descripcion: 'ADIESTRAMIENTO PERROS DE  COMBATE'
	},
	{
		codigo: '200012',
		descripcion: 'ADIESTRAMIENTO FIERAS'
	},
	{
		codigo: '200013',
		descripcion: 'ADMINISTRACIÓN'
	},
	{
		codigo: '200014',
		descripcion: 'ADMINISTRADOR DE EMPRESAS'
	},
	{
		codigo: '200015',
		descripcion: 'EMPLEADO ADMINISTRATIVO'
	},
	{
		codigo: '200016',
		descripcion: 'INGENIERO COMERCIAL'
	},
	{
		codigo: '200017',
		descripcion: 'PERSONAL ADMINISTRATIVO'
	},
	{
		codigo: '200018',
		descripcion: 'SECRETARIO/A'
	},
	{
		codigo: '200020',
		descripcion: 'AGENTE DE CAMBIO, BOLSA'
	},
	{
		codigo: '200021',
		descripcion: 'AGRICULTURA. CAMPESINO'
	},
	{
		codigo: '200022',
		descripcion: 'APICULTOR'
	},
	{
		codigo: '200023',
		descripcion: 'GANADERO. GANADO MANSO'
	},
	{
		codigo: '200024',
		descripcion: 'GANADERO. GANADO BRAVO'
	},
	{
		codigo: '200025',
		descripcion: 'GANADERO. INSEMINADORES Y VETERINARIOS'
	},
	{
		codigo: '200026',
		descripcion: 'GANADERO. UTILIZACIÓN DE MAQUINARIA PESADA'
	},
	{
		codigo: '200027',
		descripcion: 'PESCA. EN ALTA MAR'
	},
	{
		codigo: '200028',
		descripcion: 'PESCA. RIOS, LAGOS Y COSTAS'
	},
	{
		codigo: '200029',
		descripcion: 'ALCOHOL (PRODUCCION)'
	},
	{
		codigo: '200030',
		descripcion: 'ALCOHOL. COMERCIALIZACIÓN (EXCEPTO CENTROS NOCTURN'
	},
	{
		codigo: '200031',
		descripcion: 'AMA DE CASA'
	},
	{
		codigo: '200032',
		descripcion: 'ANALISTA DE SISTEMAS'
	},
	{
		codigo: '200033',
		descripcion: 'ANTICUARIO'
	},
	{
		codigo: '200034',
		descripcion: 'ARMERO'
	},
	{
		codigo: '200035',
		descripcion: 'ARQUEÓLOGO'
	},
	{
		codigo: '200036',
		descripcion: 'ARQUITECTO'
	},
	{
		codigo: '200037',
		descripcion: 'ARQUITECTO DE INTERIORES'
	},
	{
		codigo: '200038',
		descripcion: 'ARSENALES, POLVORINES'
	},
	{
		codigo: '200039',
		descripcion: 'AUDITOR'
	},
	{
		codigo: '200040',
		descripcion: 'AUTOR'
	},
	{
		codigo: '200041',
		descripcion: 'EDITOR'
	},
	{
		codigo: '200042',
		descripcion: 'ESCRITOR'
	},
	{
		codigo: '200043',
		descripcion: 'MÚSICO DE SINFÓNICA'
	},
	{
		codigo: '200044',
		descripcion: 'CANTANTE'
	},
	{
		codigo: '200045',
		descripcion: 'DECORADOR'
	},
	{
		codigo: '200046',
		descripcion: 'DISEÑADOR. DISEÑADOR GRÁFICO'
	},
	{
		codigo: '200047',
		descripcion: 'ESCULTOR'
	},
	{
		codigo: '200048',
		descripcion: 'DISEÑADOR VESTUARIO'
	},
	{
		codigo: '200049',
		descripcion: 'SASTRE'
	},
	{
		codigo: '200050',
		descripcion: 'MODISTA'
	},
	{
		codigo: '200051',
		descripcion: 'PAISAJISTA'
	},
	{
		codigo: '200052',
		descripcion: 'ASCENSORISTA'
	},
	{
		codigo: '200053',
		descripcion: 'INSTALADOR DE ELEVADORES'
	},
	{
		codigo: '200054',
		descripcion: 'MONTAJE Y MANTENIMIENTO DE ASCENSORES'
	},
	{
		codigo: '200055',
		descripcion: 'ASESOR'
	},
	{
		codigo: '200056',
		descripcion: 'FABRICACIÓN DE ASFALTO'
	},
	{
		codigo: '200057',
		descripcion: 'ASTILLERO - INGENIERO'
	},
	{
		codigo: '200058',
		descripcion: 'ASTILLERO - MONTAJE'
	},
	{
		codigo: '200059',
		descripcion: 'ASTILLERO ADMINISTRATIVO'
	},
	{
		codigo: '200060',
		descripcion: 'ASTILLERO ARTESANAL'
	},
	{
		codigo: '200061',
		descripcion: 'ASTILLERO INDUSTRIAL'
	},
	{
		codigo: '200062',
		descripcion: 'AUTO-ESCUELA'
	},
	{
		codigo: '200063',
		descripcion: 'AUTOMOVILISMO PROFESIONAL'
	},
	{
		codigo: '200064',
		descripcion: 'AUXILIAR DE DENTISTA'
	},
	{
		codigo: '200065',
		descripcion: 'AUXILIAR DE FARMACIA'
	},
	{
		codigo: '200066',
		descripcion: 'AUXILIAR MÉDICO'
	},
	{
		codigo: '200067',
		descripcion: 'AVIACIÓN PROFESIONAL'
	},
	{
		codigo: '200069',
		descripcion: 'AZATA, AUXILIAR DE VUELO, VUELOS REGULARES'
	},
	{
		codigo: '200070',
		descripcion: 'BAILARINA DE BALLET'
	},
	{
		codigo: '200071',
		descripcion: 'BAILARÍN  DE BALLET'
	},
	{
		codigo: '200072',
		descripcion: 'BAILARÍN ESPAÑOL'
	},
	{
		codigo: '200073',
		descripcion: 'BANCA.  TAREAS ADMINISTRATIVAS'
	},
	{
		codigo: '200074',
		descripcion: 'BANCA.  CHOFER DE CAMIONETA CUSTODIA  DE VALORES'
	},
	{
		codigo: '200075',
		descripcion: 'BANCA.  TRANSPORTE DE VALORES CAUDALES'
	},
	{
		codigo: '200076',
		descripcion: 'BARES, CANTINAS (EXCEPTO CENTROS NOCTURNOS): PROPI'
	},
	{
		codigo: '200077',
		descripcion: 'BARMAN (EMPLEADO BAR O PUB, PREPARADOR DE TRAGOS)'
	},
	{
		codigo: '200078',
		descripcion: 'BIBLIOTECARIO'
	},
	{
		codigo: '200079',
		descripcion: 'BIÓLOGO'
	},
	{
		codigo: '200080',
		descripcion: 'BIOQUÍMICO  DE (ENSAYO, INDUSTRIAL, FARMACEUTICO)'
	},
	{
		codigo: '200081',
		descripcion: 'BOLSA DE COMERCIO'
	},
	{
		codigo: '200082',
		descripcion: 'BOMBERO. EXTINCIÓN DE FUEGOS POR TIERRA'
	},
	{
		codigo: '200083',
		descripcion: 'BOMBERO. EXTINCIÓN DE FUEGOS DESDE EL AIRE'
	},
	{
		codigo: '200084',
		descripcion: 'BOMBERO. EXTINCIÓN DE FUEGOS BAJO TIERRA'
	},
	{
		codigo: '200085',
		descripcion: 'BOMBERO. TAREAS ADMINISTRATIVAS SOLAMENTE'
	},
	{
		codigo: '200086',
		descripcion: 'BOMBERO. PROTECCIÓN O DEFENSA CIVIL'
	},
	{
		codigo: '200087',
		descripcion: 'BOMBERO. CHOFER'
	},
	{
		codigo: '200088',
		descripcion: 'BOTÁNICO'
	},
	{
		codigo: '200089',
		descripcion: 'BOXEO PROFESIONAL'
	},
	{
		codigo: '200090',
		descripcion: 'BUCEO PROFESIONAL'
	},
	{
		codigo: '200091',
		descripcion: 'CALDERERO . EDIFICIOS Y FÁBRICAS'
	},
	{
		codigo: '200092',
		descripcion: 'CALDERERO . REFINERÍA'
	},
	{
		codigo: '200093',
		descripcion: 'CARNICERO'
	},
	{
		codigo: '200094',
		descripcion: 'CARPINTERO, EBANISTA'
	},
	{
		codigo: '200095',
		descripcion: 'CATHC, PROFESIONAL'
	},
	{
		codigo: '200096',
		descripcion: 'CEMENTO (FABRICACIÓN Y EMPAQUE)'
	},
	{
		codigo: '200097',
		descripcion: 'CENTRO NOCTURNO (CABARETISTA, VEDETTE, EMPLEADO DE'
	},
	{
		codigo: '200098',
		descripcion: 'CERÁMICA, ALFARERO, CERAMISTA'
	},
	{
		codigo: '200099',
		descripcion: 'CERRAJERO'
	},
	{
		codigo: '200100',
		descripcion: 'CICLISMO PROFESIONAL (BICICLETA DE COMPETCIÓN O DE'
	},
	{
		codigo: '200101',
		descripcion: 'CIRUJANO'
	},
	{
		codigo: '200102',
		descripcion: 'COBRADOR (EN ZONAS NO PELIGROSAS, TALES COMO CONFL'
	},
	{
		codigo: '200103',
		descripcion: 'COBRADOR (EN ZONAS PELIGROSAS)'
	},
	{
		codigo: '200104',
		descripcion: 'COMBUSTIBLE. EXPENDEDOR DE COMBUSTIBLES (GASOLINA,'
	},
	{
		codigo: '200105',
		descripcion: 'COMBUSTIBLE. TRANSPORTE DE SUSTANCIAS INFLAMABLES'
	},
	{
		codigo: '200106',
		descripcion: 'COMERCIO.EN ZONA DE CONFLICTO O PELIGROSAS'
	},
	{
		codigo: '200107',
		descripcion: 'COMERCIO. TODAS LAS DEMAS'
	},
	{
		codigo: '200108',
		descripcion: 'COMPUTACIÓN E INFOMÁTICA.  INGENIERO'
	},
	{
		codigo: '200109',
		descripcion: 'COMPUTACIÓN E INFOMÁTICA.  PROGRAMADOR'
	},
	{
		codigo: '200110',
		descripcion: 'CONDUCTOR AMBULANCIA'
	},
	{
		codigo: '200111',
		descripcion: 'CONDUCTOR  AUTOBUSES'
	},
	{
		codigo: '200112',
		descripcion: 'CONDUCTOR TAXI'
	},
	{
		codigo: '200113',
		descripcion: 'ARQUITECTO, APAREJADOR, MAESTRO MAYOR DE OBRAS'
	},
	{
		codigo: '200114',
		descripcion: 'SUPERVISOR, MAESTRO DE OBRAS'
	},
	{
		codigo: '200115',
		descripcion: 'ALBAÑIL'
	},
	{
		codigo: '200116',
		descripcion: 'PRODUCCIÓN, EMPAQUETADO DE CEMENTO'
	},
	{
		codigo: '200117',
		descripcion: 'CARPINTERO EN CONSTRUCCIÓN'
	},
	{
		codigo: '200118',
		descripcion: 'TALADRADORES'
	},
	{
		codigo: '200119',
		descripcion: 'ENSAMBLADORES ESTRUCTURAS METÁLICAS'
	},
	{
		codigo: '200120',
		descripcion: 'CONTABLE'
	},
	{
		codigo: '200121',
		descripcion: 'CONTADOR'
	},
	{
		codigo: '200122',
		descripcion: 'CONTROLADOR AÉREO'
	},
	{
		codigo: '200123',
		descripcion: 'CRISTALERO'
	},
	{
		codigo: '200124',
		descripcion: 'CÓNSUL'
	},
	{
		codigo: '200125',
		descripcion: 'EMBAJADOR'
	},
	{
		codigo: '200126',
		descripcion: 'EMPLEADO DE EMBAJADA'
	},
	{
		codigo: '200127',
		descripcion: 'CUERPO DIPLOMÁTICO EN ZONAS CON PELIGROS ESPECIALE'
	},
	{
		codigo: '200128',
		descripcion: 'CURTIDOR'
	},
	{
		codigo: '200130',
		descripcion: 'DEMOLICION  CON USO DE EXPLOSIVOS'
	},
	{
		codigo: '200131',
		descripcion: 'DEMOLICION   SIN USO DE EXPLOSIVOS'
	},
	{
		codigo: '200132',
		descripcion: 'DENTISTA / ODONTÓLOGO'
	},
	{
		codigo: '200133',
		descripcion: 'DOCKER'
	},
	{
		codigo: '200134',
		descripcion: 'DRAGA, DRENAJE'
	},
	{
		codigo: '200135',
		descripcion: 'DROGUERO'
	},
	{
		codigo: '200136',
		descripcion: 'EBANISTA'
	},
	{
		codigo: '200137',
		descripcion: 'EDUCADOR'
	},
	{
		codigo: '200138',
		descripcion: 'EJECUTIVO DE CUENTAS'
	},
	{
		codigo: '200139',
		descripcion: 'CABLEADORES - ELÉCTRICO'
	},
	{
		codigo: '200140',
		descripcion: 'CONTROLADORES ELÉCTRICO'
	},
	{
		codigo: '200141',
		descripcion: 'ELECTRICISTA BAJA TENSIÓN'
	},
	{
		codigo: '200142',
		descripcion: 'ELECTRICISTA DE LA CONSTRUCCIÓN'
	},
	{
		codigo: '200143',
		descripcion: 'ELECTRICISTA EDIFICIOS, CASAS Y CAMINOS'
	},
	{
		codigo: '200144',
		descripcion: 'ELECTRISISTA ALTA TENSIÓN'
	},
	{
		codigo: '200145',
		descripcion: 'MONTADOR ELÉCTRICO'
	},
	{
		codigo: '200146',
		descripcion: 'OPERADORES ELÉCTRICOS'
	},
	{
		codigo: '200148',
		descripcion: 'ESPECTÁCULOS CIRCENSES'
	},
	{
		codigo: '200149',
		descripcion: 'ESTIBADOR'
	},
	{
		codigo: '200150',
		descripcion: 'EXPLOSIVOS'
	},
	{
		codigo: '200151',
		descripcion: 'CONDUCTORES - SERVICIO DE TRANSPORTE FERROVIARIO/R'
	},
	{
		codigo: '200152',
		descripcion: 'FERROCARRILES (OTRA PROFESIÓN RELACIONADA)'
	},
	{
		codigo: '200153',
		descripcion: 'MANTENCIÓN DE VÍAS, SEÑALES Y LÍNEAS DE PODER'
	},
	{
		codigo: '200154',
		descripcion: 'MAQUINISTA DE FERROCARRIL'
	},
	{
		codigo: '200155',
		descripcion: 'PERSONAL DE CARGA DE FERROCARRIL'
	},
	{
		codigo: '200156',
		descripcion: 'FRESADOR/FORJADOR'
	},
	{
		codigo: '200157',
		descripcion: 'FUERAS ARMADAS SOLO TAREAS ADMINISTRATIVAS'
	},
	{
		codigo: '200158',
		descripcion: 'FUERAS ARMADAS RESTO'
	},
	{
		codigo: '200159',
		descripcion: 'FUERZAS ESPECIALES   BRIGADA NARCOTRÁFICO'
	},
	{
		codigo: '200160',
		descripcion: 'FUERZAS ESPECIALES   BRIGADA ANTITERRORISTA'
	},
	{
		codigo: '200161',
		descripcion: 'FUMIGACIÓN:    TERRESTRE'
	},
	{
		codigo: '200162',
		descripcion: 'FUMIGACIÓN:  AEREA'
	},
	{
		codigo: '200163',
		descripcion: 'GAS. CAPATAZ SUPERVISOR'
	},
	{
		codigo: '200164',
		descripcion: 'GAS. OBRERO, INSTALADOR'
	},
	{
		codigo: '200165',
		descripcion: 'GEÓLOGOS:'
	},
	{
		codigo: '200166',
		descripcion: 'GUARDABOSQUES'
	},
	{
		codigo: '200167',
		descripcion: 'GURADAESPALDAS'
	},
	{
		codigo: '200168',
		descripcion: 'GURADAVIDAS, SALVAVIDAS (OTRAS)'
	},
	{
		codigo: '200169',
		descripcion: 'GURADAVIDAS, SALVAVIDAS (PISCINAS)'
	},
	{
		codigo: '200170',
		descripcion: 'HOSTELERIA'
	},
	{
		codigo: '200171',
		descripcion: 'HÍPICA:  DOMADOR'
	},
	{
		codigo: '200172',
		descripcion: 'HÍPICA:  SALTO DE OBSTÁCULOS'
	},
	{
		codigo: '200173',
		descripcion: 'HÍPICA:   JOCKEY, CARRERA DE CABALLO'
	},
	{
		codigo: '200174',
		descripcion: 'HÍPICA:   POLO O PATO'
	},
	{
		codigo: '200175',
		descripcion: 'HÍPICA:   RODEO'
	},
	{
		codigo: '200176',
		descripcion: 'HÍPICA:  PROFESOR'
	},
	{
		codigo: '200177',
		descripcion: 'HÍPICA:  CHARRERÍA'
	},
	{
		codigo: '200178',
		descripcion: 'INDUSTRIA AUTOMOVILISTICA'
	},
	{
		codigo: '200179',
		descripcion: 'INDUSTRIA CÁRNICA'
	},
	{
		codigo: '200180',
		descripcion: 'INDUSTRIA MADERERA'
	},
	{
		codigo: '200181',
		descripcion: 'INDUSTRIA MANUFACTURERA'
	},
	{
		codigo: '200182',
		descripcion: 'INDUSTRIA PETROQUÍMICA:   BÚSQUEDA, EXPLORACIÓN A'
	},
	{
		codigo: '200183',
		descripcion: 'INDUSTRIA PETROQUÍMICA:    VIGILANCIA DE REFINERÍA'
	},
	{
		codigo: '200184',
		descripcion: 'INDUSTRIA PETROQUÍMICA:   MANTENIMIENTO DE CUBAS O'
	},
	{
		codigo: '200185',
		descripcion: 'INDUSTRIA PETROQUÍMICA:    PLATAFORMAS PETROLÍFERA'
	},
	{
		codigo: '200186',
		descripcion: 'INDUSTRIA QUÍMICA:     SUSTANCIAS PELIGROSAS'
	},
	{
		codigo: '200187',
		descripcion: 'INDUSTRIA QUÍMICA:     OTRAS SUSTANCIAS'
	},
	{
		codigo: '200188',
		descripcion: 'INDUSTRIAS BIOQUÍMICA Y FARMACÉUTICA'
	},
	{
		codigo: '200189',
		descripcion: 'INGENIERÍA:   INGENIERÍA FORESTAL'
	},
	{
		codigo: '200190',
		descripcion: 'INGENIERÍA:   INGENIERO AGRÓNOMO'
	},
	{
		codigo: '200191',
		descripcion: 'INGENIERÍA:   INGENIERO ELÉCTRICO'
	},
	{
		codigo: '200192',
		descripcion: 'INGENIERÍA:    INGENIERO ELECTRÓNICO'
	},
	{
		codigo: '200193',
		descripcion: 'INGENIERÍA:    INGENIERO CIVIL'
	},
	{
		codigo: '200194',
		descripcion: 'FABRICACIÓN DE INSECTICIDAS'
	},
	{
		codigo: '200195',
		descripcion: 'INSTALCIÓN DE CAÑERIAS'
	},
	{
		codigo: '200196',
		descripcion: 'INSTALCIÓN O COLOCACIÓN DE ANTENAS'
	},
	{
		codigo: '200197',
		descripcion: 'DETECTIVE PRIVADO'
	},
	{
		codigo: '200198',
		descripcion: 'LEÑADOR'
	},
	{
		codigo: '200199',
		descripcion: 'MANIPULACIÓN DE RESIDUOS PATOLÓGICOS'
	},
	{
		codigo: '200200',
		descripcion: 'MANIPULACIÓN DE SUSTANCIAS CORROSIVAS, TÓXICAS'
	},
	{
		codigo: '200201',
		descripcion: 'MANUFACTURAS DE ARMAS'
	},
	{
		codigo: '200202',
		descripcion: 'CONDUCTOR DE GRUA, EXCAVADORAS, APLANADORAS, ETC.'
	},
	{
		codigo: '200203',
		descripcion: 'OPERADOR DE MÁQUINAS DE OBRA Y MOVIMIENTO DE TIERR'
	},
	{
		codigo: '200204',
		descripcion: 'MARINA. MERCANTE ZONAS SIN RIESGO DE RESIDENCIA'
	},
	{
		codigo: '200205',
		descripcion: 'MATADERO'
	},
	{
		codigo: '200206',
		descripcion: 'MECÁNICA'
	},
	{
		codigo: '200207',
		descripcion: 'METALURGIA'
	},
	{
		codigo: '200208',
		descripcion: 'MINERÍA   TAREAS EN SUPERFICIES'
	},
	{
		codigo: '200209',
		descripcion: 'MINERÍA   TAREAS BAJO TIERRA'
	},
	{
		codigo: '200210',
		descripcion: 'MINERÍA   MANEJO DE EXPLOSIVOS'
	},
	{
		codigo: '200211',
		descripcion: 'PELUQUERO, PELUQUERA'
	},
	{
		codigo: '200212',
		descripcion: 'PERIODISMO EN ZONA DE CONFLICTO'
	},
	{
		codigo: '200213',
		descripcion: 'PERIODISMO, TODOS LOS DEMÁS'
	},
	{
		codigo: '200214',
		descripcion: 'FOTÓGRAFO EN ZONA DE CONFLICTO'
	},
	{
		codigo: '200215',
		descripcion: 'FOTÓGRAFO, TODOS LOS DEMÁS'
	},
	{
		codigo: '200216',
		descripcion: 'PERSONAL DE ADUANA, FRONTERA              ZONAS SI'
	},
	{
		codigo: '200217',
		descripcion: 'PERSONAL DE ADUANA, FRONTERA          ZONAS DE RIE'
	},
	{
		codigo: '200219',
		descripcion: 'PESCA. EN COSTAS, RÍOS Y LAGOS'
	},
	{
		codigo: '200220',
		descripcion: 'PIROTECNIA, JUEGOS PIROTÉCNICOS'
	},
	{
		codigo: '200221',
		descripcion: 'POLICÍA/G.CIVIL: SOLO TAREAS ADMINISTRATIVAS'
	},
	{
		codigo: '200222',
		descripcion: 'POLICÍA:   MUNICIPAL'
	},
	{
		codigo: '200223',
		descripcion: 'POLICÍA:   PROVINCIAL O AUTONÓMICA'
	},
	{
		codigo: '200224',
		descripcion: 'POLICÍA/G.CIVIL: NACIONAL O FEDERAL'
	},
	{
		codigo: '200225',
		descripcion: 'POLICÍA/G.CIVIL: DESACTIVACIÓN DE EXPLOSIVOS'
	},
	{
		codigo: '200226',
		descripcion: 'POLICÍA/G.CIVIL: FRONTERAS,GENDARMERÍA'
	},
	{
		codigo: '200227',
		descripcion: 'POLICÍA/G.CIVIL: BRIGADA NARCOTRÁFICO'
	},
	{
		codigo: '200228',
		descripcion: 'POLICÍA/G.CIVIL: BRIGADA ANTITERRORISTA'
	},
	{
		codigo: '200229',
		descripcion: 'POLICÍA/G.CIVIL: CARCELERO, GUARDIA DE CARCEL'
	},
	{
		codigo: '200230',
		descripcion: 'PSIQUIATRA'
	},
	{
		codigo: '200231',
		descripcion: 'PUBLICIDAD. AÉREA'
	},
	{
		codigo: '200232',
		descripcion: 'PUBLICIDAD. TODOS LOS DEMÁS'
	},
	{
		codigo: '200233',
		descripcion: 'RADIOLOGÍA'
	},
	{
		codigo: '200234',
		descripcion: 'REPARTIDOR EN MOTO'
	},
	{
		codigo: '200235',
		descripcion: 'REPARTIDOR EN AUTO, FURGONETA O CAMIÓN'
	},
	{
		codigo: '200236',
		descripcion: 'SERVICIO DOMÉSTICO'
	},
	{
		codigo: '200237',
		descripcion: 'SERVICIOS DE CARGA Y DESCARGA'
	},
	{
		codigo: '200238',
		descripcion: 'VIGILANTE SIN PORTAR ARMAS'
	},
	{
		codigo: '200239',
		descripcion: 'VIGILANTE PORTADOR DE ARMAS'
	},
	{
		codigo: '200240',
		descripcion: 'TAPICERIA'
	},
	{
		codigo: '200241',
		descripcion: 'CAMARÓGRAFO, ERAMAN, CÁMARA, TV:    EN ZONA DE CON'
	},
	{
		codigo: '200242',
		descripcion: 'CAMARÓGRAFO, ERAMAN, CÁMARA, TV:     TODOS LOS DEM'
	},
	{
		codigo: '200243',
		descripcion: 'TOPÓGRAFOS'
	},
	{
		codigo: '200244',
		descripcion: 'TORERO'
	},
	{
		codigo: '200245',
		descripcion: 'TRABAJOS EN ALTURA'
	},
	{
		codigo: '200246',
		descripcion: 'TRANSPORTE TERRESTRE: MERCANCÍA NO PELIGROSAS'
	},
	{
		codigo: '200247',
		descripcion: 'TRANSPORTE TERRESTRE:  CARGAS PESADAS, GANADAO'
	},
	{
		codigo: '200248',
		descripcion: 'TRANSPORTE TERRESTRE:   SUSTANCIAS PELIGROSAS (TÓX'
	},
	{
		codigo: '200249',
		descripcion: 'TRANSPORTE TERRESTRE:   VALORES CAUDALES,'
	},
	{
		codigo: '200250',
		descripcion: 'TRANSPORTE TERRESTRE:  TRANSPORTE DE PERSONAS (TAX'
	},
	{
		codigo: '200251',
		descripcion: 'FISIOTERAPEUTA / KINESIÓLOGO'
	},
	{
		codigo: '200252',
		descripcion: 'KIROPRÁCTICO /  OSTEÓPATA'
	},
	{
		codigo: '200253',
		descripcion: 'HERRERO'
	},
	{
		codigo: '200254',
		descripcion: 'JUBILADO'
	},
	{
		codigo: '200255',
		descripcion: 'MEDICINA GENERAL'
	},
	{
		codigo: '200256',
		descripcion: 'ESTUDIANTE'
	},
	{
		codigo: '200257',
		descripcion: 'FUTBOLISTA PROFESIONAL'
	},
	{
		codigo: '200258',
		descripcion: 'ALUMNOPILOTO'
	},
	{
		codigo: '200259',
		descripcion: 'INSTRUCTOR DE VUELO'
	},
	{
		codigo: '200260',
		descripcion: 'PILOTO VUELO < 200 H/AÑOS'
	},
	{
		codigo: '200261',
		descripcion: 'PILOTO VUELO 200-500 H/AÑOS'
	},
	{
		codigo: '200262',
		descripcion: 'PINTOR ( NO TRABAJOS EN ALTURA)'
	},
	{
		codigo: '200263',
		descripcion: 'MILITAR'
	},
	{
		codigo: '200264',
		descripcion: 'TRANSPORTADOR TORO DE FUEGO'
	},
	{
		codigo: '200265',
		descripcion: 'LOTERIAS, ADMINISTRADOR'
	},
	{
		codigo: '200266',
		descripcion: 'BUTANO, REPARTO DE'
	},
	{
		codigo: '200267',
		descripcion: 'VETERINARIO'
	},
	{
		codigo: '200268',
		descripcion: 'ANTIBIOTICOS'
	},
	{
		codigo: '200269',
		descripcion: 'AYUDANTE TÉCNICO SANITARIO'
	},
	{
		codigo: '200270',
		descripcion: 'COMADRONA'
	},
	{
		codigo: '200271',
		descripcion: 'CLÍNICA, AUXILIAR DE'
	},
	{
		codigo: '200272',
		descripcion: 'OPTICO'
	},
	{
		codigo: '200273',
		descripcion: 'PODOLOGO'
	},
	{
		codigo: '200274',
		descripcion: 'MATEMATICO'
	},
	{
		codigo: '200275',
		descripcion: 'PEDAGOGO'
	},
	{
		codigo: '200276',
		descripcion: 'PROFESOR IDIOMAS'
	},
	{
		codigo: '200277',
		descripcion: 'INSPECTOR DE EDUCACION'
	},
	{
		codigo: '200278',
		descripcion: 'PUERICULTOR'
	},
	{
		codigo: '200279',
		descripcion: 'PROFESOR DE INFORMATICA'
	},
	{
		codigo: '200280',
		descripcion: 'CINEMATOGRAFIA, OPERADOR DE'
	},
	{
		codigo: '200281',
		descripcion: 'PROFESOR DE BAILE'
	},
	{
		codigo: '200282',
		descripcion: 'PRODUCCIÓN-REALIZACIÓN T.V.'
	},
	{
		codigo: '200283',
		descripcion: 'MATADOR DE TOROS'
	},
	{
		codigo: '200284',
		descripcion: 'REJONEADOR'
	},
	{
		codigo: '200285',
		descripcion: 'MATADOR DE NOVILLOS'
	},
	{
		codigo: '200286',
		descripcion: 'BINGO, EMPLEADO DE'
	},
	{
		codigo: '200287',
		descripcion: 'PROFESOR DE GIMNASIA'
	},
	{
		codigo: '200288',
		descripcion: 'PSICOLOGO'
	},
	{
		codigo: '200289',
		descripcion: 'CONSEJERO'
	},
	{
		codigo: '200290',
		descripcion: 'POZOS SÉPTICOS, LIMPIADOR'
	},
	{
		codigo: '200291',
		descripcion: 'ESTETICISTA'
	},
	{
		codigo: '200292',
		descripcion: 'CÁRCELES, DIRECTOR DE'
	},
	{
		codigo: '200293',
		descripcion: 'CÁRCELES, JEFE DE SERVICIO EN'
	},
	{
		codigo: '200294',
		descripcion: 'MÉDICO DE PRISIONES'
	},
	{
		codigo: '200295',
		descripcion: 'JARDINERO'
	},
	{
		codigo: '200296',
		descripcion: 'CHACINERO'
	},
	{
		codigo: '200297',
		descripcion: 'TOCINERO CON MATANZA'
	},
	{
		codigo: '200298',
		descripcion: 'ZAPATERO'
	},
	{
		codigo: '200299',
		descripcion: 'FONTANERO'
	},
	{
		codigo: '200300',
		descripcion: 'VIDRIERO'
	},
	{
		codigo: '200301',
		descripcion: 'VIDRIO, GRABADOR DE'
	},
	{
		codigo: '200302',
		descripcion: 'CONDUCTOR PROF. VEHÍCULO PARTICULAR'
	},
	{
		codigo: '200303',
		descripcion: 'CONDUCTOR TRENES EN FERIAS'
	},
	{
		codigo: '200304',
		descripcion: 'BASURERO'
	},
	{
		codigo: '200305',
		descripcion: 'ANIMALES, CRIADOR DE'
	},
	{
		codigo: '200306',
		descripcion: 'VIAJANTE'
	},
	{
		codigo: '200307',
		descripcion: 'CÁRCELES, ADMINISTRATIVO EN'
	},
	{
		codigo: '200308',
		descripcion: 'CAMARERO'
	},
	{
		codigo: '200309',
		descripcion: 'DELINEANTE'
	},
	{
		codigo: '200310',
		descripcion: 'VIVERISTA'
	},
	{
		codigo: '200311',
		descripcion: 'TEJEDOR'
	},
	{
		codigo: '200312',
		descripcion: 'VENDEDOR AMBULANTE'
	},
	{
		codigo: '200314',
		descripcion: 'PINTOR CON TRABAJOS EN ALTURAS'
	},
	{
		codigo: '200315',
		descripcion: 'PESCADERO'
	},
	{
		codigo: '200316',
		descripcion: 'MOLINERO'
	},
	{
		codigo: '200317',
		descripcion: 'CONDUCTOR MAQUINARIA AGRICOLA.'
	},
	{
		codigo: '200318',
		descripcion: 'GRANJERO'
	},
	{
		codigo: '200320',
		descripcion: 'ORTOPEDICO'
	},
	{
		codigo: '200321',
		descripcion: 'MARMOLISTA'
	},
	{
		codigo: '200322',
		descripcion: 'CARTERO'
	},
	{
		codigo: '200325',
		descripcion: 'QUIMICO SIN SUSTANCIAS PELIGROSAS'
	},
	{
		codigo: '200327',
		descripcion: 'INGENIERO,EXCLUIDOS MINAS'
	},
	{
		codigo: '200328',
		descripcion: 'GEOGRAFO'
	},
	{
		codigo: '200331',
		descripcion: 'DIBUJANTE TECNICO'
	},
	{
		codigo: '200332',
		descripcion: 'TOPOGRAFO, CON VISITA A OBRAS'
	},
	{
		codigo: '200333',
		descripcion: 'MEDICO-MEDICINA GENERAL'
	},
	{
		codigo: '200334',
		descripcion: 'MEDICO ESPECIALISTA'
	},
	{
		codigo: '200335',
		descripcion: 'MEDICO RADIOLOGO'
	},
	{
		codigo: '200336',
		descripcion: 'MEDICO, RESTO'
	},
	{
		codigo: '200337',
		descripcion: 'MEDICO ODONTOLOGO'
	},
	{
		codigo: '200338',
		descripcion: 'FARMACEUTICO'
	},
	{
		codigo: '200339',
		descripcion: 'SANITARIO, PERSONAL'
	},
	{
		codigo: '200341',
		descripcion: 'FISIOTERAPEUTA'
	},
	{
		codigo: '200343',
		descripcion: 'CONDUCTOR AMBULANCIAS'
	},
	{
		codigo: '200345',
		descripcion: 'LABORATORIO, TRABAJO EN'
	},
	{
		codigo: '200346',
		descripcion: 'INFORMATICO, SIN VIAJES'
	},
	{
		codigo: '200347',
		descripcion: 'ECONOMISTA'
	},
	{
		codigo: '200349',
		descripcion: 'NOTARIO'
	},
	{
		codigo: '200350',
		descripcion: 'ASESOR JURIDICO'
	},
	{
		codigo: '200351',
		descripcion: 'PROFESOR DE EDUCACION, EXCEPTO DEPORTES'
	},
	{
		codigo: '200354',
		descripcion: 'PERIODISTA SIN REPORTAJES PELIGROSOS'
	},
	{
		codigo: '200355',
		descripcion: 'DISEÑADOR'
	},
	{
		codigo: '200356',
		descripcion: 'FOTOGRAFO EN ESTUDIO'
	},
	{
		codigo: '200357',
		descripcion: 'FOTOGRAFO DE PRENSA'
	},
	{
		codigo: '200359',
		descripcion: 'DEPORTISTA PROFESIONAL'
	},
	{
		codigo: '200361',
		descripcion: 'ASISTENTE SOCIAL'
	},
	{
		codigo: '200362',
		descripcion: 'GRADUADO SOCIAL'
	},
	{
		codigo: '200363',
		descripcion: 'PUBLICISTA'
	},
	{
		codigo: '200364',
		descripcion: 'RELACIONES PUBLICAS'
	},
	{
		codigo: '200365',
		descripcion: 'DIRECTIVO, SIN VIAJES'
	},
	{
		codigo: '200366',
		descripcion: 'APAREJADOR CON VISITA A OBRAS'
	},
	{
		codigo: '200367',
		descripcion: 'ADMINISTRATIVO(NO CARCELES)SIN VIAJES'
	},
	{
		codigo: '200368',
		descripcion: 'ADMINISTRATIVO(NO CARCELES)CON VIAJES'
	},
	{
		codigo: '200369',
		descripcion: 'RECEPCIONISTA DE HOTEL'
	},
	{
		codigo: '200370',
		descripcion: 'COMERCIANTE EN LOCAL'
	},
	{
		codigo: '200371',
		descripcion: 'COMERCIANTE, FRECUENTES VIAJES'
	},
	{
		codigo: '200372',
		descripcion: 'COMERCIO,PROPIETARIO DE'
	},
	{
		codigo: '200373',
		descripcion: 'JEFE DE VENTAS EN PLAZA'
	},
	{
		codigo: '200374',
		descripcion: 'VISITADOR MEDICO'
	},
	{
		codigo: '200375',
		descripcion: 'AGENTE COMERCIAL, SIN VIAJES'
	},
	{
		codigo: '200376',
		descripcion: 'AGENTE VENTAS, CON VIAJES'
	},
	{
		codigo: '200377',
		descripcion: 'AGENTE COMERCIAL, CON VIAJES'
	},
	{
		codigo: '200378',
		descripcion: 'AGENTE VENTAS, SIN VIAJES'
	},
	{
		codigo: '200379',
		descripcion: 'AGENTE DE SEGUROS, SIN VIAJES'
	},
	{
		codigo: '200380',
		descripcion: 'AGENTE DE SEGUROS, CON VIAJES'
	},
	{
		codigo: '200381',
		descripcion: 'COMERCIO,EMPLEADO DE'
	},
	{
		codigo: '200382',
		descripcion: 'COMERCIANTE AMBULANTE'
	},
	{
		codigo: '200383',
		descripcion: 'COCINERO'
	},
	{
		codigo: '200385',
		descripcion: 'EMPLEADA DE HOGAR'
	},
	{
		codigo: '200386',
		descripcion: 'TRIPULACION DE VUELO'
	},
	{
		codigo: '200387',
		descripcion: 'LIMPIEZA EN INTERIORES, EMPLEADO DE'
	},
	{
		codigo: '200388',
		descripcion: 'PELUQUERO'
	},
	{
		codigo: '200390',
		descripcion: 'BOMBERO'
	},
	{
		codigo: '200391',
		descripcion: 'CARCELES, AUXILIAR DE'
	},
	{
		codigo: '200392',
		descripcion: 'POLICIA NACIONAL'
	},
	{
		codigo: '200393',
		descripcion: 'POLICIA MUNICIPAL'
	},
	{
		codigo: '200394',
		descripcion: 'VIGILANTE, CON ARMA DE FUEGO'
	},
	{
		codigo: '200395',
		descripcion: 'GUARDA JURADO'
	},
	{
		codigo: '200396',
		descripcion: 'VIGILANTE, SIN ARMA DE FUEGO'
	},
	{
		codigo: '200397',
		descripcion: 'AGRICOLA-GANADERO, OBRERO'
	},
	{
		codigo: '200399',
		descripcion: 'GUARDA FORESTAL'
	},
	{
		codigo: '200400',
		descripcion: 'PESCADOR'
	},
	{
		codigo: '200402',
		descripcion: 'METALURGIA, EMPLEADO (SIN FUNDICION)'
	},
	{
		codigo: '200403',
		descripcion: 'CARPINTERIA METALICA'
	},
	{
		codigo: '200406',
		descripcion: 'TEXTIL,INDUSTRIA'
	},
	{
		codigo: '200407',
		descripcion: 'ALIMENTICIA,INDUSTRIA'
	},
	{
		codigo: '200408',
		descripcion: 'CARNICERO CON MATANZA'
	},
	{
		codigo: '200409',
		descripcion: 'CARNICERO SIN MATANZA'
	},
	{
		codigo: '200410',
		descripcion: 'PANADERO'
	},
	{
		codigo: '200411',
		descripcion: 'TAPICERO'
	},
	{
		codigo: '200412',
		descripcion: 'DECORADOR-INTERIORISTA'
	},
	{
		codigo: '200414',
		descripcion: 'CALZADO,INDUSTRIA'
	},
	{
		codigo: '200417',
		descripcion: 'TORNERO'
	},
	{
		codigo: '200418',
		descripcion: 'AIRE ACONDICIONADO, MECANICO-MONTADOR'
	},
	{
		codigo: '200419',
		descripcion: 'CADENA DE MONTAJE, OPERARIO'
	},
	{
		codigo: '200420',
		descripcion: 'INFORMATICO, CON VIAJES'
	},
	{
		codigo: '200421',
		descripcion: 'MECANICO'
	},
	{
		codigo: '200422',
		descripcion: 'MECANICO DE VEHICULOS DE MOTOR'
	},
	{
		codigo: '200423',
		descripcion: 'MANTENIMIENTO DE EDIFICIOS'
	},
	{
		codigo: '200424',
		descripcion: 'MECANICO ELECTRICISTA'
	},
	{
		codigo: '200425',
		descripcion: 'ELECTRICIDAD BAJA TENSION, INSTALACION'
	},
	{
		codigo: '200426',
		descripcion: 'ELECTRICISTA BAJA TENSION'
	},
	{
		codigo: '200427',
		descripcion: 'ELECTRICISTA ALTA TENSION'
	},
	{
		codigo: '200428',
		descripcion: 'AISLANTES, MONTADOR DE'
	},
	{
		codigo: '200430',
		descripcion: 'SOLDADOR AUTOGENO'
	},
	{
		codigo: '200431',
		descripcion: 'SOLDADOR ELECTRICO'
	},
	{
		codigo: '200432',
		descripcion: 'METAL, MONTADOR DE ESTRUCTURAS DE'
	},
	{
		codigo: '200433',
		descripcion: 'IMPRENTA,TALLER DE'
	},
	{
		codigo: '200434',
		descripcion: 'PINTOR EN INTERIORES'
	},
	{
		codigo: '200435',
		descripcion: 'CEMENTO, FABRICACION'
	},
	{
		codigo: '200436',
		descripcion: 'CONSTRUCCION'
	},
	{
		codigo: '200438',
		descripcion: 'YESERO'
	},
	{
		codigo: '200439',
		descripcion: 'ALMACEN, CARGA Y DESCARGA'
	},
	{
		codigo: '200440',
		descripcion: 'EMBALADOR A MANO'
	},
	{
		codigo: '200441',
		descripcion: 'CONDUCTOR DE GRUA'
	},
	{
		codigo: '200442',
		descripcion: 'CONDUCTOR DE EXCAVADORA'
	},
	{
		codigo: '200443',
		descripcion: 'REPARTIDOR SIN CICLOMOTOR/MOTOCICLETA'
	},
	{
		codigo: '200444',
		descripcion: 'TAXISTA'
	},
	{
		codigo: '200445',
		descripcion: 'CONDUCTOR AUTOBUSES'
	},
	{
		codigo: '200446',
		descripcion: 'CONDUCTOR DE CAMIONES RESTO'
	},
	{
		codigo: '200447',
		descripcion: 'CONDUCTOR MAQUINARIA PESADA'
	},
	{
		codigo: '200448',
		descripcion: 'TRANSPORTISTA'
	},
	{
		codigo: '200452',
		descripcion: 'GERENTE, SIN VIAJES'
	},
	{
		codigo: '200453',
		descripcion: 'GERENTE, CON VIAJES'
	},
	{
		codigo: '200454',
		descripcion: 'DIRECTIVO, CON VIAJES'
	},
	{
		codigo: '200456',
		descripcion: 'GUARDIA CIVIL'
	},
	{
		codigo: '200457',
		descripcion: 'AGRICULTOR CON EMPLEO DE MAQUINARIA'
	},
	{
		codigo: '200458',
		descripcion: 'EBANISTA SIN SIERRA'
	},
	{
		codigo: '200459',
		descripcion: 'MUEBLES, MONTADOR DE'
	},
	{
		codigo: '200460',
		descripcion: 'CARPINTERO CON SIERRA'
	},
	{
		codigo: '200461',
		descripcion: 'ALMACENERO'
	},
	{
		codigo: '200462',
		descripcion: 'CONDUCTOR DE FURGONETAS'
	},
	{
		codigo: '200463',
		descripcion: 'MECANICO DE AVIONES'
	},
	{
		codigo: '200466',
		descripcion: 'ASTRONOMO'
	},
	{
		codigo: '200467',
		descripcion: 'PROTESICO DENTAL'
	},
	{
		codigo: '200468',
		descripcion: 'ARQUITECTO, SIN VISITA A OBRAS'
	},
	{
		codigo: '200469',
		descripcion: 'ARQUITECTO, CON VISITA A OBRAS'
	},
	{
		codigo: '200470',
		descripcion: 'TOPOGRAFO, SIN VISITA A OBRAS'
	},
	{
		codigo: '200471',
		descripcion: 'ARMADOR DE BUQUES'
	},
	{
		codigo: '200474',
		descripcion: 'TERAPEUTA OCUPACIONAL'
	},
	{
		codigo: '200475',
		descripcion: 'MASAJISTA'
	},
	{
		codigo: '200479',
		descripcion: 'INFORMATICA, ANALISTA DE SISTEMAS'
	},
	{
		codigo: '200480',
		descripcion: 'ASESOR FISCAL'
	},
	{
		codigo: '200481',
		descripcion: 'FRIGORISTA'
	},
	{
		codigo: '200483',
		descripcion: 'PROCURADOR DE LOS TRIBUNALES'
	},
	{
		codigo: '200485',
		descripcion: 'INDUSTRIAL, SIN VIAJES'
	},
	{
		codigo: '200486',
		descripcion: 'INDUSTRIAL, CON VIAJES'
	},
	{
		codigo: '200487',
		descripcion: 'GESTOR ADMINISTRATIVO'
	},
	{
		codigo: '200489',
		descripcion: 'FILOLOGO'
	},
	{
		codigo: '200490',
		descripcion: 'PROFESOR DE AUTO-ESCUELA'
	},
	{
		codigo: '200491',
		descripcion: 'PROFESOR DE NATACION'
	},
	{
		codigo: '200492',
		descripcion: 'DECORACIONES DE OBRA EN INTERIOR'
	},
	{
		codigo: '200494',
		descripcion: 'CAMARA, OPERADOR DE'
	},
	{
		codigo: '200495',
		descripcion: 'MUSICO'
	},
	{
		codigo: '200497',
		descripcion: 'PROFESOR DE DEPORTES, EXCEPTO NATACION'
	},
	{
		codigo: '200499',
		descripcion: 'INTERPRETE'
	},
	{
		codigo: '200500',
		descripcion: 'DIRECTOR COMERCIAL, SIN VIAJES FRECUENTES'
	},
	{
		codigo: '200501',
		descripcion: 'APAREJADOR SIN VISITA A OBRAS'
	},
	{
		codigo: '200502',
		descripcion: 'INTERMEDIARIO FINANCIERO, SIN VIAJES'
	},
	{
		codigo: '200504',
		descripcion: 'TELEFONISTA'
	},
	{
		codigo: '200505',
		descripcion: 'ADMINISTRATIVO EN CARCELES'
	},
	{
		codigo: '200506',
		descripcion: 'REPRESENTANTE DE COMERCIO, CON VIAJES'
	},
	{
		codigo: '200507',
		descripcion: 'REPRESENTANTE DE COMERCIO, SIN VIAJES'
	},
	{
		codigo: '200508',
		descripcion: 'AGENTE PROPIEDAD INMOBILIARIA'
	},
	{
		codigo: '200509',
		descripcion: 'MODELO PUBLICITARIO'
	},
	{
		codigo: '200510',
		descripcion: 'FRUTERIA, EMPLEADO DE'
	},
	{
		codigo: '200511',
		descripcion: 'CONSERJE'
	},
	{
		codigo: '200512',
		descripcion: 'LIMPIEZA EN EXTERIORES, EMPLEADO DE'
	},
	{
		codigo: '200513',
		descripcion: 'AUTOMOVILES, LIMPIEZA DE'
	},
	{
		codigo: '200514',
		descripcion: 'LAVANDERIA, EMPLEADO DE'
	},
	{
		codigo: '200515',
		descripcion: 'POLICIA, INSPECTOR DE'
	},
	{
		codigo: '200516',
		descripcion: 'CARCELES, OFICIAL DE'
	},
	{
		codigo: '200518',
		descripcion: 'GUIA DE TURISMO'
	},
	{
		codigo: '200519',
		descripcion: 'DESINFECCION-DESINSECTACION'
	},
	{
		codigo: '200520',
		descripcion: 'AGRICOLA, TECNICO'
	},
	{
		codigo: '200521',
		descripcion: 'GANADERO SIN RESES BRAVAS'
	},
	{
		codigo: '200522',
		descripcion: 'GANADERO,GANADO DE CARNE Y LANA'
	},
	{
		codigo: '200523',
		descripcion: 'MEDICO PSIQUIATRA'
	},
	{
		codigo: '200524',
		descripcion: 'HORTICULTOR'
	},
	{
		codigo: '200525',
		descripcion: 'ENCARGADO, EXCLUSIVAMENTE SUPERVISION'
	},
	{
		codigo: '200526',
		descripcion: 'METALURGIA, TRABAJADOR EN HORNOS DE LA'
	},
	{
		codigo: '200527',
		descripcion: 'METALURGIA, FUNDIDOR DE LA'
	},
	{
		codigo: '200528',
		descripcion: 'ACERO, FABRICACION DE OBJETOS DE'
	},
	{
		codigo: '200529',
		descripcion: 'ENVASES METALICOS,FABRICACION'
	},
	{
		codigo: '200530',
		descripcion: 'ASERRADOR'
	},
	{
		codigo: '200531',
		descripcion: 'TINTORERIA,TRABAJO EN'
	},
	{
		codigo: '200532',
		descripcion: 'CELADOR'
	},
	{
		codigo: '200533',
		descripcion: 'CONSERVERA, INDUSTRIA'
	},
	{
		codigo: '200534',
		descripcion: 'PASTELERO'
	},
	{
		codigo: '200535',
		descripcion: 'CONFECCION'
	},
	{
		codigo: '200537',
		descripcion: 'MARROQUINERO'
	},
	{
		codigo: '200539',
		descripcion: 'FRESADOR'
	},
	{
		codigo: '200540',
		descripcion: 'AIRE ACONDICIONADO, FABRICACION APARATOS'
	},
	{
		codigo: '200541',
		descripcion: 'MAQUINARIA LIGERA O PRECISION, MONTADOR DE'
	},
	{
		codigo: '200542',
		descripcion: 'MAQUINARIA PESADA, MONTADOR DE'
	},
	{
		codigo: '200543',
		descripcion: 'MECANICO DE MOTOCICLETAS'
	},
	{
		codigo: '200544',
		descripcion: 'ASCENSORES, INSTALACION'
	},
	{
		codigo: '200545',
		descripcion: 'APARATOS ELECTRONICOS, REPARACION DE'
	},
	{
		codigo: '200546',
		descripcion: 'TECNICO RADIO Y TELEVISION'
	},
	{
		codigo: '200547',
		descripcion: 'ELECTRODOMESTICOS, REPARACION DE'
	},
	{
		codigo: '200548',
		descripcion: 'EQUIPOS TELEFONICOS, INSTALADOR DE'
	},
	{
		codigo: '200549',
		descripcion: 'CABLEADOS, FABRICACION DE'
	},
	{
		codigo: '200550',
		descripcion: 'ROTULOS GRAFICOS, INSTALACION'
	},
	{
		codigo: '200551',
		descripcion: 'GAS, INSTALACION'
	},
	{
		codigo: '200552',
		descripcion: 'CALEFACCION, INSTALACION'
	},
	{
		codigo: '200553',
		descripcion: 'CALEFACCION, MANTENIMIENTO'
	},
	{
		codigo: '200554',
		descripcion: 'CHAPISTA'
	},
	{
		codigo: '200555',
		descripcion: 'PIZARRA, MONTADOR DE TEJADOS DE'
	},
	{
		codigo: '200556',
		descripcion: 'ALUMINIO, MONTADOR DE'
	},
	{
		codigo: '200557',
		descripcion: 'JOYERO'
	},
	{
		codigo: '200558',
		descripcion: 'PORCELANA, FABRICACION DE'
	},
	{
		codigo: '200560',
		descripcion: 'PLASTICO INYECTADO,FABRICACION'
	},
	{
		codigo: '200562',
		descripcion: 'MARINERO'
	},
	{
		codigo: '200563',
		descripcion: 'PINTOR DE AUTOMOVILES'
	},
	{
		codigo: '200564',
		descripcion: 'CEMENTO, FABRICACION ARTICULOS'
	},
	{
		codigo: '200565',
		descripcion: 'SOLADOR'
	},
	{
		codigo: '200566',
		descripcion: 'HORMIGON, MONTADOR ESTRUCTURAS DE'
	},
	{
		codigo: '200567',
		descripcion: 'ENCOFRADOR'
	},
	{
		codigo: '200568',
		descripcion: 'FERRALLISTA'
	},
	{
		codigo: '200569',
		descripcion: 'PARQUETISTA-ENTARIMADOR'
	},
	{
		codigo: '200570',
		descripcion: 'ESCAYOLISTA'
	},
	{
		codigo: '200572',
		descripcion: 'PERSIANAS, INSTALACION DE'
	},
	{
		codigo: '200573',
		descripcion: 'ESTIBADOR PORTUARIO'
	},
	{
		codigo: '200574',
		descripcion: 'REPONEDOR DE MERCANCIAS EN SUPERMERCADO'
	},
	{
		codigo: '200575',
		descripcion: 'DOBLADOR-PRENSADOR'
	},
	{
		codigo: '200576',
		descripcion: 'MENSAJERO'
	},
	{
		codigo: '200578',
		descripcion: 'GASOLINA, EXPENDEDOR DE'
	},
	{
		codigo: '200579',
		descripcion: 'EXTINTORES, INSTALACION Y RECARGA'
	},
	{
		codigo: '200580',
		descripcion: 'ACTOR SIN RIESGOS ESPECIALES'
	},
	{
		codigo: '200581',
		descripcion: 'SEGUROS SIN VIAJES, EMPLEADO DE'
	},
	{
		codigo: '200582',
		descripcion: 'TECNICO COMERCIAL DE SEGUROS'
	},
	{
		codigo: '200583',
		descripcion: 'SEGUROS CON VIAJES, EMPLEADO DE'
	},
	{
		codigo: '200585',
		descripcion: 'PERITO TASADOR'
	},
	{
		codigo: '200587',
		descripcion: 'AGRICULTOR SIN EMPLEO DE MAQUINARIA'
	},
	{
		codigo: '200588',
		descripcion: 'LEÑADOR CON SIERRA MECANICA'
	},
	{
		codigo: '200589',
		descripcion: 'ALMACEN, JEFE DE(EXCLUSIVAMENTE SUPERVISION)'
	},
	{
		codigo: '200590',
		descripcion: 'MINERO SIN EXPLOSIVOS'
	},
	{
		codigo: '200591',
		descripcion: 'DESTILERIA,INDUSTRIA DE'
	},
	{
		codigo: '200592',
		descripcion: 'CANTERO SIN EXPLOSIVOS'
	},
	{
		codigo: '200593',
		descripcion: 'MATADERO, TRABAJO EN'
	},
	{
		codigo: '200594',
		descripcion: 'EBANISTA CON SIERRA'
	},
	{
		codigo: '200595',
		descripcion: 'ASCENSORES, REPARACION'
	},
	{
		codigo: '200596',
		descripcion: 'ANTENAS, INSTALACION'
	},
	{
		codigo: '200597',
		descripcion: 'PISCINAS, MANTENIMIENTO'
	},
	{
		codigo: '200598',
		descripcion: 'ARTES GRAFICAS, EMPLEADO DE'
	},
	{
		codigo: '200599',
		descripcion: 'PINTOR EN EXTERIORES'
	},
	{
		codigo: '200600',
		descripcion: 'CONTRATISTA DE OBRAS'
	},
	{
		codigo: '200601',
		descripcion: 'CONSTRUCCION, CONTROL CALIDAD'
	},
	{
		codigo: '200602',
		descripcion: 'CARPINTERO SIN SIERRA'
	},
	{
		codigo: '200603',
		descripcion: 'CONSTRUCCION, PEON DE LA'
	},
	{
		codigo: '200604',
		descripcion: 'FISICO, SIN RIESGO NUCLEAR'
	},
	{
		codigo: '200605',
		descripcion: 'FISICO NUCLEAR'
	},
	{
		codigo: '200606',
		descripcion: 'METEOROLOGO'
	},
	{
		codigo: '200609',
		descripcion: 'PRACTICO DE PUERTO'
	},
	{
		codigo: '200610',
		descripcion: 'ZOOLOGO'
	},
	{
		codigo: '200611',
		descripcion: 'MEDICO FORENSE'
	},
	{
		codigo: '200613',
		descripcion: 'MEDICO OFTALMOLOGO'
	},
	{
		codigo: '200618',
		descripcion: 'SECRETARIO JUDICIAL'
	},
	{
		codigo: '200620',
		descripcion: 'REGISTRADOR DE LA PROPIEDAD'
	},
	{
		codigo: '200622',
		descripcion: 'SOCIOLOGO'
	},
	{
		codigo: '200623',
		descripcion: 'METEOROLOGOS EN OBSERVATORIO PELIGROSO'
	},
	{
		codigo: '200625',
		descripcion: 'SACERDOTE'
	},
	{
		codigo: '200626',
		descripcion: 'CLERIGO'
	},
	{
		codigo: '200627',
		descripcion: 'PINTOR-ARTISTA'
	},
	{
		codigo: '200628',
		descripcion: 'RESTAURADOR OBRAS ARTE'
	},
	{
		codigo: '200629',
		descripcion: 'RESTAURADOR DE EDIFICIOS'
	},
	{
		codigo: '200630',
		descripcion: 'REPRESENTANTE ARTISTICO'
	},
	{
		codigo: '200631',
		descripcion: 'PROFESIONAL ESPECTACULOS TAURINOS'
	},
	{
		codigo: '200632',
		descripcion: 'TECNICO TENIS DE MESA'
	},
	{
		codigo: '200634',
		descripcion: 'ENTRENADOR DEPORTIVO'
	},
	{
		codigo: '200640',
		descripcion: 'ASTROLOGO'
	},
	{
		codigo: '200641',
		descripcion: 'CONCEJAL DE OBRAS'
	},
	{
		codigo: '200646',
		descripcion: 'TAQUILLERO/CAJERO'
	},
	{
		codigo: '200648',
		descripcion: 'INTERMEDIARIO FINANCIERO, CON VIAJES'
	},
	{
		codigo: '200649',
		descripcion: 'JEFE DE ESTACION'
	},
	{
		codigo: '200650',
		descripcion: 'INSPECTOR DE AUTOBUSES URBANOS'
	},
	{
		codigo: '200651',
		descripcion: 'ORDENANZA'
	},
	{
		codigo: '200652',
		descripcion: 'BOTONES'
	},
	{
		codigo: '200655',
		descripcion: 'ENCUESTADOR'
	},
	{
		codigo: '200658',
		descripcion: 'CONDUCTOR LANCHA PARACAIDISMO ACUATICO'
	},
	{
		codigo: '200665',
		descripcion: 'CHURRERIA,TRABAJO EN'
	},
	{
		codigo: '200667',
		descripcion: 'PORTERO EN FINCAS URBANAS'
	},
	{
		codigo: '200670',
		descripcion: 'LIMPIADOR DE CRISTALES'
	},
	{
		codigo: '200674',
		descripcion: 'GUIA DE MONTA¥A (CON ESCALADA)'
	},
	{
		codigo: '200675',
		descripcion: 'TANATORIO, EMPLEADO DE'
	},
	{
		codigo: '200677',
		descripcion: 'AGRICOLA, LIMPIEZA FINCAS'
	},
	{
		codigo: '200679',
		descripcion: 'REGADOR'
	},
	{
		codigo: '200680',
		descripcion: 'GAS, FABRICACION'
	},
	{
		codigo: '200681',
		descripcion: 'ANILINAS, FABRICACION'
	},
	{
		codigo: '200682',
		descripcion: 'ESTAMPADOR TEXTIL'
	},
	{
		codigo: '200685',
		descripcion: 'IMPERMEABILIZADOR FACHADAS Y TEJADOS'
	},
	{
		codigo: '200686',
		descripcion: 'PASAMANERO A MANO'
	},
	{
		codigo: '200687',
		descripcion: 'PASAMANERO A MAQUINA'
	},
	{
		codigo: '200688',
		descripcion: 'TEJEDOR DE REDES A MANO'
	},
	{
		codigo: '200692',
		descripcion: 'ALMAZARERO'
	},
	{
		codigo: '200693',
		descripcion: 'ACEITE INDUSTRIAL, RECICLAJE'
	},
	{
		codigo: '200694',
		descripcion: 'TABAQUERA,INDUSTRIA'
	},
	{
		codigo: '200695',
		descripcion: 'PELETERO'
	},
	{
		codigo: '200696',
		descripcion: 'PARAGUAS Y BASTONES,FABRICACION'
	},
	{
		codigo: '200699',
		descripcion: 'GUARNICIONERO'
	},
	{
		codigo: '200700',
		descripcion: 'BARNIZADOR'
	},
	{
		codigo: '200702',
		descripcion: 'CUCHILLOS, FABRICACION'
	},
	{
		codigo: '200704',
		descripcion: 'NAVAJAS, FABRICACION DE'
	},
	{
		codigo: '200707',
		descripcion: 'FRIGORIFICOS INDUSTRIALES,INSTALACION'
	},
	{
		codigo: '200710',
		descripcion: 'RELOJES, REPARACION'
	},
	{
		codigo: '200711',
		descripcion: 'ORTOPEDICOS, MECANICO APARATOS'
	},
	{
		codigo: '200713',
		descripcion: 'LAVADO Y ENGRASE VEHICULOS A MOTOR'
	},
	{
		codigo: '200720',
		descripcion: 'SONIDO E ILUMINACION, INSTALADOR DE'
	},
	{
		codigo: '200725',
		descripcion: 'HOJALATA, FABRICACION'
	},
	{
		codigo: '200729',
		descripcion: 'ACERO, MONTADOR DE ESTRUCTURAS DE'
	},
	{
		codigo: '200731',
		descripcion: 'TRAMOYISTA'
	},
	{
		codigo: '200732',
		descripcion: 'URALITA, MONTADOR DE'
	},
	{
		codigo: '200734',
		descripcion: 'ORFEBRE-PLATERO'
	},
	{
		codigo: '200736',
		descripcion: 'GOMA,FABRICACION DE ARTICULOS'
	},
	{
		codigo: '200738',
		descripcion: 'PLASTICO SOLDADURA, FABRICACION'
	},
	{
		codigo: '200739',
		descripcion: 'NEUMATICOS, VULCANIZACION'
	},
	{
		codigo: '200740',
		descripcion: 'CELULOIDE,FABRICACION ARTICULOS'
	},
	{
		codigo: '200741',
		descripcion: 'CARTON, FABRICACION'
	},
	{
		codigo: '200742',
		descripcion: 'PAPEL, EMPLEADO FABRICA DE'
	},
	{
		codigo: '200744',
		descripcion: 'CAJAS EMBALAJES, FABRICACION'
	},
	{
		codigo: '200745',
		descripcion: 'LITOGRAFO'
	},
	{
		codigo: '200746',
		descripcion: 'ENCUADERNADOR'
	},
	{
		codigo: '200748',
		descripcion: 'MUSICA,FABRICACION INSTRUMENTOS DE'
	},
	{
		codigo: '200749',
		descripcion: 'INSTRUMENTOS MUSICALES, AFINADOR'
	},
	{
		codigo: '200750',
		descripcion: 'CESTERO'
	},
	{
		codigo: '200751',
		descripcion: 'JUGUETES,INDUSTRIA'
	},
	{
		codigo: '200752',
		descripcion: 'CERA, FABRICACION ARTICULOS'
	},
	{
		codigo: '200753',
		descripcion: 'TAXIDERMISTA'
	},
	{
		codigo: '200754',
		descripcion: 'MAMPOSTERO'
	},
	{
		codigo: '200756',
		descripcion: 'ALICATADOR'
	},
	{
		codigo: '200759',
		descripcion: 'TECHADOR'
	},
	{
		codigo: '200761',
		descripcion: 'PULIDOR'
	},
	{
		codigo: '200764',
		descripcion: 'CAL, FABRICACION'
	},
	{
		codigo: '200769',
		descripcion: 'EMBOTELLADOR'
	},
	{
		codigo: '200771',
		descripcion: 'CHATARRERO'
	},
	{
		codigo: '200773',
		descripcion: 'CAMPOS, LIMPIEZA DE'
	},
	{
		codigo: '200778',
		descripcion: 'GUIA DE MONTA¥A (SIN ESCALADA)'
	},
	{
		codigo: '200779',
		descripcion: 'ORGANIZADOR DE CACERIAS'
	},
	{
		codigo: '200782',
		descripcion: 'ENOLOGO'
	},
	{
		codigo: '200783',
		descripcion: 'PIZARRA EN FABRICA, OBRERO DE'
	},
	{
		codigo: '200785',
		descripcion: 'EQUIPOS TELEGRAFICOS, INSTALADOR DE'
	},
	{
		codigo: '200787',
		descripcion: 'ATRACCIONES DE FERIA CON MONTAJE'
	},
	{
		codigo: '200788',
		descripcion: 'ATRACCIONES DE FERIA SIN MONTAJE'
	},
	{
		codigo: '200789',
		descripcion: 'OPTICA, FABRICACION INSTRUMENTOS'
	},
	{
		codigo: '200794',
		descripcion: 'POCERO SIN EXPLOSIVOS'
	},
	{
		codigo: '200795',
		descripcion: 'POCERO CON EXPLOSIVOS'
	},
	{
		codigo: '200797',
		descripcion: 'HORNO CREMATORIO, OPERARIO'
	},
	{
		codigo: '200799',
		descripcion: 'ALCALDE'
	},
	{
		codigo: '200802',
		descripcion: 'MOSSOS D¿ESQUADRA'
	},
	{
		codigo: '200825',
		descripcion: 'PILOTO VUELO  > 500 H/AÑOS'
	},
	{
		codigo: '200826',
		descripcion: 'CONDUCTOR DE CAMIONES DE PRODUCTOS INFLAMABLES'
	},
	{
		codigo: '200827',
		descripcion: 'CONDUCTOR CAMIONES PRODUCTOS TÓXICOS O CORROSIVOS'
	},
	{
		codigo: '200828',
		descripcion: 'CONDUCTOR DE CAMIONES DE PRODUCTOS EXPLOSIVOS'
	},
	{
		codigo: '200829',
		descripcion: 'DESEMPLEADO'
	}
].map((x) => ({ label: x.descripcion, value: x.codigo }));
