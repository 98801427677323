import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';

UiWrapper.defineComponent('textField', {
	styles: {
		default: {
			color: CONFIG.colors.black,
			containerComponent: {
				width: '80%',
				backgroundColor: CONFIG.colors.grey
				// border: "1px solid #f60",
			},
			inputContainerComponent: {
				borderRadius: '30px',
				paddingLeft: 0,
				backgroundColor: 'rgb(100, 100, 100, 0.35)',
				border: '1px solid ' + CONFIG.colors.primary,
				fontFamily: CONFIG.fonts.regular
			},
			inputComponent: {
				textAlign: 'center',
				color: CONFIG.colors.black
				// padding: "16px 0 12px 0",
				// padding: "25px 0px 7px",
			},
			labelComponent: {
				fontSize: 16,
				fontWeight: 400,
				fontFamily: CONFIG.fonts.regular,
				textAlign: 'center',
				width: '90%',
				color: CONFIG.colors.grey
				// display: "none",
			}
			// noLabelMode: {
			//   inputComponent: {
			//     paddingTop: 7,
			//   },
			// },
		},

		centerSt: {
			inputComponent: {
				textAlign: 'center'
			}
		}
	}
});
