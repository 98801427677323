import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
	AnalyticsWrapper,
	BackendWrapper,
	NavigationWrapper,
	PickerHOC,
	SessionWrapper,
	TargetTypesWrapper,
	TargetsUtilsWrapper,
	TargetsWrapper,
	Text,
	UiWrapper,
	UserWrapper
} from '@coinscrap/webapp-core';

import moment from 'moment';

import { professions } from '../03_SimulationData/professions';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import InputLabel from 'components/InputLabel/InputLabel';
import Header from 'layout/components/Header/Header';

import routes from 'config/routes';
import { alcoholTypes } from 'data/alcoholTypes';
import { smokeTypes } from 'data/smokeTypes';
import { Wrapper } from 'layout/Wrapper';

import * as S from './styles';

export const LoginClient = PickerHOC({})(() => {
	// CORE WRAPPERS
	const { getInstanceApi } = BackendWrapper.use();
	const { startSession, clearSession } = SessionWrapper.use();
	const { navigateTo, query, goBack } = NavigationWrapper.use();
	const { transports, pageView, emitEvent, updateData } = AnalyticsWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { user, updateUser } = UserWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { targetTypes } = TargetTypesWrapper.use();
	const { instanciateNewTarget, userTargets } = TargetsUtilsWrapper.use();

	// BASIC STATE
	const [loading, setLoading] = useState();
	const [error, setError] = useState();

	// START PROCESS STATE
	const [creatingResources, setCreatingResources] = useState(false);
	const [targetType, setTargetType] = useState(undefined);
	const [currentTarget, setCurrentTarget] = useState(userTargets?.[0] ?? undefined);
	const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
	const [dataSaved, setDataSaved] = useState(false);

	// USER DATA
	const [birthDate, setBirthDate] = useState(user?.metadata?.birthDate || moment().subtract(18, 'years'));
	const [gender, setGender] = useState(user?.metadata?.gender || '');
	const [weight, setWeight] = useState(user?.metadata?.weight || '');
	const [height, setHeight] = useState(user?.metadata?.height || '');
	const [profession, setProfession] = useState(user?.metadata?.profession || '');

	// USER DATA FUMAS
	const [smoke, setSmoke] = useState(user?.metadata?.smoke || 'no');
	const [smokeQuantity, setSmokeQuantity] = useState(user?.metadata?.smokeQuantity || null);
	const [smokeType, setSmokeType] = useState(user?.metadata?.smokeType || 'Cigarrillos');

	//  USER DATA ALCOHOL
	const [alcohol, setAlcohol] = useState(user?.metadata?.alcohol || 'no');
	const [alcoholQuantity, setAlcoholQuantity] = useState(user?.metadata?.alcoholQuantity || null);
	const [alcoholType, setAlcoholType] = useState(user?.metadata?.alcoholType || 'Cerveza');

	// ONBOARDING ONLY KYC FLOW
	const [isKycFlow, setIsKycFlow] = useState(false);
	useEffect(() => {
		const onlyOnboarding = query?.kyc;
		setIsKycFlow(onlyOnboarding === 'true');
	}, [query?.kyc]);

	useEffect(() => {
		if (isKycFlow) {
			(async () => {
				let response;
				try {
					setLoading('Accediendo...');
					response = await getInstanceApi().loginUser(
						{
							source: query?.source || 'KYC'
						},
						'anonymous'
					);

					await startSession({ token: response.accessToken });

					localStorage.removeItem('B2Flow');
					localStorage.setItem('B2Flow', 'B2C');

					navigateTo(routes.startOnboarding, {
						clearItems: ['kyc']
					});
				} catch (e) {
					setError(e.status || true);

					let messageError = e?.message?.toString();
					messageError = 'Ha ocurrido un error. Vuelva a acceder por favor.';
					setLoading(undefined);

					openModal(
						() => (
							<ErrorModal>
								<Text headingPrimarySt>{messageError}</Text>
							</ErrorModal>
						),
						{
							disableClickAway: true,
							closeButton: false,
							modalClass: isMobile ? 'modal-xl' : 'modal-main',
							key: 'modal-OnboardingKYCClient'
						}
					);
				}
			})();
		}
		// eslint-disable-next-line
	}, [isKycFlow]);

	// CREATE RESOURCES ON BACKGROUND
	useEffect(() => {
		if (isKycFlow === false && !creatingResources) {
			setCreatingResources(true);
			(async () => {
				await nextCreateUser();
			})();
		}
		// eslint-disable-next-line
	}, [isKycFlow, creatingResources]);

	// NEXT FUNCTIONS
	const nextCreateUser = async () => {
		try {
			if (user?.metadata?.weight && user?.metadata?.height && user?.metadata?.birthDate) {
			} else {
				await clearSession();
				localStorage.removeItem('B2Flow');
				localStorage.setItem('B2Flow', 'B2C');

				const response = await getInstanceApi().loginUser(
					{
						source: query?.source || '000'
					},
					'anonymous'
				);

				await startSession({ token: response.accessToken });
			}
		} catch (e) {
			setError(e.status || true);

			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error. Vuelva a acceder por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-StartClient'
				}
			);
		}
	};

	const nextCreateTarget = async (userId, targetTypeId) => {
		try {
			const createdTarget = await instanciateNewTarget({
				targetType: targetTypeId
			});
			setCurrentTarget(createdTarget);
		} catch (e) {
			setError(e.status || true);

			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error. Reinicie el proceso por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-UpsertProductError'
				}
			);
		}
	};

	const nextCheckAnalytics = async () => {
		// console.log('🕵️‍♀️ CHECKING ANALYTICS LOADED FOR USER');
		try {
			// ESPERAMOS QUE SE HAYAN CARGADO TODOS LOS ANALYTICS EN EL CORE
			// setLoading('Comprobando...');
			const lastEngine = transports[transports?.length - 1 || 0]?.engine || '';
			if (user?.metadata?.analytics?.[lastEngine]) {
				setAnalyticsLoaded(true);

				const tealiumData = {
					page_type: 'quoting',
					process_name: 'particulares simulador riesgo vida',
					process_step: 'datos simulacion',
					process_type: 'tarificacion'
				};

				pageView({
					pageType: tealiumData.page_type,
					processName: tealiumData.process_name,
					processStep: tealiumData.process_step,
					processType: tealiumData.process_type
				});

				updateData(tealiumData);
			}
		} catch (e) {
			setError(e.status || true);

			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error. Reinicie el proceso por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-UpsertAnalyticsError'
				}
			);
		}
	};

	const nextSaveSimulationData = async (targetId) => {
		try {
			// GUARDAMOS LOS DATOS PREVIOS DE SIMULACIÓN RELATIVOS AL USUARIO
			setLoading('Guardando...');
			const currentAge = moment().diff(moment(birthDate), 'years');
			await updateUser({
				metadata: {
					...user?.metadata,
					birthDate,
					gender,
					weight: parseInt(weight),
					height: parseInt(height),
					profession,
					professionCode: professions?.find((p) => p?.label === profession)?.value,
					smoke,
					smokeQuantity: smokeQuantity || 0,
					smokeType: smokeType || undefined,
					alcohol,
					alcoholQuantity: alcoholQuantity || 0,
					alcoholType: alcoholType || undefined,
					isB2B: false
				}
			});

			// GUARDAMOS LOS DATOS PREVIOS DE SIMULACIÓN RELATIVOS AL TARGET
			setLoading('Actualizando...');
			await saveTargetProperty(targetId, 'creationData', {
				details: {
					birthDate,
					gender,
					weight: parseInt(weight),
					height: parseInt(height),
					profession,
					professionCode: professions?.find((p) => p?.label === profession)?.value,
					smoke,
					smokeQuantity: smokeQuantity || 0,
					smokeType: smokeType || undefined,
					alcohol,
					alcoholQuantity: alcoholQuantity || 0,
					alcoholType: alcoholType || undefined,
					isB2B: false
				}
			});

			if (currentAge > 69) {
				navigateTo(routes.cannotConfirm, {
					queryParams: {
						fromReason: 'SIMULATION_DETAILS',
						fromView: 'simulationData'
					}
				});
			} else {
				// TEALIUM EVENT (QUOTER CALCULATE)
				emitEvent({
					tealium_event: 'quoter_calculate',
					user_birthdate: birthDate,
					user_profession: profession,
					user_sex: gender,
					user_weight: weight,
					user_height: height
				});
				setDataSaved(true);
			}
		} catch (e) {
			setError(e.status || true);

			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error. Reinicie el proceso por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-SimulationDataError'
				}
			);
		}
	};

	const nextNavigation = async (targetId) => {
		try {
			if (dataSaved && analyticsLoaded) {
				navigateTo(routes.simulation, { routeParams: { targetId } });
			}
		} catch (e) {
			setError(e.status || true);

			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error. Reinicie el proceso por favor.';
			setLoading(undefined);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: false,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-NextNavigationaError'
				}
			);
		}
	};

	// ESPERAMOS QUE EL CORE SE ENTERE DE LOS TARGETS TYPES PARA OBTENER EL DE CASER
	useEffect(() => {
		if (!error && !targetType && targetTypes?.length) {
			const selectedTargetType = targetTypes.find((t) => t?.identifier === 'CASER_VIDA_RIESGO');
			if (selectedTargetType) {
				setTargetType(selectedTargetType);
			}
		}
	}, [error, targetTypes, targetType]);

	// ESPERAMOS QUE HAYA USER Y TARGET TYPE PARA CREAR UN NUEVO TARGET
	useEffect(() => {
		if (!error && targetType && user && !userTargets) {
			// eslint-disable-next-line no-console
			nextCreateTarget(user.id, targetType.id).catch(console.error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, targetType, user, userTargets]);

	// ESPERAMOS QUE HAYA USER PARA COMPROBAR LA CARGA DE ANALYTICS
	useEffect(() => {
		if (!error && user && !analyticsLoaded) {
			// eslint-disable-next-line no-console
			nextCheckAnalytics().catch(console.error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, user, analyticsLoaded]);

	// ESPERAMOS QUE SE HAYAN CARGADO LOS ANALYTICS Y SE HAYAN GUARDADO LOS DATOS
	useEffect(() => {
		if (!error && analyticsLoaded && dataSaved) {
			// eslint-disable-next-line no-console
			nextNavigation(currentTarget.id).catch(console.error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, dataSaved, analyticsLoaded]);

	const back = async () => {
		goBack();
	};

	const isDisabled = () => {
		return (
			!birthDate ||
			moment(birthDate).unix() > moment().subtract(18, 'years').unix() ||
			!gender ||
			!weight ||
			!height ||
			!profession ||
			(smoke === 'yes' && (smokeQuantity === null || smokeType === null)) ||
			(alcohol === 'yes' && (alcoholQuantity === null || alcoholType === null)) ||
			!currentTarget
		);
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header mobile={isMobile} buttonEnabled={analyticsLoaded} />, [analyticsLoaded]);

	return (
		<Wrapper width={'80%'}>
			<S.MainTitle>Calcula el precio de tu seguro de vida</S.MainTitle>
			<Text headingSecondarySt>
				Para poder calcular el precio de tu seguro de vida necesitamos que contestes a unas preguntas.
			</Text>
			<Text headingSecondarySt>Todos los campos son obligatorios.</Text>
			<S.FormWContainer>
				<InputLabel
					value={birthDate}
					onChange={(e) => {
						//console.log(e);
						setBirthDate(moment(e).format('YYYY-MM-DD'));
					}}
					label="Fecha de nacimiento"
					type="date"
					errorMsg={
						moment(birthDate).unix() > moment().subtract(18, 'years').unix() &&
						`La edad mínima de contratación es de 18 años`
					}
				/>
				<InputLabel
					showLabel={true}
					value={profession || ''}
					onChange={(value) => {
						setProfession(value);
					}}
					label={'Profesión'}
					type="autoSuggest"
					selectOptions={professions.map((item) => {
						return {
							label: item.label,
							value: item.label
						};
					})}
				/>
				<InputLabel
					showLabel={true}
					label="¿Cuál es tu género?"
					value={gender || ''}
					onChange={(e) => {
						setGender(e.target.value);
					}}
					type="select"
					selectOptions={[
						{ label: 'Masculino', value: 'masculino' },
						{ label: 'Femenino', value: 'femenino' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
				<InputLabel
					value={height}
					onChange={(e) => {
						if (isNaN(+e.target.value) || '') return;

						setHeight(parseInt(e.target.value || 0));
					}}
					label="¿Cuál es tu altura? (cm)"
				/>
				<InputLabel
					value={weight}
					onChange={(e) => {
						if (isNaN(+e.target.value) || '') return;
						setWeight(parseInt(e.target.value || 0));
					}}
					label="¿Cuál es tu peso? (kg)"
				/>

				<InputLabel
					value={smoke || 'no'}
					onChange={(e) => {
						setSmoke(e.target.value);
					}}
					label="¿Fumas?"
					type="select"
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
				{smoke === 'yes' && (
					<S.OptionsSmokeAndAlcohol>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={smokeQuantity}
							onChange={(e) => {
								//console.log('10 Quantity Change', e.target.value);
								setSmokeQuantity(+e.target.value);
							}}
							label="Cantidad diaria"
							type={'number'}
						/>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={smokeType}
							onChange={(e) => {
								setSmokeType(e.target.value);
							}}
							label="Tipo de tabaco"
							type="select"
							selectOptions={smokeTypes.map((item) => {
								return {
									label: item.name,
									value: item.value
								};
							})}
						/>
					</S.OptionsSmokeAndAlcohol>
				)}

				<InputLabel
					value={alcohol || 'no'}
					onChange={(e) => {
						setAlcohol(e.target.value);
					}}
					label="¿Consumes bebidas alcohólicas?"
					type="select"
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
				{alcohol === 'yes' && (
					//TODO: refactor OptionsSmokeAndAlcohol
					<S.OptionsSmokeAndAlcohol>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={alcoholQuantity}
							onChange={(e) => {
								//console.log('11 Quantity Change', e.target.value);
								setAlcoholQuantity(+e.target.value);
							}}
							label="Cantidad diaria"
							errorMsg={true}
							type={'number'}
						/>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={alcoholType}
							onChange={(e) => {
								setAlcoholType(e.target.value);
							}}
							label="Tipo de alcohol"
							type="select"
							selectOptions={alcoholTypes.map((item) => {
								return {
									label: item.name,
									value: item.value
								};
							})}
						/>
					</S.OptionsSmokeAndAlcohol>
				)}
			</S.FormWContainer>

			<BackNextButton
				onClickNext={() => nextSaveSimulationData(currentTarget?.id)}
				onClickBack={back}
				isBackShown={false}
				isNextDisabled={isDisabled()}
				nextText={isMobile ? 'SIMULAR' : 'ACCEDE A LA SIMULACIÓN'}
				justifyContent={'end'}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
