import React, { useRef, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import {
    Button,
    IdentityDocumentsWrapper,
    NavigationWrapper,
    PickerHOC,
    TargetsWrapper,
    Text,
    UiWrapper,
    UserWrapper
} from '@coinscrap/webapp-core';

import 'aliceonboarding/dist/aliceonboarding.css';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { Faq } from 'components/FAQ/Faq';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import Header from 'layout/components/Header/Header';
import { aliceOnError } from '../../libs/aliceOnError';

import { CONFIG } from 'config/config';
import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';

import * as S from './styles';

//import IframeResizer from 'iframe-resizer-react';
export const IdentityBiometric = PickerHOC({
	dniData: {
		arrayItemMatcher: {
			type: 'value',
			itemPropertyPath: 'main',
			value: true
		},
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	target: {
		sourceWrapper: TargetsWrapper,
		sourcePath: 'targets',
		arrayItemMatcher: {
			type: 'param',
			paramName: 'targetId',
			itemPropertyPath: 'id'
		}
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	}
})(() => {
	const { dniData, user, target } = PickerHOC.use();
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const { navigateTo, goBack } = NavigationWrapper.use();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, _] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	// ### DNI PROCESS
	const scanManaggerReference = useRef();
	let currentLang = 'es';

	useLoading(undefined, { loadingMessage: undefined });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);
	return (
		<Wrapper width={'80%'}>
			<HorizontalProgressBar
				step={4}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Documento de identidad' : ''}
			/>
			<IdentityDocumentsWrapper.ScanManager
				ref={scanManaggerReference}
				style={{
					marginTop: 30,
					width: '100%',
					height: isMobile ? '500px' : '100%',
					minHeight: isMobile ? '500px' : '750px',
					overflow: 'hidden'
				}}
				identityDocumentId={dniData?.id}
				mockEnabled={true}
				scanConfig={{
					// providerIdentifier: 'mock',
					additionalStages: ['selfie'],
					completeTimeout: 2000,
					// Custom "Scan" dependant parameters (for this case is Alice)
					metadata: {
						isIOS: isIOS,
						issuingCountry: 'ESP',
						// adjustHeigths: true,
						typographyTheme: 'corporate',
						iconsTheme: 'modern',
						iconsVariant: 'shape',
						language: currentLang,
						documentCapturingType: 'all',
						documentAutoCapturing: true,
						customMessages: {
							es: {
								onboarding_stages_title: 'Documento de identidad',
								onboarding_sides_title_id_card: 'Documento de identidad',
								onboarding_stages_id_card: 'Documento de identidad',
								onboarding_stages_description: 'Proceso de identificación por vídeo'
							},
							onboarding_country_selector_start: 'CONTINUAR'
						},
						customTheme: {
							alice_color_primary: CONFIG.colors.primary,
							alice_color_secondary: CONFIG.colors.grey,
							alice_color_accent: CONFIG.colors.tertiary
							/*
							NOT available in BACKEND ALL IN ONE PROCESS
						alice_title_font_family: CONFIG.fonts.regular,
						alice_font_family: CONFIG.fonts.regular,
						*/
						}
					}
				}}
				onSuccess={(data) => {
					// Respuesta OK, continuamos con lo que venga
					const aliceDNI = data?.value?.data?.report?.documents[0]?.summary_fields?.find(
						(sf) => sf?.name === 'id_number'
					)?.value;
					const sameBenefDNI = target?.creationData?.details?.beneficiaries?.find(
						(be) => be?.dni.toUpperCase() === aliceDNI.toUpperCase()
					);
					const diferentToReceived = user?.metadata?.document && aliceDNI !== user?.metadata?.document;

					if (sameBenefDNI) {
						openModal(
							(close) => (
								<S.ModalWrapper>
									<Text headingPrimarySt>Se ha encontrado un error en el listado de beneficiarios</Text>
									<Text headingSecondarySt>No puedes añadirte tú mismo como beneficiario</Text>
									<Button
										style={{ marginTop: 30, marginBottom: 0, display: 'inline-block' }}
										onClick={() => {
											close();
											navigateTo(routes.clientData);
										}}
									>
										Modificar
									</Button>
								</S.ModalWrapper>
							),
							{
								disableClickAway: true,
								closeButton: false,
								executeOnClose: () => {
									navigateTo(routes.clientData);
								},
								modalClass: isMobile ? 'modal-xl' : 'modal-main',
								key: 'modal-alice-sameBenef'
							}
						);
					} else if (diferentToReceived) {
						openModal(
							(close) => (
								<S.ModalWrapper>
									<Text headingPrimarySt errorSt>
										Se ha encontrado un error en el documento añadido
									</Text>
									<Text headingTertiarySt errorSt>
										El número del documento escaneado no coincide con el que se indicó al inicio del proceso.
									</Text>
									<Button
										style={{ marginTop: 30, marginBottom: 0 }}
										onClick={() => {
											close();
											navigateTo(routes.identityBiometricUpsert);
										}}
									>
										Modificar
									</Button>
								</S.ModalWrapper>
							),
							{
								disableClickAway: true,
								closeButton: false,
								modalClass: isMobile ? 'modal-xl' : 'modal-main',
								key: 'modal-alice-diferent'
							}
						);
					} else {
						navigateTo(routes.activateProduct);
					}
				}}
				onFailure={(data) => {
					aliceOnError(data, openModal, navigateTo, scanManaggerReference);
				}}
				onCancel={(data) => {
					navigateTo(routes.bankAccount);
				}}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
