import React, { useState } from 'react';

import { PickerHOC, Text, UiWrapper } from '@coinscrap/webapp-core';

import FacebookLogo from 'assets/images/svgs/facebook.svg';
import InstagramLogo from 'assets/images/svgs/instagram.svg';
import LinkedinLogo from 'assets/images/svgs/linkedin.svg';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

import { Wrapper } from 'layout/Wrapper';

import * as S from './stylesB2B';

export const ProductCreationFinishedB2B = PickerHOC(
	{},
	LoadingScreen
)(() => {
	const { useBackground, useLoading } = UiWrapper.use();
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState();
	useBackground('#F9EDE4');
	useLoading(loading, { loadingMessage: loading });
	return (
		//TODO: It is neccesary this content?
		<Wrapper>
			<Text headingPrimarySt>¡Enhorabuena! Tu seguro de vida ha sido contratado con éxito</Text>
			<Text headingSecondarySt>
				Puedes descargar tu contrato desde el área El cliente de Caser, introduciendo los dígitos de tu cuenta bancaria
				y el número de póliza de tu contrato que encontrarás a continuación:
			</Text>
			<Text headingPrimarySt>XXXXXXXXX</Text>
			<S.BottomTextWrapper>
				<S.BottomText style={{ marginLeft: 10 }}>
					Recuerda que dispones de 30 días para el desistimiento del contrato. Debes ponerte en contacto con tu agente
					de Caser o llamar al <S.BottomTextSpan>965023744</S.BottomTextSpan>
				</S.BottomText>
			</S.BottomTextWrapper>
			<S.Border style={{ marginTop: 20 }} />
			<S.BottomBox>
				<S.BottomBoxText>Política de privacidad</S.BottomBoxText>
				<S.BottomBoxText>Aviso legal</S.BottomBoxText>
				<S.BottomBoxText>Política de cookies</S.BottomBoxText>
			</S.BottomBox>
			<S.LinksWrapper>
				<S.LinkCircle>
					<S.LinkIcon alt="" src={LinkedinLogo} />
				</S.LinkCircle>
				<S.LinkCircle>
					<S.LinkIcon alt="" src={InstagramLogo} />
				</S.LinkCircle>
				<S.LinkCircle>
					<S.LinkIcon alt="" src={FacebookLogo} />
				</S.LinkCircle>
			</S.LinksWrapper>
			<S.Border style={{ marginTop: 20, width: '100%' }} />
			<S.FooterText>
				Caser es una marca registrada de CAJA DE SEGUROS REUNIDOS, Compañía de Seguros y Reaseguros, S.A. Como corredor
				de seguros, CAJA DE SEGUROS REUNIDOS, Compañía de Seguros y Reaseguros, S.A. está inscrito en el Registro
				administrativo especial de mediadores de seguros de la Dirección General y Seguros y Fondos de Pensiones,
				organismo dependiente del Ministerio de Economía, Industria y Competitividad del Estado Español.
			</S.FooterText>
			<S.FooterText>Copyright © 2021 Caser. Todos los derechos reservados.</S.FooterText>
		</Wrapper>
	);
});
