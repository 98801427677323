import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';

import { Button, Text } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';

import * as S from './styles';

const BeneficiaryBox = ({ ben, onDelete, onEdit }) => {
	return (
		<S.PrimaryWrapper>
			<S.QuestionnaireBoxWrapper>
				<Text questionnariesSt>Nombre: {ben.name || ''}</Text>
				<Text questionnariesSt>DNI/NIE: {ben.dni || ''}</Text>
				<Text questionnariesSt>Apellido: {ben.surname || ''}</Text>
				<Text questionnariesSt>Porcentaje: {`${ben.percentage} %` || ''}</Text>
			</S.QuestionnaireBoxWrapper>
			<S.QuestionnaireBoxButtonWrapper>
				<Button beneficiaryBoxSt onClick={onEdit}>
					<FiEdit style={{ color: CONFIG.colors.primary }} size={24} />
				</Button>
				<Button beneficiaryBoxSt onClick={onDelete}>
					<RiDeleteBin6Line style={{ color: CONFIG.colors.primary }} size={24} />
				</Button>
			</S.QuestionnaireBoxButtonWrapper>
		</S.PrimaryWrapper>
	);
};

export default BeneficiaryBox;
