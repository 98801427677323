import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
    NavigationWrapper,
    PickerHOC,
    TargetsWrapper,
    Text,
    UiWrapper,
    UserWrapper,
    View
} from '@coinscrap/webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Header from 'layout/components/Header/Header';

import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';
import { currencyFormat } from 'libs/currencyFormat';

export const SimulationFinancialCalculator = PickerHOC(
	{
		targets: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets'
		},
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { updateUser } = UserWrapper.use();
	const { useHeader, openModal, useLoading } = UiWrapper.use();

	const { targets, user } = PickerHOC.use();
	const target = targets[0];

	const [mortgage, setMortgage] = useState(user?.metadata?.mortage || 'no');
	const [balanceMortgage, setBalanceMortgage] = useState(user?.metadata?.balanceMortgage || 0);
	const [otherLoans, setOtherLoans] = useState(user?.metadata?.otherLoans || 0);

	const [loading, setLoading] = useState();

	const creationData = target?.targetProperties?.find((x) => x.name === 'creationData')?.value;

	const back = async () => {
		goBack();
	};

	const next = async () => {
		try {
			setLoading('Guardando');
			await updateUser({
				metadata: {
					...user?.metadata,
					mortgage,
					balanceMortgage,
					otherLoans
				}
			});
			await saveTargetProperty(target.id, 'creationData', {
				...creationData,
				details: {
					...creationData?.details,
					mortgage,
					balanceMortgage,
					otherLoans
				}
			});
			navigateTo(routes.simulationEmploymentCalculator);
		} catch (e) {
			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso';
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-SimulationFinancialCalculatorError'
				}
			);
		}
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} />, []);

	return (
		<Wrapper width={'80%'}>
			<Text headingPrimarySt>Datos financieros</Text>
			<Text headingSecondarySt>
				Estas preguntas son necesarias para calcular el capital que mejor se adapte a tu situación familiar.
			</Text>
			<View tripleInputRowSt>
				<InputLabel
					label={'¿Tienes hipoteca sobre tu vivienda?'}
					value={mortgage || ''}
					onChange={(e) => {
						setMortgage(e.target.value);
					}}
					type="select"
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
				{mortgage === 'yes' && (
					<InputLabel
						label={'¿Cuánto te queda por pagar?'}
						value={balanceMortgage}
						onChange={(e) => {
							if (isNaN(+e.target.value) || '') return;
							setBalanceMortgage(e.target.value);
						}}
						onBlur={(e) => setBalanceMortgage(currencyFormat(e.target.value))}
					/>
				)}
			</View>
			<View tripleInputRowSt>
				<InputLabel
					label={'¿Cuánto tienes pendiente en otros préstamos?'}
					value={otherLoans}
					onChange={(e) => {
						if (isNaN(+e.target.value) || '') return;
						setOtherLoans(e.target.value);
					}}
					onBlur={(e) => setOtherLoans(currencyFormat(e.target.value))}
				/>
			</View>
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				// isNextDisabled={isDisabled()}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
