import React from 'react';

import * as S from './styles';

export const Wrapper = ({ children, width }) => {
	return <S.Wrapper wrapperWidth={width}>{children}</S.Wrapper>;
};

export const WrapperKYC = ({ children, width, widthMobile }) => {
	return (
		<S.WrapperKYC wrapperWidth={width} wrapperWidthMobile={widthMobile}>
			{children}
		</S.WrapperKYC>
	);
};
