import React from 'react';

import * as S from './styles';

const HorizontalProgressBar = ({ label, step, total, styleWrapper = {} }) => {
	return (
		<S.HorizontalProgressBarWrapper style={styleWrapper}>
			{label && <S.Label>{label}</S.Label>}
			<S.ProgressBar>
				{[...new Array(step)].map((item, index, list) => {
					return (
						<S.ProgressBarItemStep key={`pbis-${index}`} index={index} length={list.length}>
							<S.ProgressBarItemDot step={step.toString()} />
						</S.ProgressBarItemStep>
					);
				})}

				{[...new Array(total - step)].map((item, index, list) => {
					return (
						<S.ProgressBarItem key={`pbi-${index}`}>
							<S.ProgressBarItemDot />
						</S.ProgressBarItem>
					);
				})}
			</S.ProgressBar>
		</S.HorizontalProgressBarWrapper>
	);
};

export default HorizontalProgressBar;
