import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
    BackendWrapper,
    Button,
    NavigationWrapper,
    SessionWrapper,
    Text,
    UiWrapper,
    View,
    isValidEmail
} from '@coinscrap/webapp-core';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import InputLabel from 'components/InputLabel/InputLabel';
import Header from 'layout/components/Header/Header';

import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';

export const LoginAgent = () => {
	const { getInstanceApi } = BackendWrapper.use();
	const { startSession, clearSession } = SessionWrapper.use();
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { useHeader, openModal, useLoading, showAlert } = UiWrapper.use();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		clearSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const doLogin = async () => {
		if (!email || !email.trim() || !password || !password.trim() || !isValidEmail(email)) {
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>
							{isValidEmail(email) ? 'Por favor, rellene todos los campos' : 'Introduzca un email válido'}
						</Text>
					</ErrorModal>
				),
				{
					disableClickAway: false,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-LoginAgent'
				}
			);
			return;
		}

		const data = {
			identifier: email.trim(),
			password: password.trim()
		};
		try {
			const response = await getInstanceApi().loginUser(data);
			const token = response && response.accessToken;
			if (token) {
				const loginAgent = await startSession({ token }, ['AGENT']);
				if (loginAgent) {
					//eslint-disable-next-line
					console.log('ROLE LOGGED', 'AGENT');
				} else {
					const globalAdminLogin = await startSession({ token }, ['GLOBAL_AGENT']);
					if (globalAdminLogin) {
						//eslint-disable-next-line
						console.log('ROLE LOGGED', 'GLOBAL_AGENT');
					} else {
						showAlert('No tienes permisos suficientes para acceder a esta sección. 🔒');
						return;
					}
				}
				localStorage.removeItem('B2Flow');
				localStorage.setItem('B2Flow', 'B2B');
				navigateTo(routes.dashboard, { handleRedirection: true });
			}
		} catch (e) {
			//console.log(" ERROR LOGIN ", e);
			let msg = 'Usuario o contraseña no válidos';
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{msg}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-LoginAgent2'
				}
			);
		}
	};

	useLoading(undefined, { loadingMessage: undefined });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} isB2B={true} />, []);
	return (
		<Wrapper width={'70%'}>
			<Text headingPrimarySt>Login agentes</Text>
			<View style={{ maxWidth: 350, marginTop: 40 }}>
				<InputLabel
					style={{ marginBottom: 30 }}
					value={email}
					onChange={(e) => {
						setEmail(e.target.value);
					}}
					label="Email"
				/>

				<InputLabel
					value={password}
					onChange={(e) => {
						setPassword(e.target.value);
					}}
					type="password"
					label="Contraseña"
				/>
			</View>
			<Button selectedSt disabled={!email || !password} onClick={doLogin} style={{ marginTop: '40px' }}>
				Entrar
			</Button>
		</Wrapper>
	);
};
