import React, { useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Modal from '@material-ui/core/Modal';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Button, UiWrapper, View } from '@coinscrap/webapp-core';

import CloseButton from 'components/CloseButton/CloseButton';

import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

const TermsModal = ({ isOpen, onClose, children, onClick }) => {
	const faqRef = useRef();
	const { useBackground } = UiWrapper.use();
	useBackground(CONFIG.colors.white);
	const [isScroll, setIsScroll] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(true);

	const observer = useRef();

	const lastPlanElementRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[entries.length - 1].isIntersecting) {
					setButtonDisabled(false);
					setIsScroll(false);
				} else {
					setIsScroll(true);
				}
			});
			if (node) observer.current.observe(node);
		},
		[observer]
	);

	const Text = styled.p`
		margin-top: 10px;
	`;

	return (
		<Modal open={isOpen} onClose={onClose} key={'terms_modal'}>
			<View fullHeightSt style={{ backgroundColor: CONFIG.colors.white, overflowY: 'auto' }}>
				<View fullHeightSt style={{ justifyContent: 'flex-start' }}>
					<View startSt fullHeightSt>
						<View horizontalSt endSt style={{ marginTop: 10 }}>
							<CloseButton
								color={CONFIG.colors.black}
								action={() => {
									onClose();
								}}
							/>
						</View>

						<View
							startSt
							style={{
								overflowY: 'scroll',
								height: 'calc(100% - 150px)',
								maxWidth: 850,
								padding: 10,
								width: '90%'
							}}
						>
							{children}
							<div>
								<h2 style={{ marginBottom: 10, fontStyle: 'italic' }}>Información básica de política de privacidad</h2>
								<Text>
									<em>
										CAJA DE SEGUROS REUNIDOS, COMPAÑÍA DE SEGUROS Y REASEGUROS, S.A. – CASER (N.I.F.: A-28013050), es
										responsable del tratamiento de los datos personales que nos facilita con el fin de proporcionarle la
										información solicitada sobre nuestros productos o servicios por cualquier medio, incluso por
										teléfono, correo electrónico, SMS, o equivalente, directamente o a través de nuestros agentes de
										seguros. Los datos proporcionados se conservarán mientras no solicite el cese de la actividad. Sus
										personales en ningún caso se cederán a terceros salvo en los casos en que exista una obligación
										legal o lo consienta expresamente. Tiene derecho ejercer los derechos de acceso, rectificación,
										supresión, limitación del tratamiento y oposición. Puede encontrar información adicional en nuestra
										Política de Privacidad{' '}
										<a target="_blank" rel="noreferrer" href="https://www.caser.es/politica-de-privacidad">
											(https://www.caser.es/politica-de-privacidad)
										</a>
										.
									</em>
								</Text>
							</div>
							<View ref={lastPlanElementRef} style={{ height: 10 }}>
								<View ref={faqRef} />
							</View>
						</View>

						<ArrowForwardIosIcon
							onClick={() => {
								faqRef.current.scrollIntoView({ behavior: 'smooth' });
							}}
							style={{
								position: 'fixed',
								bottom: isMobile ? 25 : 30,
								right: 30,
								width: 45,
								height: 'auto',
								transform: 'rotate(90deg)',
								opacity: isScroll ? 1 : 0,
								cursor: 'pointer'
							}}
						/>
						<Button
							termsSt
							disabled={!!buttonDisabled}
							onClick={() => {
								onClick();
								onClose();
							}}
						>
							Aceptar
						</Button>
					</View>
				</View>
			</View>
		</Modal>
	);
};

export default TermsModal;
