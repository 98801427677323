import React from 'react';

import IconButton from '@material-ui/core/IconButton';
// import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import Icon from '@material-ui/icons/Clear';

const CloseButton = ({ color, onClick, action, ...props }) => {
	return (
		<IconButton color={color || 'primary'} onClick={action || onClick} style={{ color: color, margin: '0px 15px' }}>
			<Icon />
		</IconButton>
	);
};

export default CloseButton;
