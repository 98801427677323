import { isMobile } from 'react-device-detect';

import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from '../config';
import commonStyles from './common';

//TODO:
// textUppercase should be a parameter

UiWrapper.defineComponent('text', {
	styles: {
		default: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.light,
			fontSize: CONFIG.fontSizes.medium
		},

		headerSt: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.regular,
			fontSize: 14
		},

		headingPrimarySt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.bold,
			fontSize: CONFIG.fontSizes.large,
			marginBottom: 8,
			textAlign: 'center'
		},

		headingSecondarySt: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.medium,
			marginBottom: isMobile ? 15 : 20,
			maxWidth: 750,
			textAlign: 'center'
		},

		faqTitleSt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.bold,
			fontSize: isMobile ? 24 : 28,
			marginBottom: isMobile ? 15 : 24,
			textAlign: 'left'
		},

		faqHeadingSt: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.bold,
			fontSize: CONFIG.fontSizes.mediumLabel,
			maxWidth: isMobile ? 300 : 600,
			textAlign: isMobile ? 'center' : 'left'
		},

		faqAnswerSt: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.medium,
			textAlign: 'left'
		},

		labelInputSt: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.medium,
			marginBottom: 12,
			textAlign: 'center'
		},

		callToActionSt: {
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.medium,
			display: 'flex',
			alignItems: 'center',
			textAlign: 'center'
		},

		featuresPrimarySt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.bold,
			fontSize: CONFIG.fontSizes.large,
			textAlign: 'center'
		},

		featuresMarginBottomSt: {
			marginBottom: isMobile ? 15 : 25
		},

		featuresMarginTopSt: {
			marginTop: isMobile ? 20 : 40
		},

		marginLefConditiontSt: {
			marginLeft: 10
		},

		questionnariesSt: {
			color: CONFIG.colors.white,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.small
		},

		boxQuestionnariesSt: {
			color: CONFIG.colors.tertiary,
			display: 'flex',
			fontFamily: CONFIG.fonts.regular,
			marginRight: 8,
			width: '100%'
		},

		stepsSt: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.regular,
			fontSize: isMobile ? 18 : 20,
			maxWidth: 750,
			textAlign: 'left'
		},

		dangerSt: {
			color: CONFIG.colors.danger,
			fontSize: CONFIG.fontSizes.small,
			marginTop: isMobile ? 8 : 15
		},

		//Dashboard agent header filters
		tableHeadingSt: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.bold,
			fontSize: CONFIG.fontSizes.medium,
			maxWidth: 750
		},

		tableTextSt: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.medium,
			maxWidth: 300,
			overflow: 'hidden',
			wordBreak: 'break-word'
		},

		//Dashboard 'acciones' text
		actionsSt: {
			color: CONFIG.colors.secondary,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.small
		},

		//Dashboard Progress text when its active in FollowUp view
		progressTextSt: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.largeSpan
		},

		//Dashboard Progress text in FollowUp view
		activeProgressTextSt: {
			color: CONFIG.colors.primary,
			fontFamily: CONFIG.fonts.bold,
			fontSize: CONFIG.fontSizes.largeSpan
		},

		//Text for ProductCreationFinished
		manualContractSt: {
			color: CONFIG.colors.tertiary,
			fontFamily: CONFIG.fonts.regular,
			fontSize: CONFIG.fontSizes.medium,
			marginBottom: 0,
			maxWidth: 750,
			textAlign: 'left'
		},
		tooltipTitleSt: {
			color: CONFIG.colors.black,
			marginBottom: 10,
			fontFamily: CONFIG.fonts.bold,
			fontSize: isMobile ? 14 : 16,
			textAlign: 'left'
		},
		tooltipTextSt: {
			color: CONFIG.colors.black,
			marginBottom: 10,
			fontFamily: CONFIG.fonts.regular,
			fontSize: isMobile ? 14 : 16,
			textAlign: 'left'
		},
		errorModalSt: {
			color: CONFIG.colors.danger,
			fontFamily: CONFIG.fonts.bold,
			fontSize: CONFIG.fontSizes.medium,
			textAlign: 'center'
		},
		...commonStyles.styles
	}
});
