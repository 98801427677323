import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';

UiWrapper.defineComponent('inputDate', {
	styles: {
		default: {
			containerComponent: {
				border: `1px solid ${CONFIG.colors.tertiaryLight}`,
				borderRadius: '3px',
				color: `${CONFIG.colors.tertiary}`,
				display: 'flex',
				flexDirection: 'row',
				fontSize: '14px',
				height: '29px',
				padding: '4px 12px',
				width: '100%',
				marginBottom: 0,
				marginTop: 0
			},
			inputContainerComponent: {
				border: 'none',
				boxSizing: 'border-box',
				background: 'white',
				display: 'flex',
				flexGrow: 1,
				width: '40%'
			},
			inputComponent: {
				color: CONFIG.colors.tertiary,
				boxSizing: 'border-box',
				fontFamily: CONFIG.fonts.regular,
				fontSize: '14px',
				textAlign: 'left',
				padding: 0
			},
			iconContainerComponent: {
				height: 20,
				padding: 0,
				width: 150
			},
			adornmentContainerComponent: {
				display: 'none'
			},
			dayComponent: {
				color: CONFIG.colors.tertiary,
				fontFamily: CONFIG.fonts.regular
			},

			daySelectedComponent: {
				backgroundColor: CONFIG.colors.primaryLight,
				color: CONFIG.colors.white
			},
			actionTextComponent: {
				// display: 'none',
				fontFamily: CONFIG.fonts.regular
			}
		},
		fullWidthSt: {
			containerComponent: {
				width: '100%'
			}
		},

		filledMode: {
			inputContainerComponent: {
				background: 'white'
			}
		},
		dateWithBorderSt: {
			inputContainerComponent: {
				backgroundColor: '#FAFBFC',
				borderBottom: `1px solid ${CONFIG.colors.red}`,
				padding: 0,
				paddingRight: 6
			},
			containerComponent: {
				height: '25px',
				border: 'none',
				borderRadius: 0,
				boxSizing: 'border-box',
				padding: 0,
				marginTop: 0,
				marginBottom: 0,
				borderBottom: 'none',
				width: '100%',
				color: 'black',
				backgroundColor: '#FAFBFC',
				display: 'flex'
			},
			inputComponent: {
				// paddingBottom: '25px',
				boxSizing: 'border-box',
				paddingRight: 0,
				height: '100%',
				// paddingLeft: 5,
				fontSize: 12,
				color: 'black',
				lineHeight: '20px',
				fontFamily: CONFIG.fonts.regular,
				padding: 0,
				paddingBottom: 0
			},
			labelComponent: {
				color: '#727272',
				fontFamily: CONFIG.fonts.regular,
				fontWeight: 'normal',
				marginTop: '-2px',
				padding: 0,
				fontSize: 17
			},
			iconContainerComponent: {
				height: '25px'
			},
			iconComponent: {
				padding: 0,

				height: '25px',
				boxSizing: 'content-box',
				width: '24px'
			}
		}
	}
});
