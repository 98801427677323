import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import {
    EnvelopesWrapper,
    InputCheck,
    NavigationWrapper,
    PickerHOC,
    TargetsWrapper,
    Text,
    UiWrapper,
    UserWrapper
} from '@coinscrap/webapp-core';

import DownloadIcon from 'assets/images/svgs/DownloadIcon';
import PdfIcon from 'assets/images/svgs/PdfIcon';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import DocView from 'components/MyDocView/MyDocView';
import Header from 'layout/components/Header/Header';

import { CONFIG } from 'config/config';
import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';
import { docNames } from 'libs/common';

import * as S from './styles';

export const SignatureRead = PickerHOC(
	{
		envelope: {
			sourcePath: 'envelopes',
			sourceWrapper: EnvelopesWrapper,
			arrayItemMatcher: {
				type: 'param',
				paramName: 'envelopeId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		},
		targets: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets'
		}
	},
	LoadingScreen
)(() => {
	const { envelope, user } = PickerHOC.use();
	const { updateUser } = UserWrapper.use();
	const { getPreSignedDocumentUrl } = EnvelopesWrapper.use();
	const [loading, setLoading] = useState();

	const [documents, setDocuments] = useState(envelope.documents);

	useEffect(() => {
		async function fetchPreSignedUrls() {
			let docs = envelope.documents
				.filter((d) => d.name !== 'documento_identidad_01')
				.filter((d) => d.name !== 'documento_identidad_02');

			const docsWithPresignedUrl = await Promise.all(
				docs.map(async (doc) => {
					const { url: preSignedDocumentUrl } = await getPreSignedDocumentUrl(doc.id);
					return {
						...doc,
						preSignedDocumentUrl
					};
				})
			);
			setDocuments(docsWithPresignedUrl);
		}
		fetchPreSignedUrls();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [currentDoc, setCurrentDoc] = useState(undefined);
	const [modalDocOpen, setModalDocOpen] = useState(false);
	const [read, setRead] = useState(
		(documents || []).reduce(
			(acc, act) => ({
				...acc,
				[act.id]: envelope.signStatus !== 'PENDING' ? true : false
			}),
			{}
		)
	);

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { navigateTo, goBack } = NavigationWrapper.use();

	const downloadDoc = (url) => {
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `doc.pdf`);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};

	const isDisabled = useCallback(() => {
		return documents && documents.find((d) => !read?.[d.id]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [read]);

	// NAVIGATION
	const back = async () => {
		goBack();
	};

	const next = async () => {
		try {
			setLoading('Guardando...');
			await updateUser({
				...user,
				metadata: {
					...user?.metadata,
					docsRead: true
				}
			});

			navigateTo(routes.signatureContract);
		} catch (e) {
			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-signatureReadNext'
			});
		}
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);
	return (
		<Wrapper width={'80%'}>
			<HorizontalProgressBar step={6} total={6} styleWrapper={{ maxWidth: 450 }} />
			<Text headingPrimarySt isB2B>
				Documentos
			</Text>
			<S.InnerWrapper>
				{(documents || []).map((d) => (
					<S.DocumentWrapper key={d.id}>
						<S.DocumentWrapperText>{docNames[d.name] || d.name}</S.DocumentWrapperText>
						<S.DocumentWrapperButtons>
							<PdfIcon
								style={{
									cursor: 'pointer',
									fill: read?.[d.id] ? CONFIG.colors.secondary : CONFIG.colors.tertiary
								}}
								onClick={() => {
									//OPEN IN MODAL
									setCurrentDoc(d);
									setModalDocOpen(true);
								}}
							/>
							<DownloadIcon
								style={{
									cursor: 'pointer',
									fill: read?.[d.id] ? CONFIG.colors.secondary : CONFIG.colors.tertiary
								}}
								onClick={() => {
									downloadDoc(d.preSignedDocumentUrl);
								}}
							/>
							<InputCheck
								style={{
									padding: 4
								}}
								//disabled={true}
								checkedIcon={<CheckBoxOutlinedIcon />}
								value={read?.[d.id]}
								onChange={() => {
									//OPEN IN MODAL
									setCurrentDoc(d);
									setModalDocOpen(true);
								}}
							/>
						</S.DocumentWrapperButtons>
					</S.DocumentWrapper>
				))}
			</S.InnerWrapper>
			{modalDocOpen && (
				<DocView
					open={modalDocOpen}
					title={docNames[currentDoc.name] || currentDoc.name}
					onClose={() => {
						setModalDocOpen(false);
						setRead((prevState) => {
							return { ...prevState, [currentDoc.id]: true };
						});
					}}
					url={currentDoc.preSignedDocumentUrl}
					styleType="white"
					disk
				/>
			)}
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				isBackShown={!isMobile}
				isNextDisabled={isDisabled()}
				justifyContent={'between'}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
