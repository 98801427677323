import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import EmailIcon from '@material-ui/icons/Email';
import ErrorIcon from '@material-ui/icons/Error';
import TextsmsIcon from '@material-ui/icons/Textsms';

import { Button, Text, UiWrapper, View } from '@coinscrap/webapp-core';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Modal } from 'components/Modal/Modal';

import { CONFIG } from 'config/config';

import * as S from './styles';

const SendLinkModal = ({ close, user, send, next }) => {
	const { openModal, showAlert } = UiWrapper.use();
	const [loading, setLoading] = useState();
	return (
		<Modal>
			<S.ModalWrapper>
				<ErrorIcon style={{ marginBottom: 15, color: CONFIG.colors.primary, height: 45, width: 45 }} />

				<Text headingPrimarySt>¿Cómo quieres enviar el enlace al cliente?</Text>
				<View notificationWrapperSt>
					<Button
						backSt
						disabled={!user?.email?.trim()}
						onClick={async () => {
							if (loading) {
								return;
							}
							try {
								setLoading(true);
								await send('USER_RECOVERY');
								setLoading(false);
								showAlert('EMAIL enviado');
								close();
								next && next();
							} catch (e) {
								setLoading(false);
								close();
								openModal(
									() => (
										<ErrorModal>
											<Text headingPrimarySt>No se ha podido enviar el email</Text>
										</ErrorModal>
									),
									{
										disableClickAway: true,
										closeButton: true,
										modalClass: isMobile ? 'modal-xl' : 'modal-main',
										key: 'modal-SendEmailError'
									}
								);
							}
						}}
					>
						<EmailIcon style={{ marginLeft: 3, marginRight: 3, fontSize: 22 }} />
						Email
					</Button>
					<Button
						backSt
						disabled={!user?.phoneNumber?.trim()}
						onClick={async () => {
							if (loading) {
								return;
							}
							try {
								setLoading(true);
								await send('SMS_UNIQUE_ACCESS');
								setLoading(false);
								showAlert('SMS enviado');
								close();
								next && next();
							} catch (e) {
								setLoading(false);
								close();
								openModal(
									() => (
										<ErrorModal>
											<Text headingPrimarySt>No se ha podido enviar el SMS</Text>
										</ErrorModal>
									),
									{
										disableClickAway: true,
										closeButton: true,
										modalClass: isMobile ? 'modal-xl' : 'modal-main',
										key: 'modal-SendSMSError'
									}
								);
							}
						}}
					>
						<TextsmsIcon style={{ marginLeft: 3, marginRight: 3, fontSize: 22 }} />
						SMS
					</Button>
				</View>
			</S.ModalWrapper>
		</Modal>
	);
};

export default SendLinkModal;
