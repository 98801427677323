import React from 'react';
import { isMobile } from 'react-device-detect';

import { BackendWrapper, NavigationWrapper, UiWrapper, VinculationsWrapper } from '@coinscrap/webapp-core';

import { ErrorModal as MainModal } from 'components/ErrorModal/ErrorModal';

import { CONFIG } from 'config/config';

export const useAgentSimulationActions = (targetTypeId, setLoading, agentOffice) => {
	const { createMasterVinculation: createVinculation } = VinculationsWrapper.use();
	const { getInstanceApiForVinculation } = BackendWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const { openModal } = UiWrapper.use();

	const newSimulationHandler = async (receivedData) => {
		let vinculationId, clientId;
		try {
			setLoading('Generando cliente');

			let vinculation;
			vinculation = await createVinculation('AGENTE_DE', {
				name: ' '
			});

			await new Promise((r) => setTimeout(r, 500));

			vinculationId = vinculation.id;
			clientId = vinculation.secondaryUserId;
		} catch (e) {
			openModal(() => <MainModal type="error" text={'Error al crear nueva simulación'} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-NewSimulationError'
			});
		}

		if (vinculationId && clientId) {
			await accessSimulationHandler(vinculationId, clientId);
		} else {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={'Error al crear nueva simulación'} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-NewSimulationError2'
			});
		}
	};

	const generateSimulation = async (vinculationId, clientId) => {
		setLoading('Autenticando cliente');
		const clientApiInstance = await getInstanceApiForVinculation(vinculationId);

		setLoading('Generando simulación');
		const simulation = await clientApiInstance.createTargetForUser(clientId, {
			targetTypeId: targetTypeId
		});

		const simulationId = simulation.id;
		return simulationId;
	};

	const accessSimulationHandler = async (vinculationId, clientId, rawSimulationId, to = 'start') => {
		let simulationId = rawSimulationId;
		try {
			if (!simulationId) {
				simulationId = await generateSimulation(vinculationId, clientId);
			}
		} catch (e) {
			openModal(() => <MainModal type="error" text={'Error al crear nueva simulación'} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-NewSimulationError3'
			});
		}

		if (simulationId) {
			if (to === 'followUp') {
				CONFIG.routing.dashboard.nextFollowUp(navigateTo, vinculationId, clientId, simulationId);
			} else {
				CONFIG.routing.dashboard.nextNewVinculation(navigateTo, vinculationId, clientId, simulationId);
			}
		} else {
			setLoading(undefined);
			openModal(() => <MainModal type="error" text={'Error al crear nueva simulación'} />, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-accessSimulationHandler-KO'
			});
		}
	};

	return { newSimulationHandler, accessSimulationHandler };
};
