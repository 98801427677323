import { useCallback } from 'react';

import { BackendWrapper } from '@coinscrap/webapp-core';

export const useGetAuthRequests = () => {
	const { getInstanceApi } = BackendWrapper.use();
	const apiInstance = getInstanceApi();
	const authRequests = useCallback(async (userId) => {
		if (apiInstance) {
			const authRequests = await apiInstance.microserviceCall('Us')(
				`/auth-requests?filter=${encodeURIComponent(
					JSON.stringify({
						where: { userId: userId },
						order: 'createDate DESC'
					})
				)}`,
				'GET',
				{}
			);
			//console.log('authRequests', authRequests);
			return authRequests;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return authRequests;
};
