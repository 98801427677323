export const smokeTypes = [
	{
		name: 'Pipas',
		value: 'Pipas'
	},
	{
		name: 'Puros',
		value: 'Puros'
	},
	{
		name: 'Cigarrillos',
		value: 'Cigarrillos'
	},
	{
		name: 'Cigarrillos electrónicos',
		value: 'eCigs'
	}
];
