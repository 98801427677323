import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const Lines = styled.div`
	border: 2px dashed ${CONFIG.colors.primary};
	margin-top: 27px;
	margin-bottom: 27px;
	transform: rotate(180deg);
	width: 100%;
`;
