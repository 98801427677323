import React from 'react';

import { AnalyticsWrapper, Button, NavigationWrapper, Text, View } from '@coinscrap/webapp-core';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

import logoCaser from 'assets/images/png/caser_helvetia.png';
import { SpanCallUs } from 'config/components/span';

import { CONFIG } from 'config/config';
import routes from 'config/routes';

import * as S from './styles';

/**
 * Renders the header section of the page.
 *
 * @param {function} goBack - function to go back
 * @param {boolean} mobile - flag to check if the device is mobile
 * @param {boolean} isB2B - flag to check if the user is a B2B user
 * @param {boolean} buttonEnabled - flag to check if the button is enabled
 *
 * @returns {JSX.Element} - the header section of the page
 */
const Header = ({ goBack, mobile, isB2B, buttonEnabled = true }) => {
	const { navigateTo, getCurrentRoute } = NavigationWrapper.use();
	const { emitEvent } = AnalyticsWrapper.use();

	let currentRoute = getCurrentRoute()?.url;
	currentRoute = currentRoute.split('/')[currentRoute.split('/').length - 1];

	return (
		<View headerSt>
			<div className={'logos'} style={{ alignItems: 'center', display: 'flex' }}>
				<S.Logo alt="Caser logo" src={logoCaser} />
			</div>
			{isB2B && !mobile && (
				<div style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
						<Text headerSt>
							Atención comercial{' '}
							<SpanCallUs>
								<a
									href={'tel:+34911553520'}
									onClick={() => {
										// TEALIUM EVENT
										emitEvent({
											tealium_event: 'static_ctc_click',
											form_name: 'cmb:agente'
										});
									}}
								>
									{CONFIG.callUsComercial}
								</a>
							</SpanCallUs>
						</Text>
						<Text headerSt>
							Atención al cliente{' '}
							<SpanCallUs>
								<a
									href={'tel:+915909712'}
									onClick={() => {
										// TEALIUM EVENT
										emitEvent({
											tealium_event: 'static_ctc_click',
											form_name: 'cmb:agente'
										});
									}}
								>
									{CONFIG.callUsAtencion}
								</a>
							</SpanCallUs>
						</Text>
					</div>
					<Button
						headerSt
						onClick={() => {
							navigateTo(routes.dashboard);
						}}
					>
						Dashboard
					</Button>
				</div>
			)}
			{!isB2B && !mobile && (
				<div style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
						<Text headerSt>
							Atención comercial{' '}
							<SpanCallUs>
								<a
									href={'tel:+34911553520'}
									onClick={() => {
										// TEALIUM EVENT
										emitEvent({
											tealium_event: 'static_ctc_click',
											form_name: 'cmb'
										});
									}}
								>
									{CONFIG.callUsComercial}
								</a>
							</SpanCallUs>
						</Text>
						<Text headerSt>
							Atención al cliente{' '}
							<SpanCallUs>
								<a
									href={'tel:+915909712'}
									onClick={() => {
										// TEALIUM EVENT
										emitEvent({
											tealium_event: 'static_ctc_click',
											form_name: 'cmb'
										});
									}}
								>
									{CONFIG.callUsAtencion}
								</a>
							</SpanCallUs>
						</Text>
					</div>
					<Button
						disabled={!buttonEnabled}
						headerSt
						onClick={() => {
							// TEALIUM EVENT
							emitEvent({
								tealium_event: 'cmb_open',
								form_name: 'cmb'
							});

							navigateTo(routes.cannotConfirm, {
								queryParams: {
									fromReason: 'CALL',
									fromView: currentRoute
								}
							});
						}}
					>
						¿Hablamos?
					</Button>
				</div>
			)}
			{!isB2B && mobile && (
				<LocalPhoneIcon
					onClick={() =>
						navigateTo(routes.cannotConfirm, {
							queryParams: {
								fromReason: 'CALL',
								fromView: currentRoute
							}
						})
					}
					style={{ color: '#007167' }}
				/>
			)}
		</View>
	);
};

export default Header;
