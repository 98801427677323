export const provinces = [
	{
		label: 'Albacete',
		value: '02'
	},
	{
		label: 'Alicante/Alacant',
		value: '03'
	},
	{
		label: 'Almería',
		value: '04'
	},
	{
		label: 'Araba/Álava',
		value: '01'
	},
	{
		label: 'Asturias',
		value: '33'
	},
	{
		label: 'Ávila',
		value: '05'
	},
	{
		label: 'Badajoz',
		value: '06'
	},
	{
		label: 'Balears, Illes',
		value: '07'
	},
	{
		label: 'Barcelona',
		value: '08'
	},
	{
		label: 'Bizkaia',
		value: '48'
	},
	{
		label: 'Burgos',
		value: '09'
	},
	{
		label: 'Cáceres',
		value: '10'
	},
	{
		label: 'Cádiz',
		value: '11'
	},
	{
		label: 'Cantabria',
		value: '39'
	},
	{
		label: 'Castellón/Castelló',
		value: '12'
	},
	{
		label: 'Ciudad Real',
		value: '13'
	},
	{
		label: 'Córdoba',
		value: '14'
	},
	{
		label: 'Coruña',
		value: '15'
	},
	{
		label: 'Cuenca',
		value: '16'
	},
	{
		label: 'Gipuzkoa',
		value: '20'
	},
	{
		label: 'Girona',
		value: '17'
	},
	{
		label: 'Granada',
		value: '18'
	},
	{
		label: 'Guadalajara',
		value: '19'
	},
	{
		label: 'Huelva',
		value: '21'
	},
	{
		label: 'Huesca',
		value: '22'
	},
	{
		label: 'Jaén',
		value: '23'
	},
	{
		label: 'León',
		value: '24'
	},
	{
		label: 'Lleida',
		value: '25'
	},
	{
		label: 'Lugo',
		value: '27'
	},
	{
		label: 'Madrid',
		value: '28'
	},
	{
		label: 'Málaga',
		value: '29'
	},
	{
		label: 'Murcia',
		value: '30'
	},
	{
		label: 'Navarra',
		value: '31'
	},
	{
		label: 'Ourense',
		value: '32'
	},
	{
		label: 'Pontevedra',
		value: '36'
	},
	{
		label: 'Palencia',
		value: '34'
	},
	{
		label: 'Palmas, Las',
		value: '35'
	},
	{
		label: 'Rioja, La',
		value: '26'
	},
	{
		label: 'Salamanca',
		value: '37'
	},
	{
		label: 'Santa Cruz de Tenerife',
		value: '38'
	},
	{
		label: 'Segovia',
		value: '40'
	},
	{
		label: 'Sevilla',
		value: '41'
	},
	{
		label: 'Soria',
		value: '42'
	},
	{
		label: 'Tarragona',
		value: '43'
	},
	{
		label: 'Teruel',
		value: '44'
	},
	{
		label: 'Toledo',
		value: '45'
	},
	{
		label: 'Valencia/València',
		value: '46'
	},
	{
		label: 'Valladolid',
		value: '47'
	},
	{
		label: 'Zamora',
		value: '49'
	},
	{
		label: 'Zaragoza',
		value: '50'
	},
	{
		label: 'Ceuta',
		value: '51'
	},
	{
		label: 'Melilla',
		value: '52'
	}
];
