import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
    Button,
    NavigationWrapper,
    PickerHOC,
    TargetsWrapper,
    Text,
    UiWrapper,
    UserWrapper,
    View
} from '@coinscrap/webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Header from 'layout/components/Header/Header';

import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';

export const SimulationFamilyCalculator = PickerHOC(
	{
		targets: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets'
		},
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { updateUser } = UserWrapper.use();
	const { targets, user } = PickerHOC.use();
	const target = targets[0];

	const { navigateTo, goBack } = NavigationWrapper.use();

	const [married, setMarried] = useState(user?.metadata?.married || '');
	const [spouseAge, setSpouseAge] = useState(user?.metadata?.spouseAge || '');
	const [sons, setSons] = useState(user?.metadata?.sons || '');
	const [college, setCollege] = useState(user?.metadata?.college || 'public');
	const [inputFields, setInputFields] = useState(user?.metadata?.inputFields || [{ ageSons: '' }]);

	const [loading, setLoading] = useState();

	// CREATION DATA
	const creationData = target?.targetProperties?.find((x) => x.name === 'creationData')?.value;

	const next = async () => {
		try {
			setLoading('Guardando...');
			await updateUser({
				metadata: {
					...user?.metadata,
					married,
					spouseAge,
					sons,
					college,
					inputFields
				}
			});
			setLoading('Actualizando...');
			await saveTargetProperty(target.id, 'creationData', {
				...creationData,
				details: {
					...creationData?.details,
					married,
					spouseAge,
					sons,
					college,
					inputFields
				}
			});
			navigateTo(routes.simulationFinancialCalculator);
		} catch (e) {
			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-SimulationFamilyCalculatorError'
				}
			);
		}
	};

	// NAVIGATION
	const back = async () => {
		goBack();
	};

	const isDisabled = () => {
		return (
			!married ||
			(married === 'yes' && !spouseAge) ||
			!sons ||
			(sons === 'yes' && inputFields.every((son) => son.AgeSons)) ||
			!college
		);
	};

	// HANDLERS
	const handleFormChange = (index, event) => {
		if (isNaN(+event.target.value) || '') return;
		let data = [...inputFields];
		data[index][event.target.name] = event.target.value;
		setInputFields(data);
	};

	const addFields = () => {
		let newfield = { ageSons: '' };
		setInputFields([...inputFields, newfield]);
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} />, []);

	return (
		<Wrapper width={'80%'}>
			<Text headingPrimarySt>Datos familiares</Text>
			<Text headingSecondarySt>
				Estas preguntas son necesarias para calcular el capital que mejor se adapte a tu situación familiar.
			</Text>
			<View tripleInputRowSt>
				<InputLabel
					value={married}
					onChange={(e) => {
						setMarried(e.target.value);
					}}
					label={'¿Tienes cónyuge?'}
					type={'select'}
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
				{married === 'yes' && (
					<InputLabel
						value={spouseAge}
						onChange={(e) => {
							if (isNaN(+e.target.value) || '') return;
							setSpouseAge(parseInt(e.target.value || 0));
						}}
						label={'¿Cuántos años tiene?'}
					/>
				)}
			</View>
			<View tripleInputRowSt>
				<InputLabel
					value={sons}
					type={'select'}
					onChange={(e) => {
						setSons(e.target.value);
					}}
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
					label={'¿Tienes hijos?'}
				/>
				{sons === 'yes' &&
					inputFields.map((input, index) => (
						<InputLabel
							key={index}
							name="ageSons"
							value={input.ageSons}
							onChange={(event) => handleFormChange(index, event)}
							label={'¿Cuál es la edad de tu hijo?'}
						/>
					))}
				{sons === 'yes' && (
					<div style={{ alignSelf: 'flex-end', display: 'flex' }}>
						<Button addSonSt onClick={addFields}>
							Añade un hijo
						</Button>
					</div>
				)}
				<InputLabel
					value={college}
					onChange={(e) => {
						setCollege(e.target.value);
					}}
					label={'¿A qué tipo de colegio asisten?'}
					type="select"
					selectOptions={[
						{ label: 'Público', value: 'public' },
						{ label: 'Privado', value: 'private' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
			</View>
			<BackNextButton onClickNext={next} onClickBack={back} isNextDisabled={isDisabled()} />
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
