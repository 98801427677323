import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { Button, Text, View } from '@coinscrap/webapp-core';

import InputLabel from 'components/InputLabel/InputLabel';
import '../../config/components/modal.css';

import { ValidateSpanishID } from 'libs/DNI';

import * as S from './styles';

const BeneficiaryModal = ({ close, beneficiaries, setBeneficiaries, beneficiary = undefined }) => {
	let availablePercentage = 0;
	const totalPercentage = beneficiary
		? beneficiaries.reduce(function (acc, ben) {
				return acc + ben.percentage;
		  }, 0) - beneficiary.percentage
		: beneficiaries.reduce(function (acc, ben) {
				return acc + ben.percentage;
		  }, 0);
	//console.log(totalPercentage);

	availablePercentage = 100 - totalPercentage;
	//console.log(availablePercentage);

	const [state, setState] = useState(
		beneficiary || {
			name: '',
			surname: '',
			dni: '',
			percentage: availablePercentage
		}
	);

	const [percentageInput, setPercentageInput] = useState(state?.percentage ? `${state.percentage} %` : '');

	// CHECKS
	const [isValidDNI, setIsValidDNI] = useState(true);
	const [invalidDNIreason, setInvalidDNIreason] = useState('DNI inválido');
	const [isValidPercentage, setIsValidPercentage] = useState(true);

	const isDisabled = () => {
		return !state?.name || !state?.surname || !state?.dni || !state?.percentage || !isValidDNI || !isValidPercentage;
	};

	const noAnyadido = (dni) => {
		if (beneficiaries.some((ben) => ben.dni === dni)) {
			setInvalidDNIreason('El DNI ya ha sido añadido a la lista de beneficiarios.');
			return false;
		}
		setInvalidDNIreason('DNI inválido');
		return true;
	};

	return (
		<View
			style={{
				padding: isMobile ? 28 : 20,
				background: 'white'
			}}
		>
			<Text headingPrimarySt>{beneficiary ? 'Datos del beneficiario' : 'Datos del beneficiario'}</Text>
			<View inputRowSt>
				<InputLabel
					value={state?.name || ''}
					onChange={(e) => {
						setState({ ...state, name: e.target.value });
					}}
					label="Nombre"
				/>
				<InputLabel
					value={state?.surname || ''}
					onChange={(e) => {
						setState({ ...state, surname: e.target.value });
					}}
					label="Apellido(s)"
				/>
				<InputLabel
					value={state?.dni || ''}
					onBlur={() => {
						setIsValidDNI(ValidateSpanishID(state?.dni).valid && noAnyadido(state?.dni));
					}}
					onChange={(e) => {
						if (!isValidDNI) {
							setIsValidDNI(ValidateSpanishID(e.target.value).valid && noAnyadido(e.target.value));
						}
						setState({ ...state, dni: e.target.value });
					}}
					label="DNI"
					errorMsg={!isValidDNI && invalidDNIreason}
				/>
				<InputLabel
					value={percentageInput || ''}
					onChange={(e) => {
						if (!isValidPercentage) {
							setIsValidPercentage(parseFloat(e.target.value) <= availablePercentage && e.target.value > 0);
						}
						if (e.target.value === '') {
							setState({ ...state, percentage: 0 });
							setPercentageInput('');
						} else {
							setState({
								...state,
								percentage: e.target.value.replace(/\D/g, '')
							});
							setPercentageInput(e.target.value.replace(/\D/g, ''));
						}
					}}
					onBlur={() => {
						setIsValidPercentage(state?.percentage <= availablePercentage && state?.percentage > 0);
						if (state.percentage === '') {
							setState({ ...state, percentage: 0 });
							setPercentageInput('');
						} else {
							setState({ ...state, percentage: state.percentage });
							setPercentageInput(`${state.percentage} %`);
						}
					}}
					label="Porcentaje"
					errorMsg={!isValidPercentage && `Máximo asignable: ${availablePercentage} %`}
				/>
			</View>
			<S.ButtonsWrapper>
				<Button
					smallSt
					onClick={() => {
						close();
					}}
				>
					CERRAR
				</Button>
				<Button
					smallSt
					disabled={isDisabled()}
					onClick={() => {
						const newBen = {
							source: 'designed',
							name: state.name,
							surname: state.surname,
							dni: state.dni,
							percentage: parseFloat(state.percentage)
						};
						if (beneficiary) {
							const benAmodificar = beneficiaries.find((ben) => ben.dni === newBen.dni);
							const index = beneficiaries.indexOf(benAmodificar);
							beneficiaries[index] = newBen;
						} else {
							beneficiaries.push(newBen);
						}
						setBeneficiaries(beneficiaries);
						close();
					}}
				>
					{beneficiary ? 'MODIFICAR' : 'AÑADIR'}
				</Button>
			</S.ButtonsWrapper>
		</View>
	);
};

export default BeneficiaryModal;
