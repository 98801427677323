import React, { Component } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import {
	ApplicationWrapper,
	EnvelopesWrapper,
	Module,
	Page,
	VinculationWrapper,
	WithSession
} from '@coinscrap/webapp-core';

import jwt from 'jsonwebtoken';

import Animations from 'layout/Animations/Animations';
import { LoginAgent } from 'views/00_Login/LoginAgent';
import { LoginClient } from 'views/00_Login/LoginClient';
import { Dashboard } from 'views/01_AccessPoint/Dashboard';
import DashboardProvider from 'views/01_AccessPoint/contexts/Dashboard';
import DashboardFiltersProvider from 'views/01_AccessPoint/contexts/DashboardFilters';
import { SimulationData } from 'views/03_SimulationData/SimulationData';
import { SimulationDataAgent } from 'views/03_SimulationData/SimulationDataAgent';
import { Simulation } from 'views/04_Simulation/Simulation';
import { SimulationAgent } from 'views/04_Simulation/SimulationAgent';
import { SimulationEmploymentCalculator } from 'views/04_Simulation/SimulationEmploymentCalculator';
import { SimulationFamilyCalculator } from 'views/04_Simulation/SimulationFamilyCalculator';
import { SimulationFinancialCalculator } from 'views/04_Simulation/SimulationFinancialCalculator';
import { ClientData } from 'views/05_ClientData/ClientData';
import { ClientDataAgent } from 'views/05_ClientData/ClientDataAgent';
import { FollowUp } from 'views/06_FollowUp/FollowUp';
import { Health } from 'views/07_Questionnaires/Health';
import { Pbc } from 'views/07_Questionnaires/Pbc';
import { BankAccount } from 'views/08_BankAccount/BankAccount';
import { IdentityBiometricUpsert } from 'views/09_IdentityBiometricUpsert/IdentityBiometricUpsert';
import { IdentityBiometric } from 'views/11_IdentityBiometric/IdentityBiometric';
import { IdentityBiometricExtraDocs } from 'views/12_IdentityBiometricExtraDocs/IdentityBiometricExtraDocs';
import { ActivateProduct } from 'views/13_ActivateProduct/ActivateProduct';
import { SignatureSteps } from 'views/14_SignatureSteps/SignatureSteps';
import { SignatureRead } from 'views/15_SignatureRead/SignatureRead';
import { SignatureContract } from 'views/16_SignatureContract/SignatureContract';
import { ProductCreationFinishedB2B } from 'views/17_ProductCreationFinished/ProductCreationFinishedB2B';
import { ProductCreationFinishedB2C } from 'views/17_ProductCreationFinished/ProductCreationFinishedB2C';
import { ProductCreationFinishedManual } from 'views/17_ProductCreationFinished/ProductCreationFinishedManual';
import { CannotConfirm } from 'views/_Common/CannotConfirm/CannotConfirm';
import { Continue } from 'views/_Handlers/00_AccessPoint/Continue';
import { LoadTarget } from 'views/_Handlers/01_LoadTarget/LoadTarget';
import { ClientSimulationWrapper } from 'wrappers/customWrapper/ClientSimulationWrapper';
import { StartOnboarding } from './views/01_AccessPoint/StartOnboarding';
import { KYCIdentityBiometricUpsert } from './views/kyc_09_IdentityBiometricUpsert/IdentityBiometricUpsert';
import { KYCIdentityBiometric } from './views/kyc_11_IdentityBiometric/IdentityBiometric';

import routes from 'config/routes';
import { MainLayout } from 'layout/MainLayout';
import { startUpController } from 'libs/startUpController';
import WrappersComponent from 'wrappers/WrappersComponent';

require('intersection-observer');

//APP INIT
ApplicationWrapper.init({
	projectName: 'caser',
	contextTheme: 'light',
	disableNative: true,
	onStartupParams: () => (startUpController.params = {})
});

const queryClient = new QueryClient();

export default class App extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<>
				<WrappersComponent>
					{/*B2B AGENTE*/}
					<Module path={'/agent'} defaultRoute={routes.loginAgent}>
						<MainLayout>
							<Page
								name={routes.loginAgent}
								component={LoginAgent}
								pushAnimation={Animations.push}
								backAnimation={Animations.back}
							/>
							<Module path={'/internal'} defaultRoute={routes.dashboard}>
								<WithSession
									pageNoSession={routes.loginAgent}
									//availableRoles={['AGENT', 'GLOBAL_AGENT']}
									sessionValidator={(sessionData) => {
										if (sessionData) {
											//console.log(sessionData);
											let decodedToken = jwt.decode(sessionData.token);
											//console.log(decodedToken);
											if (
												decodedToken?.roles?.length &&
												decodedToken.roles.find(
													(role) => role.identifier === 'AGENT' || role.identifier === 'GLOBAL_AGENT'
												)
											) {
												return sessionData.token;
											}
											return false;
										}
										return false;
									}}
								>
									<DashboardProvider>
										<DashboardFiltersProvider>
											<QueryClientProvider client={queryClient}>
												<Page
													path={'/Dashboard'}
													name={routes.dashboard}
													component={Dashboard}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
											</QueryClientProvider>
										</DashboardFiltersProvider>
									</DashboardProvider>
									<Module
										path={'/vinculations/:vinculationId/clients/:clientId/simulations/:simulationId'}
										defaultRoute={routes.simulationAgentDetails}
									>
										<VinculationWrapper fromParam="vinculationId">
											<ClientSimulationWrapper>
												<Page
													path={'/simulationDataAgent'}
													name={routes.simulationDataAgent}
													component={SimulationDataAgent}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/ClientDataAgent'}
													name={routes.clientDataAgent}
													component={ClientDataAgent}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/SimulationAgent'}
													name={routes.simulationAgent}
													component={SimulationAgent}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/FollowUp'}
													name={routes.followUp}
													component={FollowUp}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
											</ClientSimulationWrapper>
										</VinculationWrapper>
									</Module>
								</WithSession>
							</Module>
						</MainLayout>
					</Module>

					{/*
            <FLUJOS CLIENTE>
                  B2B:
                    → /continue → /loadTarget → /simulation → ...
                  B2C:
                    → /caser (client) → /loginClient → /upsertProduct → /simulationData → /simulation → ...
          */}

					{/*B2B CONTINUE LINK*/}
					<Page
						name={routes.continue}
						pushAnimation={Animations.push}
						backAnimation={Animations.back}
						component={Continue}
					/>

					{/*B2B y B2C CLIENTE*/}
					<Module path="/caser" defaultRoute={routes.loginClient}>
						<MainLayout>
							<Page
								name={routes.loginClient}
								component={LoginClient}
								pushAnimation={Animations.push}
								backAnimation={Animations.back}
							/>

							<Page
								path={'/startOnboarding'}
								name={routes.startOnboarding}
								component={StartOnboarding}
								pushAnimation={Animations.push}
								backAnimation={Animations.back}
							/>
							<Page
								path={'/loadTarget'}
								name={routes.loadTarget}
								component={LoadTarget}
								pushAnimation={Animations.push}
								backAnimation={Animations.back}
							/>
							<Module path="/internal" defaultRoute={routes.simulationData}>
								<WithSession pageNoSession={routes.loginClient}>
									<Page
										path={'/simulationData'}
										name={routes.simulationData}
										component={SimulationData}
										pushAnimation={Animations.push}
										backAnimation={Animations.back}
									/>
									<Module path="/target/:targetId">
										<Page
											path={'/simulation'}
											name={routes.simulation}
											component={Simulation}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Page
											path={'/SimulationFamilyCalculator'}
											name={routes.simulationFamilyCalculator}
											component={SimulationFamilyCalculator}
											pushAnimation={Animation.push}
											backAnimation={Animation.back}
										/>
										<Page
											path={'/SimulationFinancialCalculator'}
											name={routes.simulationFinancialCalculator}
											component={SimulationFinancialCalculator}
											pushAnimation={Animation.push}
											backAnimation={Animation.back}
										/>
										<Page
											path={'/SimulationEmploymentCalculator'}
											name={routes.simulationEmploymentCalculator}
											component={SimulationEmploymentCalculator}
											pushAnimation={Animation.push}
											backAnimation={Animation.back}
										/>
										<Page
											path={'/clientData'}
											name={routes.clientData}
											component={ClientData}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Module path={'/questionnaires'} defaultRoute={routes.pbc}>
											<Page
												path={'/pbc'}
												name={routes.pbc}
												component={Pbc}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
											<Page
												path={'/health'}
												name={routes.health}
												component={Health}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
										</Module>
										<Page
											path={'/bankAccount'}
											name={routes.bankAccount}
											component={BankAccount}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Module path={'/identityDocuments'} defaultRoute={routes.identityBiometricUpsert}>
											<Page
												path={'/identityBiometricUpsert'}
												name={routes.identityBiometricUpsert}
												component={IdentityBiometricUpsert}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
											{/* It is not necessary to choose a nationality because in Caser you are always a Spanish citizen. */}
											{/* <Page
												path={'/identityBiometricSelectDoc'}
												name={routes.identityBiometricSelectDoc}
												component={IdentityBiometricSelectDoc}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/> */}
											<Page
												path={'/identityBiometric'}
												name={routes.identityBiometric}
												component={IdentityBiometric}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
											<Page
												path={'/identityBiometricExtraDocs'}
												name={routes.identityBiometricExtraDocs}
												component={IdentityBiometricExtraDocs}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
										</Module>
										<Page
											path={'/activateProduct'}
											name={routes.activateProduct}
											component={ActivateProduct}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Module path={'/sign/:envelopeId'} defaultRoute={routes.signatureSteps}>
											<EnvelopesWrapper>
												<Page
													path={'/signatureSteps'}
													name={routes.signatureSteps}
													component={SignatureSteps}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/signatureRead'}
													name={routes.signatureRead}
													component={SignatureRead}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/signatureContract'}
													name={routes.signatureContract}
													component={SignatureContract}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/productCreationFinishedB2B'}
													name={routes.productCreationFinishedB2B}
													component={ProductCreationFinishedB2B}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/productCreationFinishedB2C'}
													name={routes.productCreationFinishedB2C}
													component={ProductCreationFinishedB2C}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/productCreationFinishedManual'}
													name={routes.productCreationFinishedManual}
													component={ProductCreationFinishedManual}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
											</EnvelopesWrapper>
										</Module>
									</Module>
									<Page
										path={'/cannotConfirm'}
										name={routes.cannotConfirm}
										component={CannotConfirm}
										pushAnimation={Animations.push}
										backAnimation={Animations.back}
									/>

									<Module path={'/KYCidentityDocuments'} defaultRoute={routes.KYCidentityBiometricUpsert}>
										<Page
											path={'/KYCidentityBiometricUpsert'}
											name={routes.KYCidentityBiometricUpsert}
											component={KYCIdentityBiometricUpsert}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Page
											path={'/KYCidentityBiometric'}
											name={routes.KYCidentityBiometric}
											component={KYCIdentityBiometric}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
									</Module>
								</WithSession>
							</Module>
						</MainLayout>
					</Module>
				</WrappersComponent>
			</>
		);
	}
}
