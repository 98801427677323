/* eslint-disable no-undef */
import React, { createContext, useState } from 'react';

import { cloneDeep } from 'lodash';

export const DashboardContext = createContext();

const dashboardInitialContext = {
	agent: undefined,
	isGLobal: false,
	pagination: {
		itemsPerPage: 4,
		currentPage: 1,
		hasMorePages: true
	},
	data: {
		fetch: true,
		data: []
	}
};

const DashboardProvider = ({ children }) => {
	const initialContext = cloneDeep(dashboardInitialContext);

	// AGENT
	const setAgent = (value) => {
		setDashboard((prevState) => {
			const data = { ...prevState };
			data.dashboard.agent = value;
			return data;
		});
	};

	// TYPE
	const setIsGlobal = (value) => {
		setDashboard((prevState) => {
			const data = { ...prevState };
			if (value === 'GLOBAL_AGENT') {
				data.dashboard.isGLobal = true;
			} else {
				data.dashboard.isGLobal = false;
			}
			return data;
		});
	};

	// PAGINATION
	const setPaginationValues = (key, value) => {
		setDashboard((prevState) => {
			const data = { ...prevState };
			data.dashboard.pagination[key] = value;
			return data;
		});
	};

	const fetchNextData = () => {
		setDashboard((prevState) => {
			const data = { ...prevState };
			data.dashboard.pagination.currentPage = data.dashboard.pagination.currentPage + 1;
			data.dashboard.data.fetch = true;
			return data;
		});
	};

	// DATA
	const setDataFetch = (value) => {
		setDashboard((prevState) => {
			const data = { ...prevState };
			data.dashboard.data.fetch = value;
			return data;
		});
	};

	const setDataValues = (value) => {
		setDashboard((prevState) => {
			const data = { ...prevState };
			data.dashboard.data.data = value;
			return data;
		});
	};

	const updateDataValue = (value) => {
		setDashboard((prevState) => {
			const data = { ...prevState };
			const newData = [
				...prevState.dashboard.data.data.map((x) =>
					x.simulation?.id === value?.simulation?.id
						? {
								...x,
								simulation: value.simulation,
								client: value.client,
								clientAuthRequests: value.clientAuthRequests,
								clientDniDocuments: value.clientDniDocuments,
								clientTestCompletions: value.clientTestCompletions,
								currentStep: value.currentStep
						  }
						: x
				)
			];
			data.dashboard.data.data = newData;
			return data;
		});
	};

	// FILTERS
	const resetFilterValues = () => {
		setDashboard((prevState) => {
			const initialContext = cloneDeep(dashboardInitialContext);
			const data = { ...prevState };
			data.dashboard.data.data = [];
			data.dashboard.pagination.itemsPerPage = initialContext.pagination.itemsPerPage;
			data.dashboard.pagination.currentPage = 1;
			data.dashboard.pagination.hasMorePages = true;
			data.dashboard.data.fetch = true;
			return data;
		});
	};

	const dashboardState = {
		dashboard: initialContext,
		setAgent,
		setIsGlobal,
		setPaginationValues,
		fetchNextData,
		setDataFetch,
		setDataValues,
		updateDataValue,
		resetFilterValues
	};

	const [dashboard, setDashboard] = useState(dashboardState);

	// console.log('dashboard', dashboard);

	return <DashboardContext.Provider value={dashboard}>{children}</DashboardContext.Provider>;
};

export default DashboardProvider;
