import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';

UiWrapper.defineComponent('inputSelect', {
	styles: {
		default: {
			containerComponent: {
				border: '1px solid white',
				borderRadius: '49px',
				height: '50px',
				color: '#30353F',
				fontSize: '24px',
				padding: '28px 18px',
				background: 'white',
				display: 'flex',
				justifyContent: 'center',
				marginTop: 0
			},

			selectContainerComponent: {
				background: 'white'
			},

			selectComponent: {
				width: '90%',
				padding: 0,
				paddingTop: 10,
				background: 'white'
			},

			labelComponent: {
				fontSize: 14,
				fontFamily: CONFIG.fonts.regular,
				fontWeight: 400,
				color: CONFIG.colors.grey,
				textAlign: 'left'
			},
			iconComponent: {}
		}
	}
});
