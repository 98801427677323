import React, { useEffect, useState } from 'react';

import {
    IdentityDocumentsWrapper,
    NavigationWrapper,
    PickerHOC,
    SoftSwitcher,
    TargetsWrapper,
    TestResponsesWrapper,
    Text,
    UiWrapper,
    UserWrapper
} from '@coinscrap/webapp-core';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Header from 'layout/components/Header/Header';

import { Wrapper } from 'layout/Wrapper';
import getRedirectRoute from 'libs/redirectRoute';

export const LoadTarget = PickerHOC(
	{
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		},
		targets: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets'
		},
		testCompletions: {
			sourceWrapper: TestResponsesWrapper,
			sourcePath: 'testCompletions'
		},
		identityDocuments: {
			sourceWrapper: IdentityDocumentsWrapper,
			sourcePath: 'identityDocuments'
		}
	},
	LoadingScreen
)(() => {
	const { user, targets, testCompletions, identityDocuments } = PickerHOC.use();

	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const [targetId, setTargetId] = useState(undefined);

	useEffect(() => {
		const target = targets?.[0];
		const targetId = target?.id;

		if (user && target) {
			if (user?.metadata?.isB2B) {
				localStorage.setItem('B2Flow', 'B2B');
			} else {
				localStorage.setItem('B2Flow', 'B2C');
			}

			if (targetId) {
				setTargetId(targetId);
				const { redirectRoute, params } = getRedirectRoute(user, target, testCompletions, identityDocuments);
				navigateTo(redirectRoute, params);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, targets]);

	useLoading(undefined, { loadingMessage: undefined });
	useHeader(<Header mobile />, []);

	const ev = !targets ? 0 : !targetId ? 1 : 2;

	return (
		<Wrapper>
			<SoftSwitcher evaluate={ev}>
				<>
					<Text headingPrimarySt>Obteniendo Datos</Text>
					<Text headingSecondarySt>Espere mientras obtenemos los datos de su simulación.</Text>
				</>
				<>
					<Text headingPrimarySt>Sin simulación</Text>
					<Text headingSecondarySt>
						No se han encontrado datos de simulación existentes. Por favor, póngase en contacto con su agente.
					</Text>
				</>
				<>
					<Text headingPrimarySt>Redireccionando</Text>
				</>
			</SoftSwitcher>
		</Wrapper>
	);
});
