import { useContext } from 'react';

import { UiWrapper } from '@coinscrap/webapp-core';

import { DashboardContext } from '../../views/01_AccessPoint/contexts/Dashboard';

import getCurrentStep from 'libs/redirectRoute';

import { useGetAuthRequests } from './useGetAuthRequests';

export const useReloadSimulation = () => {
	const getAuthRequests = useGetAuthRequests();
	const { dashboard, updateDataValue } = useContext(DashboardContext);
	const { showAlert } = UiWrapper.use();

	const reloadSimulation = async (simulationId) => {
		const simulation = dashboard.data.data.find((x) => x.simulation?.id === simulationId);

		if (!simulation?.simulation?.id) {
			return;
		}

		const newSimulation = await simulation.apiInstance.getTarget(simulation?.simulation?.id);
		const newClient = await simulation.apiInstance.getUser(newSimulation?.userId);
		const newClientTestCompletions = await simulation.apiInstance.getUserTestCompletions(newSimulation?.userId);
		const newClientDniDocuments = await simulation.apiInstance.getUserIdentityDocuments(newSimulation?.userId);
		const { step: newCurrentStep } = getCurrentStep(
			newClient,
			newSimulation,
			newClientTestCompletions,
			newClientDniDocuments
		);

		const reloadedSimulation = {
			apiInstance: simulation.apiInstance,
			vinculation: simulation.vinculation,
			simulation: newSimulation,
			agent: simulation.agent,
			client: newClient,
			clientAuthRequests: await getAuthRequests(newSimulation?.userId),
			clientDniDocuments: newClientDniDocuments,
			clientTestCompletions: newClientTestCompletions,
			currentStep: newCurrentStep,
			filtered: false
		};

		await updateDataValue(reloadedSimulation);
		showAlert('Simulación actualizada');
		return reloadedSimulation;
	};
	return reloadSimulation;
};
