import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const InputLabelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
`;

export const PasswordInputWrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
`;
export const SelectWrapper = styled.div`
	width: 100%;
	.MuiSelect-select {
		width: calc(100% - 30px);
		display: flex;
		height: 50px;
		align-items: center;
	}

	.MuiSelect-icon {
		top: calc(50% - 15px);
		width: 35px;
		height: auto;
		right: 10px;
	}

	.Mui-focused {
		border-color: #b1b1b1;
	}
	.MuiInputBase-root {
		width: 100%;
		height: 29px;
		border: 1px solid ${CONFIG.colors.tertiaryLight};
		border-radius: 3px;
		padding: 4px 12px;
		&::before {
			border-bottom: none;
		}

		&:hover {
			&::before {
				border-bottom: none !important;
			}
		}
		::placeholder {
			color: ${CONFIG.colors.tertiary};
		}
	}
`;
export const PasswordInputIcon = styled.div`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	margin-right: 20px;
`;
export const InputSuggestWrapper = styled.div`
	position: relative;
	width: 100%;
`;
export const InputSuggestCloseWrapper = styled.div`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
`;
