import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';

UiWrapper.defineComponent('loading', {
	styles: {
		default: {
			loaderColor: CONFIG.colors.white,
			backgroundColor: CONFIG.colors.white
		}
	}
});
