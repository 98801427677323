import React from 'react';
import { isMobile } from 'react-device-detect';

import { Button, Text } from '@coinscrap/webapp-core';

import routes from '../config/routes';
import * as S from '../views/11_IdentityBiometric/styles';

export const aliceErrorsMap = {
	'Read and selected documents match': 'El documento añadido no es válido',
	'The doc contains a face in its front side': 'El documento no contiene una cara en la parte frontal',
	'The document and active selfie faces are from the same person':
		'El selfie no corresponde con la cara encontrada en el documento',
	'The document has not been found in our internal database of compromised documents':
		'El documento ha sido encontrado en un listado de documentos comprometidos',
	'The document is not a forgery': 'El documento es una falsificación',
	'The document is not a print': 'El documento es una impresión',
	"The document's pictures are not taken from a screen": 'El documento se ha obtenido desde una pantalla',
	"The document's pictures are not generated digitally": 'El documento ha sido generado digitalmente',
	"The document's MRZ/PDF417/QR is checked": 'El MRZ/PDF417/QR del documento no es válido',
	"The document's NFC is signed by an official authority. It has not been tampered nor cloned.":
		'El NFC del documento no está firmado por una autoridad oficial. Ha sido manipulado o clonado.',
	'There are less than 2 inconsistent fields between the document sides':
		'Hay datos inconsistentes entre las dos caras del documento añadido',
	'The document dates are coherent with each other': 'Las fechas del documento no son coherentes',
	'The document has not expired': 'El documento ha expirado'
};

export const aliceOnError = (e, openModal, navigateTo, scanManaggerReference) => {
	let limitReached = false;
	let textError = 'No se pudo completar el proceso de verificación de indentidad.';

	let errorMessages = [];
	if (e.value.code === 422 || e?.value?.data?.limitReached) {
		limitReached = true;
		textError =
			'No se pudo completar el proceso de verificación de indentidad y has alcanzado el límite de reintentos. Por favor, ponte en contacto con un agente.';
	}
	errorMessages = e?.value?.statusMessages?.map((msg) => aliceErrorsMap[msg]);

	openModal(
		(close) => (
			<S.ModalWrapper>
				<Text headingPrimarySt>{textError || 'Error desconocido'}</Text>
				{errorMessages?.length > 0 &&
					errorMessages?.map((msg, i) => (
						<Text key={`error-${i}`} headingSecondarySt>
							{msg}
						</Text>
					))}
				{!limitReached && (
					<Button
						style={{ marginTop: 15, width: '100%' }}
						onClick={() => {
							scanManaggerReference && scanManaggerReference.current.reset();
							close();
						}}
					>
						Reintentar
					</Button>
				)}

				<Button
					selectedSt
					style={{ marginTop: 15, width: '100%' }}
					onClick={() => {
						close();
						navigateTo(routes.cannotConfirm, {
							queryParams: {
								fromReason: 'IDENTITY',
								fromView: 'identityBiometric'
							}
						});
					}}
				>
					Contactar
				</Button>
			</S.ModalWrapper>
		),
		{
			disableClickAway: true,
			closeButton: false,
			modalClass: isMobile ? 'modal-xl' : 'modal-main',
			key: 'modal-alice-failure'
		}
	);
};
