import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { InputDate, Text } from '@coinscrap/webapp-core';

import moment from 'moment';

import Input from '../Input/Input';
import KeyboardArrowDownIcon from './svgIcons/KeyboardArrowDownIcon';

import { CONFIG } from 'config/config';
import { countriesFlags } from 'data/countriesFlags';

import * as S from './styles';

const InputLabel = ({
	name,
	label,
	onChange,
	value,
	type = 'input',
	selectOptions = [],
	children,
	disabled,
	style,
	styleLabel = {},
	onBlur,
	errorMsg,
	selectType,
	tooltip = undefined
}) => {
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [suggestions, setSuggestions] = useState(
		selectOptions.map((item) => {
			return {
				label: item.label,
				id: item.value
			};
		})
	);

	// const suggestionRef = useRef(null);

	// custom autoSuggest
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [input, setInput] = useState(
		type === 'autoSuggest' ? selectOptions.find((item) => item.value === value)?.label || '' : ''
	);

	const onChangeSuggest = (e) => {
		const userInput = e.target.value;

		// Filter our suggestions that don't contain the user's input
		const unLinked = suggestions.filter(
			(suggestion) => suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1
		);

		setInput(e.target.value);
		setFilteredSuggestions(unLinked);
		setShowSuggestions(true);
	};

	const onClickSuggest = (e) => {
		const text = e || value;
		const findSuggest = selectOptions.find((item) => item.value === text);
		setFilteredSuggestions([]);
		setInput(findSuggest?.label);
		onChange(findSuggest?.value);
		setShowSuggestions(false);
	};

	const SuggestionsListComponent = () => {
		return filteredSuggestions.length ? (
			<ul className="suggestions" id="suggestBox" tabIndex="0">
				{filteredSuggestions.map((suggestion, index) => {
					//console.log({ suggestion });
					let className;
					// Flag the active suggestion with a class
					if (suggestion.id === value) {
						className = 'suggestion-active';
					}
					return (
						<li
							className={className}
							key={suggestion?.id || suggestion?.label}
							onClick={(e) => onClickSuggest(suggestion.id, index)}
						>
							{suggestion.label}
						</li>
					);
				})}
			</ul>
		) : (
			<div className="no-suggestions">
				<p
					style={{
						color: CONFIG.colors.danger,
						fontFamily: CONFIG.fonts.bold,
						fontSize: CONFIG.fontSizes.small
					}}
				>
					No hay resultados
				</p>
			</div>
		);
	};

	return (
		<S.InputLabelWrapper style={style}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Text labelInputSt style={styleLabel}>
					{label}
				</Text>{' '}
				{tooltip && tooltip}
			</div>
			{type === 'date' && (
				<InputDate
					disabled={disabled}
					value={moment(value)}
					onChange={onChange}
					pickOnSelect={false}
					maxDate={moment().subtract(18, 'years')}
				/>
			)}
			{type === 'number' && (
				<Input
					disabled={disabled}
					value={value}
					onChange={onChange}
					placeholder={label}
					onBlur={onBlur}
					type={'number'}
					min={1}
				/>
			)}
			{type === 'input' && (
				<Input disabled={disabled} value={value} onChange={onChange} placeholder={label} onBlur={onBlur} name={name} />
			)}
			<p
				style={{
					color: CONFIG.colors.danger,
					visibility: errorMsg ? 'visible' : 'hidden',
					fontFamily: CONFIG.fonts.bold,
					fontSize: CONFIG.fontSizes.small
				}}
			>
				{errorMsg}
			</p>
			{type === 'phone' && (
				<>
					<PhoneInput
						disabled={disabled}
						value={value}
						onChange={onChange}
						placeholder={label}
						defaultCountry="ES"
						onBlur={onBlur}
						style={{ width: '100%' }}
						maxlength="12"
					/>
				</>
			)}
			{type === 'password' && (
				<S.PasswordInputWrapper>
					<Input
						disabled={disabled}
						type={!passwordVisibility ? 'password' : 'text'}
						value={value}
						onChange={onChange}
						placeholder={label}
						style={style}
					/>
					<S.PasswordInputIcon>
						{!passwordVisibility ? (
							<VisibilityIcon
								style={{ width: 30, height: 30, cursor: 'pointer' }}
								onClick={() => {
									setPasswordVisibility((prevState) => !prevState);
								}}
							/>
						) : (
							<VisibilityOffIcon
								style={{ width: 30, height: 30, cursor: 'pointer' }}
								onClick={() => {
									setPasswordVisibility((prevState) => !prevState);
								}}
							/>
						)}
					</S.PasswordInputIcon>
				</S.PasswordInputWrapper>
			)}
			{type === 'select' && (
				<S.SelectWrapper value={value}>
					<Select
						disabled={disabled}
						labelId="label"
						id="select"
						value={value}
						onChange={onChange}
						IconComponent={KeyboardArrowDownIcon}
					>
						<MenuItem value={0} disabled style={{ width: '100%' }}>
							<p style={{ fontSize: 14, color: CONFIG.colors.tertiary }}>{label}</p>
						</MenuItem>

						{selectOptions.map((option) => (
							<MenuItem key={option.value} value={option.value} style={{ width: '100%' }}>
								<p style={{ fontSize: 14, color: CONFIG.colors.tertiary }}>{option.label}</p>
							</MenuItem>
						))}
					</Select>
				</S.SelectWrapper>
			)}

			{type === 'selectCountry' && (
				<S.SelectWrapper value={value}>
					<Select
						disabled={disabled}
						labelId="label"
						id="select"
						value={value}
						onChange={onChange}
						IconComponent={KeyboardArrowDownIcon}
					>
						<MenuItem value={0} disabled style={{ width: '100%' }}>
							<p style={{ fontSize: 18, color: '#7a7a7a' }}>{label}</p>
						</MenuItem>

						{selectOptions.map((option) => {
							const img = countriesFlags[option?.alpha2];
							return (
								<MenuItem key={option.value} value={option.value} style={{ width: '100%' }}>
									<img src={img} alt="" />
									<p style={{ fontSize: 18, color: '#7a7a7a', marginLeft: 10 }}>{option.label}</p>
								</MenuItem>
							);
						})}
					</Select>
				</S.SelectWrapper>
			)}
			{type === 'autoSuggest' && (
				<S.InputLabelWrapper>
					<Input
						// ref={suggestionRef}
						onBlur={(e) => {
							let related = e.relatedTarget ? e.relatedTarget.id : null;
							if (related === 'suggestBox') {
								return;
							}
							onClickSuggest(value || '');
						}}
						disabled={disabled}
						value={input}
						onChange={onChangeSuggest}
						// onKeyDown={onKeyDown}
						placeholder={label}
					/>
					{showSuggestions && input && <SuggestionsListComponent />}
				</S.InputLabelWrapper>
			)}
			{type === 'custom' && children}
		</S.InputLabelWrapper>
	);
};

export default InputLabel;
