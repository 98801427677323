import { useEffect } from 'react';

import {
	BackendWrapper,
	NavigationWrapper,
	UserWrapper,
	VinculationWrapper,
	WrapperBuilder
} from '@coinscrap/webapp-core';

const builder = new WrapperBuilder('clientSimulation', 'clientSimulation', [
	UserWrapper,
	BackendWrapper,
	VinculationWrapper,
	NavigationWrapper
]);

// Function that loads client and simulation
builder
	.getFunctionsRegisterInstance()
	.addFunction(
		'loadClient',
		({ variablesHandler: { variablesSetter }, bindedContexts: { vinculationApiInstance } }) =>
			async (idClient) => {
				const client = await vinculationApiInstance.getUser(idClient);
				variablesSetter('client')(client);
			}
	);

builder
	.getFunctionsRegisterInstance()
	.addFunction(
		'loadSimulationForClient',
		({ variablesHandler: { variablesSetter }, bindedContexts: { vinculationApiInstance } }) =>
			async (idCLient, idSimulation) => {
				let simulation;
				if (idSimulation) {
					simulation = await vinculationApiInstance.getTarget(idSimulation);
				} else {
					const sims = await vinculationApiInstance.getUserTargets(idCLient);
					simulation = sims[0] || null;
				}
				variablesSetter('simulation')(simulation);
			}
	);

// Function to update client
builder.getFunctionsRegisterInstance().addFunction(
	'updateClient',
	({
		variablesHandler: {
			variables: { client },
			variablesSetter
		},
		bindedContexts: { vinculationApiInstance }
	}) =>
		async (clientData = {}) => {
			const { email, name, surname, phoneNumber, metadata } = clientData;
			await vinculationApiInstance.updateUserName(client.id, {
				name,
				surname
			});
			if (email) {
				await vinculationApiInstance.updateUserEmail(client.id, email);
			}
			if (phoneNumber) {
				await vinculationApiInstance.updateUserPhoneNumber(client.id, phoneNumber);
			}
			await vinculationApiInstance.updateUserMetadata(client.id, metadata);

			variablesSetter('client')((oldClient) => {
				return { ...oldClient, ...clientData };
			});
		}
);

// Function to update simulation
builder.getFunctionsRegisterInstance().addFunction(
	'updateSimulation',
	({
		variablesHandler: {
			variablesSetter,
			variables: { simulation }
		},
		bindedContexts: { vinculationApiInstance }
	}) =>
		async (simulationData) => {
			const simCreationData = simulation?.targetProperties?.find((x) => x.name === 'creationData')?.value;
			const newData = { ...simCreationData, ...simulationData };
			await vinculationApiInstance.saveTargetCreationData(simulation.id, newData);
			variablesSetter('client')((oldSimulation) => {
				return {
					...oldSimulation,
					targetProperties: simulation?.targetProperties?.map((x) => {
						if (x.name === 'creationData') {
							x.value = newData;
						}
						return x;
					})
				};
			});
		}
);

// Basic logic of the wrapper
builder.getCustomLogicRegisterInstance().setLogic(
	({
		functions: { loadClient, loadSimulationForClient },
		variablesHandler: {
			variables: { client, simulation },
			variablesSetter
		},
		componentProps: { clientIdParamName, simulationIdParamName, clientId: compCId, simlationId: compSId },
		bindedContexts: { user, vinculationApiInstance, vinculation, params }
	}) => {
		let clientId = compCId || (params || {})[clientIdParamName || 'clientId'] || vinculation?.secondaryUserId;
		let simulationId = compSId || (params || {})[simulationIdParamName || 'simulationId'];

		// Check the current logged user (Obtained from UserWrapper)
		const userId = user && (user._id || user.id);

		useEffect(() => {
			if (userId) {
				// If user is logged, then load the simulation and vinculation
				if (clientId && vinculationApiInstance) {
					loadClient(clientId);
					loadSimulationForClient(clientId, simulationId);
				} else {
					// eslint-disable-next-line no-console
					console.log(' NO CLIENT OR VINCULATION INSTANCE', params, clientId, vinculationApiInstance);
				}
			} else if (!userId) {
				// If no user logged, remove info
				if (client || simulation) {
					variablesSetter('client')(undefined);
					variablesSetter('simulation')(undefined);
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [userId, clientId, simulationId, vinculationApiInstance]);
	}
);

builder
	.getVariablesRegisterInstance()
	.addVariable('client', { startValue: undefined })
	.addVariable('simulation', { startValue: undefined });

export const ClientSimulationWrapper = builder.build();
