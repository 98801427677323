/* eslint-disable no-undef */
import { useQuery } from '@tanstack/react-query';

import { BackendWrapper } from '@coinscrap/webapp-core';

import getCurrentStep from 'libs/redirectRoute';

import { useGetAuthRequests } from './useGetAuthRequests';

export const NormalAgentDash = async (
	page,
	itemsPerPage,
	statusFilters,
	dateFilter,
	agent,
	apiInstance,
	getInstanceApiForVinculation,
	getAuthRequests
) => {
	const offset = itemsPerPage * (page - 1);

	//ESTADO
	let filterStatusJSON = statusFilters.map((key) => {
		return { where: { status: key.toUpperCase() } };
	});
	if (filterStatusJSON.length === 1) {
		filterStatusJSON = filterStatusJSON[0];
	} else if (filterStatusJSON.length > 1) {
		const arrayStatus = filterStatusJSON.map((key) => key.where);
		filterStatusJSON = { where: { or: arrayStatus } };
	}

	//FECHA
	let filterDateJSON = {
		where: { createDate: { between: [dateFilter.startDate, dateFilter.endDate] } }
	};

	//FILTER BUILD
	const buildWhereJSON = () => {
		if (statusFilters.length === 0) {
			return filterDateJSON.where;
		}
		const andFilter = { and: [filterDateJSON.where] };
		if (statusFilters.length > 0) {
			andFilter.and.push(filterStatusJSON.where);
		}
		return andFilter;
	};

	async function getSimulationInfo(simulation) {
		// console.log('simulation', simulation);
		// debugger;
		if (simulation.filtered) {
			return simulation;
		}
		try {
			let api = await getInstanceApiForVinculation(simulation.vinculation.vinculationId);
			const client = await api.getUser(simulation.simulation.userId);
			const clientTestCompletions = await api.getUserTestCompletions(simulation.simulation.userId);
			const clientDniDocuments = await api.getUserIdentityDocuments(simulation.simulation.userId);
			const { step: currentStep } = getCurrentStep(
				client,
				simulation.simulation,
				clientTestCompletions,
				clientDniDocuments
			);

			return {
				apiInstance: api,
				vinculation: simulation.vinculation,
				simulation: simulation.simulation,
				agent: simulation.agent,
				client,
				clientAuthRequests: await getAuthRequests(simulation.simulation.userId),
				clientDniDocuments,
				clientTestCompletions,
				currentStep,
				filtered: false
			};
		} catch (error) {
			//eslint-disable-next-line no-console
			console.log('❌ error', error);
		}

		return undefined;
	}

	async function getSimulationsData(simulations) {
		return await Promise.all(
			simulations.map(async (simulation) => {
				return await getSimulationInfo(simulation);
			})
		);
	}

	async function getSimulations() {
		const filterVinculations = {
			include: [
				{
					relation: 'masterVinculations',
					scope: {
						limit: itemsPerPage,
						skip: offset,
						order: 'createDate DESC'
					}
				}
			]
		};

		const vinculations = await apiInstance.microserviceCall('Us')(
			`/users/me?filter=${encodeURIComponent(JSON.stringify(filterVinculations))}`,
			'GET',
			{}
		);

		const agentVinculations = vinculations?.masterVinculations?.map((vinc) => {
			return {
				vinculationId: vinc.id,
				agentId: vinc.mainUserId,
				userId: vinc.secondaryUserId
			};
		});
		// console.log('agentVinculations', agentVinculations);
		// debugger;

		const filter = {
			where: buildWhereJSON(),
			include: [{ relation: 'targetType' }, { relation: 'targetProperties' }],
			order: 'createDate DESC'
		};

		if (agentVinculations) {
			let simulations = await Promise.all(
				agentVinculations.map(async (vinculation) => {
					const targets = await apiInstance.microserviceCall('Ts')(
						`/users/${vinculation.userId}/targets?filter=${encodeURIComponent(JSON.stringify(filter))}`,
						'GET',
						{}
					);
					const simulation = targets[0];
					if (simulation) {
						return { simulation, agent, vinculation };
					}
					return { filtered: true };
				})
			);
			simulations = simulations?.filter((simulation) => simulation !== undefined);
			// console.log('simulations', simulations);
			// debugger;
			return simulations;
		}
		return [];
	}

	return getSimulations().then((simulations) => {
		return getSimulationsData(simulations).then((simulation) => {
			return simulation;
		});
	});
};

export const useFetchNormalAgentDash = (fetch, page, itemsPerPage, statusFilters, dateFilter, agent) => {
	const { getInstanceApi, getInstanceApiForVinculation } = BackendWrapper.use();
	const apiInstance = getInstanceApi();
	const getAuthRequests = useGetAuthRequests();

	return useQuery(
		[
			'NormalAgentDash',
			page,
			itemsPerPage,
			statusFilters,
			dateFilter,
			agent,
			apiInstance,
			getInstanceApiForVinculation,
			getAuthRequests
		],
		() => {
			return NormalAgentDash(
				page,
				itemsPerPage,
				statusFilters,
				dateFilter,
				agent,
				apiInstance,
				getInstanceApiForVinculation,
				getAuthRequests
			);
		},
		{
			retry: false, // will disable retries before displaying an error
			staleTime: 0,
			enabled: !!fetch,
			keepPreviousData: false
		}
	);
};
