/* eslint-disable no-undef */
import { useQuery } from '@tanstack/react-query';

import { BackendWrapper } from '@coinscrap/webapp-core';

import getCurrentStep from 'libs/redirectRoute';

import { useGetAuthRequests } from './useGetAuthRequests';
import { useGetGlobalAgentVinculations } from './useGetGlobalAgentVinculations';

export const GlobalAgentDash = async (
	page,
	itemsPerPage,
	statusFilters,
	dateFilter,
	apiInstance,
	getInstanceApiForVinculation,
	getAuthRequests,
	getGlobalAgentVinculations
) => {
	const offset = itemsPerPage * (page - 1);

	//ESTADO
	let filterStatusJSON = statusFilters.map((key) => {
		return { where: { status: key.toUpperCase() } };
	});
	if (filterStatusJSON.length === 1) {
		filterStatusJSON = filterStatusJSON[0];
	} else if (filterStatusJSON.length > 1) {
		const arrayStatus = filterStatusJSON.map((key) => key.where);
		filterStatusJSON = { where: { or: arrayStatus } };
	}

	//FECHA
	let filterDateJSON = {
		where: { createDate: { between: [dateFilter.startDate, dateFilter.endDate] } }
	};

	//FILTER BUILD
	const buildWhereJSON = () => {
		if (statusFilters.length === 0) {
			return filterDateJSON.where;
		}
		const andFilter = { and: [filterDateJSON.where] };
		if (statusFilters.length > 0) {
			andFilter.and.push(filterStatusJSON.where);
		}
		return andFilter;
	};

	async function getSimulationInfo(simulation, vinculations) {
		try {
			let vinculation = vinculations.find((vinc) => vinc.userId === simulation.userId);
			let api = apiInstance;
			let agent;
			if (vinculation) {
				api = await getInstanceApiForVinculation(vinculation.vinculationId);
				agent = await api.getUser(vinculation.agentId);
			} else {
				vinculation = { vinculationId: simulation.id };
				agent = 'B2C';
			}
			const client = await api.getUser(simulation.userId);
			const clientAuthRequests = await getAuthRequests(simulation.userId);
			const clientDniDocuments = await api.getUserIdentityDocuments(simulation.userId);
			const clientTestCompletions = await api.getUserTestCompletions(simulation.userId);
			const { step: currentStep } = getCurrentStep(client, simulation, clientTestCompletions, clientDniDocuments);

			return {
				apiInstance: api,
				vinculation,
				simulation,
				agent,
				client,
				clientAuthRequests,
				clientDniDocuments,
				clientTestCompletions,
				currentStep
			};
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('❌ error', error);
		}

		return undefined;
	}

	async function getSimulationsData(simulations) {
		const allVinculations = await getGlobalAgentVinculations();
		const vinculations = allVinculations.map((vinc) => {
			return {
				vinculationId: vinc.id,
				agentId: vinc.mainUserId,
				userId: vinc.secondaryUserId
			};
		});
		return await Promise.all(
			simulations.map(async (simulation) => {
				return await getSimulationInfo(simulation, vinculations);
			})
		);
	}

	async function getSimulations() {
		const filter = {
			where: buildWhereJSON(),
			limit: itemsPerPage,
			skip: offset,
			include: [{ relation: 'targetType' }, { relation: 'targetProperties' }],
			order: 'createDate DESC'
		};

		const simulations = await apiInstance.microserviceCall('Ts')(
			`/targets?filter=${encodeURIComponent(JSON.stringify(filter))}`,
			'GET',
			{}
		);
		// console.log('simulations', simulations);
		// debugger;
		return simulations;
	}

	return getSimulations().then(async (simulations) => {
		return getSimulationsData(simulations).then((simulation) => {
			return simulation;
		});
	});
};

export const useFetchGlobalAgentDash = (fetch, page, itemsPerPage, statusFilters, dateFilter) => {
	const { getInstanceApi, getInstanceApiForVinculation } = BackendWrapper.use();
	const apiInstance = getInstanceApi();
	const getAuthRequests = useGetAuthRequests();
	const getGlobalAgentVinculations = useGetGlobalAgentVinculations();
	return useQuery(
		[
			'GlobalAgentDash',
			page,
			itemsPerPage,
			statusFilters,
			dateFilter,
			apiInstance,
			getInstanceApiForVinculation,
			getAuthRequests,
			getGlobalAgentVinculations
		],
		() => {
			return GlobalAgentDash(
				page,
				itemsPerPage,
				statusFilters,
				dateFilter,
				apiInstance,
				getInstanceApiForVinculation,
				getAuthRequests,
				getGlobalAgentVinculations
			);
		},
		{
			retry: false, // will disable retries before displaying an error
			staleTime: 1000 * 60 * 30,
			enabled: !!fetch,
			keepPreviousData: true
		}
	);
};
