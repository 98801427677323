import React from 'react';
import { isMobile } from 'react-device-detect';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Text } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';
import { faqQuestions } from 'data/faqQuestions';
import styled from 'styled-components/macro';

const FaqTitle = styled.h2`
	color: ${CONFIG.colors.primary};
	font-family: ${CONFIG.fonts.bold};
	font-size: ${isMobile ? 24 : 28}px;
	margin-bottom: ${isMobile ? 15 : 24}px;
	text-align: left;
`;

const FaqHeading = styled.h3`
	color: ${CONFIG.colors.tertiary};
	font-family: ${CONFIG.fonts.bold};
	font-size: ${CONFIG.fontSizes.mediumLabel};
	max-width: ${isMobile ? 300 : 600}px;
	text-align: ${isMobile ? 'center' : 'left'};
`;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginBottom: '50px'
	},
	expanded: {
		backgroundColor: CONFIG.colors.primaryLight,
		display: 'flex',
		flexWrap: 'wrap',
		padding: '13px 20px 14px 15px'
	},
	expandIcon: {
		color: CONFIG.colors.primary
	}
}));

export const Faq = ({ title = 'Preguntas frecuentes sobre la simulación y contratación del seguro de vida' }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<FaqTitle>{title}</FaqTitle>
			{faqQuestions.map((item, idx) => (
				<Accordion key={idx} elevation={0}>
					<AccordionSummary
						className={classes.expanded}
						expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<FaqHeading>{item.question}</FaqHeading>
					</AccordionSummary>
					<AccordionDetails>
						<Text faqAnswerSt>{item.answer}</Text>
					</AccordionDetails>
				</Accordion>
			))}
		</div>
	);
};
