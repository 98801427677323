import React from 'react';
import { isMobile } from 'react-device-detect';

import { Text } from '@coinscrap/webapp-core';

import { ErrorModal } from '../components/ErrorModal/ErrorModal';
import { SmallButton } from '../components/SmallButton/SmallButton';
import routes from '../config/routes';

export const caserOnError = (e, contactReason, openModal, navigateTo, setTrigger, capitalMinimum, capitalMaximum) => {
	let messageError = e?.safeMessage?.toString() ?? e?.message?.toString();
	let extraMessage = '';
	let showReview = false;
	let showReviewBankAccount = false;
	let showReviewSimulation = false;
	let showRetry = false;
	let showContact = false;
	//eslint-disable-next-line no-console
	console.log('❌ messageError', messageError);

	if (messageError.indexOf('cumulosLimitError') !== -1) {
		messageError = 'Superas el límite de cúmulos';
		extraMessage = 'Contacta con un agente para terminar la contratación';
		showContact = true;
		//eslint-disable-next-line no-console
		console.log('❌ messageError extraMessage', messageError, extraMessage);
	} else if (messageError.indexOf('maxCapitalReached') !== -1) {
		messageError = 'El capital seleccionado no es válido';
		extraMessage = `Recuerda los límites de capital total ${capitalMinimum && capitalMaximum ? `(${capitalMinimum}€ - ${capitalMaximum}€) ` : ''
			}cuando configures la prima mensual/trimestral/semestral/anual para obtener una simulación correcta`;
		showReviewSimulation = true;
		showContact = true;
		//eslint-disable-next-line no-console
		console.log('❌ messageError extraMessage', messageError, extraMessage);
	} else if (messageError.indexOf('minPeriodicContributionError') !== -1) {
		messageError =
			'La simulación ha fallado porque el pago fraccionado es inferior al mínimo permitido. Selecciona otro fraccionamiento.';
		showReviewSimulation = true;
		showContact = true;
		//eslint-disable-next-line no-console
		console.log('❌ messageError extraMessage', messageError, extraMessage);
	} else if (messageError.indexOf('smokeRejection') !== -1 || messageError.indexOf('alcoholRejection') !== -1) {
		messageError = 'Los datos introducidos no permiten continuar el proceso';
		extraMessage = 'Contacta con un agente para terminar la contratación';
		showReview = true;
		showContact = true;
		//eslint-disable-next-line no-console
		console.log('❌ messageError extraMessage', messageError, extraMessage);
	} else if (messageError.indexOf('cannotSaveAccountInfoError') !== -1) {
		messageError = 'Revisa la cuenta bancaria';
		extraMessage = 'Comprueba que has introducido un IBAN válido';
		showReviewBankAccount = true;
		showContact = true;
		//eslint-disable-next-line no-console
		console.log('❌ messageError extraMessage', messageError, extraMessage);
	} else {
		showReview = true;
		showRetry = true;
		showContact = true;
		messageError = 'Ha ocurrido un error desconocido';
		extraMessage = 'Revisa tus datos y vuelve a intentarlo';
		//eslint-disable-next-line no-console
		console.log('❌ messageError extraMessage', messageError, extraMessage);
	}

	openModal(
		(close) => (
			<ErrorModal>
				<Text headingPrimarySt>{messageError || 'Error desconocido'}</Text>
				{extraMessage && <Text headingSecondarySt>{extraMessage || 'Error desconocido'}</Text>}
				{showReview && (
					<SmallButton
						onClick={() => {
							//eslint-disable-next-line no-console
							console.info('🚀 NAVIGATING TO simulationData');
							navigateTo(routes.simulationData);
							close();
						}}
					>
						Revisar Datos
					</SmallButton>
				)}
				{showReviewSimulation && (
					<SmallButton
						onClick={() => {
							close();
						}}
					>
						Revisar Simulación
					</SmallButton>
				)}
				{showReviewBankAccount && (
					<SmallButton
						onClick={() => {
							//eslint-disable-next-line no-console
							console.info('🚀 NAVIGATING TO bankAccount');
							navigateTo(routes.bankAccount);
							close();
						}}
					>
						Revisar Cuenta bancaria
					</SmallButton>
				)}
				{showRetry && (
					<SmallButton
						onClick={() => {
							setTrigger((t) => t + 1);
							close();
						}}
					>
						Reintentar
					</SmallButton>
				)}
				{showContact && (
					<SmallButton
						onClick={() => {
							//eslint-disable-next-line no-console
							console.info('🚀 NAVIGATING TO cannotConfirm');
							navigateTo(routes.cannotConfirm, {
								queryParams: {
									fromReason: contactReason,
									fromView: 'activateProduct'
								}
							});
							close();
						}}
					>
						Contactar
					</SmallButton>
				)}
			</ErrorModal>
		),
		{
			disableClickAway: true,
			closeButton: false,
			modalClass: isMobile ? 'modal-xl' : 'modal-main',
			key: 'modal-CaserError'
		}
	);
};
