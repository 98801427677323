import { useCallback } from 'react';

import { BackendWrapper } from '@coinscrap/webapp-core';

export const useGetAliceCertificate = () => {
	const { getInstanceApi } = BackendWrapper.use();
	const apiInstance = getInstanceApi();
	const aliceCertificatePublicUrl = useCallback(async (documentId) => {
		if (apiInstance) {
			const aliceCertificatePublicUrl = await apiInstance.microserviceCall('Ids')(
				`/identity-documents/${documentId}/pdf-certificate/public-url`,
				'GET'
			);
			//console.log('aliceCertificatePublicUrl', aliceCertificatePublicUrl);
			return aliceCertificatePublicUrl;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return aliceCertificatePublicUrl;
};
