import React, { useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Modal from '@material-ui/core/Modal';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Button, Text, UiWrapper, View } from '@coinscrap/webapp-core';

import arrowRigth from 'assets/images/svgs/arrowRigth.svg';
import CloseButton from 'components/CloseButton/CloseButton';

import { CONFIG } from 'config/config';
import { Wrapper } from 'layout/Wrapper';

import * as S from './styles';

// components
// configs
const BeneficiariesModal = ({ isOpen, onClose, children, onClick }) => {
	const faqRef = useRef();
	const { useBackground } = UiWrapper.use();
	useBackground(CONFIG.colors.white);
	const [isScroll, setIsScroll] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);

	const observer = useRef();

	const lastPlanElementRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[entries.length - 1].isIntersecting) {
					setButtonDisabled(false);
					setIsScroll(false);
				} else {
					setIsScroll(true);
				}
			});
			if (node) observer.current.observe(node);
		},
		[observer]
	);

	return (
		<Modal open={isOpen} onClose={onClose}>
			<View fullHeightSt style={{ backgroundColor: CONFIG.colors.white, overflowY: 'auto' }}>
				<View fullHeightSt style={{ justifyContent: 'flex-start' }}>
					<View startSt fullHeightSt>
						<View horizontalSt endSt style={{ marginTop: 10 }}>
							<CloseButton
								color={CONFIG.colors.black}
								action={() => {
									onClose();
								}}
							/>
						</View>

						<View totalCenterSt>
							<Wrapper>
								<Text headingPrimarySt>Designación de beneficiarios</Text>
								<Text headingSecondarySt>
									En defecto de designación expresa será beneficiario por orden preferente y excluyente:
								</Text>
								<S.StepWrapper style={{ marginBottom: isMobile ? '60px' : 0 }}>
									<S.StepWrapperItem>
										<S.StepCircle>
											<img src={arrowRigth} alt={'arrow icon'} />
										</S.StepCircle>
										<Text stepsSt>Cónyuge no separado legalmente por resolución judicial del asegurado.</Text>
									</S.StepWrapperItem>
									<S.StepWrapperItem>
										<S.StepCircle>
											<img src={arrowRigth} alt={'arrow icon'} />
										</S.StepCircle>
										<Text stepsSt>Hijos supervivientes del asegurado a partes iguales.</Text>
									</S.StepWrapperItem>
									<S.StepWrapperItem>
										<S.StepCircle>
											<img src={arrowRigth} alt={'arrow icon'} />
										</S.StepCircle>
										<Text stepsSt>Padres supervivientes del asegurado a partes iguales.</Text>
									</S.StepWrapperItem>
									<S.StepWrapperItem>
										<S.StepCircle>
											<img src={arrowRigth} alt={'arrow icon'} />
										</S.StepCircle>
										<Text stepsSt>Herederos legales del asegurado.</Text>
									</S.StepWrapperItem>
								</S.StepWrapper>
								<View ref={lastPlanElementRef} style={{ height: 10 }}>
									<View ref={faqRef} />
								</View>
							</Wrapper>
						</View>

						<ArrowForwardIosIcon
							onClick={() => {
								faqRef.current.scrollIntoView({ behavior: 'smooth' });
							}}
							style={{
								position: 'fixed',
								bottom: 15,
								right: 15,
								width: 20,
								height: 'auto',
								transform: 'rotate(90deg)',
								opacity: isScroll ? 1 : 0,
								cursor: 'pointer'
							}}
						/>
						<Button
							termsSt
							disabled={!!buttonDisabled}
							onClick={() => {
								onClick();
								onClose();
							}}
						>
							VOLVER
						</Button>
					</View>
				</View>
			</View>
		</Modal>
	);
};

export default BeneficiariesModal;
