import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
    NavigationWrapper,
    PickerHOC,
    TargetsWrapper,
    TestResponsesWrapper,
    TestsWrapper,
    Text,
    UiWrapper,
    UserWrapper
} from '@coinscrap/webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { Faq } from 'components/FAQ/Faq';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Header from 'layout/components/Header/Header';
import QuestionnaireBox from './Components/QuestionnaireBox/QuestionnaireBox';

import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';

import * as S from './styles';

const testIdentifier = 'health';

export const Health = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		},
		test: {
			sourceWrapper: TestsWrapper,
			sourcePath: 'tests',
			arrayItemMatcher: {
				type: 'value',
				itemPropertyPath: 'identifier',
				value: testIdentifier
			}
		}
	},
	LoadingScreen
)(() => {
	const { saveResponse, getResponse, isTestSettled, registerTestCompletion } = TestResponsesWrapper.use();
	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { updateUser } = UserWrapper.use();

	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	const { target, user, test } = PickerHOC.use();

	// NAVIGATION
	const back = async () => {
		goBack();
	};

	const next = async () => {
		try {
			await registerTestCompletion(testIdentifier);
			setLoading('Guardando...');
			await updateUser({
				...user,
				metadata: {
					...user?.metadata,
					healthOK: true
				}
			});
			navigateTo(routes.bankAccount);
		} catch (e) {
			setLoading('Guardando...');
			await updateUser({
				...user,
				metadata: {
					...user?.metadata,
					healthOK: false
				}
			});
			navigateTo(routes.cannotConfirm, {
				queryParams: {
					fromReason: 'SALUD',
					fromView: 'health'
				}
			});
		}
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} />, []);

	return (
		<Wrapper width={'80%'}>
			<HorizontalProgressBar step={3} total={6} styleWrapper={{ maxWidth: 450 }} />
			<Text headingPrimarySt>Para poder contratar tu seguro necesitamos que contestes a unas preguntas</Text>
			{test.testQuestions?.map((tq) => [
				<QuestionnaireBox
					text={tq.title}
					listWithTooltips={true}
					tooltipText={tq.metadata?.tooltip}
					onChange={(v) => saveResponse(tq.id, v)}
					value={getResponse(tq.id)}
				/>,
				<S.QuestionnaireBoxLine />
			])}
			<BackNextButton onClickNext={next} onClickBack={back} isNextDisabled={!isTestSettled(testIdentifier)} />
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
