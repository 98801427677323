import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
	AnalyticsWrapper,
	Button,
	InputCheck,
	NavigationWrapper,
	PickerHOC,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import TermsModal from '../../../components/TermsModal/TermsModal';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Header from 'layout/components/Header/Header';
import { useSendEmailContact } from 'libs/hooks/useSendEmailContact';
import isEmail from 'validator/lib/isEmail';

import { CONFIG } from 'config/config';
import { Wrapper } from 'layout/Wrapper';

import * as S from './styles';

export const CannotConfirm = PickerHOC(
	{
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	const { user } = PickerHOC.use();
	const { updateUser } = UserWrapper.use();
	const { goBack, query } = NavigationWrapper.use();
	const { openModal, useHeader, useLoading } = UiWrapper.use();
	const { pageView, emitEvent, updateData, regLead } = AnalyticsWrapper.use();

	const [loading, setLoading] = useState();
	const [showContactForm, setShowContactForm] = useState(false);
	const [showOnlyError, setShowOnlyError] = useState(false);
	const [prevPage, setPrevPage] = useState('No detectada');
	const [prevView, setPrevView] = useState('No detectada');
	const [emailSent, setEmailSent] = useState(false);
	const isB2B = useMemo(() => {
		return localStorage.getItem('B2Flow') === 'B2B' ? true : false || false;
	}, []);

	const sendContactEmail = useSendEmailContact(user.id);

	// FORM
	const [name, setName] = useState(user?.name?.trim() === 'NEW_USER' ? '' : user?.name?.trim() || '');
	const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
	const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);
	const [email, setEmail] = useState(user?.email?.trim() || '');
	const [isEmailCorrect, setIsEmailCorrect] = useState(true);
	const [horarioLlamadas, setHorarioLlamadas] = useState(user?.metadata?.horarioLlamadas || 'ahora');

	const handleInputChange = (setValue) => (e) => {
		const inputValue = e.target.value;
		if (/^[A-Za-z\s]+$/.test(inputValue)) {
			setValue(inputValue);
		}
	};

	// CHECKS
	const [wantsInformation, setWantsInformation] = useState(user?.metadata?.acceptContact || false);
	const [privacityAccepted, setPrivacityAccepted] = useState(false);
	const [walmericDelioId, setWalmericDelioId] = useState(
		user?.metadata?.analytics?.WALMERIC_DELIO?.leadId || undefined
	);

	// EL CORE TARDA UN POCO EN ACTUALIZAR query POR LO QUE HAY QUE ESPERAR A TENER VALORES VÁLIDOS
	useEffect(() => {
		if (
			query?.fromReason &&
			query?.fromView &&
			typeof query?.fromReason === 'string' &&
			typeof query?.fromView === 'string'
		) {
			if (query?.fromReason) {
				if (query?.fromReason === 'PBC' || query?.fromReason === 'SALUD') {
					setShowOnlyError(true);
				}
				setPrevPage(query?.fromReason);
			}
			if (query?.fromView) {
				setPrevView(query?.fromView);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useEffect(() => {
		if (!isB2B) {
			setShowContactForm(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// NAVIGATION
	const back = async () => {
		// TEALIUM EVENT
		emitEvent({
			tealium_event: 'cmb_close',
			form_name: 'cmb'
		});

		goBack();
	};

	const next = async () => {
		try {
			setLoading('Guardando...');

			let leadId;
			try {
				// DELIO/WALMERIC LEAD REGISTER
				if (!walmericDelioId || (walmericDelioId && walmericDelioId?.toLowerCase()?.includes('error'))) {
					const nombreLead = encodeURIComponent(`${name} ${user?.surname || ''}`);
					leadId = await regLead({
						solicita_desde: callProcessType[prevView]?.solicita_desde || '',
						horario_llamadas: horarioLlamadas || undefined,
						phone: phoneNumber.slice(-9),
						email: email,
						name: nombreLead
					});
					setWalmericDelioId(leadId);

					// TEALIUM EVENT
					emitEvent({
						tealium_event: 'cmb_delio_lead_register',
						form_name: 'cmb'
					});
				}
			} catch (e) {
				leadId = e;
				setWalmericDelioId(leadId);
				setLoading(undefined);
			}
			//eslint-disable-next-line no-console
			console.log('🚀 Delio leadId →', leadId || walmericDelioId);

			await updateUser({
				username: {
					name,
					surname: user?.surname || ''
				},
				email,
				phoneNumber,
				metadata: {
					...user?.metadata,
					acceptContact: wantsInformation,
					privacityAccepted,
					horarioLlamadas,
					analytics: {
						...user?.metadata?.analytics,
						WALMERIC_DELIO: {
							...user?.metadata?.analytics?.WALMERIC_DELIO,
							leadId: leadId && typeof leadId === 'string' ? leadId : walmericDelioId
						}
					}
				}
			});

			setLoading('Enviando...');

			// TEALIUM EVENT
			emitEvent({
				tealium_event: 'cmb_send',
				form_name: 'cmb'
			});

			const screenNames = {
				SALUD: 'Preguntas de salud',
				PBC: 'Preguntas de blanqueo de capitales',
				SIGN_CONTRACT: 'Firma del contrato',
				SIMULATION_DETAILS: 'Detalles previos a la simulación',
				SIMULATION: 'Simulación de póliza',
				IDENTITY: 'El proceso de videoidentificación ha fallado',
				CALL: 'Solicita una llamada',
				ACTIVATION: 'Alta de póliza'
			};

			const paginaPrevia = screenNames[prevPage];
			await sendContactEmail(name, phoneNumber, email, paginaPrevia);
			setEmailSent(true);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>
							Tu solicitud ha sido enviada con éxito. Un agente se pondrá en contacto contigo a la mayor brevedad.
						</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-cannot-confirm'
				}
			);

			// TEALIUM EVENT
			emitEvent({
				tealium_event: 'cmb_send_success',
				form_name: 'cmb'
			});
			setLoading(undefined);
		} catch (e) {
			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);

			// TEALIUM EVENT
			emitEvent({
				tealium_event: 'cmb_send_failure',
				form_name: 'cmb'
			});
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-cannot-confirmnext'
				}
			);
		}
	};

	const isDisabled = () => {
		return emailSent || !name || !phoneNumber || phoneNumber.length !== 12 || !email || !privacityAccepted;
	};

	// TEALIUM PAGE VIEW
	const callProcessType = {
		simulationData: { process_type: 'tarificacion', solicita_desde: 'tarificacion' },
		simulation: { process_type: 'tarificacion', solicita_desde: 'precio' },
		SimulationFamilyCalculator: { process_type: 'tarificacion', solicita_desde: 'precio' },
		SimulationFinancialCalculator: { process_type: 'tarificacion', solicita_desde: 'precio' },
		SimulationEmploymentCalculator: { process_type: 'tarificacion', solicita_desde: 'precio' },
		clientData: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		pbc: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		health: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		bankAccount: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		identityBiometricUpsert: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		identityBiometric: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		identityBiometricExtraDocs: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		activateProduct: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		signatureSteps: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		signatureRead: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		signatureContract: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		productCreationFinishedB2C: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		productCreationFinishedManual: { process_type: 'contratacion', solicita_desde: 'contratacion' },
		cannotConfirm: { process_type: 'contratacion', solicita_desde: 'contratacion' }
	};

	const screenPageType = {
		SALUD: { page_type: 'error', process_step: 'error de servicio', process_type: 'contratacion' },
		PBC: { page_type: 'error', process_step: 'error de servicio', process_type: 'contratacion' },
		SIGN_CONTRACT: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'contratacion'
		},
		SIMULATION_DETAILS: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'tarificacion'
		},
		SIMULATION: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'tarificacion'
		},
		IDENTITY: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'contratacion'
		},
		CALL: {
			page_type: 'cmb',
			process_step: 'error de servicio',
			process_type: callProcessType[prevView]?.process_type || ''
		},
		ACTIVATION: {
			page_type: 'error',
			process_step: 'error de servicio',
			process_type: 'contratacion'
		}
	};

	useEffect(() => {
		if (prevPage !== 'No detectada' && prevView !== 'No detectada') {
			const tealiumData = {
				page_type: screenPageType[prevPage]?.page_type ?? 'Not defined',
				process_name: 'particulares simulador riesgo vida',
				process_step: screenPageType[prevPage]?.process_step ?? 'Not defined',
				process_type: screenPageType[prevPage]?.process_type ?? 'Not defined'
			};

			if (screenPageType[prevPage]?.page_type !== 'cmb' && screenPageType[prevPage]?.page_type !== 'cmb:agente') {
				pageView({
					pageType: tealiumData?.page_type ?? 'Not defined',
					processName: tealiumData?.process_name ?? 'Not defined',
					processStep: tealiumData?.process_step ?? 'Not defined',
					processType: tealiumData?.process_type ?? 'Not defined'
				});

				updateData(tealiumData);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prevPage, prevView, screenPageType]);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);

	if (showContactForm) {
		return (
			<Wrapper width={'80%'}>
				<Text headingPrimarySt>
					{isB2B
						? 'Te llamamos'
						: prevPage === 'PBC'
						? '¡Lo sentimos! Con los datos facilitados no es posible continuar con el proceso de contratación online.'
						: prevPage === 'SALUD'
						? '¡Lo sentimos! Con los datos facilitados no es posible continuar con el proceso de contratación online.'
						: prevPage === 'CALL'
						? '¿Te llamamos?'
						: '¡Lo sentimos! No es posible continuar con el proceso.'}
				</Text>
				{!showOnlyError && (
					<>
						<Text headingSecondarySt isB2B>
							Si tienes alguna duda nos pondremos en contacto contigo a la mayor brevedad posible.
						</Text>
						<S.FormWContainer>
							<InputLabel value={name} 
							onChange={handleInputChange(setName)} label="Nombre*" />
							<InputLabel
								onBlur={() => {
									setIsEmailCorrect(isEmail(email));
								}}
								value={email}
								onChange={(e) => {
									if (!isEmailCorrect) {
										setIsEmailCorrect(isEmail(e.target.value));
									}
									setEmail(e.target.value);
								}}
								label="Email*"
								errorMsg={!isEmailCorrect && `Email inválido`}
							/>
							<InputLabel
								value={phoneNumber}
								onChange={(value) => {
									if (!isPhoneCorrect) {
										setIsPhoneCorrect(value.length === 12);
									}
									setPhoneNumber(value);
								}}
								label="Teléfono*"
								type="phone"
							/>
							<InputLabel
								style={{ flexGrow: 1 }}
								value={horarioLlamadas}
								onChange={(e) => {
									setHorarioLlamadas(e.target.value);
								}}
								label="¿Cuándo te llamamos?"
								type="select"
								selectOptions={[
									{ name: 'Mañana', value: 'mañana' },
									{ name: 'Ahora', value: 'ahora' },
									{ name: 'Tarde', value: 'tarde' }
								].map((item) => {
									return {
										label: item.name,
										value: item.value
									};
								})}
							/>
						</S.FormWContainer>
						{!isB2B && (
							<Text labelInputSt featuresMarginTopSt>
								*Campos obligatorios
							</Text>
						)}
						<div>
							<View
								conditionsWrapperSt
								marginTopSt
								onClick={() => {
									setWantsInformation(!wantsInformation);
								}}
							>
								<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={wantsInformation} />
								<Text marginLefConditiontSt>
									<S.ConditionsTextSpan>Quiero</S.ConditionsTextSpan> recibir información sobre productos y ofertas que
									me puedan beneficiar.
								</Text>
							</View>

							<View
								conditionsWrapperSt
								onClick={() => {
									privacityAccepted
										? setPrivacityAccepted(false)
										: openModal(
												(close) => (
													<TermsModal
														onClick={() => setPrivacityAccepted(!privacityAccepted)}
														isOpen={true}
														onClose={close}
													/>
												),
												{
													mode: 'old'
												}
										  );
								}}
							>
								<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={privacityAccepted} />
								<Text marginLefConditiontSt>
									<S.ConditionsTextSpan>Acepto </S.ConditionsTextSpan> la política de privacidad *
								</Text>
							</View>
						</div>

						<BackNextButton onClickBack={back} onClickNext={next} nextText={'ENVIAR'} isNextDisabled={isDisabled()} />
					</>
				)}
				<DashedLines />
				<Faq />
			</Wrapper>
		);
	}

	return (
		<Wrapper isB2B>
			<Text
				headingPrimarySt
				style={{
					marginBottom: 20
				}}
			>
				{isB2B
					? 'Te llamamos'
					: prevPage === 'PBC'
					? '¡Lo sentimos! Con los datos facilitados no es posible continuar con el proceso de contratación online.'
					: prevPage === 'SALUD'
					? '¡Lo sentimos! Con los datos facilitados no es posible continuar con el proceso de contratación online.'
					: prevPage === 'CALL'
					? '¿Hablamos?'
					: 'Oh.. lamentablemente no es posible confirmar tu seguro de vida online.'}
			</Text>
			<Text
				headingSecondarySt
				style={{
					marginBottom: 40
				}}
			>
				Puedes ponerte en contacto con nosotros para finalizar tu contratación.
			</Text>
			<Button
				onClick={() => setShowContactForm(true)}
				style={{
					cursor: 'pointer',
					borderRadius: 45,
					border: `2px solid ${CONFIG.colors.primary}`,
					backgroundColor: CONFIG.colors.primary,
					width: isMobile ? '100%' : '250px',
					outline: 'none',
					padding: isMobile ? '10px 20px' : '14px 20px',
					textComponent: {
						fontSize: isMobile ? '18px' : '24px',
						fontWeight: 800,
						color: CONFIG.colors.tertiary,
						textAlign: 'center',
						marginTop: 0,
						marginBottom: 0
					}
				}}
			>
				Quiero que me llamen
			</Button>
			{/* TODO: pass tel throw global config (config.js) */}
			<a href="tel:+34911553520" style={{ width: isMobile ? '100%' : '250px' }}>
				<Button
					style={{
						cursor: 'pointer',
						borderRadius: 45,
						border: `2px solid ${CONFIG.colors.primary}`,
						backgroundColor: CONFIG.colors.primary,
						width: isMobile ? '100%' : '250px',
						outline: 'none',
						padding: isMobile ? '10px 20px' : '14px 20px',
						textComponent: {
							fontSize: isMobile ? '18px' : '24px',
							fontWeight: 800,
							color: CONFIG.colors.tertiary,
							textAlign: 'center',
							marginTop: 0,
							marginBottom: 0
						}
					}}
				>
					{CONFIG.callUsComercial}
				</Button>
			</a>
		</Wrapper>
	);
});
