import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
    NavigationWrapper,
    PickerHOC,
    Text,
    UiWrapper,
    UserWrapper,
    View,
    VinculationWrapper
} from '@coinscrap/webapp-core';

import moment from 'moment';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import InputLabel from 'components/InputLabel/InputLabel';
import Header from 'layout/components/Header/Header';
import { ClientSimulationWrapper } from 'wrappers/customWrapper/ClientSimulationWrapper';

import routes from 'config/routes';
import { alcoholTypes } from 'data/alcoholTypes';
import { smokeTypes } from 'data/smokeTypes';
import { Wrapper } from 'layout/Wrapper';

import { professions } from './professions';
import * as S from './styles';

export const SimulationDataAgent = PickerHOC({
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	},
	vinculation: {
		sourcePath: 'vinculation',
		sourceWrapper: VinculationWrapper
	},
	instance: {
		sourcePath: 'vinculationApiInstance',
		sourceWrapper: VinculationWrapper
	},
	simulation: {
		sourcePath: 'simulation',
		sourceWrapper: ClientSimulationWrapper
	},
	client: {
		sourcePath: 'client',
		sourceWrapper: ClientSimulationWrapper
	}
})(() => {
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const { updateClient, updateSimulation } = ClientSimulationWrapper.use();
	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	const pickerValues = PickerHOC.use();

	const { client, simulation } = pickerValues;
	const creationData = simulation?.targetProperties?.find((x) => x.name === 'creationData')?.value;

	const [gender, setGender] = useState(client?.metadata?.gender || '');

	const [birthDate, setBirthDate] = useState(client?.metadata?.birthDate || moment().subtract(18, 'years'));

	const [weight, setWeight] = useState(client?.metadata?.weight || '');
	const [height, setHeight] = useState(client?.metadata?.height || '');

	const [profession, setProfession] = useState(client?.metadata?.profession || '');

	// FUMAS?
	const [smoke, setSmoke] = useState(client?.metadata?.smoke || 'select');
	const [smokeQuantity, setSmokeQuantity] = useState(client?.metadata?.smokeQuantity || '');
	const [smokeType, setSmokeType] = useState(client?.metadata?.smokeType || 'Cigarrillos');

	// ALCOHOL?
	const [alcohol, setAlcohol] = useState(client?.metadata?.alcohol || 'select');
	const [alcoholQuantity, setAlcoholQuantity] = useState(client?.metadata?.alcoholQuantity || '');
	const [alcoholType, setAlcoholType] = useState(client?.metadata?.alcoholType || 'Cerveza');

	const next = async () => {
		try {
			setLoading('Guardando datos de cliente');
			await updateClient({
				metadata: {
					birthDate,
					gender,
					weight: parseInt(weight),
					height: parseInt(height),
					profession,
					professionCode: professions?.find((p) => p?.label === profession)?.value,
					smoke,
					smokeQuantity: smokeQuantity || 0,
					smokeType: smokeType || undefined,
					alcohol,
					alcoholQuantity: alcoholQuantity || 0,
					alcoholType: alcoholType || undefined,
					isB2B: true
				}
			});
			setLoading('Guardando datos de simulación');
			await updateSimulation({
				details: {
					birthDate,
					gender,
					weight: parseInt(weight),
					height: parseInt(height),
					profession,
					professionCode: professions?.find((p) => p?.label === profession)?.value,
					smoke,
					smokeQuantity: smokeQuantity || 0,
					smokeType: smokeType || undefined,
					alcohol,
					alcoholQuantity: alcoholQuantity || 0,
					alcoholType: alcoholType || undefined,
					isB2B: true
				}
			});
			setLoading(undefined);
			navigateTo(routes.clientDataAgent);
		} catch (e) {
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>Ha ocurrido un error generando la simulación</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-SavingSimulationDataAgentError'
				}
			);
		}
	};

	const isDisabled = () => {
		return (
			!birthDate ||
			moment(birthDate).unix() > moment().subtract(18, 'years').unix() ||
			!gender ||
			!weight ||
			!height ||
			!profession ||
			(smoke === 'yes' && (smokeQuantity === null || smokeType === null)) ||
			(alcohol === 'yes' && (alcoholQuantity === null || alcoholType === null))
		);
	};

	//NAVIGATION

	const back = async () => {
		goBack();
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} isB2B={true} />, []);

	return (
		<Wrapper width={'80%'}>
			<Text headingPrimarySt>Datos obligatorios</Text>
			<Text headingSecondarySt>
				Para poder calcular el precio del seguro necesitamos que tu cliente conteste a unas preguntas obligatorias.
			</Text>

			<View tripleInputRowSt>
				<InputLabel
					value={birthDate}
					onChange={(e) => {
						//console.log(e);
						setBirthDate(moment(e).format('YYYY-MM-DD'));
					}}
					label="Fecha de nacimiento"
					type="date"
					errorMsg={
						moment(birthDate).unix() > moment().subtract(18, 'years').unix() &&
						`La edad mínima de contratación es de 18 años`
					}
				/>
				<InputLabel
					showLabel={true}
					value={profession || ''}
					onChange={(value) => {
						setProfession(value);
					}}
					label={'Profesión'}
					type="autoSuggest"
					selectOptions={professions.map((item) => {
						return {
							label: item.label,
							value: item.label
						};
					})}
				/>
				<InputLabel
					showLabel={true}
					label="¿Cuál es tu género?"
					value={gender || ''}
					onChange={(e) => {
						setGender(e.target.value);
					}}
					type="select"
					selectOptions={[
						{ label: 'Masculino', value: 'masculino' },
						{ label: 'Femenino', value: 'femenino' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
			</View>

			<View tripleInputRowSt alignItemsSt>
				<InputLabel
					value={height}
					onChange={(e) => {
						if (isNaN(+e.target.value) || '') return;

						setHeight(parseInt(e.target.value || 0));
					}}
					label="¿Cuál es tu altura? (cm)"
				/>
				<InputLabel
					value={weight}
					onChange={(e) => {
						if (isNaN(+e.target.value) || '') return;
						setWeight(parseInt(e.target.value || 0));
					}}
					label="¿Cuál es tu peso? (kg)"
				/>
			</View>

			<View tripleInputRowSt>
				<InputLabel
					value={smoke || 'no'}
					onChange={(e) => {
						setSmoke(e.target.value);
					}}
					label="¿Fumas?"
					type="select"
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
				{smoke === 'yes' && (
					<S.OptionsSmokeAndAlcohol>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={smokeQuantity}
							onChange={(e) => {
								//console.log('10 Quantity Change', e.target.value);
								setSmokeQuantity(+e.target.value);
							}}
							label="Cantidad diaria"
						/>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={smokeType}
							onChange={(e) => {
								setSmokeType(e.target.value);
							}}
							label="Tipo de tabaco"
							type="select"
							selectOptions={smokeTypes.map((item) => {
								return {
									label: item.name,
									value: item.value
								};
							})}
						/>
					</S.OptionsSmokeAndAlcohol>
				)}
			</View>

			<View tripleInputRowSt>
				<InputLabel
					value={alcohol || 'no'}
					onChange={(e) => {
						setAlcohol(e.target.value);
					}}
					label="¿Consumes bebidas alcohólicas?"
					type="select"
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
				{alcohol === 'yes' && (
					//TODO: refactor OptionsSmokeAndAlcohol
					<S.OptionsSmokeAndAlcohol>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={alcoholQuantity}
							onChange={(e) => {
								//console.log('11 Quantity Change', e.target.value);
								setAlcoholQuantity(+e.target.value);
							}}
							label="Cantidad diaria"
							errorMsg={true}
						/>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={alcoholType}
							onChange={(e) => {
								setAlcoholType(e.target.value);
							}}
							label="Tipo de alcohol"
							type="select"
							selectOptions={alcoholTypes.map((item) => {
								return {
									label: item.name,
									value: item.value
								};
							})}
						/>
					</S.OptionsSmokeAndAlcohol>
				)}
			</View>
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				isNextDisabled={isDisabled()}
				nextText={'ACCEDE A LA SIMULACIÓN'}
				justifyContent={'between'}
			/>
		</Wrapper>
	);
});
