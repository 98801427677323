import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const ProgressBarItem = styled.div`
	width: 32px;
	/* border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
  background: ${CONFIG.colors.primary}; */
	/* background: transparent; */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
`;
export const ProgressBarItemStep = styled.div`
	background: ${CONFIG.colors.primary};
	border-top-left-radius: ${(props) => {
		if (props.index === 0) {
			return '45px';
		} else {
			return 0;
		}
	}};
	border-top-right-radius: ${({ index, length }) => {
		if (index === 0) {
			return '45px';
		} else {
			return 0;
		}
	}};
	border-bottom-right-radius: ${({ index, length }) => {
		if (index + 1 === length) {
			return '45px';
		} else {
			return 0;
		}
	}};
	border-bottom-left-radius: ${({ index, length }) => {
		if (index + 1 === length) {
			return '45px';
		} else {
			return 0;
		}
	}};
	width: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	position: 'absolute';
`;
