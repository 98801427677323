import { isMobile } from 'react-device-detect';

import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';

UiWrapper.defineMedia('isSimulationPeriodMq', '(max-width: 850px)');

//CONFIG only for testing pourposes
const config = {
	boxSizing: 'border-box',
	borderStyle: 'solid',
	borderRadius: '5px',
	borderWidth: '2px',
	cursor: 'pointer',
	maxWidth: 350,
	padding: '10px 20px',
	outline: 'none',
	width: '50%'
};

UiWrapper.defineComponent('button', {
	styles: {
		default: {
			alignItems: 'center',
			alignContent: 'center',
			backgroundColor: CONFIG.colors.white,
			boxSizing: config.boxSizing,
			borderStyle: config.borderStyle,
			borderColor: CONFIG.colors.tertiary,
			borderRadius: config.borderRadius,
			borderWidth: config.borderWidth,
			cursor: config.cursor,
			disabledMode: {
				opacity: 0.5
			},
			display: 'flex',
			justifyContent: 'center',
			maxWidth: config.maxWidth,
			transition: '0.6s all',
			textComponent: {
				color: CONFIG.colors.secondary,
				fontSize: isMobile ? '14px' : '20px',
				fontFamily: CONFIG.fonts.regular,
				textAlign: 'center'
			},
			padding: config.padding,
			outline: config.outline,
			width: config.width
		},

		selectedSt: {
			backgroundColor: CONFIG.colors.secondary,
			borderColor: CONFIG.colors.secondary,
			textComponent: {
				color: CONFIG.colors.white,
				fontFamily: CONFIG.fonts.bold,
				display: 'flex',
				alignItems: 'center'
			}
		},

		addSonSt: {
			backgroundColor: CONFIG.colors.secondary,
			borderColor: CONFIG.colors.secondary,
			height: 35,
			minWidth: 130,
			textComponent: {
				alignItems: 'center',
				color: CONFIG.colors.white,
				display: 'flex',
				fontFamily: CONFIG.fonts.regular,
				fontSize: 14
			}
		},

		termsSt: {
			backgroundColor: CONFIG.colors.primary,
			borderColor: CONFIG.colors.primary,
			bottom: 5,
			height: 52,
			marginBottom: 10,
			marginTop: 10,
			textComponent: {
				color: CONFIG.colors.white,
				fontFamily: CONFIG.fonts.regular
			},
			position: 'fixed',
			width: isMobile ? '35%' : '290px'
		},

		toActionSt: {
			flexDirection: 'row',
			padding: '12px 15px 12px 30px',
			gap: 20,
			width: 198,
			height: 39,
			backgroundColor: '#002C90',
			borderColor: '#ffffff',
			borderStyle: 'solid',
			borderRadius: '2px',
			borderWidth: '1px',
			flex: 'none',
			flexGrow: 0,
			order: 0,
			textComponent: {
				color: CONFIG.colors.white,
				fontFamily: CONFIG.fonts.bold,
				fontSize: 14,
				textAlign: 'left'
			}
		},

		backSt: {
			alignSelf: 'center',
			padding: '10px 20px',
			background: CONFIG.colors.white,
			borderColor: CONFIG.colors.primary,
			flex: '0.8 1 auto',
			order: 1,
			textComponent: {
				display: 'flex',
				fontFamily: CONFIG.fonts.bold,
				fontSize: CONFIG.fontSizes.medium,
				color: CONFIG.colors.primary,
				textAlign: 'center'
			},
			width: '100%'
		},

		nextSt: {
			alignSelf: 'center',
			padding: '10px 20px',
			background: CONFIG.colors.secondary,
			borderColor: CONFIG.colors.secondary,
			flex: '0.8 1 auto',
			textComponent: {
				display: 'flex',
				fontFamily: CONFIG.fonts.bold,
				fontSize: CONFIG.fontSizes.medium,
				color: CONFIG.colors.white,
				textAlign: 'center'
			},
			width: '100%'
		},

		smallSt: {
			backgroundColor: CONFIG.colors.primary,
			borderColor: CONFIG.colors.primary,
			minWidth: isMobile ? '100%' : '225px',
			padding: isMobile ? '7px 10px' : '10px 10px',
			textComponent: {
				fontFamily: CONFIG.fonts.bold,
				fontSize: 15,
				color: CONFIG.colors.white,
				textAlign: 'center'
			}
		},

		beneficiaryBoxSt: {
			backgroundColor: CONFIG.colors.white,
			borderColor: CONFIG.colors.white,
			color: CONFIG.colors.white,
			borderRadius: 50,
			height: isMobile ? 25 : 45,
			margin: 5,
			padding: isMobile ? '16px 16px' : '10px 20px',
			width: isMobile ? 25 : 45
		},

		modalSt: {
			backgroundColor: CONFIG.colors.primary,
			display: 'flex',
			padding: isMobile ? '10px 20px' : '14px 20px',
			textComponent: {
				fontSize: isMobile ? '16px' : '18px',
				fontFamily: CONFIG.fonts.bold,
				color: CONFIG.colors.white,
				textAlign: 'center'
			},
			width: isMobile ? '100%' : 150
		},

		//Dashboard new simualtion
		newSimulationSt: {
			backgroundColor: CONFIG.colors.primaryLight,
			borderColor: CONFIG.colors.primary,
			borderRadius: '1px',
			color: CONFIG.colors.primary,
			padding: '10px 15px',
			outline: 'none',
			width: 'max-content',
			textComponent: {
				color: CONFIG.colors.primary,
				fontSize: '14px',
				fontFamily: CONFIG.fonts.light
			}
		},

		// Pildora hueca con borde rojo
		pillSt: {
			borderWidth: 1,
			borderColor: CONFIG.colors.danger,
			margin: 0,
			padding: 0,
			backgroundColor: 'transparent',
			height: 40,
			textComponent: {
				color: CONFIG.colors.danger,
				fontSize: 16,
				lineHeight: '23px',
				fontWeight: 400,
				fontFamily: CONFIG.fonts.regular
			}
		},

		clearFiltersBtnSt: {
			width: 'auto',
			padding: 5,
			textComponent: {
				fontSize: 13,
				lineHeight: 1.2
			}
		},

		pillFillSt: {
			borderColor: CONFIG.colors.danger,
			borderStyle: 'solid',
			borderRadius: 20,
			borderWidth: 1,
			margin: 0,
			padding: 0,
			backgroundColor: CONFIG.colors.danger,
			height: 40,

			textComponent: {
				color: CONFIG.colors.white,
				padding: 0,
				fontWeight: 700,
				fontSize: 16,
				fontFamily: CONFIG.fonts.regular
			}
		},

		backButtonSt: {
			// width: "45%",
			height: 40,
			borderRadius: 24,
			alignSelf: 'flex-end',
			fontSize: 14,
			borderWidth: 0,
			backgroundColor: 'transparent',
			flexDirection: 'row',
			padding: 0,
			margin: '16px 0 16px 24px',
			textComponent: {
				display: 'flex',
				flexDirection: 'row',
				verticalAlign: 'center'
			}
		},

		nextButtonSt: {
			// width: "45%",
			maxWidth: 160,
			height: 40,
			borderRadius: 24,
			alignSelf: 'flex-end',
			fontSize: 16,
			margin: '16px 24px 16px 0'
		},

		// Para cuando se deshabilita
		disabledSt: {
			backgroundColor: 'white',
			borderColor: 'silver',

			textComponent: {
				color: CONFIG.colors.grey
			}
		},

		simulationPeriodSt: {
			$mediaQueries: {
				isSimulationPeriodMq: {
					width: '40%'
				}
			},
			minWidth: '140px'
		},

		regularButtonSt: {
			height: 62,
			width: 175,

			textComponent: {
				fontSize: 20
			}
		},
		borderWhiteButtonSt: {
			height: 52,
			width: 155,
			border: `1px solid ${CONFIG.colors.white}`,
			textComponent: {
				fontSize: 18,
				color: CONFIG.colors.white
			}
		},
		smallBorderWhiteButtonSt: {
			height: 28,
			width: 78,
			margin: 0,
			border: `1px solid ${CONFIG.colors.white}`,
			textComponent: {
				fontSize: 10,
				color: CONFIG.colors.white
			}
		},
		whiteButtonSt: {
			backgroundColor: CONFIG.colors.white,
			textComponent: {
				fontSize: 20,
				color: CONFIG.colors.danger
			}
		},

		whiteFooterButtonSt: {
			backgroundColor: CONFIG.colors.white,
			height: 52,
			width: 155,
			border: `1px solid ${CONFIG.colors.white}`,
			textComponent: {
				fontSize: 18,
				color: CONFIG.colors.danger
			}
		},

		noSt: {
			backgroundColor: CONFIG.colors.white,
			borderColor: CONFIG.colors.tertiaryLighter,
			borderRadius: 50,
			height: 30,
			margin: 5,
			textComponent: {
				color: CONFIG.colors.tertiary,
				fontFamily: CONFIG.fonts.light
			},
			width: isMobile ? 40 : 45
		},

		questionarySt: {
			backgroundColor: CONFIG.colors.secondary,
			borderColor: CONFIG.colors.secondary,
			borderRadius: 50,
			height: 30,
			margin: 5,
			textComponent: {
				color: CONFIG.colors.white,
				fontFamily: CONFIG.fonts.bold
			},
			width: isMobile ? 40 : 45
		},

		headerSt: {
			backgroundColor: CONFIG.colors.secondary,
			borderColor: CONFIG.colors.secondary,
			borderRadius: 5,
			height: 40,
			margin: 5,
			maxWidth: config.maxWidth,
			textComponent: {
				color: CONFIG.colors.white,
				fontFamily: CONFIG.fonts.bold,
				fontSize: 16
			},
			padding: '10px 20px',
			width: '100%'
		}
	}
});
