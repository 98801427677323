import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { NavigationWrapper, Text, UiWrapper } from '@coinscrap/webapp-core';

import arrowRigth from 'assets/images/svgs/arrowRigth.svg';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import Header from 'layout/components/Header/Header';

import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';

import * as S from './styles';

export const SignatureSteps = () => {
	const [loading, setLoading] = useState();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { navigateTo, goBack } = NavigationWrapper.use();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	// NAVIGATION
	const back = async () => {
		goBack();
	};

	const next = async () => {
		try {
			setLoading('Empezando el proceso...');
			navigateTo(routes.signatureRead);
		} catch (e) {
			let messageError = e.message.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-signatureStepsNext'
			});
		}
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);

	return (
		<Wrapper width={'80%'}>
			<HorizontalProgressBar step={6} total={6} styleWrapper={{ maxWidth: 450 }} />
			<Text headingPrimarySt>Firma de forma rápida, fácil y segura</Text>
			<S.InnerWrapper>
				<S.StepWrapper>
					<S.StepWrapperItem>
						<S.StepCircle>
							<img src={arrowRigth} alt={'arrow icon'} />
						</S.StepCircle>
						<Text stepsSt>Abre los documentos</Text>
					</S.StepWrapperItem>
					<S.StepWrapperItem>
						<S.StepCircle>
							<img src={arrowRigth} alt={'arrow icon'} />
						</S.StepCircle>
						<Text stepsSt>Márcalos como leídos</Text>
					</S.StepWrapperItem>
					<S.StepWrapperItem>
						<S.StepCircle>
							<img src={arrowRigth} alt={'arrow icon'} />
						</S.StepCircle>
						<Text stepsSt>Pulsa Continuar</Text>
					</S.StepWrapperItem>
					<S.StepWrapperItem>
						<S.StepCircle>
							<img src={arrowRigth} alt={'arrow icon'} />
						</S.StepCircle>
						<Text stepsSt>Firma los documentos</Text>
					</S.StepWrapperItem>
				</S.StepWrapper>
				<BackNextButton
					onClickNext={next}
					onClickBack={back}
					nextText={'CONTINUAR'}
					isBackShown={!isMobile}
					isNextDisabled={false}
					justifyContent={'between'}
				/>
			</S.InnerWrapper>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
};
