import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/globalStyle';

export const InnerWrapper = styled.div`
	${verticalFlex}
	flex-grow: 1;
	width: 90%;

	.title {
		margin-top: 40px;
	}
`;

export const SliderItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;
export const SummaryItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 5px;
`;
export const SliderDots = styled.div`
	margin-top: 10px;
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(4, 1fr);
`;
export const SliderDot = styled.div`
	border-radius: 50%;
	height: 8px;
	width: 8px;
	background-color: ${(props) => {
		if (props.selected) {
			return CONFIG.colors.primary;
		} else {
			return '#c4c4c4';
		}
	}};
`;
