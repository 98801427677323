//https://colors.eva.design/

const colors = {
	primary: '#007167',
	primaryLight: '#F5FAFA',
	//TODO: Review the rest of the colors
	primaryLighter: '#E5F1EF',
	primaryDark: '#001967',
	primaryDarker: '#000C45',

	secondary: '#EE7600',
	secondaryLight: '#ffb268',
	secondaryLighter: '#ffffff',
	secondaryDark: '#ffffff',
	secondaryDarker: '#ffffff',

	tertiary: '#666666',
	tertiaryLight: '#DEDEDE',
	tertiaryLighter: '#C4C4C4',
	//TODO: Review the rest of the colors
	tertiaryDark: '#24191B',
	tertiaryDarker: '#18090E',

	white: '#FFFFFF',
	greyLight: '#D4D4D7',
	grey: '#818181',
	greyDark: '#4a4a4a',
	black: '#20252B',

	danger: '#FF0E54',
	success: '#649544'
};

export default colors;
