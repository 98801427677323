import { CONFIG } from 'config/config';

import componentsConfig from './components';

const config = {
	...componentsConfig,
	fontFamily: CONFIG.fonts.regular,
	colors: {
		defaultFontColor: CONFIG.colors.white,
		main: CONFIG.colors.white,
		secondary: CONFIG.colors.white
	}
};

export default config;
