import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
    NavigationWrapper,
    PickerHOC,
    TargetsWrapper,
    Text,
    UiWrapper,
    UserWrapper,
    View
} from '@coinscrap/webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Header from 'layout/components/Header/Header';

import routes from 'config/routes';
import { professionalSituationList } from 'data/professionalSituationList';
import { Wrapper } from 'layout/Wrapper';
import { currencyFormat } from 'libs/currencyFormat';

export const SimulationEmploymentCalculator = PickerHOC(
	{
		targets: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets'
		},
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { updateUser } = UserWrapper.use();
	const { targets, user } = PickerHOC.use();
	const target = targets[0];

	const [professionalSituation, setProfessionalStatus] = useState(user?.metadata?.professionalSituation || '');
	const [otherProfessionalSituation, setOtherProfessionalSituation] = useState(
		user?.metadata?.otherProfessionalSituation || ''
	);
	const [annualIncome, setAnnualIcome] = useState(user?.metadata?.annualIncome || 0);

	const [loading, setLoading] = useState();

	const creationData = target?.targetProperties?.find((x) => x.name === 'creationData')?.value;

	const back = async () => {
		goBack();
	};

	const next = async () => {
		try {
			setLoading('Guardando...');
			await updateUser({
				metadata: {
					...user?.metadata,
					professionalSituation,
					otherProfessionalSituation,
					annualIncome
				}
			});
			setLoading('Actualizando...');
			await saveTargetProperty(target.id, 'creationData', {
				details: {
					...creationData?.details,
					professionalSituation,
					otherProfessionalSituation,
					annualIncome
				}
			});
			navigateTo(routes.simulation);
		} catch (e) {
			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-SimulationEmploymentError'
				}
			);
		}
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} />, []);

	return (
		<Wrapper width={'80%'}>
			<Text headingPrimarySt>Datos laborales</Text>
			<Text headingSecondarySt>
				Estas preguntas son necesarias para calcular el capital que mejor se adapte a tu situación familiar.
			</Text>
			<View tripleInputRowSt>
				<InputLabel
					value={professionalSituation || 0}
					onChange={(e) => {
						setProfessionalStatus(e.target.value);
					}}
					label={'¿Cuál es tu situación laboral?'}
					type="select"
					selectOptions={professionalSituationList}
				/>
				{professionalSituation === 'OTROS' && (
					<InputLabel
						value={otherProfessionalSituation}
						onChange={(e) => {
							setOtherProfessionalSituation(e.target.value);
						}}
						label="Otra situación profesional"
					/>
				)}
				<InputLabel
					label={'¿Cuáles son tus ingresos anuales?'}
					value={annualIncome}
					onChange={(e) => {
						if (isNaN(+e.target.value) || '') return;
						setAnnualIcome(e.target.value);
					}}
					onBlur={(e) => setAnnualIcome(currencyFormat(e.target.value))}
				/>
			</View>
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				// isNextDisabled={isDisabled()}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
