export const editPagesMapping = {
	COINSCRAP_ROUNDS: 'redondeo',
	PERIODIC_SAVING: 'aportacionperodica',
	NOMINA_SAVING: 'nomina',
	COFFEE_SAVING: 'sincafeina',
	TEAM: 'equipo',
	SMOKE_SAVING: 'sinnicotina'
};

export const docNames = {
	CONDICIONES_GENERALES: 'Solicitud de seguro',
	SEPA: 'Mandato SEPA',
	DDF: 'Datos fundamentales',
	PROMOTION: 'Condiciones de promoción',
	CCPP: 'Boletín',
	caser_pbc: 'Formulario de conocimiento de cliente',
	documento_identidad_01: 'Documento de identidad 1',
	documento_identidad_02: 'Documento de identidad 2'
};

export const OTP_ACTIONS = {
	HOT: 'HOT',
	SELECT_POLICY: 'SELECT_POLICY',
	SAVE_ACCOUNTS: 'SAVE_ACCOUNTS',
	TOGGLE_RULE: 'TOGGLE_RULE',
	TARGET_FINISH: 'TARGET_FINISH'
};
