export const faqQuestions = [
	{
		id: 1,
		question: '¿A quién va dirigido este seguro de vida?',
		answer:
			'El seguro de vida de Caser está dirigido a todas aquellas personas entre 18 y 64 años que quieran protegerse a sí mismos y a los suyos en caso de fallecimiento y, opcionalmente, invalidez.'
	},
	{
		id: 2,
		question: '¿Cuáles son las coberturas del seguro de vida de Caser?',
		answer:
			'El seguro de vida de Caser garantiza a los beneficiarios, en caso de fallecimiento del asegurado por cualquier causa, un capital a partir de 5.000€, con un máximo de 180.000€. Además, tienes la opción de incluir una cobertura por invalidez que te protegerá en caso de incapacidad permanente absoluta.'
	},
	{
		id: 3,
		question: '¿Qué datos me van a pedir para calcular mi tarifa?',
		answer:
			'Para poder calcular el precio de tu tarifa necesitaremos hacerte una serie de preguntas en referencia a tu edad, sexo, profesión, peso, altura y consumo de tabaco y bebidas alcohólicas. Estas preguntas nos permitirán ofrecerte un cálculo definitivo y lo más adecuado a tu situación'
	},
	{
		id: 4,
		question: '¿Por qué no puedo obtener el cálculo de mi prima online?',
		answer:
			'Si nuestro tarificador online no puede ofrecerte una propuesta, ¡no te preocupes! Rellena el formulario y un agente se pondrá en contacto contigo en el menor tiempo posible.'
	},
	{
		id: 5,
		question: '¿Qué datos son necesarios para realizar la contratación?',
		answer:
			'Para poder realizar el proceso de contratación necesitamos que nos facilites una serie de datos personales básicos (nombre, apellidos, DNI, dirección…), así como datos bancarios para poder domiciliar el pago de tu seguro de vida.'
	},
	{
		id: 6,
		question: '¿Qué sucede si me equivoco a la hora de incluir algún dato?',
		answer:
			'No te preocupes, podrás modificar los datos en cualquier momento y volver a calcular el precio de tu tarifa. Ten en cuenta que los datos facilitados deben ser reales para poder ajustar así el precio de tu seguro a tu situación actual.'
	},
	{
		id: 7,
		question: '¿Cuáles son las formas de pago?',
		answer:
			'El pago de la prima se realizará mediante domiciliación bancaria y podrás fraccionarlo de forma mensual, trimestral, semestral o anual.'
	},
	{
		id: 8,
		question: '¿Qué sucede si respondo que sí a alguna de las preguntas del cuestionario de salud?',
		answer:
			'En caso de haber contestado afirmativamente a alguna de las preguntas del cuestionario de salud, no podrás continuar con el proceso de contratación online. ¡Pero no te preocupes! Rellena el formulario y un agente se pondrá en contacto contigo en la mayor brevedad.'
	},
	{
		id: 9,
		question: '¿Qué pasa si no dispongo de mi DNI en el momento de la contratación?',
		answer:
			'Para poder continuar con la contratación del seguro, necesitamos que completes el proceso de captura de tu DNI. Si no dispones de él en este momento, podrás continuar con la contratación más adelante.'
	},
	{
		id: 10,
		question: '¿Qué sucede si no firmo el contrato?',
		answer:
			'El seguro solo se hará efectivo cuando finalices el proceso de firma del contrato. Si te surge alguna duda durante el procedimiento, puedes ponerte en contacto con nosotros a través de nuestro formulario.'
	},
	{
		id: 11,
		question: '¿Cuándo entra en vigor mi póliza?',
		answer:
			'El seguro de vida de Caser tiene validez desde el día en el que finalizas el proceso de firma, realizando así la contratación.'
	},
	{
		id: 12,
		question: '¿Cómo puedo consultar mi póliza?',
		answer:
			'Una vez finalizado <a>me</a> for help", "And <a>me</a> el proceso de firma recibirás una copia de tu contrato por correo electrónico. De igual forma, el documento estará siempre accesible para ti en el Área de clientes de Caser (https://www.caser.es/ecliente/acceso). Si tienes cualquier duda o quieres solicitar información, puedes contactar con nosotros a través del formulario de nuestra web.'
	}
];
