const Animations = () => {
	return {
		push: {
			exit: {
				opacity: 0,
				x: '100%'
			},
			initial: {
				opacity: 0,
				x: 300
			},
			animate: {
				opacity: 1,
				x: 0
			},
			transition: {
				duration: 0.4
			}
		},
		back: {
			exit: {
				opacity: 0,
				x: '100%'
			},
			initial: {
				opacity: 0,
				x: -300
			},
			animate: {
				opacity: 1,
				x: 0
			},
			transition: {
				duration: 0.4
			}
		}
	};
};
export default Animations;
