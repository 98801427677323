import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
	AnalyticsWrapper,
	Button,
	InputCheck,
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import CommercialActionsModal from '../../components/CommercialActionsModal/CommercialActionsModal';
import { SpanUnderline } from '../../config/components/span';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import BeneficiariesModal from 'components/BeneficiariesModal/BeneficiariesModal';
import BeneficiaryBox from 'components/BeneficiaryBox/BeneficiaryBox';
import BeneficiaryModal from 'components/BeneficiaryModal/BeneficiaryModal';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import TooltipSimple from 'config/components/tooltipSimple';
import Header from 'layout/components/Header/Header';
import isEmail from 'validator/lib/isEmail';

import routes from 'config/routes';
import { municipalities } from 'data/municipalities';
import { professionalSituationList } from 'data/professionalSituationList';
import { provinces } from 'data/provinces';
import { Wrapper } from 'layout/Wrapper';

import * as S from './styles';

export const ClientData = PickerHOC(
	{
		targets: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets'
		},
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { pageView, emitEvent, updateData } = AnalyticsWrapper.use();
	const { updateUser } = UserWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { target, user } = PickerHOC.use();

	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	// CREATION DATA
	const creationData = target?.targetProperties?.find((x) => x.name === 'creationData')?.value;

	const [name, setName] = useState(user?.name?.trim() === 'NEW_USER' ? '' : user?.name?.trim() || '');
	const [surname, setSurname] = useState(user?.surname?.trim() || '');
	const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
	const [email, setEmail] = useState(user?.email?.trim() || '');
	const dni = useMemo(() => user?.metadata.dni || '', [user]);

	const [commercialActions, setCommercialActionsAccepted] = useState(user?.metadata?.commercialActions || false);

	const [professionalSituation, setProfessionalStatus] = useState(user?.metadata?.professionalSituation || '');
	const [otherProfessionalSituation, setOtherProfessionalSituation] = useState(
		user?.metadata?.otherProfessionalSituation || ''
	);
	const [address, setAddress] = useState(user?.metadata?.address || '');
	const [postalCode, setPostalCode] = useState(user?.metadata?.postalCode || '');
	const [province, setProvince] = useState(user?.metadata?.province);
	const [municipality, setMunicipality] = useState(user?.metadata?.municipality || 0);
	const [beneficiaryType, setBeneficiaryType] = useState(creationData?.details?.beneficiaryType || 'default');

	const [beneficiaries, setBeneficiaries] = useState(
		creationData?.details?.beneficiaries ? creationData?.details?.beneficiaries : []
	);

	const [showBeneficiaries, setShowBeneficiaries] = useState(beneficiaries?.length > 0 ? true : false);

	const totalPercentage = beneficiaries.reduce(function (acc, ben) {
		return acc + ben.percentage;
	}, 0);

	// CHECKS
	const [isEmailCorrect, setIsEmailCorrect] = useState(true);
	const [isPostalCorrect, setIsPostalCorrect] = useState(true);
	const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);

	const [existsDNI, setExistsDNI] = useState(false);
	const [invalidDNIreason, setInvalidDNIreason] = useState('');

	const handleInputChange = (setValue) => (e) => {
		const inputValue = e.target.value;
		if (/^[A-Za-z\s]+$/.test(inputValue)) {
			setValue(inputValue);
		}
	};

	useEffect(() => {
		const checkIfExist = beneficiaries.some((ben) => ben.dni === dni);
		if (checkIfExist) {
			setExistsDNI(false);
			setInvalidDNIreason('El DNI/NIE ya ha sido declarado');
		}
		if (!checkIfExist) {
			setExistsDNI(true);
		}
	}, [dni, beneficiaries.length, beneficiaries, invalidDNIreason, existsDNI]);

	// SELECT OPTIONS
	let listMunicipalities = [];

	useEffect(() => {
		const findProvince = provinces.find(
			(item) => item.value.toString().substr(0, 2) === postalCode.toString().substr(0, 2)
		);
		if (findProvince) {
			setProvince(findProvince.label);
		} else {
			setProvince(null);
		}
	}, [postalCode]);

	if (province) {
		const findProvince = provinces.find((item) => item.label === province);
		listMunicipalities = municipalities.filter((item) => item.CPRO === findProvince.value);
	}

	useEffect(() => {
		setShowBeneficiaries(beneficiaries.length > 0 ? true : false);
	}, [beneficiaries.length]);

	// NAVIGATION
	const back = async () => {
		goBack();
	};

	const next = async () => {
		try {
			setLoading('Guardando...');

			// TEALIUM EVENT
			emitEvent({
				tealium_event: 'quoter_purchase',
				user_full_name: `${name} ${surname}`,
				user_phone_number: phoneNumber,
				user_email_addres: email,
				user_zip_code: postalCode,
				user_province: province,
				user_city: municipality,
				holder_full_name:
					beneficiaryType === 'default'
						? `${name} ${surname}`
						: beneficiaries
								.map((beneficiary) => {
									return `${beneficiary?.name} ${beneficiary?.surname}`;
								})
								.join(', ')
			});

			await updateUser({
				username: {
					name,
					surname
				},
				email,
				phoneNumber,
				metadata: {
					...user?.metadata,
					professionalSituation,
					professionalSituationName: professionalSituationList.find((pS) => pS.value === professionalSituation).label,
					dni,
					otherProfessionalSituation,
					address,
					postalCode,
					province,
					municipality,
					commercialActions
				}
			});
			//await new Promise((r) => setTimeout(r, 10000));
			setLoading('Actualizando...');
			await saveTargetProperty(target.id, 'creationData', {
				...creationData,
				details: {
					...creationData?.details,
					beneficiaryType,
					beneficiaries
				}
			});
			navigateTo(routes.pbc);
		} catch (e) {
			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-ClientDetailsError'
				}
			);
		}
	};

	const isDisabled = () => {
		return (
			!name ||
			!surname ||
			!phoneNumber ||
			phoneNumber.length !== 12 ||
			!email ||
			!professionalSituation ||
			(professionalSituation === 'OTROS' && !otherProfessionalSituation) ||
			!address ||
			!postalCode ||
			postalCode.length !== 5 ||
			!province ||
			!municipality ||
			!existsDNI ||
			!beneficiaryType ||
			(beneficiaryType === 'designed' && beneficiaries.length === 0) ||
			(beneficiaryType !== 'default' && totalPercentage < 100)
		);
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} />, []);

	// TEALIUM PAGE VIEW
	useEffect(() => {
		const tealiumData = {
			page_type: 'checkout',
			process_name: 'particulares simulador riesgo vida',
			process_step: 'datos personales',
			process_type: 'contratacion'
		};

		pageView({
			pageType: tealiumData.page_type,
			processName: tealiumData.process_name,
			processStep: tealiumData.process_step,
			processType: tealiumData.process_type
		});

		updateData({ ...tealiumData, lead_config: 'contratacion' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Wrapper width={'80%'}>
			<HorizontalProgressBar
				step={2}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Datos personales' : ''}
			/>
			<Text headingPrimarySt isB2B>
				{isB2B ? 'Datos personales' : 'Rellena los campos para iniciar la contratación'}
			</Text>
			<S.FormWContainer>
				<InputLabel
					value={name}
					onChange={handleInputChange(setName)}
					label="Nombre"
				/>
				<InputLabel
					value={surname}
					onChange={handleInputChange(setSurname)}
					label="Apellidos"
				/>
				<InputLabel
					value={phoneNumber}
					onChange={(value) => {
						if (!isPhoneCorrect) {
							setIsPhoneCorrect(value.length === 12);
						}
						setPhoneNumber(value);
					}}
					label="Teléfono"
					type="phone"
				/>

				<InputLabel
					onBlur={() => {
						setIsEmailCorrect(isEmail(email));
					}}
					value={email}
					onChange={(e) => {
						if (!isEmailCorrect) {
							setIsEmailCorrect(isEmail(e.target.value));
						}
						setEmail(e.target.value);
					}}
					label="Correo electrónico"
					errorMsg={!isEmailCorrect && `Email inválido`}
				/>
				<InputLabel
					value={professionalSituation || 0}
					onChange={(e) => {
						setProfessionalStatus(e.target.value);
					}}
					label="Situación profesional"
					type="select"
					selectOptions={professionalSituationList}
				/>
				{professionalSituation === 'OTROS' && (
					<View>
						<InputLabel
							value={otherProfessionalSituation}
							onChange={(e) => {
								setOtherProfessionalSituation(e.target.value);
							}}
							label="Otra situación profesional"
						/>
					</View>
				)}
				<InputLabel
					value={address}
					onChange={(e) => {
						setAddress(e.target.value);
					}}
					label={'Dirección'}
				/>

				<InputLabel
					value={postalCode}
					onChange={(e) => {
						if (isNaN(+e.target.value) || e.target.value.length > 5) return;

						if (!isPostalCorrect) {
							setIsPostalCorrect(e.target.value.length === 5);
						}
						setPostalCode(e.target.value);
					}}
					onBlur={() => {
						setIsPostalCorrect(postalCode.length === 5);
					}}
					label="Código postal"
				/>
				<InputLabel
					value={province || ''}
					disabled
					onChange={(e) => {
						// console.log(e.target.value);
						// setProvince(e.target.value);
					}}
					label="Provincia"
					// type="select"
					//selectOptions={provinces}
				/>
				<InputLabel
					value={municipality || 0}
					onChange={(e) => {
						setMunicipality(e.target.value);
					}}
					label="Municipio"
					type="select"
					selectOptions={listMunicipalities.map((item) => {
						return {
							label: item.NOMBRE,
							value: item.NOMBRE
						};
					})}
				/>

				<InputLabel
					value={beneficiaryType}
					onChange={(e) => {
						setBeneficiaryType(e.target.value);
						if (e.target.value === 'default') {
							setBeneficiaries([]);
						} else if (e.target.value === 'designed') {
							openModal(
								(close) => (
									<BeneficiaryModal close={close} beneficiaries={beneficiaries} setBeneficiaries={setBeneficiaries} />
								),
								{
									disableClickAway: true,
									closeButton: false,
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-anyadir-first-beneficiary'
								}
							);
						}
					}}
					label="Beneficiario"
					tooltip={
						<View
							style={{ width: '30%' }}
							onClick={() => {
								openModal((close) => <BeneficiariesModal isOpen={true} onClose={close} />, {
									mode: 'old'
								});
							}}
						>
							<TooltipSimple>
								<Text tooltipTitleSt>Información sobre designación de beneficiarios.</Text>
								<Text tooltipTextSt>Haz click para ver más información.</Text>
							</TooltipSimple>
						</View>
					}
					type="select"
					selectOptions={[
						{ label: 'Designar beneficiario', value: 'designed' },
						{ label: 'Beneficiario por defecto ', value: 'default' }
					]}
				/>
			</S.FormWContainer>

			<View
				style={{ marginTop: 30 }}
				conditionsWrapperSt
				marginBottomMobileSt
				onClick={() => {
					commercialActions
						? setCommercialActionsAccepted(false)
						: openModal(
								(close) => (
									<CommercialActionsModal
										onClick={() => setCommercialActionsAccepted(!commercialActions)}
										isOpen={true}
										onClose={close}
									/>
								),
								{
									mode: 'old'
								}
						  );
				}}
			>
				<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={commercialActions} />
				<Text marginLefConditiontSt>
					Acepto recibir <SpanUnderline>llamadas comerciales</SpanUnderline>
				</Text>
			</View>

			{showBeneficiaries && (
				<View beneficiarySt>
					<Text labelInputSt style={{ alignSelf: 'flex-start' }}>
						Beneficiarios añadidos
						{totalPercentage < 100 && <Text>Debes asignar un 100 %</Text>}
					</Text>
					{beneficiaries.map((ben, index) => {
						return (
							<BeneficiaryBox
								key={`ben-${ben.dni}`}
								ben={ben}
								onDelete={() => {
									setBeneficiaries(
										beneficiaries.filter(function (benf) {
											return benf.dni !== ben.dni;
										})
									);
								}}
								onEdit={() => {
									openModal(
										(close) => (
											<BeneficiaryModal
												close={close}
												beneficiaries={beneficiaries}
												setBeneficiaries={setBeneficiaries}
												beneficiary={ben}
											/>
										),
										{
											disableClickAway: true,
											closeButton: false,
											modalClass: isMobile ? 'modal-xl' : 'modal-main',
											key: 'modal-anyadir-beneficiaries2'
										}
									);
								}}
							/>
						);
					})}
				</View>
			)}
			{showBeneficiaries && totalPercentage < 100 && (
				<View bottomSt style={{ marginTop: 10, alignItems: 'center' }}>
					<Button
						smallSt
						disabled={beneficiaryType === 'default'}
						onClick={() => {
							openModal(
								(close) => (
									<BeneficiaryModal close={close} beneficiaries={beneficiaries} setBeneficiaries={setBeneficiaries} />
								),
								{
									disableClickAway: true,
									closeButton: false,
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-anyadir-new-beneficiary3'
								}
							);
						}}
					>
						Añadir otro
					</Button>
				</View>
			)}
			<BackNextButton onClickNext={next} onClickBack={back} isNextDisabled={isDisabled()} />
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
