import React from 'react';

const TextSpan = ({ children, style = {}, onClick }) => {
	return (
		<span onClick={onClick} style={{ fontSize: 'inherit', fontFamily: 'inherit', ...style }}>
			{children}
		</span>
	);
};

export default TextSpan;
