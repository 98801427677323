import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';

UiWrapper.defineComponent('inputCheck', {
	styles: {
		default: {
			containerComponent: {
				width: 'auto',
				flexDirection: 'row'
			},
			labelComponent: {
				flexDirection: 'row'
			},
			checkComponent: {
				color: CONFIG.colors.secondary,
				backgroundColor: CONFIG.colors.white,
				padding: 4
			},
			checkedMode: {
				checkComponent: {
					color: CONFIG.colors.secondary,
					backgroundColor: CONFIG.colors.white,
					padding: 4
				}
			}
		}
	}
});
