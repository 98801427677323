import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
    AnalyticsWrapper,
    NavigationWrapper,
    PickerHOC,
    TargetsWrapper,
    Text,
    UiWrapper,
    UserWrapper
} from '@coinscrap/webapp-core';

import moment from 'moment';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Header from 'layout/components/Header/Header';

import routes from 'config/routes';
import { alcoholTypes } from 'data/alcoholTypes';
import { smokeTypes } from 'data/smokeTypes';
import { Wrapper } from 'layout/Wrapper';

import { professions } from './professions';
import * as S from './styles';

export const SimulationData = PickerHOC(
	{
		targets: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets'
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	const { saveTargetProperty } = TargetsWrapper.use();

	const { navigateTo, goBack } = NavigationWrapper.use();
	const { updateUser } = UserWrapper.use();
	const { pageView, emitEvent, updateData } = AnalyticsWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { targets, user } = PickerHOC.use();

	const [loading, setLoading] = useState();

	// CREATION DATA
	const creationData = targets[0]?.targetProperties?.find((x) => x.name === 'creationData')?.value;

	const [birthDate, setBirthDate] = useState(user?.metadata?.birthDate || moment().subtract(18, 'years'));
	const [gender, setGender] = useState(user?.metadata?.gender || '');
	const [weight, setWeight] = useState(user?.metadata?.weight || '');
	const [height, setHeight] = useState(user?.metadata?.height || '');

	const [profession, setProfession] = useState(user?.metadata?.profession || '');

	// FUMAS?
	const [smoke, setSmoke] = useState(user?.metadata?.smoke || 'no');
	const [smokeQuantity, setSmokeQuantity] = useState(user?.metadata?.smokeQuantity || null);
	const [smokeType, setSmokeType] = useState(user?.metadata?.smokeType || 'Cigarrillos');

	// ALCOHOL?
	const [alcohol, setAlcohol] = useState(user?.metadata?.alcohol || 'no');
	const [alcoholQuantity, setAlcoholQuantity] = useState(user?.metadata?.alcoholQuantity || null);
	const [alcoholType, setAlcoholType] = useState(user?.metadata?.alcoholType || 'Cerveza');

	// NAVIGATION
	const next = async () => {
		try {
			const currentAge = moment().diff(moment(birthDate), 'years');

			// TEALIUM EVENT
			emitEvent({
				tealium_event: 'quoter_calculate',
				user_birthdate: birthDate,
				user_profession: profession,
				user_sex: gender,
				user_weight: weight,
				user_height: height
			});

			setLoading('Guardando...');
			await updateUser({
				metadata: {
					...user?.metadata,
					birthDate,
					gender,
					weight: parseInt(weight),
					height: parseInt(height),
					profession,
					professionCode: professions?.find((p) => p?.label === profession)?.value,
					smoke,
					smokeQuantity: smokeQuantity || 0,
					smokeType: smokeType || undefined,
					alcohol,
					alcoholQuantity: alcoholQuantity || 0,
					alcoholType: alcoholType || undefined,
					isB2B: false
				}
			});
			//await new Promise((r) => setTimeout(r, 10000));
			setLoading('Actualizando...');
			await saveTargetProperty(targets[0].id, 'creationData', {
				...creationData,
				details: {
					...creationData?.details,
					birthDate,
					gender,
					weight: parseInt(weight),
					height: parseInt(height),
					profession,
					professionCode: professions?.find((p) => p?.label === profession)?.value,
					smoke,
					smokeQuantity: smokeQuantity || 0,
					smokeType: smokeType || undefined,
					alcohol,
					alcoholQuantity: alcoholQuantity || 0,
					alcoholType: alcoholType || undefined,
					isB2B: false
				}
			});

			if (currentAge > 69) {
				navigateTo(routes.cannotConfirm, {
					queryParams: {
						fromReason: 'SIMULATION_DETAILS',
						fromView: 'simulationData'
					}
				});
			} else {
				navigateTo(routes.simulation, { routeParams: { targetId: targets[0].id } });
			}
		} catch (e) {
			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-SimulationDataError'
				}
			);
		}
	};

	const back = async () => {
		goBack();
	};

	const isDisabled = () => {
		return (
			!birthDate ||
			moment(birthDate).unix() > moment().subtract(18, 'years').unix() ||
			!gender ||
			!weight ||
			!height ||
			!profession ||
			(smoke === 'yes' && (smokeQuantity === null || smokeType === null)) ||
			(alcohol === 'yes' && (alcoholQuantity === null || alcoholType === null))
		);
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header mobile={isMobile} />, []);

	return (
		<Wrapper width={'80%'}>
			<S.MainTitle>Calcula el precio de tu seguro de vida</S.MainTitle>
			<Text headingSecondarySt>
				Para poder calcular el precio de tu seguro de vida necesitamos que contestes a unas preguntas.
			</Text>
			<Text headingSecondarySt>Todos los campos son obligatorios.</Text>
			<S.FormWContainer>
				<InputLabel
					value={birthDate}
					onChange={(e) => {
						//console.log(e);
						setBirthDate(moment(e).format('YYYY-MM-DD'));
					}}
					label="Fecha de nacimiento"
					type="date"
					errorMsg={
						moment(birthDate).unix() > moment().subtract(18, 'years').unix() &&
						`La edad mínima de contratación es de 18 años`
					}
				/>
				<InputLabel
					showLabel={true}
					value={profession || ''}
					onChange={(value) => {
						setProfession(value);
					}}
					label={'Profesión'}
					type="autoSuggest"
					selectOptions={professions.map((item) => {
						return {
							label: item.label,
							value: item.label
						};
					})}
				/>
				<InputLabel
					showLabel={true}
					label="¿Cuál es tu género?"
					value={gender || ''}
					onChange={(e) => {
						setGender(e.target.value);
					}}
					type="select"
					selectOptions={[
						{ label: 'Masculino', value: 'masculino' },
						{ label: 'Femenino', value: 'femenino' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
				<InputLabel
					value={height}
					onChange={(e) => {
						if (isNaN(+e.target.value) || '') return;

						setHeight(parseInt(e.target.value || 0));
					}}
					label="¿Cuál es tu altura? (cm)"
				/>
				<InputLabel
					value={weight}
					onChange={(e) => {
						if (isNaN(+e.target.value) || '') return;
						setWeight(parseInt(e.target.value || 0));
					}}
					label="¿Cuál es tu peso? (kg)"
				/>

				<InputLabel
					value={smoke || 'no'}
					onChange={(e) => {
						setSmoke(e.target.value);
					}}
					label="¿Fumas?"
					type="select"
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
				{smoke === 'yes' && (
					<S.OptionsSmokeAndAlcohol>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={smokeQuantity}
							onChange={(e) => {
								//console.log('10 Quantity Change', e.target.value);
								setSmokeQuantity(+e.target.value);
							}}
							label="Cantidad diaria"
							type={'number'}
						/>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={smokeType}
							onChange={(e) => {
								setSmokeType(e.target.value);
							}}
							label="Tipo de tabaco"
							type="select"
							selectOptions={smokeTypes.map((item) => {
								return {
									label: item.name,
									value: item.value
								};
							})}
						/>
					</S.OptionsSmokeAndAlcohol>
				)}

				<InputLabel
					value={alcohol || 'no'}
					onChange={(e) => {
						setAlcohol(e.target.value);
					}}
					label="¿Consumes bebidas alcohólicas?"
					type="select"
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' }
					].map((item) => {
						return {
							label: item.label,
							value: item.value
						};
					})}
				/>
				{alcohol === 'yes' && (
					//TODO: refactor OptionsSmokeAndAlcohol
					<S.OptionsSmokeAndAlcohol>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={alcoholQuantity}
							onChange={(e) => {
								//console.log('11 Quantity Change', e.target.value);
								setAlcoholQuantity(+e.target.value);
							}}
							label="Cantidad diaria"
							errorMsg={true}
							type={'number'}
						/>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={alcoholType}
							onChange={(e) => {
								setAlcoholType(e.target.value);
							}}
							label="Tipo de alcohol"
							type="select"
							selectOptions={alcoholTypes.map((item) => {
								return {
									label: item.name,
									value: item.value
								};
							})}
						/>
					</S.OptionsSmokeAndAlcohol>
				)}
			</S.FormWContainer>
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				isNextDisabled={isDisabled()}
				nextText={isMobile ? 'SIMULAR' : 'ACCEDE A LA SIMULACIÓN'}
				justifyContent={'between'}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
