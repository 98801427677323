import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { IdentityDocumentsWrapper, NavigationWrapper, PickerHOC, Text, UiWrapper, View } from '@coinscrap/webapp-core';

import { set } from 'lodash';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import Header from 'layout/components/Header/Header';

import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';

import * as S from './styles';

export const IdentityBiometricUpsert = PickerHOC({
	dniDocuments: {
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	}
})(() => {
	const {
		// eslint-disable-next-line no-unused-vars
		dniDocuments: dniDocumentsSource,
		createIdentityDocument,
		changeIdentityDocumentValidationMode
	} = IdentityDocumentsWrapper.use();

	const { dniDocuments } = PickerHOC.use();
	const mainUserDni = dniDocuments.find((d) => d.main);
	const mainUserDniId = mainUserDni?.id;

	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { navigateTo, goBack } = NavigationWrapper.use();
	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	useEffect(() => {
		if (!mainUserDniId) {
			(async () => {
				const dniHanlded = await createIdentityDocument('dni', true);
				await changeIdentityDocumentValidationMode(dniHanlded.id, true);
				set();
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainUserDniId]);

	useEffect(() => {
		(async () => {
			if (mainUserDni && mainUserDni?.number && mainUserDni?.filled) {
				setLoading('El usuario ya tiene ha añadido su DNI...');
				navigateTo(routes.activateProduct);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainUserDni, mainUserDniId, navigateTo]);

	// NAVIGATION
	const back = async () => {
		goBack();
	};

	const next = async () => {
		try {
			setLoading('Empezando el proceso...');
			navigateTo(routes.identityBiometric);
		} catch (e) {
			let messageError = e.message.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				key: 'modal-idsUpsert'
			});
		}
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);
	return (
		<Wrapper width={'80%'}>
			<HorizontalProgressBar step={4} total={6} styleWrapper={{ maxWidth: 450 }} label={isB2B ? 'Firma' : ''} />
			<Text headingPrimarySt isB2B>
				Proceso de onboarding
			</Text>
			<Text headingSecondarySt>Por favor, completa los siguientes pasos para un onboarding correcto</Text>

			<View wrapperStepSt>
				<View itemStepSt>
					<S.StepCircle>1</S.StepCircle>
					<Text stepsSt>Hazte un selfie</Text>
				</View>
				<View itemStepSt>
					<S.StepCircle>2</S.StepCircle>
					<Text stepsSt>Captura el DNI/NIE por ambas caras</Text>
				</View>
			</View>
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				nextText={isB2B ? 'EMPEZAR' : 'CONTINUAR'}
				isNextDisabled={false}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
