import { useCallback, useEffect, useMemo } from 'react';

export const useInitialScrollToTop = () => {
	// https://stackoverflow.com/a/62985207
	const element = useMemo(() => document.querySelector("div[coreid='Layout_HF-Body-Parent']"), []);

	const scrollToTop = useCallback(() => {
		element && element.scroll(0, 0);
	}, [element]);

	const scrollToBottom = useCallback(() => {
		document.getElementById('layoutBottom').scrollIntoView({
			behavior: 'smooth'
		});
	}, []);

	useEffect(() => {
		scrollToTop();
		return () => {};
	}, [scrollToTop]);

	return { scrollToTop, scrollToBottom };
};
