import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import Tooltip from '@material-ui/core/Tooltip';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import { NavigationWrapper, Text, UiWrapper, View } from '@coinscrap/webapp-core';

import moment from 'moment';

import SendLinkModal from 'components/SendLinkModal/SendLinkModal';
import { useSendLink } from 'libs/hooks/useSendLink';

import { CONFIG } from 'config/config';
import routes from 'config/routes';
import { getCurrency } from 'libs/Currency';
import { getStatus } from 'libs/targetStatus';

const getStep = (status, step) => {
	switch (status) {
		case 'ACTIVE':
			return '';
		default:
			return `${step}/10 → `;
	}
};

const SimulationRowInner = (props) => {
	const { openModal } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();

	const {
		client,
		authRequests,
		agent,
		vinculation,
		apiInstance,
		simulation,
		reloadSimulation,
		onContinue,
		isGlobalAgent,
		currentStep
	} = props;

	const contributionData = simulation?.targetProperties?.find((x) => x.name === 'contributionData')?.value;

	const send = useSendLink(client.id, apiInstance);

	const getPeriod = (period) => {
		switch (period) {
			case 'semestral':
			case 'SEMIANNUALLY':
				return 'sem';
			case 'trimestral':
			case 'QUARTERLY':
				return 'tri';
			case 'mensual':
			case 'MONTHLY':
				return 'mes';
			case 'anual':
			case 'YEARLY':
				return 'anual';
			default:
				return 'mes';
		}
	};

	return (
		<>
			<Text tableTextSt>
				{client?.name?.trim()
					? client?.name?.trim() !== 'NEW_USER'
						? `${client?.name?.trim()} ${client?.surname?.trim()}`
						: 'Nuevo Usuario'
					: 'Nuevo Usuario'}
			</Text>

			<Text tableTextSt style={getStatus(simulation?.status) !== 'Activo' ? { fontSize: 16 } : { fontSize: 20 }}>
				{simulation?.status ? `${getStep(simulation?.status, currentStep)} ${getStatus(simulation?.status)} ` : '---'}
				{getStatus(simulation?.status) !== 'Activo' && (
					<View
						style={{ cursor: 'pointer', justifyContent: 'flex-start' }}
						rowSt
						onClick={() => {
							navigateTo(routes.followUp, {
								routeParams: {
									vinculationId: vinculation?.vinculationId,
									clientId: simulation?.userId,
									simulationId: simulation?.id
								}
							});
						}}
					>
						<VisibilityOutlinedIcon style={{ marginLeft: 3, marginRight: 10, fontSize: 22 }} />
						<Text style={{ fontSize: 14 }}>Ver detalles</Text>
					</View>
				)}
			</Text>

			<Text tableTextSt>{moment(simulation?.createDate).format('DD/MM/YYYY HH:mm')}</Text>

			<Text tableTextSt>
				{contributionData?.periodicContribution
					? getCurrency(contributionData?.periodicContribution, 2) +
					  '/' +
					  getPeriod(contributionData?.chargePeriod || 'mes')
					: '---'}{' '}
			</Text>

			<Text tableTextSt>
				{contributionData?.amountToCover ? getCurrency(contributionData?.amountToCover, 2) : '---'}
			</Text>

			{isGlobalAgent && (
				<Text tableTextSt style={{ fontSize: 14 }}>
					{agent?.email?.trim() || agent.toString() || ''}
				</Text>
			)}

			<Text tableTextSt style={{ fontSize: 14, textAlign: 'center' }}>
				{authRequests.length ? authRequests[0]?.source || authRequests[0]?.queryParams?.source || '---' : '---'}
			</Text>

			<View style={{ padding: '14px 0px' }}>
				<View>
					<View
						style={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: `${isMobile ? 'center' : 'space-evenly'}`,
							marginTop: `${isMobile ? '20px' : ''}`,
							marginBottom: 20
						}}
					>
						{/*RECARGAR*/}
						<div
							style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
							onClick={() => reloadSimulation(simulation?.id)}
						>
							<Tooltip title="Actualizar">
								<CachedIcon style={{ marginLeft: 3, marginRight: 3, fontSize: 22 }} />
							</Tooltip>
						</div>

						{/*EDITAR*/}
						{agent.toString() !== 'B2C' &&
							simulation?.status !== 'CANCELLED' &&
							simulation?.status !== 'ACTIVE' &&
							client?.metadata?.acceptContact !== false && (
								<div
									style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
									onClick={() => onContinue(simulation?.id)}
								>
									<Tooltip title="Editar">
										<EditIcon style={{ marginLeft: 3, marginRight: 3, fontSize: 22 }} />
									</Tooltip>
								</div>
							)}

						{/*NOTIFICAR*/}
						{simulation?.status !== 'CANCELLED' &&
							contributionData?.amountToCover &&
							contributionData?.periodicContribution &&
							simulation?.status !== 'ACTIVE' &&
							(client?.email?.trim() || client?.phoneNumber?.trim()) &&
							client?.metadata?.acceptContact !== false && (
								<div
									style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
									onClick={() =>
										openModal((close) => <SendLinkModal close={close} user={client} send={send} />, {
											disableClickAway: true,
											closeButton: true,
											modalClass: isMobile ? 'modal-xl' : 'modal-main',
											key: 'modal-SendLink'
										})
									}
								>
									<Tooltip title="Editar">
										<SendIcon style={{ marginLeft: 3, marginRight: 3, fontSize: 22 }} />
									</Tooltip>
								</div>
							)}
					</View>
				</View>

				{client?.metadata?.acceptContact === false && (
					<View>
						<Text style={{ fontSize: 16, color: CONFIG.colors.danger, marginBottom: 10, marginTop: 0 }}>
							No quiere recibir ofertas
						</Text>
					</View>
				)}

				{client?.phoneNumber && (
					<View>
						<a
							href={`tel:${client?.phoneNumber?.trim()}`}
							style={{ color: CONFIG.colors.primary, textDecoration: 'none', fontSize: 14 }}
						>
							{client.phoneNumber}
						</a>
					</View>
				)}

				{client?.email && (
					<View style={{ marginTop: 10 }}>
						<a
							href={`mailto:${client.email}`}
							style={{ color: CONFIG.colors.primary, textDecoration: 'none', fontSize: 14 }}
						>
							{client.email}
						</a>
					</View>
				)}
			</View>
		</>
	);
};

export const SimulationRow = (props) => {
	useEffect(() => {}, [props]);
	return (
		<SimulationRowInner
			isGlobalAgent={props.isGlobalAgent}
			apiInstance={props.apiInstance}
			vinculation={props.vinculation}
			simulation={props.simulation}
			agent={props.agent}
			client={props.client}
			authRequests={props.authRequests}
			dniDocuments={props.dniDocuments}
			testCompletions={props.testCompletions}
			currentStep={props.currentStep}
			onContinue={props.onContinue}
			reloadSimulation={props.reloadSimulation}
		/>
	);
};
