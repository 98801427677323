import React, { useEffect, useState } from 'react';

import { NavigationWrapper, PickerHOC, SessionWrapper, UiWrapper, UserWrapper } from '@coinscrap/webapp-core';

import Header from 'layout/components/Header/Header';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import routes from '../../config/routes';

export const StartOnboarding = PickerHOC(
	{},
	LoadingScreen
)(() => {
	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();

	const { sessionData, sessionStatus } = SessionWrapper.use();
	const { user, loadSessionUser } = UserWrapper.use();
	const [loading, setLoading] = useState('Accediendo...');

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header />);

	useEffect(() => {
		if (!user || !sessionData || sessionStatus === 'NOT_CREATED') {
			(async () => {
				await loadSessionUser();
			})();
		} else {
			setLoading(undefined);
			navigateTo(routes.KYCidentityBiometricUpsert);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionData, sessionStatus, user]);

	return <></>;
});
