import { useCallback } from 'react';

import { VinculationWrapper } from '@coinscrap/webapp-core';

import moment from 'moment';

export const useSendLink = (clientId, instance) => {
	const vinculationContext = VinculationWrapper.use();
	const vinculationApiInstance = instance || vinculationContext.vinculationApiInstance;
	const send = useCallback(
		async (type) => {
			if (vinculationApiInstance) {
				const definitions = await vinculationApiInstance.microserviceCall('Ns')('/notification-definitions', 'GET', {
					filter: { where: { identifier: type } }
				});

				const eventCreated = await vinculationApiInstance.microserviceCall('Ns')(`/users/${clientId}/events`, 'POST', {
					notificationDefinitionId: definitions[0]?.id,
					eventDefinitionIdentifier: 'HTTP',
					payload: {}
				});

				let status = eventCreated.status;
				let finishDate = moment().add(20, 'seconds').toDate();
				while (status !== 'NOTIFICATIONS_CREATED' || +new Date() > +finishDate) {
					await new Promise((r) => setTimeout(r, 1000));
					const eventPooled = await vinculationApiInstance.microserviceCall('Ns')(`/events/${eventCreated.id}`, 'GET');
					status = eventPooled.status;
				}

				if (status !== 'NOTIFICATIONS_CREATED') {
					throw new Error('Timeout reached creating notifications');
				}

				let notifications;
				let notResolvedNotification = undefined;
				do {
					await new Promise((r) => setTimeout(r, 500));
					notifications = await vinculationApiInstance.microserviceCall('Ns')(
						`/events/${eventCreated.id}/notifications`,
						'GET'
					);
					notResolvedNotification = notifications?.find((x) => x.status !== 'SENDING_RESOLVED' && !x.errorDate);
				} while (notResolvedNotification);

				const errorNotification = notifications?.find((x) => x.errorDate);
				if (errorNotification) {
					throw new Error(errorNotification.error || 'Error on notification send');
				}

				const sendingData = notifications?.find((x) => x.status === 'SENDING_RESOLVED').sendingData;
				if (sendingData) {
					if (sendingData.publishRes) {
						try {
							const info = JSON.parse(sendingData.publishRes);
							if (info.mockEnabled) {
								localStorage.setItem(
									'SENDED LINK',
									sendingData.text.substring(sendingData.text.indexOf('/continue') + 1)
								);
								//eslint-disable-next-line no-console
								console.log('## LINK', sendingData.text);
								//alert(sendingData.text);
							}
						} catch (e) { }
					}
				}
			}
		},
		[vinculationApiInstance, clientId]
	);

	return send;
};
