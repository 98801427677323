import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
    Button,
    NavigationWrapper,
    PickerHOC,
    Text,
    UiWrapper,
    UserWrapper,
    View,
    VinculationWrapper
} from '@coinscrap/webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import BeneficiariesModal from 'components/BeneficiariesModal/BeneficiariesModal';
import BeneficiaryBox from 'components/BeneficiaryBox/BeneficiaryBox';
import BeneficiaryModal from 'components/BeneficiaryModal/BeneficiaryModal';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import InputLabel from 'components/InputLabel/InputLabel';
import TooltipSimple from 'config/components/tooltipSimple';
import Header from 'layout/components/Header/Header';
import isEmail from 'validator/lib/isEmail';
import { ClientSimulationWrapper } from 'wrappers/customWrapper/ClientSimulationWrapper';

import routes from 'config/routes';
import { municipalities } from 'data/municipalities';
import { professionalSituationList } from 'data/professionalSituationList';
import { provinces } from 'data/provinces';
import { Wrapper } from 'layout/Wrapper';

export const ClientDataAgent = PickerHOC({
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	},
	vinculation: {
		sourcePath: 'vinculation',
		sourceWrapper: VinculationWrapper
	},
	instance: {
		sourcePath: 'vinculationApiInstance',
		sourceWrapper: VinculationWrapper
	},
	simulation: {
		sourcePath: 'simulation',
		sourceWrapper: ClientSimulationWrapper
	},
	client: {
		sourcePath: 'client',
		sourceWrapper: ClientSimulationWrapper
	}
})(() => {
	const { updateClient, updateSimulation, loadSimulationForClient } = ClientSimulationWrapper.use();

	const { navigateTo, goBack } = NavigationWrapper.use();
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	const pickerValues = PickerHOC.use();

	const { client, simulation } = pickerValues;

	useEffect(() => {
		(async () => {
			//TODO QUITAR CUANDO EN CHANGE PRODUCT AL ACTUALIZAR EL PRODUCT SE ACTUALICE BIEN EL TARGET SIN TENER QUE HACER ESTO AL VOLVER AL SUMMARY
			await loadSimulationForClient(client.id, simulation.id);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const creationData = simulation?.targetProperties?.find((x) => x.name === 'creationData')?.value;

	const [name, setName] = useState(client?.name?.trim() || '');
	const [surname, setSurname] = useState(client?.surname || '');
	const [address, setAddress] = useState(client?.metadata?.address || '');
	const [phoneNumber, setPhoneNumber] = useState(client?.phoneNumber || '');
	const [professionalSituation, setProfessionalStatus] = useState(client?.metadata?.professionalSituation || '');
	const [otherProfessionalSituation, setOtherProfessionalSituation] = useState(
		client?.metadata?.otherProfessionalSituation || ''
	);
	const dni = useMemo(() => client?.metadata.dni || '', [client]);
	const [postalCode, setPostalCode] = useState(client?.metadata?.postalCode || '');
	const [province, setProvince] = useState(client?.metadata?.province);
	const [municipality, setMunicipality] = useState(client?.metadata?.municipality || 0);
	const [email, setEmail] = useState(client?.email || '');

	const [beneficiaryType, setBeneficiaryType] = useState(creationData?.details?.beneficiaryType || 'default');

	const [beneficiaries, setBeneficiaries] = useState(
		creationData?.details?.beneficiaries ? creationData?.details?.beneficiaries : []
	);

	const [showBeneficiaries, setShowBeneficiaries] = useState(beneficiaries?.length > 0 ? true : false);

	const totalPercentage = beneficiaries.reduce(function (acc, ben) {
		return acc + ben.percentage;
	}, 0);

	// isInvalid
	const [isEmailCorrect, setIsEmailCorrect] = useState(true);
	const [isPostalCorrect, setIsPostalCorrect] = useState(true);

	const handleInputChange = (setValue) => (e) => {
		const inputValue = e.target.value;
		if (/^[A-Za-z\s]+$/.test(inputValue)) {
			setValue(inputValue);
		}
	};

	useEffect(() => {
		const findProvince = provinces.find(
			(item) => item.value.toString().substr(0, 2) === postalCode.toString().substr(0, 2)
		);
		if (findProvince) {
			setProvince(findProvince.label);
		} else {
			setProvince(null);
		}
	}, [postalCode]);

	let listMunicipalities = [];

	if (province) {
		const findProvince = provinces.find((item) => item.label === province);
		listMunicipalities = municipalities.filter((item) => item.CPRO === findProvince.value);
	}

	useEffect(() => {
		setShowBeneficiaries(beneficiaries.length > 0 ? true : false);
	}, [beneficiaries.length]);

	// NAVIGATION
	const back = async () => {
		navigateTo(routes.simulationDataAgent);
	};

	const next = async () => {
		try {
			setLoading('Guardando datos de cliente');
			await updateClient({
				name,
				surname,
				email,
				phoneNumber,
				metadata: {
					...client?.metadata,
					professionalSituation,
					professionalSituationName: professionalSituationList.find((pS) => pS.value === professionalSituation).label,
					dni,
					otherProfessionalSituation,
					address,
					postalCode,
					province,
					municipality
				}
			});
			setLoading('Guardando datos de simulación');

			const creationData = simulation?.targetProperties?.find((x) => x.name === 'creationData')?.value;

			await updateSimulation({
				details: {
					...creationData?.details,
					beneficiaryType,
					beneficiaries
				}
			});
			setLoading(undefined);
			navigateTo(routes.simulationAgent);
		} catch (e) {
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>Ha ocurrido un generando la simulación</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-clientDataAgent1'
				}
			);
		}
	};

	const isDisabled = () => {
		return (
			(email !== '' && !isEmail(email)) ||
			(beneficiaryType === 'designed' && beneficiaries.length === 0) ||
			(beneficiaryType !== 'default' && totalPercentage < 100)
		);
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} isB2B={true} />, []);

	return (
		<Wrapper width={'80%'}>
			<Text headingPrimarySt>Datos opcionales</Text>
			<Text headingSecondarySt>
				Los siguientes datos no influirán en el cálculo de la prima. Puedes rellenarlos ahora o que sea el propio
				cliente el que lo haga durante el proceso de contratación.
			</Text>
			<View tripleInputRowSt>
				<InputLabel
					value={name}
					onChange={handleInputChange(setName)}
					label="Nombre"
				/>
				<InputLabel
					value={surname}
					onChange={handleInputChange(setSurname)}
					label="Apellidos"
				/>
				<InputLabel
					value={phoneNumber}
					onChange={(value) => {
						setPhoneNumber(value);
					}}
					label="Teléfono"
					type="phone"
				/>
			</View>
			<View tripleInputRowSt>
				<InputLabel
					onBlur={() => {
						setIsEmailCorrect(isEmail(email));
					}}
					value={email}
					onChange={(e) => {
						if (!isEmailCorrect) {
							setIsEmailCorrect(isEmail(e.target.value));
						}
						setEmail(e.target.value);
					}}
					label="Correo electrónico"
					errorMsg={!isEmailCorrect && `Email inválido`}
				/>
				<InputLabel
					value={professionalSituation || 0}
					onChange={(e) => {
						setProfessionalStatus(e.target.value);
					}}
					label="Situación profesional"
					type="select"
					selectOptions={professionalSituationList}
				/>
				{professionalSituation === 'OTROS' && (
					<View>
						<InputLabel
							value={otherProfessionalSituation}
							onChange={(e) => {
								setOtherProfessionalSituation(e.target.value);
							}}
							label="Otra situación profesional"
						/>
					</View>
				)}
				<InputLabel
					value={address}
					onChange={(e) => {
						setAddress(e.target.value);
					}}
					label={'Dirección'}
				/>
			</View>
			<View tripleInputRowSt>
				<InputLabel
					value={postalCode}
					onChange={(e) => {
						if (isNaN(+e.target.value) || e.target.value.length > 5) return;

						if (!isPostalCorrect) {
							setIsEmailCorrect(e.target.value.length === 5);
						}
						setPostalCode(e.target.value);
					}}
					onBlur={() => {
						setIsPostalCorrect(postalCode.length === 5);
					}}
					label="Código postal"
				/>
				<InputLabel
					value={province || ''}
					disabled
					onChange={(e) => {
						// console.log(e.target.value);
						// setProvince(e.target.value);
					}}
					label="Provincia"
					// type="select"
					//selectOptions={provinces}
				/>
				<InputLabel
					value={municipality || 0}
					onChange={(e) => {
						setMunicipality(e.target.value);
					}}
					label="Municipio"
					type="select"
					selectOptions={listMunicipalities.map((item) => {
						return {
							label: item.NOMBRE,
							value: item.NOMBRE
						};
					})}
				/>
			</View>
			<View tripleInputRowSt>
				<InputLabel
					value={beneficiaryType}
					onChange={(e) => {
						setBeneficiaryType(e.target.value);
						if (e.target.value === 'default') {
							setBeneficiaries([]);
						} else if (e.target.value === 'designed') {
							openModal(
								(close) => (
									<BeneficiaryModal close={close} beneficiaries={beneficiaries} setBeneficiaries={setBeneficiaries} />
								),
								{
									disableClickAway: true,
									closeButton: false,
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-anyadir-first-beneficiary'
								}
							);
						}
					}}
					label="Beneficiario"
					tooltip={
						<View
							style={{ width: '30%' }}
							onClick={() => {
								openModal((close) => <BeneficiariesModal isOpen={true} onClose={close} />, {
									mode: 'old'
								});
							}}
						>
							<TooltipSimple>
								<Text tooltipTitleSt>Información sobre designación de beneficiarios.</Text>
								<Text tooltipTextSt>Haz click para ver más información.</Text>
							</TooltipSimple>
						</View>
					}
					type="select"
					selectOptions={[
						{ label: 'Designar beneficiario', value: 'designed' },
						{ label: 'Beneficiario por defecto ', value: 'default' }
					]}
				/>
			</View>
			{showBeneficiaries && (
				<View beneficiarySt>
					<Text labelInputSt style={{ alignSelf: 'flex-start' }}>
						Beneficiarios añadidos
						{totalPercentage < 100 && <Text>Debes asignar un 100 %</Text>}
					</Text>
					{beneficiaries.map((ben, index) => {
						return (
							<BeneficiaryBox
								key={`ben-${ben.dni}`}
								ben={ben}
								onDelete={() => {
									setBeneficiaries(
										beneficiaries.filter(function (benf) {
											return benf.dni !== ben.dni;
										})
									);
								}}
								onEdit={() => {
									openModal(
										(close) => (
											<BeneficiaryModal
												close={close}
												beneficiaries={beneficiaries}
												setBeneficiaries={setBeneficiaries}
												beneficiary={ben}
											/>
										),
										{
											disableClickAway: true,
											closeButton: false,
											modalClass: isMobile ? 'modal-xl' : 'modal-main',
											key: 'modal-anyadir-beneficiaries2'
										}
									);
								}}
							/>
						);
					})}
				</View>
			)}
			{showBeneficiaries && totalPercentage < 100 && (
				<View bottomSt style={{ marginTop: 10, alignItems: 'center' }}>
					<Button
						smallSt
						disabled={beneficiaryType === 'default'}
						onClick={() => {
							openModal(
								(close) => (
									<BeneficiaryModal close={close} beneficiaries={beneficiaries} setBeneficiaries={setBeneficiaries} />
								),
								{
									disableClickAway: true,
									closeButton: false,
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-anyadir-new-beneficiary3'
								}
							);
						}}
					>
						Añadir otro
					</Button>
				</View>
			)}
			<BackNextButton onClickNext={next} onClickBack={back} isBackShown={!isMobile} isNextDisabled={isDisabled()} />
		</Wrapper>
	);
});
