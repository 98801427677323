import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';

import { Button, Layout, Text, View } from '@coinscrap/webapp-core';

import * as ModalConfig from 'config/components/modal';

import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';
import { GlobalStyle } from 'styles/globalStyle';

export const MaxWidthMobile = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
`;

export const MainLayout = ({ children }) => {
	return (
		<Layout
			predefinedLayout={'HeaderFooter'}
			loadingGenerator={(props) => {
				return (
					<Modal open>
						<View loadingModalSt>
							{props?.loadingMessage && (
								<Text
									style={{
										color: CONFIG.colors.primary,
										fontFamily: CONFIG.fonts.bold,
										fontSize: '32px',
										margin: '16px'
									}}
								>
									{props?.loadingMessage}
								</Text>
							)}
							<CircularProgress />
						</View>
					</Modal>
				);
			}}
			modalGenerator={(handleClose, children, options) => {
				const mode = options?.mode ? options.mode : 'new';
				const key = options?.key ? options.key : 'modalKey';
				const modalClass = options?.modalClass ? options.modalClass : 'modal-main';
				const showHideCloseButtonClassName = options?.closeButton ? { display: 'block' } : { display: 'none' };

				if (mode === 'new') {
					return (
						<div className={'modal display-flex'} key={key}>
							<section className={modalClass}>
								<ModalConfig.ModalWrapper>
									{children}
									<Button
										modalSt
										style={{
											...showHideCloseButtonClassName
										}}
										onClick={() => {
											handleClose();
										}}
									>
										Cerrar
									</Button>
								</ModalConfig.ModalWrapper>
							</section>
						</div>
					);
				} else {
					return children;
				}
			}}
			style={{
				bodyComponent: {
					backgroundColor: 'white',
					justifyContent: 'flex-start',
					paddingTop: '0px',
					paddingBottom: '0px',
					overflowY: 'auto'
				},
				bodyContentComponent: {
					overflowX: 'hidden',
					justifyContent: 'flex-start',
					maxWidth: '100%',
					overflowY: 'auto'
					// flexShrink: 1,
				}
			}}
		>
			<MaxWidthMobile>
				<GlobalStyle />
				{children}
			</MaxWidthMobile>
		</Layout>
	);
};
