import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
	AnalyticsWrapper,
	Button,
	Input,
	InputCheck,
	InstitutionsWrapper,
	NavigationWrapper,
	PickerHOC,
	TargetTypesWrapper,
	TargetsWrapper,
	Text,
	Tooltip,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import { caserOnError } from '../../libs/caserOnError';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import TermsModal from 'components/TermsModal/TermsModal';
import TextSpan from 'components/TextSpan/TextSpan';
import { SpanBold, SpanCurrency } from 'config/components/span';
import TooltipSimple from 'config/components/tooltipSimple';
import Header from 'layout/components/Header/Header';
import { useSimulation } from 'libs/hooks/useSimulation';

import { CONFIG } from 'config/config';
import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';
import { getCurrency } from 'libs/Currency';

import * as S from './styles';
import './styles.css';

export const Simulation = PickerHOC(
	{
		targetType: {
			arrayItemMatcher: {
				itemPropertyPath: 'identifier',
				type: 'value',
				value: 'CASER_VIDA_RIESGO'
			},
			sourcePath: 'targetTypes',
			sourceWrapper: TargetTypesWrapper
		},
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		},
		caserInstitution: {
			arrayItemMatcher: {
				itemPropertyPath: 'identifier',
				type: 'value',
				value: 'caser'
			},
			sourcePath: 'institutions',
			sourceWrapper: InstitutionsWrapper
		}
	},
	LoadingScreen
)(() => {
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const { pageView, updateData } = AnalyticsWrapper.use();
	const pickerValues = PickerHOC.use();
	const { target, user, caserInstitution, targetType } = pickerValues;

	const onError = (e) =>
		caserOnError(e, 'SIMULATION', openModal, navigateTo, setTrigger, capitalMinimum, capitalMaximum);

	const textPeriodicidad = () => {
		switch (period) {
			case 'mensual':
			case 'MONTHLY':
				return 'mes';
			case 'trimestral':
			case 'QUARTERLY':
				return 'trimestre';
			case 'semestral':
			case 'SEMIANNUALLY':
				return 'semestre';
			case 'anual':
			case 'YEARLY':
				return 'año';
			default:
				return '';
		}
	};

	const textPeriodicidadLong = () => {
		switch (period) {
			case 'mensual':
			case 'MONTHLY':
				return 'al mes';
			case 'trimestral':
			case 'QUARTERLY':
				return 'trimestralmente';
			case 'semestral':
			case 'SEMIANNUALLY':
				return 'semestralmente';
			case 'anual':
			case 'YEARLY':
				return 'al año';
			default:
				return '';
		}
	};

	// CREATION DATA
	const creationData = target?.targetProperties?.find((x) => x.name === 'creationData')?.value;
	const contributionData = target?.targetProperties?.find((x) => x.name === 'contributionData')?.value;

	// CAPITAL MÁXIMO
	// eslint-disable-next-line no-unused-vars
	const [capitalMaximum, setCapitalMaximum] = useState(180000);

	// CAPITAL MÍNIMO
	// eslint-disable-next-line no-unused-vars
	const [capitalMinimum, setCapitalMinimum] = useState(20000);

	// CHECKS
	const [termsConditions, setTermsConditions] = useState(creationData?.termsConditions || false);

	// eslint-disable-next-line no-unused-vars
	const [trigger, setTrigger] = useState(0);

	const [
		simulationLoading,
		// eslint-disable-next-line no-unused-vars
		setSimulationLoading,
		invoice,
		setInvoice,
		capital,
		setCapital,
		isValidCapital,
		setIsValidCapital,
		invalidCapitalReason,
		setInvalidCapitalReason,
		period,
		setPeriod,
		disabilityCoverage,
		setDisabilityCoverage,
		disabilityCoverageAccident,
		setDisabilityCoverageAccident,
		quoteSimulationId
	] = useSimulation(
		caserInstitution,
		target.id,
		targetType,
		contributionData,
		user,
		capitalMaximum,
		capitalMinimum,
		onError,
		trigger
	);

	const [capitalRange, setCapitalRange] = React.useState(capital);

	//const send = useSendLink(user.id);
	const { navigateTo, goBack } = NavigationWrapper.use();

	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	const [inputWidth, setInputWidth] = useState(2);
	const [inputWidthCapital, setInputWidthCapital] = useState(2);

	// NAVIGATION
	const back = async () => {
		goBack();
	};

	const next = async () => {
		try {
			setLoading('Guardando...');
			await saveTargetProperty(target.id, 'creationData', {
				...creationData,
				termsConditions,
				quoteSimulationId
			});
			navigateTo(routes.clientData);
		} catch (e) {
			let messageError = e?.message?.toString();
			messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				modalClass: isMobile ? 'modal-xl' : 'modal-main',
				closeButton: true,
				key: 'modal-saveSimulationError'
			});
		}
	};

	useEffect(() => {
		const findPeriodsAndDecimals = [...invoice.toString()].filter((item) => item === '.' || item === ',');
		setInputWidth(invoice.toString().trim().length + 2 + findPeriodsAndDecimals.length * -0.6);

		const findPeriodsAndDecimalsCapital = [...capital.toString()].filter((item) => item === '.' || item === ',');
		setInputWidthCapital(capital.toString().trim().length + 2 + findPeriodsAndDecimalsCapital.length * -0.6);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoice, capital]);

	// TEALIUM PAGE VIEW
	useEffect(() => {
		const tealiumData = {
			page_type: 'pricing',
			process_name: 'particulares simulador riesgo vida',
			process_step: 'parrilla presupuestos',
			process_type: 'tarificacion'
		};

		pageView({
			pageType: tealiumData.page_type,
			processName: tealiumData.process_name,
			processStep: tealiumData.process_step,
			processType: tealiumData.process_type
		});

		updateData({ ...tealiumData, lead_config: 'precio' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} />, []);
	useLoading(simulationLoading || loading, {
		loadingMessage: simulationLoading || loading
	});

	return (
		<Wrapper width={'80%'}>
			<HorizontalProgressBar
				step={1}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Confirmar simulación' : ''}
			/>
			<Text headingPrimarySt isB2B>
				{isB2B ? 'Confirma tu seguro' : '¿Quieres contratar un seguro de vida?'}
			</Text>
			<Text headingSecondarySt>
				{
					{
						true: (
							<>
								Por <TextSpan style={{ fontWeight: 800, color: CONFIG.colors.secondary }}>{invoice} €</TextSpan>{' '}
								{textPeriodicidadLong()} vas a contratar tu seguro de vida con un capital de{' '}
								<TextSpan style={{ fontWeight: 800, color: CONFIG.colors.secondary }}>{capital} €</TextSpan>{' '}
							</>
						),
						false: (
							<>
								Por solo <TextSpan style={{ fontWeight: 800, color: CONFIG.colors.secondary }}>{invoice} €</TextSpan>{' '}
								{textPeriodicidadLong()} puedes tener un seguro de vida que te cubra{' '}
								<TextSpan style={{ fontWeight: 800, color: CONFIG.colors.secondary }}>{capital} €</TextSpan> cuando tú
								faltes{' '}
							</>
						)
					}[isB2B]
				}

				<TooltipSimple>
					<SpanBold style={{ color: 'black' }}>Fallecimiento:</SpanBold>
					<Text tooltipTextSt>Obligatoria.</Text>
					<Text tooltipTextSt>Por cualquier causa, enfermedad o accidente.</Text>
				</TooltipSimple>
			</Text>
			<Text featuresPrimarySt>{isMobile ? 'Capital a asegurar' : 'Escoge el capital a asegurar'}</Text>
			<S.InputWrapper>
				<Input
					style={{
						color: CONFIG.colors.secondary,
						fontSize: '36px',
						fontFamily: CONFIG.fonts.bold,
						width: inputWidthCapital + 'ch',
						height: 'auto'
					}}
					value={capital}
					onChange={(e) => {
						if (simulationLoading) return;
						const capital = e.target.value || '';
						const val = capital.replace(/\D/g, '');
						setIsValidCapital(val !== '' && val <= capitalMaximum && val >= capitalMinimum);
						if (val > capitalMaximum) {
							setInvalidCapitalReason('El capital máximo asegurable es de ' + getCurrency(capitalMaximum, 0));
						} else if (val === '' || val < capitalMinimum) {
							setInvalidCapitalReason('El capital mínimo asegurable es de ' + getCurrency(capitalMinimum, 0));
						}
						setCapital(val);
						setCapitalRange(val);
					}}
				/>
				<SpanCurrency>€</SpanCurrency>
			</S.InputWrapper>
			<input
				type={'range'}
				min={20000}
				max={180000}
				step={500}
				value={capitalRange}
				onChange={({ target: { value: radius } }) => {
					setCapitalRange(radius);
					setCapital(radius);
				}}
			/>
			<View
				invalidCapitalSt
				style={{
					display: !isValidCapital && invalidCapitalReason ? 'block' : 'none',
					visibility: !isValidCapital && invalidCapitalReason ? 'visible' : 'hidden'
				}}
			>
				{!isValidCapital && invalidCapitalReason}
			</View>
			<Text featuresPrimarySt featuresMarginBottomSt>
				{isMobile ? 'Periodicidad del pago' : 'Selecciona la periodicidad'}
			</Text>
			<View elementsAroundFlexSt>
				<Button
					simulationPeriodSt
					selectedSt={period === 'mensual' || period === 'MONTHLY'}
					onClick={() => {
						setPeriod('mensual');
					}}
				>
					Mensual
				</Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'trimestral' || period === 'QUARTERLY'}
					onClick={() => {
						setPeriod('trimestral');
					}}
				>
					Trimestral
				</Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'semestral' || period === 'SEMIANNUALLY'}
					onClick={() => {
						setPeriod('semestral');
					}}
				>
					Semestral
				</Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'anual' || period === 'YEARLY'}
					onClick={() => {
						setPeriod('anual');
					}}
				>
					Anual
				</Button>
			</View>
			<View conditionsWrapperSt marginTopMobileSt>
				<InputCheck
					checkedIcon={<CheckBoxOutlinedIcon />}
					value={disabilityCoverage}
					onChange={() => {
						if (disabilityCoverage && disabilityCoverageAccident) {
							setDisabilityCoverageAccident(!disabilityCoverageAccident);
						}
						setDisabilityCoverage(!disabilityCoverage);
					}}
				/>
				<Text marginLefConditiontSt>
					Incluir cobertura por incapacidad permanente absoluta{' '}
					<TooltipSimple>
						<Text tooltipTextSt>
							Pago del capital garantizado, alternativo al seguro principal, si el asegurado/a sufre una incapacidad
							permanente absoluta para toda profesión durante la vigencia del seguro. Incapacidad Permanente Absoluta se
							define como la situación física irreversible provocada por accidente o enfermedad originados
							independientemente de la voluntad del asegurado/a, determinante de su total ineptitud para el
							mantenimiento permanente de cualquier relación laboral o actividad profesional.
						</Text>
					</TooltipSimple>
					<Tooltip open={false} />
				</Text>
			</View>

			<Text
				featuresPrimarySt
				featuresMarginTopSt
				isB2B
				style={{ marginTop: isMobile ? '16px' : '25px', marginBottom: 0 }}
			>
				La prima resultante es de :
			</Text>
			<S.InputWrapper>
				<Input
					style={{
						color: CONFIG.colors.secondary,
						fontSize: '36px',
						fontFamily: CONFIG.fonts.bold,
						width: inputWidth + 'ch',
						height: 'auto'
					}}
					value={invoice}
					onChange={(e) => {
						const val = e.target.value || '';
						const valNumber = Number(val.replace(/./g, ','));
						setInvoice(valNumber);
					}}
				/>
				<SpanCurrency>{`€ / ${textPeriodicidad()}`}</SpanCurrency>
			</S.InputWrapper>

			<View
				conditionsWrapperSt
				marginBottomMobileSt
				onClick={() => {
					termsConditions
						? setTermsConditions(false)
						: openModal(
								(close) => (
									<TermsModal onClick={() => setTermsConditions(!termsConditions)} isOpen={true} onClose={close} />
								),
								{
									mode: 'old'
								}
						  );
				}}
			>
				<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={termsConditions} />
				<Text marginLefConditiontSt>
					<SpanBold>Acepto</SpanBold> la Política de Privacidad y Términos y Condiciones *
				</Text>
			</View>

			<BackNextButton
				onClickBack={back}
				onClickNext={next}
				isBackDisabled={false}
				isNextDisabled={
					!Number(invoice.toString().replace('.', '').replace(',', '.')) ||
					!Number(capital.toString().replace('.', '').replace(',', '.')) ||
					!termsConditions ||
					!isValidCapital
				}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
