import { isMobile } from 'react-device-detect';

import fonts from '../fonts';

const common = {
	styles: {
		// FLEX
		rowSt: {
			flexDirection: 'row'
		},
		colSt: {
			flexDirection: 'column'
		},

		//JUSTIFY
		justifyStartSt: {
			justifyContent: 'flex-start'
		},
		justifyEndSt: {
			justifyContent: 'flex-end'
		},
		justifyEvenlySt: {
			justifyContent: 'space-evenly'
		},
		justifyBetweenSt: {
			justifyContent: 'space-between'
		},
		justifyCenterSt: {
			justifyContent: 'center'
		},

		//ALIGN
		alignItemsStartSt: {
			alignItems: 'flex-start'
		},
		alignItemsEndSt: {
			alignItems: 'flex-end'
		},
		alignItemsEvenlySt: {
			alignItems: 'space-evenly'
		},
		alignItemsBetweenSt: {
			alignItems: 'space-between'
		},
		alignItemsCenterSt: {
			alignItems: 'center'
		},

		// FONT FAMILY / WEIGHT
		lightSt: {
			fontFamily: fonts.light
		},
		regularSt: {
			fontFamily: fonts.regular
		},
		semiBoldSt: {
			fontFamily: fonts.semiBold
		},
		boldSt: {
			fontFamily: fonts.bold
		},

		// TEXT ALIGN
		leftSt: {
			textAlign: 'left'
		},
		centerSt: {
			textAlign: 'center'
		},
		rightSt: {
			textAlign: 'right'
		},

		fullHeightSt: {
			height: '100%'
		},

		fullWidthSt: {
			width: '100%'
		},

		fixedWidthSt: {
			minWidth: 200
		},

		fullHeightMobileSt: {
			height: isMobile && '100%'
		},

		fullWidthMobileSt: {
			width: isMobile && '100%'
		},

		wInitialSt: {
			width: 'initial'
		},

		growSt: {
			flexGrow: 1
		},

		// OTHER
		cursorPointerSt: { cursor: 'pointer' }
	}
};

export default common;
