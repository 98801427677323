import { isMobile } from 'react-device-detect';

import colors from './colors';
import fonts from './fonts';

export const CONFIG = {
	colors: {
		primary: colors.primary,
		primaryLight: colors.primaryLight,
		primaryLighter: colors.primaryLighter,
		primaryDark: colors.primaryDark,
		primaryDarker: colors.primaryDarker,

		secondary: colors.secondary,
		secondaryLight: colors.secondaryLight,
		secondaryLighter: colors.secondaryLighter,
		secondaryDark: colors.secondaryDark,
		secondaryDarker: colors.secondaryDarker,

		tertiary: colors.tertiary,
		tertiaryLight: colors.tertiaryLight,
		tertiaryLighter: colors.tertiaryLighter,
		tertiaryDark: colors.tertiaryDark,
		tertiaryDarker: colors.tertiaryDarker,

		white: colors.white,
		greyLight: colors.greyLight,
		grey: colors.grey,
		greyDark: colors.greyDark,
		black: colors.black,

		danger: colors.danger,
		success: colors.success,

		loadingModalBackground: colors.primaryLighter
	},
	fonts: {
		light: fonts.PrimaryLight,
		regular: fonts.PrimaryRegular,
		bold: fonts.PrimaryBold
	},
	fontSizes: {
		small: isMobile ? '12px' : '14px',
		medium: isMobile ? '14px' : '16px',
		mediumLabel: isMobile ? '16px' : '20px',
		largeSpan: isMobile ? '20px' : '24px',
		large: isMobile ? '22px' : '32px'
	},
	callUsComercial: `91 155 35 20`,
	callUsAtencion: `91 590 97 12`
};
