import { isMobile } from 'react-device-detect';

import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const IbanWrapper = styled.div`
	width: 100%;
	justify-content: center;
	display: flex;
	align-items: center;
`;

export const IbanInput = styled.input`
	font-family: ${CONFIG.fonts.regular};
	font-size: ${isMobile ? '14px' : '20px'};
	text-align: center;
	border: 1px solid ${CONFIG.colors.tertiaryLighter};
	padding: 13px 5px;
	border-radius: 8px;
	min-width: ${isMobile ? '100%' : '400px'};
	letter-spacing: 1px;
`;

export const IbanLabelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 90%;
`;

export const InfoWrapper = styled.div`
	display: flex;
`;
