import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { BackendWrapper, NavigationWrapper, Page, SessionWrapper, Text, UiWrapper, View } from '@coinscrap/webapp-core';

import Header from 'layout/components/Header/Header';

import routes from 'config/routes';

export const Continue = () => {
	const { getInstanceApi } = BackendWrapper.use();
	const { goBack, navigateTo } = NavigationWrapper.use();
	const { startSession, clearSession } = SessionWrapper.use();
	const { useHeader, useLoading } = UiWrapper.use();
	const pageContext = Page.use();
	const [error, setError] = useState();
	const { query } = pageContext;

	useEffect(() => {
		clearSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const token = query?.t;
	useEffect(() => {
		if (token) {
			(async () => {
				let response;
				try {
					response = await getInstanceApi().loginUser(
						{
							uniqueIdentifier: token,
							source: 'CON'
						},
						'unique-identifier'
					);
					await startSession({ token: response.accessToken });

					localStorage.removeItem('B2Flow');

					navigateTo(routes.loadTarget, {
						clearItems: ['uniqueIdentifier', 't'],
						handleRedirection: true
					});
				} catch (e) {
					setError(e.status || true);
				}
			})();
		}

		// eslint-disable-next-line
	}, []);

	useLoading(undefined, { loadingMessage: undefined });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);

	return (
		<View fullHeightSt style={{ padding: '5vh 0 5vh 0' }}>
			<View style={{ width: '80%' }} vAlign="center">
				<View cardSt>
					<Text headingPrimarySt isB2B>
						{'Identificación'}
					</Text>
					{error && (
						<Text style={{ textAlign: 'center' }}>
							{error === 403
								? 'La clave de acceso ha caducado o ya ha sido utilizada. Inténtelo con una nueva.'
								: 'Datos de inicio de sesión incorrectos.'}
						</Text>
					)}
					{!error &&
						(token ? (
							<Text style={{ textAlign: 'center' }}>Estamos obteniendo tus datos de usuario, espere por favor.</Text>
						) : (
							<Text style={{ textAlign: 'center' }}>
								No se han encontrado datos de inicio de sesión. Asegurese que esta accediendo correctamente a la
								plataforma
							</Text>
						))}
				</View>
			</View>
		</View>
	);
};
