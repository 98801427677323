import React, { useContext, useEffect } from 'react';

import { Button, PickerHOC, Text, View } from '@coinscrap/webapp-core';

import { useFetchNormalAgentDash } from '../../../../libs/hooks/useFetchNormalAgentDash';
import { useReloadSimulation } from '../../../../libs/hooks/useReloadSimulation';
import { DashboardContext } from '../../contexts/Dashboard';
import { DashboardFiltersContext } from '../../contexts/DashboardFilters';
import * as S from '../../styles';
import { SimulationRow } from '../SimulationRow/SimulationRow';

export const NormalAgent = PickerHOC()(({ accessSimulation }) => {
	const { dashboard, setDataFetch, setDataValues, setPaginationValues, fetchNextData } = useContext(DashboardContext);

	const { dashboardFilters } = useContext(DashboardFiltersContext);
	const reloadSimulation = useReloadSimulation();

	// console.log('dashboard', dashboard);
	// console.log('dashboardFilters', dashboardFilters);

	// Llamada API / VINCULATIONS
	const {
		// eslint-disable-next-line no-unused-vars
		status: fetchVinculationsStatus,
		data: fetchedVinculations,
		error: fetchVinculationsError,
		isFetching: isFetchingVinculations
	} = useFetchNormalAgentDash(
		dashboard.data.fetch,
		dashboard.pagination.currentPage,
		dashboard.pagination.itemsPerPage,
		dashboardFilters.filters.status.value,
		dashboardFilters.filters.dates.value,
		dashboard.agent
	);

	useEffect(() => {
		// console.log('fetchVinculations', fetchVinculationsStatus);
		// console.log('isFetchingVinculations', isFetchingVinculations);
		if (!isFetchingVinculations) {
			// console.log('fetchedVinculations', fetchedVinculations);
			if (fetchVinculationsError || fetchedVinculations?.hasOwnProperty('error')) {
				// console.log('fetchVinculationsError', fetchVinculationsError);
				setDataFetch(false);
				setDataValues([]);
				setPaginationValues('hasMorePages', false);
			} else if (fetchedVinculations !== undefined) {
				if (dashboard.data.fetch) {
					const totalVinculations = dashboard.data.data.concat(fetchedVinculations);
					setDataValues(totalVinculations);
					setDataFetch(false);
				}

				if (fetchedVinculations.length === 0 || fetchedVinculations.length < dashboard.pagination.itemsPerPage) {
					setPaginationValues('hasMorePages', false);
				} else {
					if (fetchedVinculations.every((v) => v.filtered)) {
						fetchNextData();
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchedVinculations, dashboard.data.fetch, isFetchingVinculations]);

	return (
		<View
			style={{
				width: '95%'
			}}
			justifyStartSt
		>
			<S.TableRowHeading isGlobalAgent>
				<Text>Nombre</Text>
				<Text>Estado</Text>
				<Text>Solicitud </Text>
				<Text>Prima</Text>
				<Text>Capital</Text>
				<Text style={{ textAlign: 'center' }}>Fuente</Text>
				<Text style={{ textAlign: 'center' }}>Acciones</Text>
			</S.TableRowHeading>

			{dashboard.data.data.map((element) => {
				return (
					<>
						{element.filtered ? null : (
							<View agentTableRowSt key={element.simulation.id} isGlobalAgentTableRowSt={false}>
								<SimulationRow
									isGlobalAgent={false}
									apiInstance={element?.apiInstance}
									vinculation={element?.vinculation}
									simulation={element?.simulation}
									agent={element?.agent}
									client={element?.client}
									authRequests={element?.clientAuthRequests}
									dniDocuments={element?.clientDniDocuments}
									testCompletions={element?.clientTestCompletions}
									currentStep={element?.currentStep}
									onContinue={(simulationId) =>
										accessSimulation(element.vinculation.vinculationId, element.vinculation.userId, simulationId)
									}
									reloadSimulation={reloadSimulation}
								/>
							</View>
						)}
					</>
				);
			})}
			<Button style={{ marginBottom: 40 }} onClick={dashboard.pagination.hasMorePages ? () => fetchNextData() : false}>
				{dashboard.data.fetch
					? 'Cargando...'
					: dashboard.pagination.hasMorePages
					? 'Cargar más'
					: 'No hay más resultados'}
			</Button>
		</View>
	);
});
