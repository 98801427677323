import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { NavigationWrapper, PickerHOC, Text, UiWrapper } from '@coinscrap/webapp-core';

import arrowRigth from 'assets/images/svgs/arrowRigth.svg';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { SpanCallUs } from 'config/components/span';
import Header from 'layout/components/Header/Header';

import { Wrapper } from 'layout/Wrapper';

import * as S from './stylesB2C';

export const ProductCreationFinishedManual = PickerHOC(
	{},
	LoadingScreen
)(() => {
	const { goBack } = NavigationWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const [loading, setLoading] = useState();

	const next = async () => {
		let messageError = 'El proceso ha terminado, puedes cerrar la ventana.';
		setLoading(undefined);
		openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
			disableClickAway: true,
			closeButton: true,
			modalClass: isMobile ? 'modal-xl' : 'modal-main',
			key: 'modal-finish-manual'
		});
	};

	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);
	useLoading(loading, { loadingMessage: loading });

	return (
		<Wrapper width={'80%'}>
			<Text headingPrimarySt>Crearemos tu contrato en diferido</Text>
			<S.StepWrapper>
				<S.StepWrapperItem>
					<S.StepCircle>
						<img src={arrowRigth} alt={'arrow icon'} />
					</S.StepCircle>
					<Text manualContractSt>Recibirás un correo electrónico con toda la información sobre tu póliza.</Text>
				</S.StepWrapperItem>
				<S.StepWrapperItem>
					<S.StepCircle>
						<img src={arrowRigth} alt={'arrow icon'} />
					</S.StepCircle>
					<Text manualContractSt>
						Dispones de un plazo de 30 días naturales, a contar desde la fecha de contratación, para desistir del
						presente seguro de vida, en cuyo caso se te reintegrará el importe de la prima cobrada sin aplicación de
						penalización alguna. Para ello, puedes llamar al 915909738.
					</Text>
				</S.StepWrapperItem>
				<S.StepWrapperItem>
					<S.StepCircle>
						<img src={arrowRigth} alt={'arrow icon'} />
					</S.StepCircle>
					<Text manualContractSt>
						Recuerda que dándote de alta en el{' '}
						<SpanCallUs>
							<a href={'https://www.caser.es/ecliente/acceso'}>Área de Clientes de Caser Seguros</a>
						</SpanCallUs>
						, tendrás a tu disposición toda la documentación en cualquier momento.
					</Text>
				</S.StepWrapperItem>
			</S.StepWrapper>

			<BackNextButton
				onClickNext={next}
				nextText={'FINALIZAR'}
				isBackShown={false}
				isNextDisabled={false}
				justifyContent={'center'}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
