import React from 'react';

const DownloadIcon = ({ style, onClick }) => {
	return (
		<svg
			width="14"
			height="20"
			viewBox="0 0 14 20"
			fill={style.fill}
			xmlns="http://www.w3.org/2000/svg"
			style={{ ...style }}
			onClick={onClick}
		>
			<path d="M14 20H0V18H14V20ZM7 16L1 10L2.41 8.59L6 12.17V0H8V12.17L11.59 8.59L13 10L7 16Z" fill={style.fill} />
		</svg>
	);
};

export default DownloadIcon;
