import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const BottomTextWrapper = styled.div`
	display: flex;
	/* align-items: center; */
	width: 100%;
	align-items: flex-end;

	flex-grow: 1;
`;

export const BottomText = styled.p`
	font-size: 12px;
	text-align: center;
`;
export const BottomTextSpan = styled.span`
	font-size: 12px;
	color: ${CONFIG.colors.primary};
	font-weight: 700;
`;
export const Border = styled.div`
	height: 1px;
	background-color: #cbcaca;
	width: 90%;
`;
export const BottomBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const BottomBoxText = styled.p`
	font-size: 16px;
	margin: 10px 0px;
`;
export const LinksWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	margin-top: 20px;
`;

export const LinkCircle = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: ${CONFIG.colors.primary};
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const LinkIcon = styled.img`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	width: 24px;
	height: auto;
`;
export const FooterText = styled.p`
	margin-top: 20px;
	font-size: 14px;
	color: #5a6265;
	text-align: center;
`;
