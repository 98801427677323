import styled from 'styled-components/macro';

export const FormWContainer = styled.div`
	align-items: baseline;
	display: grid;
	gap: 24px;
	grid-template-columns: ${(props) => props.columns || '1fr 1fr 1fr'};
	width: 90%;
	@media (max-width: 450px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;
