import { isMobile } from 'react-device-detect';

import styled from 'styled-components/macro';

export const ModalWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 0 ${isMobile ? '5px' : '40px'} ${isMobile ? '20px' : '40px'} ${isMobile ? '5px' : '40px'};
`;
