import { isMobile } from 'react-device-detect';

import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const Input = styled.input`
	border: none;
	height: 100%;
	color: #333333;
	font-family: ${CONFIG.fonts.bold};
	font-size: ${isMobile ? '18px' : '26px'};
	font-weight: 700;
	text-align: center;
	::placeholder {
		color: #7a7a7a;
	}
	width: auto;
`;

export const InputWrapper = styled.div`
	border: 1px solid #b1b1b1;
	border-radius: 1px;
	height: ${isMobile ? '73px' : '70px'};
	margin: ${isMobile ? '18px 0px 32px 0px' : '25px 0px'};
	padding: 20px;
	width: ${isMobile ? '350px' : '422px'};
	display: flex;
	justify-content: center;
	align-items: center;
`;
