import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const TextPrimary = styled.div`
	color: ${(props) => props.mainColor || CONFIG.colors.primary};
	font-family: ${CONFIG.fonts.bold};
	font-size: ${(props) => props.size || '29px'};
	text-align: ${(props) => props.textAlign || 'left'};
	margin-bottom: ${(props) => props.marginBott || '0px'};
`;

export const TextSecondary = styled.div`
	color: ${(props) => props.mainColor || CONFIG.colors.dark};
	font-family: ${(props) => props.font || CONFIG.fonts.light};
	font-size: ${(props) => props.size || '15px'};
	text-align: ${(props) => props.textAlign || 'left'};
`;

export const LandingFeatures = styled.div`
  display: flex;
  justify-content: center;
  .container {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.6fr 0.6fr 2fr 2fr 2fr;
    gap: 10px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      'title-feature title-feature title-feature'
      'title-feature-secondary title-feature-secondary title-feature-secondary'
      'title-feature-articles title-feature-articles title-feature-articles'
      'title-feature-articles-secondary title-feature-articles-secondary title-feature-articles-secondary'
      'title-feature-articles-terciary title-feature-articles-terciary title-feature-articles-terciary';
    width: 85%;
  }

  .title-feature {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      'title-feature-explanation title-feature-explanation title-feature-explanation'
      '. . .';
    grid-area: title-feature;
  }

  .title-feature-explanation {
    grid-area: title-feature-explanation;
    max-width: 1150px;
  }

  .title-feature-secondary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      'title-feature-secondary-explanation title-feature-secondary-explanation title-feature-secondary-explanation'
      '. . .';
    grid-area: title-feature-secondary;
  }

  .title-feature-secondary-explanation {
    grid-area: title-feature-secondary-explanation;
    max-width: 1150px;
  }

  .title-feature-articles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1.4fr 1.2fr 1.8fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      'feature-paperless feature-documents feature-capital'
      'feature-paperless-description feature-documets-description feature-capital-description';
    grid-area: title-feature-articles;
  }

  .title-feature-flex{
    display: flex;
  }

  .feature-paperless {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-paperless-title feature-paperless-title feature-paperless-title';
    justify-items: center;
    justify-self: center;
    grid-area: feature-paperless;
  }

  .feature-paperless-title {
    grid-area: feature-paperless-title;
  }

  .feature-paperless-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-paperless-description;
    max-width: 350px;
  }

  .feature-documents {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-documents-title feature-documents-title feature-documents-title';
    justify-items: center;
    justify-self: center;
    grid-area: feature-documents;
  }

  .feature-documents-title {
    grid-area: feature-documents-title;
  }

  .feature-documets-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-documets-description;
    max-width: 350px;
  }

  .feature-capital {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-capital-title feature-capital-title feature-capital-title';
    justify-items: center;
    justify-self: center;
    grid-area: feature-capital;
  }

  .feature-capital-title {
    grid-area: feature-capital-title;
  }

  .feature-capital-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-capital-description;
    max-width: 350px;
  }

  .title-feature-articles-secondary {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.8fr 1.2fr 2.5fr 1.5fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      'title-feature-articles-secondary-description title-feature-articles-secondary-description title-feature-articles-secondary-description'
      'feature-decease feature-incapacity feature-repatriation'
      'feature-decease-description feature-incapacity-description feature-repatriation-description';
    grid-area: title-feature-articles-secondary;
  }

  .title-feature-articles-secondary-description {
    grid-area: title-feature-articles-secondary-description;
  }

  .feature-decease {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-decease-title feature-decease-title feature-decease-title';
    justify-items: center;
    justify-self: center;
    grid-area: feature-decease;
  }

  .feature-decease-title {
    grid-area: feature-decease-title;
  }

  .feature-incapacity {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-incapacity-title feature-incapacity-title feature-incapacity-title';
    justify-items: center;
    justify-self: center;
    grid-area: feature-incapacity;
  }

  .feature-incapacity-title {
    grid-area: feature-incapacity-title;
  }

  .feature-repatriation {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-repatriation-title feature-repatriation-title feature-repatriation-title';
    grid-area: feature-repatriation;
    justify-items: center;
    justify-self: center;
  }

  .feature-repatriation-title {
    grid-area: feature-repatriation-title;
  }

  .feature-decease-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-decease-description;
    max-width: 350px;
  }

  .feature-incapacity-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-incapacity-description;f
    max-width: 350px;
  }

  .feature-repatriation-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-repatriation-description;
    max-width: 350px;
  }

  .title-feature-articles-terciary {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1.1fr 1fr 2.5fr 0.7fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      'titles-feature-articles-terciary-description titles-feature-articles-terciary-description titles-feature-articles-terciary-description'
      'feature-home-family feature-heart-rate feature-home'
      'feature-home-family-description feature-heart-rate-description feature-home-description';
    grid-area: title-feature-articles-terciary;
  }

  .titles-feature-articles-terciary-description {
    grid-area: titles-feature-articles-terciary-description;
  }

  .feature-home-family {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      '. . .';
    justify-self: center;
    align-self: center;
    grid-area: feature-home-family;
  }

  .feature-heart-rate {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      '. . .';
    justify-self: center;
    align-self: center;
    grid-area: feature-heart-rate;
  }

  .feature-home {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      '. . .';
    justify-self: center;
    align-self: center;
    grid-area: feature-home;
  }

  .feature-home-family-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-home-family-description;
    max-width: 350px;
  }

  .feature-heart-rate-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-heart-rate-description;
    max-width: 350px;
  }

  .feature-home-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-home-description;
    max-width: 350px;
  }
`;

export const LandingCallToAction = styled.div`
	width: 85%;
	.container {
		display: grid;
		background-color: ${CONFIG.colors.primary};
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 0.5fr 0.5fr 1fr;
		gap: 0px 0px;
		grid-auto-flow: row;
		grid-template-areas:
			'call-to-action--title call-to-action--title'
			'call-to-action--description call-to-action--buttons'
			'call-to-action--call-us call-to-action--mail-us';
	}

	.call-to-action--title {
		grid-area: call-to-action--title;
	}

	.call-to-action--description {
		align-self: center;
		grid-area: call-to-action--description;
	}

	.call-to-action--buttons {
		grid-area: call-to-action--buttons;
		align-self: center;
	}

	.flex-container--buttons {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
	}

	.call-to-action--call-us {
		align-self: center;
		grid-area: call-to-action--call-us;
	}

	.call-to-action--mail-us {
		align-self: center;
		grid-area: call-to-action--mail-us;
	}

	.call-to-action--call-us__flex {
		display: flex;
		gap: 6px;
	}

	.call-to-action--mail-us__flex {
		display: flex;
		gap: 6px;
	}
`;

export const LandingFAQ = styled.div`
	align-items: flex-start;
	display: flex;
	justify-content: flex-start;
	margin-bottom: 30px;
	margin-top: 40px;
	width: 85%;
	.flex-faq {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		flex-flow: wrap;
		width: 100%;
		.flex-faq--title {
			align-self: auto;
			flex: 0 1 auto;
			margin-bottom: 14px;
			order: 0;
		}
		.flex-faq--list {
			align-self: auto;
			flex: 0 1 auto;
			order: 1;
		}
	}
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	li {
		display: block;
		background-image: url(https://svgshare.com/i/hfw.svg);
		background-repeat: no-repeat;
		background-position: left top;
		height: 48px;
		padding-left: 44px;
		padding-top: 5px;
	}
`;

export const LandingReview = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 70px;
	margin-top: 40px;
	width: 85%;
`;

export const AllReviewsWrapper = styled.div`
	align-content: center;
	align-items: center;
	display: flex;
	gap: 5px;
	margin-bottom: 20px;
`;

export const ButtonToAction = styled.button`
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 15px 12px 30px;
	gap: 20px;

	width: 198px;
	height: 39px;

	background: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;

	flex: none;
	order: 0;
	flex-grow: 0;

	cursor: pointer;
`;

export const ArrowIcon = styled.img`
	height: 16px;
	width: auto;
	color: ${CONFIG.colors.primary};
`;

export const Wrapper = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const InputSingleRow = styled.div`
	width: 100%;
	display: grid;

	grid-template-columns: repeat(1, 1fr);
	margin-bottom: 35px;
`;

export const SubmitButton = styled.button`
	font-size: 14px;
	color: ${CONFIG.colors.primary};
	text-align: center;
	padding: 10px 15px;
	border-radius: 45px;
	outline: none;
	border: 2px solid ${CONFIG.colors.primary};
	background-color: #f7f3f8;

	:focus {
		outline: none;
	}
`;
export const TableButton = styled.button`
	font-size: 16px;
	color: ${CONFIG.colors.primary};
	text-align: center;
	padding: 7px 3px;
	border-radius: 45px;
	outline: none;
	border: 2px solid ${CONFIG.colors.primary};
	background-color: #f7f3f8;

	:focus {
		outline: none;
	}
`;
export const TableRowFilters = styled.div`
	display: grid;
	grid-template-columns: ${(props) =>
		props?.isGlobalAgent ? 'repeat(7, minmax(0, 1fr))' : 'repeat(6, minmax(0, 1fr))'};
	gap: 20px;
	padding: 20px 0px 20px 50px;
	width: 100%;
	align-items: center;
	margin-bottom: 30px;
`;
export const TableRowHeading = styled.div`
	display: grid;
	grid-template-columns: ${(props) =>
		props?.isGlobalAgent
			? 'repeat(2, minmax(0, 1fr)) 175px 175px 150px minmax(0, .5fr) 100px minmax(0, 1fr)'
			: 'repeat(2, minmax(0, 1fr)) 175px 175px 150px 100px minmax(0, 1fr)'};
	gap: 20px;
	padding: 20px 50px;
	width: 100%;
	align-items: center;
`;

export const FilterWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	.MuiSelect-select {
		width: calc(100% - 30px);
	}

	.Mui-focused {
		border-bottom: 1px solid ${CONFIG.colors.primary};
	}
	.MuiInputBase-root {
		width: calc(100% - 30px);
		height: 25px;
		border-bottom: 1px solid ${CONFIG.colors.black};

		&::before {
			border-bottom: 1px solid ${CONFIG.colors.primary} !important;
		}

		&:hover {
			&::before {
				border-bottom: 1px solid ${CONFIG.colors.primary} !important;
			}
		}
	}
`;
export const DNISearch = styled.input`
	outline: none;
	border: none;
	border-bottom: 1px solid #30353f;
	height: 25px;
	font-size: 13px;
	color: rgb(122, 122, 122);
	width: calc(100% - 30px);

	&::placeholder {
		opacity: 0.5;
	}
`;
