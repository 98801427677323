import { UiWrapper } from '@coinscrap/webapp-core';

import { CONFIG } from 'config/config';

import commonStyles from './common';

UiWrapper.defineComponent('enfatize', {
	styles: {
		default: {
			color: CONFIG.colors.black,
			fontFamily: CONFIG.fonts.bold
		},

		...commonStyles.styles
	}
});
