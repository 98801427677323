import React, { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CloseIcon from '@material-ui/icons/Close';

import {
    InputCheck,
    NavigationWrapper,
    PickerHOC,
    TargetsWrapper,
    Text,
    UiWrapper,
    UserWrapper,
    View
} from '@coinscrap/webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { Faq } from 'components/FAQ/Faq';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { SpanBold } from 'config/components/span';
import Header from 'layout/components/Header/Header';

import { CONFIG } from 'config/config';
import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';
import { cleanIBANNumber, isValidIBANNumber } from 'libs/Iban';

import * as S from './styles';

export const BankAccount = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id'
			}
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user'
		}
	},
	LoadingScreen
)(() => {
	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const [loading, setLoading] = useState();

	//IBAN FORMAT
	function formatBankAccountNumber(accountNumber) {
		const cleanNumber = accountNumber.replace(/\s+/g, '');
		let newFormat = '';

		const code = cleanNumber.substr(0, 2);
		if (!/^[A-Za-z]+$/.test(code)) {
			return '';
		}

		for (let i = 0; i < cleanNumber.length; i++) {
			if (i === 4 || i === 8 || i === 12 || i === 14) {
				newFormat += ' ';
			}
			if (i >= 2) {
				const character = cleanNumber[i];
				if (/[0-9]/.test(character)) {
					newFormat += character;
				} else {
					return (newFormat += '');
				}
			} else {
				newFormat += cleanNumber[i];
			}
		}
		return newFormat;
	}

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	const pickerValues = PickerHOC.use();
	const { target } = pickerValues;
	const creationData = target?.targetProperties?.find((x) => x.name === 'creationData')?.value;

	// iban inputs
	const [isDeclared, setIsDeclared] = useState(false);
	const [ibanValue, setIbanValue] = useState(creationData?.chargeAccount || '');
	const [ibanError, setIbanError] = useState(false);

	const isDisabled = useCallback(() => {
		return !isDeclared || isValidIBANNumber(ibanValue) !== 1;
	}, [isDeclared, ibanValue]);

	// const fullIban = '' + first + second + third + fourth + fifth;
	const fullIban = ibanValue.replace(/\s+/g, '');

	// NAVIGATION
	const back = async () => {
		goBack();
	};

	const next = async () => {
		setLoading('Actualizando...');
		await saveTargetProperty(target.id, 'creationData', {
			...creationData,
			chargeAccount: fullIban
		});
		navigateTo(routes.identityBiometricUpsert);
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);

	return (
		<Wrapper width={'80%'}>
			<HorizontalProgressBar
				step={4}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Datos personales' : ''}
			/>
			<Text headingPrimarySt>Cuenta bancaria</Text>
			<View bannerBankAccountSt>Esta va a ser la cuenta de cargo asociada a tu seguro de vida</View>
			<S.IbanWrapper>
				<S.IbanLabelWrapper>
					<Text labelInputSt>Número de cuenta</Text>
					<View rowSt>
						<S.IbanInput
							label={'Numero de cuenta'}
							maxLength={28}
							placeholder="ESXX XXXX XXXX XX XXXXXXXXXX"
							value={formatBankAccountNumber(ibanValue || '')}
							onChange={(e) => {
								if (ibanError) {
									setIbanError(false);
								}
								setIbanValue(cleanIBANNumber(e.target.value.toUpperCase()));
							}}
							onBlur={() => {
								const validateIban = isValidIBANNumber(ibanValue);
								setIbanError(validateIban !== 1);
							}}
						/>
						<CloseIcon
							onClick={() => {
								setIbanError(false);
								setIbanValue('');
							}}
							style={{
								marginLeft: 5,
								color: CONFIG.colors.primary,
								cursor: 'pointer',
								visibility: ibanValue ? 'visible' : 'hidden'
							}}
						/>
					</View>
				</S.IbanLabelWrapper>
			</S.IbanWrapper>

			<S.InfoWrapper style={{ marginTop: 15, marginBottom: 15 }}>
				<Text>Introduce manualmente el nº de cuenta</Text>
			</S.InfoWrapper>
			{ibanError && <Text dangerSt>Por favor introduce un IBAN válido</Text>}
			<View conditionsWrapperSt onClick={() => setIsDeclared((prevState) => !prevState)}>
				<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={isDeclared} />
				<Text marginLefConditiontSt>
					<SpanBold>Declaro </SpanBold> que la cuenta introducida es de mi titularidad
				</Text>
			</View>
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				isNextDisabled={isDisabled()}
				marginBottom={40}
				marginTop={40}
			/>
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
