import styled from 'styled-components/macro';

export const ButtonsWrapper = styled.div`
	display: grid;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	color: rgb(32, 37, 43);
	box-sizing: border-box;
	font-family: Lato-Regular;
	margin-bottom: 8px;
	gap: 25px 20px;
	grid-template-columns: repeat(2, minmax(0px, 1fr));
	margin-top: 40px;
`;
