import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { EnvelopesWrapper, NavigationWrapper, PickerHOC, TargetsWrapper, Text, UiWrapper } from '@coinscrap/webapp-core';

import { DashedLines } from 'components/DashedLines/DashedLines';
import { DocuSign } from 'components/DocuSign/DocuSign';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { Faq } from 'components/FAQ/Faq';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import Header from 'layout/components/Header/Header';

import routes from 'config/routes';
import { Wrapper } from 'layout/Wrapper';

/* eslint-disable no-console */
// eslint-disable-next-line react/prop-types
export const SignatureContract = PickerHOC({
	target: {
		arrayItemMatcher: {
			type: 'param',
			paramName: 'targetId',
			itemPropertyPath: 'id'
		},
		sourcePath: 'targets',
		sourceWrapper: TargetsWrapper
	},
	envelope: {
		sourcePath: 'envelopes',
		sourceWrapper: EnvelopesWrapper,
		arrayItemMatcher: {
			type: 'param',
			paramName: 'envelopeId',
			itemPropertyPath: 'id'
		}
	}
})((props) => {
	const { refresh, getSignUrl } = EnvelopesWrapper.use();
	const [docuSignUrl, setDocuSignUrl] = useState(null);
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { loadTargets } = TargetsWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { target, envelope } = PickerHOC.use();

	const [loading, setLoading] = useState();
	const [finished, setFinished] = useState(false);

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);
	useLoading(!docuSignUrl || loading, loading && { loadingMessage: loading });

	// TARGET CREATION DATA
	const creationData = useMemo(() => target?.targetProperties?.find((x) => x.name === 'creationData')?.value, [target]);

	// NAVIGATION
	const next = async () => {
		console.log('envelope?.signStatus', envelope?.signStatus);
		console.log('target.status', target.status);

		envelope?.signStatus === 'SIGNED' && target.status === 'ACTIVE'
			? isB2B
				? console.info('🚀 NAVIGATING TO productCreationFinishedB2B')
				: console.info('🚀 NAVIGATING TO productCreationFinishedB2C')
			: console.info('🚀 NAVIGATING TO cannotConfirm');

		envelope?.signStatus === 'SIGNED' && target.status === 'ACTIVE'
			? isB2B
				? navigateTo(routes.productCreationFinishedB2B)
				: navigateTo(routes.productCreationFinishedB2C)
			: navigateTo(routes.cannotConfirm, {
					queryParams: {
						fromReason: 'SIGN_CONTRACT',
						fromView: 'signatureContract'
					}
			  });
	};

	// EFFECTS
	useEffect(() => {
		console.log('📌 target', target);
		console.log('📌 creationData', creationData);
		console.log('📌 envelope', envelope);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (finished) {
			next();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished]);

	useEffect(() => {
		console.log('🧿 envelope', envelope);
		const signUrl = getSignUrl(envelope.id);
		setDocuSignUrl(signUrl);
		console.log('🧿 SIGN URL', signUrl);
	}, [envelope, getSignUrl]);

	return docuSignUrl ? (
		<Wrapper width={'1000px'}>
			<HorizontalProgressBar step={6} total={6} styleWrapper={{ maxWidth: 450 }} />
			<Text headingPrimarySt isB2B>
				A solo una firma
			</Text>

			{docuSignUrl && (
				<DocuSign
					url={docuSignUrl}
					onProcessFinished={async () => {
						setLoading('Actualizando Datos');
						try {
							await refresh();
						} catch (e) {
							console.error('ERROR', e);
							let messageError = e?.message?.toString();
							messageError = 'Ha ocurrido un error temporal. Reinicie el proceso.';
							setLoading(undefined);
							openModal(
								() => (
									<>
										<ErrorModal>{messageError}</ErrorModal>
									</>
								),
								{
									disableClickAway: true,
									closeButton: true,
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-signatureContractNext'
								}
							);
						}
						try {
							await loadTargets([target.id]);
						} catch (e) {}
						setLoading(undefined);
						setFinished(true);
					}}
				/>
			)}
			<DashedLines />
			<Faq />
		</Wrapper>
	) : null;
});
